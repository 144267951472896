import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const CommonStatus = ({ status, statusArray }) => {
  const statusEntry = statusArray.find((entry) => entry.code === status);
  const statusColor = statusEntry?.color || 'grey';

  return (
    <Typography style={{ color: statusColor }}>
      {statusEntry?.label || statusEntry?.id || status}
    </Typography>
  );
};

CommonStatus.propTypes = {
  status: PropTypes.string.isRequired,
  statusArray: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

export default CommonStatus;
