import PropTypes from 'prop-types';
import { Card, CardHeader } from '@mui/material';
import { SurveyItemsTable } from './survey-item-table';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { useMounted } from '../../../hooks/use-mounted';
import { goalSurveyQuestiongApi } from '../../../api/goal/survey/survey-question';
import { useSelection } from '../../../hooks/use-selection';
import GoalButton from '../../goal-button';
import ConfirmIcon from '@mui/icons-material/Check';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { useApp } from '../../../hooks/use-app';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState } from 'react';

export const SurveyQuestionSelector = ({ survey, onAfterSave }) => {
  const mounted = useMounted();
  const { showNotify, showError, currentCompany } = useApp();

  const selectQuestions = survey.selectQuestions ?? [];
  const [data, setData] = useState([]);
  const [selectedItems, handleSelect, handleSelectAll, handleClearSelected, selectionEvents] =
    useSelection();

  const handleAfterLoad = (result) => {
    if (selectQuestions.length > 0) {
      const selectedIds = selectQuestions.map((item) => item._id);
      const selectedData = result.documents.filter((item) => !selectedIds.includes(item._id));
      selectionEvents.setRows(selectedData);
      setData(selectedData);
    } else {
      selectionEvents.setRows(result.documents);
      setData(result.documents);
    }
  };
  const handleFilterSelected = (row) => {
    return !selectedItems.some((exclusionItem) => exclusionItem.id === row.id);
  };

  const [surveyQuestionsState, handleRefresh, , , , , , , , , events] = useDataBrowser({
    sourceApi: goalSurveyQuestiongApi.getSurveyQuestionsList,
    loadingMessage: 'Cargando preguntas...',
    mounted,
    filterRow: handleFilterSelected,
    onPostRefresh: handleAfterLoad
  });

  if (surveyQuestionsState.isLoading) {
    return events.renderStatus();
  }

  const addQuestions = async (idsToAdd) => {
    const response = await goalCustomeSurveyApi.addLinesFromSurvey({
      id: survey._id,
      companyId: currentCompany,
      lines: idsToAdd
    });
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      onAfterSave?.(response);
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const handleConfirmSelection = async () => {
    await addQuestions(selectedItems);
  };

  return (
    <Card>
      <CardHeader
        title="Seleccionar preguntas"
        sx={{ minWidth: 800 }}
        action={
          <>
            <GoalButton
              color="primary"
              onClick={handleConfirmSelection}
              startIcon={<ConfirmIcon />}
              disabled={selectedItems.length === 0}
              sx={{ marginRight: 1 }}
            />
            <GoalButton color="primary" onClick={handleRefresh} startIcon={<RefreshIcon />} />
          </>
        }
      />
      <SurveyItemsTable
        data={surveyQuestionsState.isLoading ? [] : data}
        disabled={surveyQuestionsState.isLoading}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        handleClearSelected={handleClearSelected}
      />
    </Card>
  );
};

SurveyQuestionSelector.propTypes = {
  survey: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onAfterSave: PropTypes.func
};
