import { dataLoader } from '../../data-loader';

const endPointLocal = '/automations/sync-object';

class SyncObjectsApi {
  async getSyncObjects({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}`,
      params: { companyId: companyId }
    });
    return response;
  }

  async getSyncObject({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}/${id}`,
      params: { companyId: companyId }
    });
    return response;
  }

  async createSyncObject({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endPointLocal}`,
      changes: values
    });
    return response;
  }

  async updateSyncObject({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `${endPointLocal}/${id}`,
      changes
    });
    return response;
  }

  async duplicateSyncObject({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endPointLocal}/duplicate`,
      changes: values
    });
    return response;
  }
}

export const syncObjectsApi = new SyncObjectsApi();
