import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalMaintenancePayroll {
  async getEmployeePositionsApi(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, id } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/payroll/master/employee-positions?companyId=${id}`,
      cacheItem: 'employee-positions',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        view,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  async getChildrenEmployeePosition({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `payroll/master/employee-positions/${id}/children?companyId=${companyId}`
    });
    return response;
  }

  async getEmployeePositionsList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `payroll/master/employee-positions?companyId=${companyId}`
    });
    return response;
  }

  async postEmployeePositionsForm({ values, companyId }) {
    const response = await dataLoader.loadGoalPost({
      action: `/payroll/master/employee-positions?companyId=${companyId}`,
      changes: values
    });
    return response;
  }

  async updateEmployeePositionsForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/payroll/master/employee-positions/${id}`,
      changes: data
    });
    return response;
  }

  async getEmployeePositionsConfig(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/payroll/master/employee-positions/config?companyId=${companyId}`
    });
    return response;
  }

  async deleteEmployeePositionsId({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/payroll/master/employee-positions/${id}?companyId=${companyId}`
    });
    return response;
  }

  // *****Departaments*************
  async getDepartamentsApi(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, id } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/payroll/master/departaments?companyId=${id}`,
      cacheItem: 'departaments',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        view,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  async getDepartamentsList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `payroll/master/departaments?companyId=${companyId}`
    });
    return response;
  }

  async getDepartamentsContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `payroll/master/departaments/${id}/departaments?companyId=${companyId}`
    });
    return response;
  }

  async postDepartamentsForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/payroll/master/departaments',
      changes: values
    });
    return response;
  }

  async deleteDepartamentsId({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/payroll/master/departaments/${id}?companyId=${companyId}`
    });
    return response;
  }

  async updateDepartamentsForm({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/payroll/master/departaments/${id}`,
      changes: changes
    });
    return response;
  }

  async getDepartamentsConfig(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/payroll/master/departaments/config?companyId=${companyId}`
    });
    return response;
  }
}

export const goalMaintenancePayrollApi = new GoalMaintenancePayroll();
