import { Helmet } from 'react-helmet-async';
import { Box, Card, CardHeader, Container } from '@mui/material';
import { useMounted } from '../../hooks/use-mounted';
import { useApp } from '../../hooks/use-app';
import GadminActions from '../../contexts/gadmin-actions';
import { goalImporterApi } from '../../api/goal/importer/goal-importer-api';
import { AddButton } from '../../components/common/buttons/add-button';
import { RefreshButton } from '../../components/common/buttons/refresh-button';
import { useGMDataSet } from '../../components/gm/dataset/use-gm-dataset';
import { GMGrid } from '../../components/gm/grid/gm-grid';

const columns = [
  {
    field: 'name',
    headerName: 'Nombre',
    link: {
      to: (item) => `/managment/import/${item.id}`,
      tooltip: 'Ver detalle de la plantilla'
    }
  },
  {
    field: 'path',
    headerName: 'Ruta',
    width: 250
  },
  {
    field: 'title',
    headerName: 'Título'
  },
  {
    field: 'require_company',
    headerName: 'Requiere empresa',
    type: 'boolean',
    width: 200
  },
  {
    field: 'updated_at',
    headerName: 'Actualizado'
  },
  {
    field: 'template_has_comment',
    headerName: 'Comentario en plantilla',
    type: 'boolean',
    width: 200
  }
];

export const ImportContainer = () => {
  const mounted = useMounted();
  const { title, currentCompany } = useApp();

  const { handleRefresh, ...returnsState } = useGMDataSet({
    sourceApi: goalImporterApi.getManagerTemplateList,
    apiParameters: { companyId: currentCompany },

    loadingMessage: 'Buscando...',
    mounted,
    columns
  });

  return (
    <>
      <Helmet>
        <title>{title('Importar')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <CardHeader
              title="Importar"
              action={
                <>
                  <AddButton
                    permission={GadminActions.G2_TEMPLATE_ADD}
                    to={'/managment/import/new'}
                  />
                  <RefreshButton onClick={handleRefresh} />
                </>
              }
            />

            <GMGrid {...returnsState} />
          </Card>
        </Container>
      </Box>
    </>
  );
};
