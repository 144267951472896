import { useApp } from './use-app';
import { compareRecordChanged, isEmpty } from './use-item-change';
import { useCallback } from 'react';

export const useFormSaver = ({
  originalData,
  apiUpdateHandler,
  id = 'id',
  handleAfterSave = null,
  apiAddHandler = null,
  hashField = null,
  includeNewValues = false,
  mandatoryFields = [],
  companyId = null,
  apiDeleteHandler = null
}) => {
  const { showNotify, showError } = useApp();

  const checkBeforeUpdate = useCallback(
    (values, helpers) => {
      if (!apiUpdateHandler)
        throw new Error('No se ha implementado la función para actualizar registros ');
      if (!values) throw new Error('No hay Valores cambiados para actualizar el registro ');
      if (!helpers) throw new Error('No se encontraron los helpers de Form');
    },
    [apiUpdateHandler]
  );

  const checkBeforeAdd = useCallback(
    (values, helpers) => {
      if (!apiAddHandler)
        throw new Error('No se ha implementado la función para agregar registros ');
      if (!values) throw new Error('No hay Valores cambiados para agregar el registro ');
      if (!helpers) throw new Error('No se encontraron los helpers de Form');
    },
    [apiAddHandler]
  );

  const checkBeforeDelete = useCallback(
    (id) => {
      if (!apiDeleteHandler)
        throw new Error('No se ha implementado la función para eliminar registros ');
      if (!id) throw new Error('No se ha proporcionado un ID para eliminar el registro ');
    },
    [apiDeleteHandler]
  );

  const handleUpdate = useCallback(
    async ({ values, helpers }) => {
      let saved = { success: false };
      try {
        checkBeforeUpdate(values, helpers);
        const changes = compareRecordChanged(originalData, values, includeNewValues);

        // console.log('changes', changes);
        if (!isEmpty(changes)) {
          // add each mandatory field to the changes object
          mandatoryFields.forEach((field) => {
            if (values[field]) {
              changes[field] = originalData[field];
            }
          });
          helpers?.setSubmitting(true);
          // console.log(changes, 'useFormik');
          const response = await apiUpdateHandler({
            id: originalData[id],
            changes: { ...changes, hashValue: originalData[hashField] },
            companyId
          });
          if (response.success) {
            showNotify(response.message || `Registro actualizado`);
            saved = { ...saved, success: true };
          } else {
            showError(response?.message || `No se procesaron `);
            saved = { ...saved, error: response.message };
          }
        } else {
          // throw new Error('No se detectaron cambios');
          saved = { ...saved, error: 'No hay cambios detectados' };
          showError(`${saved.error}`);
        }
      } catch (error) {
        showError(`${error}. No se procesaron `);
        // console.error(error);
        saved = { ...saved, error };
      }
      helpers?.setStatus({ success: saved.success });
      if (!saved.success) {
        console.error(saved.error);
        helpers?.setStatus({ success: false });
        helpers?.setErrors({ submit: saved.error });
      } else {
        handleAfterSave?.(saved);
      }

      helpers?.setSubmitting(false);

      return saved;
    },
    [originalData, includeNewValues, mandatoryFields, apiUpdateHandler, id, hashField, companyId]
  );

  const handleAdd = useCallback(
    async ({ values, helpers }) => {
      let saved = { success: false };
      try {
        checkBeforeAdd(values, helpers);
        helpers?.setSubmitting(true);
        const response = await apiAddHandler({ values, companyId });
        if (response.success) {
          showNotify(response.message);
          saved = response;
        } else {
          showError(response?.message || `No se agregó el registro`);
          saved = { ...saved, error: response.message };
        }
      } catch (error) {
        saved = { ...saved, error };
      }
      helpers?.setStatus({ success: saved.success });
      if (!saved.success) {
        console.error(saved.error);
        helpers?.setStatus({ success: false });
        helpers?.setErrors({ submit: saved.error });
      } else {
        handleAfterSave?.(saved);
      }

      helpers?.setSubmitting(false);
      return saved;
    },
    [apiAddHandler, showNotify, showError, handleAfterSave, companyId]
  );

  const handleDelete = useCallback(
    async (id) => {
      let deleted = { success: false };
      try {
        checkBeforeDelete(id);
        const response = await apiDeleteHandler({ id, companyId });
        if (response.success) {
          showNotify(response.message);
          deleted = { ...deleted, success: true };
        } else {
          showError(response?.message || `No se eliminó el registro`);
          deleted = { ...deleted, error: response.message };
        }
      } catch (error) {
        showError(`${error}. No se eliminó el registro`);
        deleted = { ...deleted, error };
      }
      return deleted;
    },
    [apiDeleteHandler, showNotify, showError, companyId]
  );

  return [handleUpdate, handleAdd, handleDelete];
};
