import PropTypes from 'prop-types';
import { Card, CardHeader, ButtonGroup, Button, TextField, Grid2 } from '@mui/material';
import { useState } from 'react';

const SellerInformationEdit = ({ data, onClosed, onAfterSave }) => {
  const [formData, setFormData] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSave = () => {
    // Implement save logic here
    onAfterSave();
  };

  return (
    <Card sx={{ padding: 2 }}>
      <CardHeader
        title="Editar Información del Vendedor"
        action={
          <ButtonGroup>
            <Button onClick={handleSave}>Guardar</Button>
            <Button onClick={onClosed}>Cancelar</Button>
          </ButtonGroup>
        }
      />
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="ID"
            name="id"
            value={formData.id}
            onChange={handleChange}
            disabled
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="ID de la Empresa"
            name="company_id"
            value={formData.company_id}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="Nombre de la Empresa"
            name="company_name"
            value={formData.company?.name}
            onChange={handleChange}
            disabled
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="Nombre Corto de la Empresa"
            name="company_short_name"
            value={formData.company?.short_name}
            onChange={handleChange}
            disabled
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="ID Remoto"
            name="remote_id"
            value={formData.remote_id}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="Móvil"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <TextField
            fullWidth
            label="Nombre de Usuario"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
};

SellerInformationEdit.propTypes = {
  data: PropTypes.object.isRequired,
  onClosed: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func.isRequired
};

export default SellerInformationEdit;
