import { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import { Box, Button } from '@mui/material';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polygon,
  OverlayView,
  OverlayViewF
} from '@react-google-maps/api';
import { InfoMapComponent } from '../info-map/info-map-component';
import { capitalizeParagraph } from '../../../hooks/use-split';
import { googleMapsConfig } from '../../../config';

export const PresaleMonitoringMap = ({
  locationState,
  documentsProp = [],
  customerProps = [],
  polygonsProps = [],
  zoomMap = 17,
  hidePolygon,
  hideCustomers,
  hideSellers
}) => {
  // console.log('documentsProp', documentsProp);

  const { x, y } = locationState;
  const containerStyle = {
    width: 'auto',
    height: '100%',
    minHeight: '900px'
  };

  const center = { lat: parseFloat(x), lng: parseFloat(y) };
  const [initialCenter, setInitialCenter] = useState(center);
  const url = `${googleMapsConfig.assetUrl}IconMap/`;
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(zoomMap);
  const [selectedPolygonData, setSelectedPolygonData] = useState(null);
  const [markers, setMarkers] = useState([]);

  const mapOptions = {
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ]
  };
  const [map, setMap] = useState(null);

  const mapRef = useRef();
  const polygonRefs = useRef([]);

  useEffect(() => {
    setInitialCenter(center);
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      const center = new window.google.maps.LatLng(parseFloat(x), parseFloat(y));
      mapRef.current.panTo(center);
      mapRef.current.setZoom(zoomMap);
    }
  }, [x, y, zoomMap]);

  useEffect(() => {
    const newMarkers = customerProps?.map((item) => ({
      lat: item.refy,
      lng: item.refx,
      ...item
    }));
    setMarkers(newMarkers);
  }, [customerProps]);

  const getPolygonCenter = (paths) => {
    let lat = 0;
    let lng = 0;
    paths.forEach((path) => {
      lat += path.lat;
      lng += path.lng;
    });
    lat /= paths.length;
    lng /= paths.length;
    return { lat, lng };
  };

  return (
    <LoadScript googleMapsApiKey={googleMapsConfig.apiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialCenter}
        zoom={zoomMap}
        options={mapOptions}
        onLoad={(map) => {
          mapRef.current = map;
          setMap(map);
        }}
        onZoomChanged={() => {
          setCurrentZoom(map?.getZoom());
        }}
      >
        {hideCustomers &&
          markers?.map((location, index) => {
            const position = {
              lng: parseFloat(location.refx),
              lat: parseFloat(location.refy)
            };
            // const colorIcon = location.documentStatus === 'O' ? 'blue' : 'red';
            const urlIcon = `${url}icon-${location.icon}.svg`;
            return (
              <Marker
                key={index + 100}
                position={position}
                title={location.CardName}
                animation={2}
                icon={urlIcon}
                options={{}}
                onClick={() => {
                  setSelectedLocation(location);
                }}
              />
            );
          })}
        {hideSellers &&
          documentsProp?.map((location, index) => {
            const position = {
              lng: parseFloat(location.longitud),
              lat: parseFloat(location.latitud)
            };
            const urlIcon = `${url}icon-blue-person.png`;
            return (
              <Marker
                key={`${index} - ${location.name}`}
                position={position}
                title={location.name}
                animation={2}
                icon={urlIcon}
                options={{}}
                onClick={() => {
                  setSelectedLocation(location);
                }}
              />
            );
          })}
        {hidePolygon &&
          polygonsProps?.map((polygonData, index) => {
            const geometry = polygonData.polygon;
            const isSelected = selectedPolygonData?.code == polygonData.code;
            let paths;

            if (geometry.type === 'Polygon') {
              paths = geometry.coordinates[0].map((coord) => {
                return { lat: coord[1], lng: coord[0] };
              });
            } else if (geometry.type === 'MultiPolygon') {
              paths = geometry.coordinates[0][0].map((coord) => {
                return { lat: coord[1], lng: coord[0] };
              });
            }
            if (paths && paths.length > 0) {
              const polygonCenter = getPolygonCenter(paths);
              return (
                <>
                  <Polygon
                    key={polygonData.code}
                    paths={paths}
                    options={{
                      fillColor: polygonData.color,
                      fillOpacity: 0.6,
                      strokeColor: isSelected ? '#19e34f' : polygonData.color,
                      strokeOpacity: isSelected ? 1 : 0.6,
                      strokeWeight: isSelected ? 5 : 2,
                      editable: false
                    }}
                    onLoad={(polygon) => {
                      polygonRefs.current[index] = polygon;
                    }}
                    onClick={() => {
                      const polygon = polygonRefs.current[index];
                      if (polygon) {
                        setSelectedPolygon(polygon);
                        setSelectedPolygonData(polygonData);
                        // console.log('polygon', polygon);
                        // polygon.setEditable(true);
                      }
                    }}
                    // onMouseUp={() => {
                    //   const polygon = polygonRefs.current[index];
                    //   if (polygon) {
                    //     const path = polygon.getPath();
                    //     const newPaths = path.getArray().map((latLng) => {
                    //       return { lat: latLng.lat(), lng: latLng.lng() };
                    //     });
                    //     console.log(newPaths);
                    //   }
                    // }}
                  >
                    <OverlayViewF
                      position={getPolygonCenter(paths)}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div
                        style={{
                          backgroundColor: 'red'
                        }}
                      >
                        <h1>{polygonData.label} </h1>
                      </div>
                    </OverlayViewF>
                  </Polygon>
                  {currentZoom > 13 ? (
                    <OverlayView position={polygonCenter} mapPaneName={OverlayView.MARKER_LAYER}>
                      <div
                        style={{
                          background: `transparent`,
                          border: `0px solid #ccc`,
                          padding: 12,
                          borderRadius: `50%`
                        }}
                      >
                        <div
                          style={{ fontSize: 22, color: `#fff`, fontWeight: `bold`, padding: 4 }}
                        >
                          {capitalizeParagraph(polygonData.label)}
                        </div>
                      </div>
                    </OverlayView>
                  ) : null}
                </>
              );
            } else {
              console.error('No valid coordinates for polygon:', polygonData.code);
              return null;
            }
          })}
        {selectedPolygon && (
          <InfoWindow
            position={{
              lat: parseFloat(selectedPolygon.getPath().getAt(0).lat()),
              lng: parseFloat(selectedPolygon.getPath().getAt(0).lng())
            }}
            onCloseClick={() => {
              // selectedPolygon.setEditable(false);
              // setSelectedPolygon(null);
            }}
            options={{ pixelOffset: new window.google.maps.Size(-40, -140) }}
          >
            <Box>
              <Button
                color="primary"
                // onClick={() => {
                //   selectedPolygon.setEditable(false);
                //   setSelectedPolygon(null);
                // }}
                onClick={() => {
                  selectedPolygon.setEditable(true);
                }}
              >
                {/* Activar edición */}
                Ruta: {selectedPolygonData?.label}
              </Button>
            </Box>
          </InfoWindow>
        )}
        {selectedLocation && (
          <InfoWindow
            position={
              selectedLocation.name
                ? {
                    lat: parseFloat(selectedLocation.latitud),
                    lng: parseFloat(selectedLocation.longitud)
                  }
                : {
                    lat: parseFloat(selectedLocation.refy),
                    lng: parseFloat(selectedLocation.refx)
                  }
            }
            onCloseClick={() => {
              setSelectedLocation(null);
            }}
            options={{ pixelOffset: new window.google.maps.Size(0, -35) }}
          >
            <InfoMapComponent selectedLocation={selectedLocation} />
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

PresaleMonitoringMap.propTypes = {
  locationState: Proptypes.object,
  documentsProp: Proptypes.array,
  customerProps: Proptypes.array,
  polygonsProps: Proptypes.array,
  zoomMap: Proptypes.number,
  hidePolygon: Proptypes.bool,
  hideCustomers: Proptypes.bool,
  hideSellers: Proptypes.bool
};
