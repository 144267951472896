import { apiBaseClient } from '../../config';
import { backendService } from '../../services/backend-service';
import getClientInformation from '../../services/client-info';

const goalLogin = async ({ token, ssid }) => {
  const clientInfo = getClientInformation();
  const response = backendService.post(`/oauth/token`, {
    grant_type: 'social',
    provider: 'keycloak',
    client_id: apiBaseClient.clientId,
    redirect: '/',
    client_secret: apiBaseClient.clientSecret,
    access_token: token,
    client_info: clientInfo,
    ssid
  });
  return response;
};

const goalLogout = async () => {
  const response = backendService.post(`/auth/authentication/logout`, {
    grant_type: 'social',
    provider: 'keycloak',
    client_id: apiBaseClient.clientId,
    redirect: '/',
    client_secret: apiBaseClient.clientSecret
  });
  return response;
};

const goalCurrentUser = async () => backendService.get(`/auth/users/current`);

export { goalLogin, goalCurrentUser, goalLogout };
