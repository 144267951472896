import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Grid2, Typography } from '@mui/material';
import { PropertyListItem } from '../../../components/property-list-item';
import { PropertyList } from '../../../components/property-list';
import { DeleteButton } from '../../../components/common/buttons';
import { EditButton } from '../../../components/common/buttons';
import { AddButton } from '../../../components/common/buttons';
import { CommonDialog } from '../../../components/common/common-dialog';
import { PolicyTemplateExpenseFieldMenu } from '../../../components/rindegasto/policy-templates/policy-template-expense-field-menu';

const ExpenseExtraFieldsTab = ({ data, onRefresh }) => {
  const handleDelete = (id) => {
    console.log(`Delete ${id}`);
  };

  const handleEdit = (id) => {
    console.log(`Edit ${id}`);
  };

  const handleAdd = () => {
    console.log('Add');
  };

  return (
    <Card>
      <CardHeader action={<AddButton onClick={handleAdd} />} />
      <CardContent>
        <Grid2 container spacing={2}>
          {data.expenseExtraFields?.map((field) => (
            <Grid2
              size={{
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
                xl: 2
              }}
              key={field.id}
            >
              <Card sx={{ padding: 2 }}>
                <CardHeader
                  title={field.name}
                  action={
                    <>
                      {/* <DeleteButton onConfirm={() => handleDelete(field.id)} /> */}
                      {/* <EditButton onClick={() => handleEdit(field.id)} /> */}
                      <PolicyTemplateExpenseFieldMenu data={field} onAfterSave={onRefresh} />
                    </>
                  }
                />
                <CardContent>
                  {field.instructions && (
                    <Typography variant="body2" color="textSecondary">
                      {field.instructions}
                    </Typography>
                  )}

                  <PropertyList>
                    <PropertyListItem label="Tipo de Campo" value={field.type} align="end" />
                    <PropertyListItem
                      label="Valor Predeterminado"
                      value={field.defaultValue || '-'}
                      align="end"
                    />
                  </PropertyList>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </CardContent>
    </Card>
  );
};

ExpenseExtraFieldsTab.propTypes = {
  data: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired
};

export default ExpenseExtraFieldsTab;
