import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { PropertyListItem } from '../../../components/property-list-item';

const UsersTab = ({ data }) => {
  return data.users?.map((user) => (
    <Card key={user.id} sx={{ marginBottom: 2, padding: 2 }}>
      <PropertyListItem label="Nombre" value={`${user.user?.firstname} ${user.user?.lastname}`} />
      <PropertyListItem label="ID" value={user.user?.id} />
    </Card>
  ));
};

UsersTab.propTypes = {
  data: PropTypes.object.isRequired
};

export default UsersTab;
