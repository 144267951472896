import { Box, Card, CardContent, CardHeader, Container } from '@mui/material';
import { listWorkflows, syncWorkflow } from '../../api/goal/rindegastos/rindegastos-workflow-api';
import LoadingContent from '../../components/common/loading-content';
import { useGMDataSet } from '../../components/gm/dataset/use-gm-dataset';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { Helmet } from 'react-helmet-async';
import { GMGrid } from '../../components/gm/grid/gm-grid';
import { RefreshButton } from '../../components/common/buttons/refresh-button';
import { SyncButton } from '../../components/common/buttons/sync-button';
import { ColumnDataTypesEnum } from '../../components/gm/grid/grid-definitions';
import {
  listRindegastoUsers,
  syncRindegastoUser,
  syncRindegastoUsers
} from '../../api/goal/rindegastos/rindegastos-user-api';
import { RindegastoLink } from '../../components/common/buttons/rindegasto-link';

const componentTitle = 'Usuarios de Rindegasto';
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: ColumnDataTypesEnum.ID,
    link: {
      to: (item) => `/rindegasto/users/${item.id}`,
      tooltip: 'Ver detalle del flujo de trabajo'
    }
  },
  {
    field: 'name',
    headerName: 'Nombre',
    flex: 1
  },
  {
    field: 'code',
    headerName: 'Código',
    flex: 1
  },
  {
    field: 'description',
    headerName: 'Descripción',
    flex: 1
  },
  {
    field: 'skip_previous_approvers',
    headerName: 'Saltar aprobadores anteriores',
    type: ColumnDataTypesEnum.BOOLEAN,
    flex: 1
  },

  {
    field: 'is_integrated',
    headerName: 'Está integrado',
    type: ColumnDataTypesEnum.BOOLEAN
  },
  {
    field: 'status',
    headerName: 'Estado',
    flex: 1,
    type: ColumnDataTypesEnum.STATUS
  },
  {
    field: 'remote_id',
    headerName: 'ID Remoto',

    link: {
      to: (item) => `/admin/users/user/${item.remote_id}`,
      tooltip: 'Ver usuario en el sistema remoto',
      component: RindegastoLink
    }
  }
];
export const RindegastoUsers = () => {
  const mounted = useMounted();
  const { title, currentCompany, showError, showNotify } = useApp();

  const { handleRefresh, ...documentState } = useGMDataSet({
    sourceApi: listRindegastoUsers,
    apiParameters: { companyId: currentCompany },
    loadingMessage: 'Cargando lista  de flujos de trabajo...',
    mounted,
    columns
  });

  const handleSync = async () => {
    try {
      const result = await syncRindegastoUsers({ companyId: currentCompany });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderContent = () => {
    if (documentState.error || documentState.isLoading || !documentState.data) {
      return (
        <LoadingContent
          loadingText={documentState.message}
          error={documentState.error}
          state={documentState}
        />
      );
    }
    return (
      <Card>
        <CardHeader
          title={componentTitle}
          action={
            <>
              <SyncButton onClick={handleSync} />
              <RefreshButton onClick={handleRefresh} />
            </>
          }
        />
        <CardContent>
          <GMGrid {...documentState} />
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title(componentTitle)}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
