import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import GoalButton from '../../components/goal-button';
import { Check as CheckIcon } from '../../icons/check';

import { GoalUploadButton } from '../../components/goal-upload-button';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Container,
  DialogActions,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useApp } from '../../hooks/use-app';
import { PropertyList } from '../../components/property-list';
import { InputField } from '../../components/input-field';

import { CompanyInputDisabled } from '../../components/common/company-input-disabled';
import { goalServicesApi } from '../../api/goal/services/goal-services';
import { goalImporterApi } from '../../api/goal/importer/goal-importer-api';
import { ExternalLink } from '../../icons/external-link';
import { Check } from '../../icons/check';
import { X as XIcon } from '../../icons/x';
import { appFormats } from '../../api/app-definitions';
import { Trash } from '../../icons/trash';
import { dataTypeEnum, defaultDataFileFormats } from '../../api/goal/importer/importer-definition';
import { AttachFile as AttachFileIcon } from '../../icons/attach-file';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import Loading from '../../components/loading';
import { Sync } from '@mui/icons-material';

export const GoalImporter = () => {
  const { title, appUrlBase, currentCompany, showNotify, showError } = useApp();
  const [typeOriginData, setTypeOrigin] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [lockSaveButton, setLockSaveButton] = useState(false);

  const [archiveFormDataName, setArchiveFormDataName] = useState([]);
  const [archiveNameCode, setArchiveNameCode] = useState([]);
  const [curentTemplate, setCurentTemplate] = useState(null);

  const domain = window.location.hostname;
  const protocol = window.location.protocol.slice(0, -1);
  const mounted = useMounted();
  const [templatesState, refreshTemplates] = useData({
    sourceApi: goalImporterApi.getManagerTemplateResourceList,
    apiParameter: currentCompany,
    defaults: {
      companyId: currentCompany
    },
    loadingMessage: 'Cargando plantillas',
    mounted
  });

  useEffect(() => {
    setTypeOrigin(templatesState.data || []);
  }, [templatesState.data]);

  const [bulkData, setBulkData] = useState([]);

  const initialValues = {
    company_id: currentCompany,
    type_origin: '',
    document_code: '',
    files: null
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      type_origin: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values, helpers) => {
      const errors = {};
      const selectedType = typeOriginData.find((item) => item.id === values.type_origin);
      if (selectedType) {
        selectedType.arguments.forEach((argumentsField) => {
          if (argumentsField.data_type === dataTypeEnum.DATE) {
            if (values[argumentsField.name]) {
              const dateValue = moment(values[argumentsField.name]);
              const today = moment().startOf('day');

              const minDate = moment(today).add(argumentsField.min, 'days');
              const maxDate = moment(today).add(argumentsField.max, 'days').endOf('day');

              if (dateValue.isBefore(minDate) || dateValue.isAfter(maxDate)) {
                helpers.setFieldError(
                  argumentsField.name,
                  `Fecha fuera de rango ${minDate.format('DD-MM-YYYY')} - ${maxDate.format(
                    'DD-MM-YYYY'
                  )}`
                );
                errors[argumentsField.name] = `Fecha fuera de rango ${minDate.format(
                  'DD-MM-YYYY'
                )} - ${maxDate.format('DD-MM-YYYY')}`;
              }
            } else {
              helpers.setFieldError(argumentsField.name, 'Esta fecha es importante');
              errors[argumentsField.name] = 'Esta fecha es importante';
            }
          } else if (argumentsField.data_type === dataTypeEnum.TEXT) {
            if (values[argumentsField.name]) {
              const textValue = values[argumentsField.name];
              if (
                (argumentsField.min !== null && textValue.length < argumentsField.min) ||
                (argumentsField.max !== null && textValue.length > argumentsField.max)
              ) {
                helpers.setFieldError(
                  argumentsField.name,
                  `Longitud de texto fuera de rango ${argumentsField?.min || 0} - ${
                    argumentsField?.max || ''
                  } `
                );
                errors[argumentsField.name] = `Longitud de texto fuera de rango ${
                  argumentsField?.min || 0
                } - ${argumentsField?.max || ''} `;
              }
            } else {
              helpers.setFieldError(argumentsField.name, 'Este campo no debe estar vacío');
              errors[argumentsField.name] = 'Este campo no debe estar vacío';
            }
          } else if (argumentsField.data_type === dataTypeEnum.NUMBER) {
            if (values[argumentsField.name]) {
              const numberValue = Number(values[argumentsField.name]);
              if (argumentsField.min !== null && numberValue < argumentsField.min) {
                helpers.setFieldError(
                  argumentsField.name,
                  `El número es menor que el mínimo permitido - ${argumentsField?.min} `
                );
                errors[argumentsField.name] =
                  `El número es menor que el mínimo permitido - ${argumentsField?.min} `;
              } else if (argumentsField.max !== null && numberValue > argumentsField.max) {
                helpers.setFieldError(
                  argumentsField.name,
                  `El número es mayor que el máximo permitido  ${argumentsField?.max} `
                );
                errors[argumentsField.name] =
                  `El número es mayor que el máximo permitido ${argumentsField?.max} `;
              }
            } else {
              helpers.setFieldError(argumentsField.name, 'Este campo no debe estar vacío');
              errors[argumentsField.name] = 'Este campo no debe estar vacío';
            }
          } else if (argumentsField.data_type === dataTypeEnum.ARCHIVE) {
            if (!archiveFormDataName.includes(argumentsField.name)) {
              helpers.setFieldError(argumentsField.name, `El archivo no existe `);
              errors[argumentsField.name] = `El archivo no existe`;
            }
          }
        });
      }

      if (Object.keys(errors).length === 0) {
        values = Object.entries(values).reduce((newObj, [key, value]) => {
          if (value !== '') {
            newObj[key] = value;
          }
          return newObj;
        }, {});
        values.path = selectedType.path;
        values.files = archiveNameCode;
        delete values.type_origin;
        const response = await goalImporterApi.getResponseImport(values);
        if (response.success) {
          setLockSaveButton(true);
          setBulkData(response.data);
          showNotify(`${response?.message} `);
        } else {
          showError({
            message: response?.message || 'No se proceso la acción...',
            detail: response?.errors
          });
        }
      } else {
        console.log('Errores de validación:', errors);
      }
    }
  });

  const handleResetForm = () => {
    formik.resetForm();
    formik.values = [];
    setSelectValue([]);
    setCurentTemplate(null);
    setBulkData([]);
    setArchiveFormDataName([]);
    setArchiveNameCode([]);
    setLockSaveButton(false);
  };

  const handleChangeType = (event) => {
    formik.setValues({
      company_id: formik.values.company_id,
      type_origin: formik.values.type_origin
    });
    const { name, value } = event.target;
    if (name !== 'type_origin') return;
    formik.setFieldValue(name, value);

    const selectedType = typeOriginData.find((item) => item.id === value);
    setSelectValue(selectedType?.links);
    if (selectedType) {
      selectedType.arguments.forEach((argumentField) => {
        if (argumentField.data_type === dataTypeEnum.TEXT) {
          formik.setFieldValue(argumentField.name, argumentField.defaultValue || '');
        }
        if (argumentField.data_type === dataTypeEnum.DATE) {
          formik.setFieldValue(argumentField.name, moment().format(appFormats.backendDateFormat));
        }
        if (argumentField.data_type === dataTypeEnum.NUMBER) {
          formik.setFieldValue(argumentField.name, 0);
        }
        if (argumentField.data_type === dataTypeEnum.BOLEANO) {
          formik.setFieldValue(argumentField.name, argumentField.defaultValue || false);
        }
        if (argumentField.data_type === dataTypeEnum.ARCHIVE) {
          // setArchiFormData([]);
          setArchiveFormDataName([]);
          formik.setFieldValue(argumentField.name, '');
        }
      });

      const selectedTemplate = typeOriginData.find((item) => item.id === selectedType._id);
      setCurentTemplate(() => selectedTemplate);
    }
  };

  const handleRefresh = () => {
    handleResetForm();

    // set formik values to initial values
    // formik.values = initialValues;

    refreshTemplates();
  };

  const handleDeleteDocument = (name) => {
    setArchiveFormDataName((prevState) => prevState.filter((doc) => doc !== name));
    setArchiveNameCode((prevState) => prevState.filter((doc) => doc.nameDocument !== name));
  };

  const generateArgumentsFields = (typeData) => {
    if (!typeData) {
      return null;
    }
    return typeData.arguments.map((argumentsField, index) => {
      if (argumentsField.data_type === dataTypeEnum.TEXT) {
        return (
          <InputField
            key={`${index}-${argumentsField.name}`}
            label={`${argumentsField.title || `Ingrese ${argumentsField.name}`}`}
            name={argumentsField.name || ''}
            type="text"
            value={formik.values[argumentsField.name]}
            onChange={formik.handleChange}
            fullWidth
            error={
              formik.touched[argumentsField.name] && Boolean(formik.errors[argumentsField.name])
            }
            helperText={formik.touched[argumentsField.name] && formik.errors[argumentsField.name]}
            sx={{ marginBottom: '1em' }}
          />
        );
      }

      if (argumentsField.data_type === dataTypeEnum.NUMBER) {
        return (
          <InputField
            key={`${index}-${argumentsField.name}`}
            label={`${argumentsField.title || `Ingrese ${argumentsField.name}`}`}
            name={argumentsField.name || 0}
            type="number"
            value={formik.values[argumentsField.name]}
            onChange={formik.handleChange}
            fullWidth
            error={
              formik.touched[argumentsField.name] && Boolean(formik.errors[argumentsField.name])
            }
            helperText={formik.touched[argumentsField.name] && formik.errors[argumentsField.name]}
            sx={{ marginBottom: '1em' }}
          />
        );
      }

      if (argumentsField.data_type === dataTypeEnum.DATE) {
        return (
          <InputField
            key={`${index}-${argumentsField.name}`}
            label={`${argumentsField.title || `Ingrese ${argumentsField.name}`}`}
            name={argumentsField.name}
            type="date"
            value={formik.values[argumentsField.name]}
            onChange={formik.handleChange}
            fullWidth
            error={
              formik.touched[argumentsField.name] && Boolean(formik.errors[argumentsField.name])
            }
            helperText={formik.touched[argumentsField.name] && formik.errors[argumentsField.name]}
            sx={{ marginBottom: '1em' }}
          />
        );
      }

      if (argumentsField.data_type === dataTypeEnum.BOLEANO) {
        return (
          <FormControl
            key={`${index}-${argumentsField.name}`}
            sx={{ display: 'block', marginBottom: 1 }}
          >
            <FormLabel id="bolean">{`${argumentsField.title ?? argumentsField.name}`}</FormLabel>
            <Checkbox
              aria-labelledby="bolean"
              name={argumentsField.name}
              checked={!!formik.values[argumentsField.name]}
              onChange={formik.handleChange}
            />
          </FormControl>
        );
      }

      return null;
    });
  };

  const generateArgumentsFieldsArchive = (typeData) => {
    if (!typeData) {
      return null;
    }
    const argumentsFields = typeData.arguments.filter(
      (argumentsField) => argumentsField.data_type === dataTypeEnum.ARCHIVE
    );

    if (argumentsFields.length === 0) {
      return null;
    }

    return (
      <Box
        sx={{
          marginTop: 5
        }}
      >
        <InputLabel
          sx={{
            marginBottom: 1,
            marginLeft: 0,
            color: 'black',
            fontWeight: 'bold'
          }}
        >
          Archivos
        </InputLabel>
        {argumentsFields.map((argumentsField, index) => (
          <Box
            key={`${index}-${argumentsField.name}`}
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'left',
              marginTop: 2,
              flexDirection: 'column'
            }}
          >
            <Box>
              <Typography variant="subtitle2" color="textPrimary">
                {argumentsField.title}
              </Typography>

              {formik.errors[argumentsField.name] && (
                <FormHelperText error>{formik.errors[argumentsField.name]}</FormHelperText>
              )}
            </Box>
            {archiveFormDataName.includes(argumentsField.name) ? (
              <Box sx={{ display: 'flex', gap: 3 }}>
                {' '}
                <Typography variant="body2" color="textPrimary">
                  {archiveNameCode.find((item) => item.nameDocument === argumentsField.name)
                    ?.fileName ?? ''}
                </Typography>
                <Check color="info" />
                <GoalButton
                  startIcon={<Trash />}
                  onClick={() => handleDeleteDocument(argumentsField.name)}
                  label="Eliminar"
                />
              </Box>
            ) : (
              <GoalUploadButton
                startIcon={<AttachFileIcon />}
                disabled={archiveFormDataName.includes(argumentsField.name)}
                mimeTypes={[
                  ...new Set([
                    ...defaultDataFileFormats,
                    ...(argumentsField.typeDocumen || '').split(',')
                  ])
                ]}
                onUpoload={handleDocuments}
                uploaderApi={goalServicesApi.postDocumentForm}
                documentName={argumentsField.name}
                label="Subir documento"
                variant="outlined"
              />
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const handleDocuments = (values, { name: nameDocument, code, path, fileName }) => {
    // setArchiFormData((prevArray) => [...prevArray, values]);
    setArchiveFormDataName((prevArray) => [...prevArray, nameDocument]);
    setArchiveNameCode([...archiveNameCode, { nameDocument, code, path, fileName }]);
    // handleCloseAppendDialog();
  };

  return (
    <>
      <Helmet>
        <title> {title('Importar')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          py: 4
        }}
      >
        <Container maxWidth="lg">
          <Divider />
          <Card>
            <CardHeader
              title="Importar Datos"
              subheader="Seleccione el tipo de origen y complete los campos requeridos"
              action={
                <GoalButton
                  label={'Refrescar'}
                  startIcon={<Sync />}
                  onClick={handleRefresh}
                ></GoalButton>
              }
            />
            <Box
              sx={{
                minWidth: 'auto',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: 5
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  spacing={8}
                  sx={{
                    marginBottom: 2
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <PropertyList>
                      <CompanyInputDisabled currentCompany={currentCompany} />
                      {templatesState.isLoading ? (
                        <Loading message={templatesState.message} />
                      ) : (
                        <InputField
                          select
                          label="Tipo de origen"
                          name="type_origin"
                          value={formik.values.type_origin}
                          onChange={handleChangeType}
                          fullWidth
                          error={formik.touched.type_origin && Boolean(formik.errors.type_origin)}
                          helperText={formik.touched.type_origin && formik.errors.type_origin}
                          sx={{ marginBottom: '1em' }}
                        >
                          {typeOriginData.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </InputField>
                      )}
                      {generateArgumentsFields(curentTemplate)}
                    </PropertyList>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PropertyList>
                      <InputLabel
                        sx={{
                          marginBottom: 1,
                          marginLeft: 0,
                          color: 'black',
                          fontWeight: 'bold'
                        }}
                      >
                        Enlaces de interes
                      </InputLabel>
                      {selectValue && selectValue.length > 0 ? (
                        selectValue.map((item, index) => (
                          <List
                            sx={{ width: '100%', maxWidth: 360 }}
                            aria-label="contacts"
                            key={index}
                          >
                            <ListItem disablePadding>
                              <Link
                                color="inherit"
                                to={`${appUrlBase}${item.name}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="none"
                                variant="subtitle2"
                              >
                                <ListItemButton>
                                  <ListItemIcon>
                                    <ExternalLink />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      appUrlBase
                                        ? item.title
                                        : `${protocol}//${domain}${appUrlBase}${item.name}`
                                    }
                                  />
                                </ListItemButton>
                              </Link>
                            </ListItem>
                          </List>
                        ))
                      ) : (
                        <p>No hay enlaces disponibles</p>
                      )}

                      {generateArgumentsFieldsArchive(curentTemplate)}
                    </PropertyList>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginBottom: 2
                  }}
                >
                  <Grid item xs={12}>
                    <DialogActions>
                      <LoadingButton
                        color="primary"
                        size="large"
                        startIcon={<CheckIcon />}
                        variant="contained"
                        type="submit"
                        loading={formik.isSubmitting}
                        disabled={lockSaveButton}
                      >
                        Guardar
                      </LoadingButton>
                      <GoalButton onClick={handleResetForm} variant="text" startIcon={<XIcon />}>
                        Limpiar
                      </GoalButton>
                    </DialogActions>
                  </Grid>
                </Grid>
              </form>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Mensaje</TableCell>
                        <TableCell>Exitoso</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bulkData?.map((row, index) => (
                        <TableRow
                          key={`${index}-${row.name ?? ''}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {/* {row?.map((item) => ( 
                            <TableCell key={item}>{item}</TableCell>
                            */}
                          <TableCell>{index}</TableCell>
                          <TableCell>{row.message}</TableCell>
                          <TableCell>{row.success ? 'SI' : 'NO'}</TableCell>
                          {/* ))} */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};
