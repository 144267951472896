import PropTypes from 'prop-types';

import { useState } from 'react';
import { ConfirmationDialog } from '../confirmation-dialog';
import GoalButton from '../goal-button';
import { useAuth } from '../../hooks/use-auth';

const ConfirmationButton = ({
  icon,
  label,
  title,
  onConfirm,
  onCancel = null,
  message = '',
  params,
  startIcon,
  permission,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { hasPermission } = useAuth();
  const handleConfirm = async () => {
    await onConfirm(params);
    setOpen(false);
  };

  const handleCancel = async () => {
    await onCancel?.();
    setOpen(false);
  };

  return (
    <>
      <GoalButton
        startIcon={icon || startIcon}
        onClick={() => {
          setOpen(true);
        }}
        loading={loading}
        label={label}
        {...rest}
      />
      <ConfirmationDialog
        message={`${message}. ¿Está seguro que desea continuar?`}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        params={params}
        open={open}
        title={title}
        variant="warning"
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
};

ConfirmationButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  message: PropTypes.string,
  params: PropTypes.objectOf(PropTypes.any),
  startIcon: PropTypes.node,
  permission: PropTypes.string
};
export { ConfirmationButton };
