import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { useApp } from '../../../hooks/use-app';
import { DotsVertical as DotsVerticalIcon } from '../../../icons/dots-vertical';
import { ConfirmationDialog } from '../../confirmation-dialog';
import GadminActions from '../../../contexts/gadmin-actions';
import { Eye as EyeIcon } from '../../../icons/eye';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { documentStatusViews } from '../../../api/app-definitions';

export const SurveySurveyItemMenu = (props) => {
  const { items, detail = false, handleRefresh, ...others } = props;
  const navigate = useNavigate();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const [modalOpen, setModalOpen] = useState(false);

  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [menuActions, setMenuActions] = useState([]);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);
  const { showNotify, showError, appUrlBase, currentCompany } = useApp();

  const handleOpenConfirmationDialog = (menuItem) => {
    setCurrentMenuItem(menuItem);
    setConfirmDialogMessage(menuItem.prompt);
    setModalOpen(true);
    handleClose();
  };

  const handleConfirm = async () => {
    if (currentMenuItem.action === 'pending_survey') {
      const response = await goalCustomeSurveyApi.validateSurvey(items._id, currentCompany);
      if (response.success) {
        showNotify(response?.message ? `${response?.message}` : `Cambio exitoso.`);
        handleRefresh();
      } else {
        showError(response?.message ? `${response?.message}` : `No se proceso la acción.`);
      }
    }
    if (GadminActions.G2_SURVEY_CANCEL === currentMenuItem.action) {
      const response = await goalCustomeSurveyApi.cancelSurvey(items._id, currentCompany);
      if (response.success) {
        showNotify(response?.message ? `${response?.message}` : `Cambio exitoso.`);
        handleRefresh();
      } else {
        showError(response?.message ? `${response?.message}` : `No se proceso la acción.`);
      }
    }
    if (GadminActions.G2_SURVEY_CLOSE === currentMenuItem.action) {
      const response = await goalCustomeSurveyApi.closeSurvey(items._id, currentCompany);
      if (response.success) {
        showNotify(response?.message ? `${response?.message}` : `Cambio exitoso.`);
        handleRefresh();
      } else {
        showError(response?.message ? `${response?.message}` : `No se proceso la acción.`);
      }
    }
    if (GadminActions.G2_SURVEY_ACTIVATE === currentMenuItem.action) {
      const response = await goalCustomeSurveyApi.activateSurvey(items._id, currentCompany);
      if (response.success) {
        showNotify(response?.message ? `${response?.message}` : `Cambio exitoso.`);
        handleRefresh();
      } else {
        showError(response?.message ? `${response?.message}` : `No se proceso la acción.`);
      }
    }

    if (GadminActions.G2_PROMOTION_ACTION_REJECT === currentMenuItem.action) {
      const response = await goalCustomeSurveyApi.rejectSurvey(items._id, currentCompany);
      if (response.success) {
        showNotify(response?.message ? `${response?.message}` : `Cambio exitoso.`);
        handleRefresh();
      } else {
        showError(response?.message ? `${response?.message}` : `No se proceso la acción.`);
      }
    }

    if (GadminActions.G2_SURVEY_ARCHIVE === currentMenuItem.action) {
      const response = await goalCustomeSurveyApi.archivedSurvey(items._id, currentCompany);
      if (response.success) {
        showNotify(response?.message ? `${response?.message}` : `Cambio exitoso.`);
        handleRefresh();
      } else {
        showError(response?.message ? `${response?.message}` : `No se proceso la acción.`);
      }
    }
    setModalOpen(false);
  };

  useEffect(() => {
    const actions = [];
    if ([documentStatusViews.VIEW_DRAFT].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_SURVEY_CANCEL,
        label: 'Cancelar',
        prompt: '¿Está seguro que desea Cancelar?'
      });
    }
    if ([documentStatusViews.VIEW_DRAFT].includes(items.status)) {
      actions.push({
        action: 'pending_survey',
        label: 'Validar',
        prompt: '¿Está seguro que desea Validar?'
      });
    }
    if ([documentStatusViews.VIEW_PENDING].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_SURVEY_CANCEL,
        label: 'Cancelar',
        prompt: '¿Está seguro que desea Cancelar?'
      });
    }
    if ([documentStatusViews.VIEW_PENDING].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_SURVEY_ACTIVATE,
        label: 'Activar',
        prompt: '¿Está seguro que desea Activar?'
      });
    }
    if ([documentStatusViews.VIEW_PENDING].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_PROMOTION_ACTION_REJECT,
        label: 'No validar',
        prompt: '¿Está seguro que desea No validar?'
      });
    }
    if ([documentStatusViews.VIEW_ACTIVE].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_SURVEY_CLOSE,
        label: 'Cerrar',
        prompt: '¿Está seguro que desea Cerrar?'
      });
    }
    if ([documentStatusViews.VIEW_CANCELLED].includes(items.status)) {
      actions.push({
        action: GadminActions.G2_SURVEY_ARCHIVE,
        label: 'Archivar',
        prompt: '¿Está seguro que desea Archivar?'
      });
    }
    setMenuActions(actions);
  }, [items.status]);

  const detailRoute = `${appUrlBase}/survey/survey/${items.uuid}`;

  const handleNavigate = () => {
    handleClose();
    navigate(detailRoute);
  };

  return (
    <>
      <Box onClick={handleOpen} ref={anchorRef} {...others}>
        <IconButton>
          <DotsVerticalIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {detail && (
          <MenuItem key="detail" onClick={handleNavigate}>
            <EyeIcon />
            Detalle
          </MenuItem>
        )}
        {menuActions.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleOpenConfirmationDialog(item);
            }}
          >{`${item.label}`}</MenuItem>
        ))}
        {menuActions.length === 0 && detail && <MenuItem>No hay acciones</MenuItem>}
      </Menu>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </>
  );
};

SurveySurveyItemMenu.propTypes = {
  items: PropTypes.object,
  detail: PropTypes.bool,
  handleRefresh: PropTypes.func
};
