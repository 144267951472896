import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Trash as ErrorIcon } from '../icons/trash';
import { ExclamationOutlined as WarningIcon } from '../icons/exclamation-outlined';
import { Check as InfoIcon } from '../icons/check';

const icons = {
  error: <ErrorIcon color="error" fontSize="large" />,
  warning: <WarningIcon color="warning" fontSize="large" />,
  info: <InfoIcon color="info" fontSize="large" />
};

export const ConfirmationDialog = (props) => {
  const {
    message = 'Esta acción no se puede deshacer',
    onCancel,
    onConfirm,
    open = false,
    title,
    variant,
    loading,
    setLoading,
    afterConfirm,
    question,
    ...other
  } = props;
  const handleConfirm = async () => {
    setLoading?.(true);
    const retult = await onConfirm?.();
    if (retult?.success) {
      afterConfirm?.();
    }
    setLoading?.(false);
  };
  return (
    <Dialog
      // onClose={onCancel}
      open={open}
      // disableEscapeKeyDown
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        {icons[variant]}
        <Typography color="inherit" sx={{ ml: 2 }} variant="inherit">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          minHeight: '150px' // Optional: to ensure some height for centering
        }}
      >
        <Typography color="textPrimary" variant="body1">
          {message}
        </Typography>
        <Typography color="primary" variant="body1">
          {question}
        </Typography>
        {loading && <CircularProgress />}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel} variant="text" disabled={loading}>
          Cancelar
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleConfirm}>
          Aceptar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['error', 'warning', 'info']),
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  afterConfirm: PropTypes.func,
  question: PropTypes.string
};
