import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import { useGMDataSet } from '../dataset/use-gm-dataset';
import { useMounted } from '../../../hooks/use-mounted';
import { useImperativeHandle, forwardRef } from 'react';
import LoadingContent from '../../common/loading-content';
import { GMGrid } from './gm-grid';

const GMGridContext = createContext();

const useGMGrid = () => useContext(GMGridContext);

const GMGridContainer = forwardRef(
  (
    {
      sourceApi,
      apiParameters,
      loadingMessage = 'Cargando...',
      columns,
      children,
      slot = {},
      slotProps = {}
    },
    ref
  ) => {
    const mounted = useMounted();
    const { handleRefresh, ...documentState } = useGMDataSet({
      sourceApi,
      apiParameters,
      loadingMessage,
      mounted,
      columns
    });

    useImperativeHandle(ref, () => ({
      handleRefresh
    }));

    const render = () => {
      if (documentState.error) {
        return (
          <LoadingContent
            loadingText={documentState.message}
            error={documentState.error}
            state={documentState}
          />
        );
      }

      if (documentState.isLoading || !documentState.data) {
        return <LoadingContent loadingText={loadingMessage} state={documentState} />;
      }

      return (
        <>
          <GMGrid {...documentState} slot={slot} slotProps={slotProps} onRefresh={handleRefresh} />
        </>
      );
    };
    return (
      <GMGridContext.Provider value={{ handleRefresh, ...documentState }}>
        {render()}
        {children}
      </GMGridContext.Provider>
    );
  }
);

GMGridContainer.propTypes = {
  sourceApi: PropTypes.func,
  apiParameters: PropTypes.object,
  loadingMessage: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node,
  slot: PropTypes.object,
  slotProps: PropTypes.object
};

export { GMGridContainer, useGMGrid };
