import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import SaveIcon from '@mui/icons-material/Save';

export const SubmitButton = ({
  onClick,
  startIcon = <SaveIcon />,
  tooltip = 'Guardar',
  type = 'submit',
  ...rest
}) => (
  <GoalButton
    onClick={onClick}
    startIcon={startIcon}
    tooltip={tooltip}
    iconOnly
    {...rest}
    type={type}
  />
);

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string,
  type: PropTypes.string
};
