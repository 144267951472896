import { AllowedGuard } from '../hooks/use-allowed';
import GadminActions from '../contexts/gadmin-actions';
// import { lazy } from 'react';
// import { Loadable } from './loadable';
import { CardContent } from '@mui/material';
import { RindegastoUsers } from '../containers/rindegasto/rindegasto-users';
import RindegastoPolicy from '../containers/rindegasto/rindegasto-policy';
import RindegastoWorkflow from '../containers/rindegasto/rindegasto-workflow';
import { RindegastoWorkflows } from '../containers/rindegasto/rindegasto-workflows';
import { RindegastoPolicies } from '../containers/rindegasto/rindegasto-policies';
import RindegastoUser from '../containers/rindegasto/rindegasto-user';
import { RindegastoExpenseUnits } from '../containers/rindegasto/rindegasto-expense-units';
import { RindegastoPolicyTemplates } from '../containers/rindegasto/rindegasto-policy-templates';
import RindegastoPolicyTemplate from '../containers/rindegasto/rindegasto-policy-template';

export const RindegastoRoutes = [
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.G2_RINDEGASTO_ADMIN}>
        <CardContent />
      </AllowedGuard>
    )
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoUsers />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoUser />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'policies',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoPolicies />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoPolicy />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'workflows',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoWorkflows />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoWorkflow />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'expense-units',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoExpenseUnits />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoExpenseUnits />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'policy-templates',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoPolicyTemplates />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_USER_LIST}>
            <RindegastoPolicyTemplate />
          </AllowedGuard>
        )
      }
    ]
  }
];
