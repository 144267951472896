import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { goalMaintenanceInventoryApi } from '../../../api/goal/maintenance/goal-maintenance';
import { useData } from '../../../hooks/use-data';
import { useGoalTheme } from '../../../hooks/use-goal-theme';
import { AddButton, RefreshButton } from '../../../components/common/buttons';
import { useTreeViewData } from '../../../hooks/use-tree-view-data';
import { CommonTreeView } from '../../../components/common/common-tree-view';
import { PropertyList } from '../../../components/property-list';
import { MaintenanceDetailDefault } from '../../../components/maintenance/maintenance-detail-default';
import GoalCustomDialog from '../../../components/goal-custom-dialog';
import { MaintenanceTypesOfVauchersForm } from '../../../components/maintenance/base/maintenance-types-of-vauchers-form';

export const MaintenanceTypesOfVauchers = () => {
  const { title, companyState, currentCompany, showNotify, showError } = useApp();
  const mounted = useMounted();
  const { isMobile } = useGoalTheme();

  const [open, setOpen] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectedNodeDataItem, setSelectedNodeDataItem] = useState(null);

  const [configState] = useData({
    sourceApi: goalMaintenanceInventoryApi.getAttributeGroupsConfig,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted,
    apiParameter: { companyId: currentCompany }
  });

  const [attributeState, attributesActions] = useTreeViewData({
    rootApi: goalMaintenanceInventoryApi.getAttributeGroupsList,
    childrenApi: goalMaintenanceInventoryApi.getAttributeGroupsContainer,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando tipos de comprobantes',
    mounted,
    defaults: {}
  });

  const handleRefresh = () => {
    attributesActions.onLoadRoot({ companyId: currentCompany });
  };

  const handleClose = () => {
    setEditmode(false);
    setOpen(false);
  };

  const onNodeSelection = (item) => {
    attributesActions.onSelectNode(item);
    setSelectedNodeDataItem(item);
  };

  useEffect(() => {
    const loadTreeRoot = async () => {
      attributesActions.onLoadRoot({ companyId: currentCompany });
    };
    loadTreeRoot();
  }, [currentCompany]);

  const renderContent = () => {
    return (
      <Card sx={{ p: 3 }}>
        <Box>
          <Grid container spacing={8} sx={{ mb: 2 }}>
            <Grid item md={6} xs={12}>
              <PropertyList>
                <CommonTreeView
                  expandedItems={expandedItems}
                  setExpandedItems={setExpandedItems}
                  title="Tipo de comprobantes..."
                  onSelectNode={onNodeSelection}
                  data={attributeState.isLoading ? [] : attributeState.data}
                  setSelectedNodeDataItem={setSelectedNodeDataItem}
                />
              </PropertyList>
            </Grid>
            <Grid item md={6} xs={12}>
              <MaintenanceDetailDefault
                company={companyState?.data}
                onEdit={() => {
                  setEditmode(true);
                  setOpen(true);
                }}
                item={selectedNodeDataItem}
                btnEdit={selectedNodeDataItem != null}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Tipos de comprobantes')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', flexGrow: 1 }}>
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography color="textPrimary" variant="h4">
                Tipos de comprobantes
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AddButton onClick={() => setOpen(true)} permission={GadminActions.gCC_TDOY_LIST} />
              <RefreshButton onClick={handleRefresh} permission={GadminActions.gCC_TDOY_LIST} />
            </Box>
          </Box>
          {renderContent()}

          <GoalCustomDialog
            open={open}
            handleClose={handleClose}
            maxWidth="xs"
            fullScreen={isMobile}
            withoutFooter
          >
            <MaintenanceTypesOfVauchersForm
              isNew={!editMode}
              onClose={handleClose}
              configState={configState.isLoading ? {} : configState}
              handleRefresh={handleRefresh}
              initialValues={editMode ? selectedNodeDataItem : null}
            />
          </GoalCustomDialog>
        </Container>
      </Box>
    </>
  );
};
