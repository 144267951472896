import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import RefreshIcon from '@mui/icons-material/Refresh';

const RefreshButton = ({
  onClick,
  startIcon = <RefreshIcon />,
  tooltip = 'Refrescar',
  ...rest
}) => <GoalButton onClick={onClick} startIcon={startIcon} tooltip={tooltip} iconOnly {...rest} />;

RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string
};

export default RefreshButton;

export { RefreshButton };
