import { useEffect, useState } from 'react';
import { applicationConfig } from '../config';

const { stage } = applicationConfig;

const useArrayColumn = (rows, columnName) => {
  rows.map((row) => row[columnName]);
};

const useLocalStorage = (key, initialValue) => {
  const savedKey = `${stage}_${key}`;
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(savedKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Object ? JSON.stringify(value) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(savedKey, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  // remove item from local storage
  const removeValue = () => {
    try {
      window.localStorage.removeItem(savedKey);
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue, removeValue];
};

const useSessionStorage = (key, initialValue) => {
  const savedKey = `${stage}_${key}`;

  // Get the initial value from session storage or use the provided initial value
  const getInitialValue = () => {
    const storedValue = sessionStorage.getItem(savedKey);
    if (storedValue !== null && storedValue !== undefined && storedValue !== 'undefined') {
      try {
        return JSON.parse(storedValue);
      } catch (e) {
        console.error('Error parsing session storage value', e);
        return storedValue;
      }
    }
    return initialValue;
  };

  const [value, setValue] = useState(getInitialValue);

  useEffect(() => {
    try {
      const valueToStore = typeof value === 'object' ? JSON.stringify(value) : value;
      sessionStorage.setItem(savedKey, valueToStore);
    } catch (e) {
      console.error('Error setting session storage value', e);
    }
  }, [key, value, savedKey]);
  //  remove item from session storage
  const removeValue = () => {
    try {
      window.sessionStorage.removeItem(savedKey);
    } catch (error) {
      console.error(error);
    }
  };
  return [value, setValue, removeValue];
};
export { useArrayColumn, useLocalStorage, useSessionStorage };
