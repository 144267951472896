import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { esES } from '@mui/x-data-grid/locales';

import {
  DataGrid,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
const printOptions = {
  hideFooter: true,
  hideToolbar: true,
  disableToolbarButton: false,
  pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }',
  includeCheckboxes: true
};
const CustomToolbar = ({ printOptions, csvOptions, ...other }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarExportContainer {...other}>
        <GridCsvExportMenuItem options={csvOptions} />
        <GridPrintExportMenuItem options={printOptions} />
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
};
export const GMGrid = (props) => {
  const {
    rows,
    columns,
    loading,
    checkboxSelection = true,
    disableSelectionOnClick = true,
    sx,
    onRowSelected,
    apiRef,
    ...rest
  } = props;
  const defaultSx = {
    minHeight: '30vh',
    maxHeight: '90vh'
  };
  return (
    <Box>
      <DataGrid
        // autoHeight
        rows={rows}
        columns={columns}
        {...rest}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick={disableSelectionOnClick}
        disableRowSelectionOnClick
        onRowSelected={onRowSelected}
        apiRef={apiRef}
        // pageSize={5}
        // rowsPerPageOptions={[5]}

        // experimentalFeatures={{ newEditingApi: true }}
        loading={loading}
        // rowHeight={38}
        slots={{
          toolbar: CustomToolbar
        }}
        slotProps={{
          toolbar: {
            printOptions,
            csvOptions: {
              // fileName: 'customerDataBase',
              // delimiter: ';',
              utf8WithBom: true
              // fields: columns.map((column) => column.field)
            },
            tooltip: { title: 'Exportar datos' },
            button: { variant: 'outlined' }
          },
          loadingOverlay: {
            variant: 'linear-progress'
          }
        }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        ignoreValueFormatterDuringExport
        sx={{ ...defaultSx, ...sx }}
      />
    </Box>
  );
};

GMGrid.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  sx: PropTypes.object,
  checkboxSelection: PropTypes.bool,
  disableSelectionOnClick: PropTypes.bool,
  onRowSelected: PropTypes.func,
  apiRef: PropTypes.object
};

CustomToolbar.propTypes = {
  csvOptions: PropTypes.object,
  printOptions: PropTypes.object
};
