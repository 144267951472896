import { dataLoader } from '../../../data-loader';

class GoalPriceListsApi {
  async getList({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `/inventory/price-lists`,
      params: { companyId }
    });
    return response;
  }

  // get detail
  async getDetail({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `/inventory/price-lists/${id}`,
      params: { companyId }
    });
    return response;
  }

  // sync
  async sync({ companyId }) {
    const response = await dataLoader.goalPost({
      action: `/inventory/price-lists/sync?companyId=${companyId}`,
      params: { companyId }
    });
    return response;
  }

  // sync lines
  async syncLines({ companyId, id }) {
    const response = await dataLoader.goalPost({
      action: `/inventory/price-lists/${id}/sync-lines?companyId=${companyId}`,
      params: { companyId }
    });
    return response;
  }

  // get lines
  async getLines({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `/inventory/price-lists/${id}/lines`,
      params: { companyId }
    });
    return response;
  }

  // update price list
  async updatePriceList({ id, changes, companyId }) {
    const response = await dataLoader.goalPut({
      path: `/inventory/price-lists/${id}?companyId=${companyId}`,
      changes: { ...changes, company_id: companyId, id: id }
    });
    return response;
  }
}

export const goalPriceListsApi = new GoalPriceListsApi();
