import PropTypes from 'prop-types';

import { useApp } from '../../../hooks/use-app';
import { ConfirmationMenuItem } from '../../common/confirmation-menu-item';
import { GoalMenu } from '../../common/goal-menu';
import PeopleIcon from '@mui/icons-material/People';
import { GoalMenuDialog } from '../../menu/goal-menu-dialog';
import { PictureAsPdf } from '@mui/icons-material';
import { Settings } from '@mui/icons-material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import {
  deliverySheetPdf,
  loadSheetPdf,
  pickingGroupedPdf,
  pickingSheetPdf,
  regenDespatchAdvice,
  retryInvoicing,
  ungroupPicking
} from '../../../api/goal/delivery/handling-api';
import { PDFViewerdDialog } from '../../common/pdf-viewer-dialog';
import { isTrue } from '../../../hooks/use-bolean';
import GadminActions from '../../../contexts/gadmin-actions';
import { useAuth } from '../../../hooks/use-auth';

export const HROpenMenu = ({ onRefresh, row }) => {
  const { currentCompany, showError, showNotify } = useApp();
  const { hasPermission } = useAuth();

  const isGrouped = isTrue(row.picking_grouped);

  const { id } = row;

  const handleUngroup = async () => {
    const result = await ungroupPicking({ id: row.picking_id, companyId: currentCompany });
    if (!result || !result.success) {
      showError(result.message || 'No se pudo desagrupar');
    } else {
      showNotify(result.message);
      onRefresh?.();
    }
  };

  const handleRetryInvoicing = async () => {
    const result = await retryInvoicing({ id: row.id, companyId: currentCompany });
    if (!result || !result.success) {
      showError(result.message || 'No se pudo continuar con la facturación');
    } else {
      showNotify(result.message);
      onRefresh?.();
    }
  };

  const handleRegenerateDespatchAdvice = async () => {
    const result = await regenDespatchAdvice({ id: row.id, companyId: currentCompany });
    if (!result || !result.success) {
      showError(result.message || 'No se pudo regenerar las guías de remisión');
    }
    showNotify(result.message);
    onRefresh?.();
  };

  const canRetryInvoicing = hasPermission(GadminActions.GCC_GPROC_MNGMT);

  const canCreateDespatchAdvice =
    hasPermission(GadminActions.GCC_DELIVERI_GR_CREATE) &&
    !['A', 'F', 'G', 'N'].includes(row.status);
  return (
    <GoalMenu>
      {canRetryInvoicing && row.status == 'W' && (
        <ConfirmationMenuItem
          startIcon={<Settings />}
          helpText={'Continuar con la facturación'}
          onConfirm={handleRetryInvoicing}
          label={'Seguir facturando'}
          confirmationMessage={'¿Está seguro que desea continuar con la facturación?'}
        />
      )}
      {isGrouped && (
        <ConfirmationMenuItem
          startIcon={<PeopleIcon />}
          helpText={'Desagrupar Hojas'}
          onConfirm={handleUngroup}
          label={'Desagrupar Hojas'}
          confirmationMessage={'¿Está seguro que desea desagrupar las hojas de reparto?'}
        />
      )}

      {isGrouped && (
        <GoalMenuDialog
          startIcon={<PictureAsPdf />}
          Component={PDFViewerdDialog}
          componentProps={{
            apiHandle: pickingGroupedPdf,
            apiParams: { id: row.picking_id, companyId: currentCompany }
          }}
          tooltip={'Ver hoja de carga'}
        >
          Picking consolidado
        </GoalMenuDialog>
      )}
      <GoalMenuDialog
        startIcon={<PictureAsPdf />}
        Component={PDFViewerdDialog}
        componentProps={{
          apiHandle: pickingSheetPdf,
          apiParams: { id, companyId: currentCompany }
        }}
        tooltip={'Ver hoja de picking'}
      >
        Hoja de picking
      </GoalMenuDialog>
      <GoalMenuDialog
        startIcon={<PictureAsPdf />}
        Component={PDFViewerdDialog}
        componentProps={{
          apiHandle: loadSheetPdf,
          apiParams: { id, companyId: currentCompany }
        }}
        tooltip={'Ver hoja de carga'}
      >
        Hoja de Carga
      </GoalMenuDialog>
      <GoalMenuDialog
        startIcon={<PictureAsPdf />}
        Component={PDFViewerdDialog}
        componentProps={{
          apiHandle: deliverySheetPdf,
          apiParams: { id, companyId: currentCompany }
        }}
        tooltip={'Ver hoja de carga'}
      >
        Hoja de reparto
      </GoalMenuDialog>
      {canCreateDespatchAdvice && (
        <ConfirmationMenuItem
          startIcon={<NoteAltIcon />}
          helpText={'Generar guías de remisión'}
          onConfirm={handleRegenerateDespatchAdvice}
          label={'Regenerar guías'}
          confirmationMessage={'¿Está seguro que desea regenerar las guías de remisión?'}
        />
      )}
    </GoalMenu>
  );
};

HROpenMenu.propTypes = {
  onRefresh: PropTypes.func,
  row: PropTypes.object
};
