import PropTypes from 'prop-types';
import { Card, CardContent, CardActions, Grid2, Typography } from '@mui/material';
import { PropertyList } from '../property-list';
import { PropertyListItem } from '../property-list-item';
import { Pencil as PencilIcon } from '../../icons/pencil';
import GoalButton from '../goal-button';

export const MaintenanceDetailDefault = ({
  onEdit,
  item,
  companyExist = true,
  emailExist = false,
  btnEdit = true,
  company = []
}) => {
  const getNameById = (id) => {
    const nameTableCompany = company.find((item) => item.id === id);
    return nameTableCompany ? nameTableCompany.short_name : id;
  };

  return (
    <Card>
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2
            size={{
              xs: 12,
              md: 6
            }}
          >
            <PropertyList>
              <PropertyListItem align="end" label="Codigo">
                <Typography color="textSecondary" variant="body2">
                  {item === null ? '---' : item.code}
                </Typography>
              </PropertyListItem>
              {companyExist && (
                <PropertyListItem align="end" label="Empresa">
                  <Typography color="textSecondary" variant="body2">
                    {item === null ? '---' : getNameById(parseFloat(item.company_id))}
                  </Typography>
                </PropertyListItem>
              )}
              {emailExist && (
                <PropertyListItem align="end" label="Email">
                  <Typography color="textSecondary" variant="body2">
                    {item === null ? '---' : item.email}
                  </Typography>
                </PropertyListItem>
              )}
              <PropertyListItem align="end" label="Nombre">
                <Typography color="textSecondary" variant="body2">
                  {item === null ? '---' : item.name}
                </Typography>
              </PropertyListItem>
            </PropertyList>
          </Grid2>
        </Grid2>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'end'
        }}
      >
        {btnEdit && (
          <GoalButton
            color="primary"
            startIcon={<PencilIcon />}
            onClick={onEdit}
            variant="contained"
            sx={{ mr: 2 }}
          >
            Editar
          </GoalButton>
        )}
      </CardActions>
    </Card>
  );
};

MaintenanceDetailDefault.propTypes = {
  onEdit: PropTypes.func,
  item: PropTypes.object,
  companyExist: PropTypes.bool,
  emailExist: PropTypes.bool,
  btnEdit: PropTypes.bool,
  company: PropTypes.array
};
