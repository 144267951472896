import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Card, CardContent, CardHeader, Paper } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { useApp } from '../../../hooks/use-app';
import gtm from '../../../lib/gtm';
import GadminActions from '../../../contexts/gadmin-actions';
import { SurveyTable } from '../../../components/survey/questionnaire/survey-table';
import { Query } from '../../../components/query';
import { goalSurveyQuestiongApi } from '../../../api/goal/survey/survey-question';
import { RefreshButton } from '../../../components/common/buttons/refresh-button';
import { AddButton } from '../../../components/common/buttons/add-button';

const SurveyList = () => {
  const query = useQuery();

  const { currentCompany } = useApp();
  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const app = useApp();

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    ,
    handlePageChange,
    handleQueryChange,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalSurveyQuestiongApi.getSurveyQuestionsList,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      companyId: currentCompany,
      view: 'all'
    }
    // onPostRefresh: handleClearSelected
  });

  const [selectedDocuments, handleSelect, handleSelectAll] = useSelection(returnsState.documents);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{app.title('Cuestionario')} </title>
      </Helmet>
      <Paper>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}
          variant="outlined"
        >
          <CardHeader
            title="Listado de cuestionarios"
            action={
              <>
                <AddButton to={`/survey/create`} permission={GadminActions.G2_QUESTIONARY_ADD} />
                <RefreshButton onClick={handleRefresh} />
              </>
            }
          />
          <CardContent>
            <Box sx={{ paddingX: 5 }}>
              <Query
                disabled={returnsState.isLoading}
                onChange={handleQueryChange}
                sx={{
                  marginTop: 2
                }}
                value={controller.query}
              />
            </Box>
            <SurveyTable
              error={returnsState.error}
              documents={returnsState.documents}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedDocuments={selectedDocuments}
              sort={controller.sort}
              sortBy={controller.sortBy}
              handleRefresh={handleRefresh}
            />
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default SurveyList;
