import { dataLoader } from '../../data-loader';

const endpointLocal = 'rindegastos/expense-reports';

class RindegastosExpenseReportsApi {
  async getExpenseReports({ companyId, view }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}`,
      params: { companyId, view }
    });
    return response;
  }

  async getExpenseReport({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getExpensesFromExpenseReport({ companyId, id, view }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}/expenses`,
      params: { companyId, view }
    });
    return response;
  }
}

export const rindegastosExpenseReportsApi = new RindegastosExpenseReportsApi();
