import PropTypes from 'prop-types';
import { useGridApiContext } from '@mui/x-data-grid';
import { useEffect, useState, useCallback } from 'react';
import { useGMGrid } from './gm-grid-context';

const GridMasiveActions = ({ component: Component, ...rest }) => {
  const apiRef = useGridApiContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);

  const { handleRefresh } = useGMGrid() || {};

  const handleSelectionChange = useCallback(() => {
    const selected = Array.from(apiRef.current.getSelectedRows().values());
    setSelectedRows(selected);
    setSelectedCount(selected.length);
  }, [apiRef]);

  useEffect(() => {
    apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    // return () => {
    //   apiRef.current.unsubscribeEvent('rowSelectionChange', handleSelectionChange);
    // };
  }, [apiRef, handleSelectionChange]);

  if (!Component || !selectedRows.length) {
    return null;
  }

  return (
    <Component
      selectedRows={selectedRows}
      selectedCount={selectedCount}
      {...rest}
      onAfterSave={handleRefresh}
    />
  );
};

GridMasiveActions.propTypes = {
  component: PropTypes.elementType
};

export default GridMasiveActions;
