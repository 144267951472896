const GadminActions = {
  EDDD: 'd41d8cd98f00b204e9800998ecf8427e',
  G2_ADMIN_MODULES_ADD_MODULE: 'a9ce6ce4-d152-4bd6-8160-ae2ca0dffeaf',
  G2_ADMIN_MODULES_DELETE_MODULE: '31762f09-df70-4411-9426-2278e5a468ef',
  G2_ADMIN_MODULES_EDIT_MODULE: 'e49a1a76-5530-4da6-a834-c5993e13876a',
  G2_ADMIN_MODULES_VIEW_MODULE_DETAIL: '8948701c-6793-4b7d-9f4c-a330a4fd6d00',
  G2_ADMIN_MODULES_VIEW_MODULE_LIST: 'd6cbea65-8c52-40f1-88bf-a9e03c854655',
  G2_ADMIN_PERMISSIONS_ADD_PERMISSION: 'f9123c65-0bc1-4dca-a6be-d362ea86d209',
  G2_ADMIN_PERMISSIONS_ADD_ROLES_TO_A_PERMISSION: '437c5fa7-17b5-41b8-86a1-4e3f0079a569',
  G2_ADMIN_PERMISSIONS_EDIT_PERMISSION: '4fc5d0aa-be46-42d6-94e8-02dfe173930f',
  G2_ADMIN_PERMISSIONS_REMOVE_PERMISSION: '44ebf8fc-f13a-468d-be91-d1b963a2d019',
  G2_ADMIN_PERMISSIONS_RESTORE_PERMISSION: 'eecc10d0-e9d9-4ea7-9048-2e7a7d91aa22',
  G2_ADMIN_PERMISSIONS_VIEW_PERMISSIONS_LIST: '3cafc354-3213-4869-abfe-9ee4ce334861',
  G2_ADMIN_PERMISSIONS_VIEW_PERMISSION_DETAILS: '86202498-88ab-41b2-a4d1-eae61a3a219b',
  G2_ADMIN_PERMISSIONS_VIEW_ROLES_OF_A_PERMISSION: 'fd6df26d-dc3a-4e85-9964-0c8a894f7686',
  G2_ADMIN_PERMISSIONS_VIEW_USERS_OF_A_PERMISSION: '57a2ea0c-671a-4ceb-941b-e8358b3d3608',
  G2_ADMIN_ROLES_ADD_PERMISSIONS_TO_A_ROLE: 'a13ae5b9-619a-459e-aa39-41a2a5788cfb',
  G2_ADMIN_ROLES_REMOVE_PERMISSIONS_FROM_A_ROLE: 'f3f37404-46fb-4b94-92f8-1f23fd0e0f2a',
  G2_ADMIN_ROLES_VIEW_PERMISSIONS_OF_A_ROLE: '52919c23-a03b-42fc-affb-8e929e742076',
  G2_ADMIN_ROLES_VIEW_ROLE_DETAIL: 'e0dbeb92-b44e-491a-b662-c5bee50a092c',
  G2_ADMIN_ROLES_VIEW_ROLE_LIST: '84395803-ea45-4ff4-a868-1e34a68afbec',
  G2_ADMIN_ROLES_VIEW_USERS_OF_A_ROLE: 'ccc47357-f795-4457-ae9a-b59fcdbf35c1',
  G2_ADMIN_USERS_ADD_COMPANY_TO_A_USER: 'f986edc9-6daa-4048-be14-deefe5b3fe9d',
  G2_ADMIN_USERS_EDIT_A_USER_COMPANY: '5f24695c-5d16-44b5-a0ec-4affbad78705',
  G2_ADMIN_USERS_VIEW_A_USERS_COMPANIES: '88bff03f-dda3-439d-bf79-f97c7211e9cb',
  G2_ADMIN_USERS_VIEW_A_USERS_DETAIL: '0e739e97-a9bd-4ac5-8577-eac8a63e4668',
  G2_ADMIN_USERS_VIEW_A_USERS_PERMISSIONS: 'd7ac08c6-caed-40a5-998b-91b01d3b43ee',
  G2_ADMIN_USERS_VIEW_A_USERS_ROLES: 'aaa21a6e-c999-4b0c-a799-2f33dee39ad5',
  G2_ADMIN_USERS_VIEW_A_USER_COMPANY_DETAILS: '825d3120-ab91-4b5a-b52c-e63f6409e297',
  G2_ADMIN_USERS_VIEW_SAP_USER_OF_A_USER: 'f1adc6c0-2260-4b8e-9b4e-e8358ae1278e',
  G2_ADMIN_USERS_VIEW_USER_LIST: '8ef0020f-f7d1-41d9-a01e-67667e1c6488',
  G2_APP_LOG: 'b72b11255b471a27bbc218d904e2032c',
  G2_BP_GEOLOCALIZAR: 'b8e0526da33333b9af0801077649a657',
  G2_BULK_LIST: '424fe4bbc80be7038f2e6e61f3d7bdd9',
  G2_CD_AUTH: '520bececd9c40bbf55cf2088d92196f4',
  G2_CD_CANCEL: 'a78869f99674627bdbb1876cd46db169',
  G2_CD_GEN: 'e648c8af69024a1f845b9472559a83e1',
  G2_CD_LINE_UP: '470e90a3253a5fcd22621ed5cd97208c',
  G2_CD_LINE_UP_MEMO: '9da2d82a1f6b19d99b7cf59b69afd947',
  G2_CD_LINE_UP_QUANTITY: '8792d3e4a2a67bb220d51c96469518ef',
  G2_CD_LIQ: '32a75706513a1f0f6373d18eab668881',
  G2_CD_LIST: '814dfdbbbe99712d672fb1bd6202c07c',
  G2_CD_LIST_HST: '5464e7b53f8ed4ce3946a320d820541c',
  G2_CD_PROGRAM: '5123a392561d3dc9db498d6f1a9f3bfe',
  G2_CD_REACTIVE: 'd6b0afcd3f79dbe7e8837aaeb04962ca',
  G2_CD_REASON_LIST: '3d5523e235958a523864f58362e6ea79',
  G2_CD_REASON_UP: '5ac7a5815af3458ccdbdc33fcfcd7db7',
  G2_CD_UNPROG: '1afe4293ab528c195eaeab0ab6980d53',
  G2_COMMERCIAL_GENERATE_COMMISSIONS_FOR_REGIONAL_MANAGERS: '1e33282c-9466-418f-b1b7-862ecbf7cf37',
  G2_COMMERCIAL_GENERATE_COMMISSIONS_FOR_RETAIL_SELLERS: '437f5341-7147-418c-9c45-9651c716c26a',
  G2_COMMERCIAL_GENERATE_COMMISSIONS_FOR_SUPERVISORS: '553f090a-6302-49bf-a967-e05c6ac852fa',
  G2_COMMERCIAL_GENERATE_COMMISSIONS_FOR_WHOLESALE_SELLERS: 'e2154439-a2ad-43d7-a9cd-171e29c741a8',
  G2_COMMERCIAL_GENERATE_COMMISSIONS_FOR_ZONE_MANAGERS: '92f75c11-fc95-47dd-80fa-c2fb65f07074',
  G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_REGIONAL_MANAGERS:
    'bfa2f7d0-b870-4b55-ad96-c24a74190a2e',
  G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_RETAIL_SELLERS: 'aa3ab673-5b54-40e8-a1f6-2980252aa5f2',
  G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_SUPERVISORS: '5b6c03a7-f406-4e1f-aff9-684c97f57d85',
  G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_WHOLESALE_SELLERS:
    '69d0c336-7365-4bbc-9b93-a72994872ae8',
  G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_ZONE_MANAGERS: 'e436e339-849a-4afb-b1fd-49e314bb3261',
  G2_COMPANY_ADMIN: '8c11fa51-53b5-45d6-936a-ab01632875ca',
  G2_COMPANY_LIST: 'a35fea8617ccdfb58d9c1bd07fb02dde',
  G2_COMPANY_UP: '66c8419b777bf8b18155f0e0d200b133',
  G2_CONTESTS_ACTIVATE: 'eb9078940adc4ae62bafb3e2dc1165bb',
  G2_CONTESTS_ADD: '22a07c9c5195b1f7fd662b10e8f839a6',
  G2_CONTESTS_CANCEL: '21dc3ff2e341a7695aa645f3744a075c',
  G2_CONTESTS_CLOSE: '8195de71e9835186a103d123da17d062',
  G2_CONTESTS_LIST: 'e1ac6e72d8266cf71dd378b10ea8aa6c',
  G2_CONTESTS_UP: 'c03555c97b62941372b7523092b08339',
  G2_CUST_LINK_ABOUT_TEST: '8f7f4c1ce7a4f933663d10543562b096',
  G2_DEX_ACTIVATE: '8b20b4924ecd543be3d814037f1a0b28',
  G2_DEX_ADD: '361681b8b49a1e83d04ca4edeefc540d',
  G2_DEX_CANCEL: '0fd1e8d4a113fa93956b37e166023e7e',
  G2_DEX_MY_REPORTS: '0d377caae8db4a3e3b467d1e9c327fe4',
  G2_DEX_UP: 'f3f8981fa21f73bda56d9e9e0ebd6081',
  G2_DOCUMENT_BULK: 'dd30dd3c9a5d0b46a7df83dcd2c6e1f2',
  G2_FLEET_VEHICLES_CREATE_VEHICLE_ODOMETERS: 'a78c5ed1-b5b0-4b3c-b3c3-80ecc81f2150',
  G2_FLEET_VEHICLES_VIEW_ATTACHMENT_VEHICLE: 'e722e005-7a16-4c23-8fbf-1d7a820d906e',
  G2_FLEET_VEHICLES_VIEW_DETAILS_VEHICLE: '1c52aca4-bbdc-42c7-98ec-81657187a652',
  G2_FLEET_VEHICLES_VIEW_VEHICLES: '05480e32-7d89-4d22-9528-3536cf34c686',
  G2_FLEET_VEHICLES_VIEW_VEHICLE_ODOMETERS: '5ddc5fa9-aa1e-4092-a530-6ad4b7ab3026',
  G2_FLEET_VEHICLES_VIEW_WORK_ORDERS_VEHICLE: '4a2281a1-1510-4f71-91c7-906cd693432e',
  G2_FLEET_WORK_ORDERS_ACTIVATE_WORK_ORDER: '5df0338f-cc18-4502-aa07-51dee1b634f7',
  G2_FLEET_WORK_ORDERS_APPROVE_WORK_ORDER: 'd47fef1f-abf1-4e29-9247-4c6902b2a2fa',
  G2_FLEET_WORK_ORDERS_CLOSE_WORK_ORDER: '09233240-dc6c-4b5b-bdd8-9c0ce53e6980',
  G2_FLEET_WORK_ORDERS_COMPLETE_WORK_ORDER: 'a7f13c73-1785-4b3a-a42d-e0d2cf4cdb72',
  G2_FLEET_WORK_ORDERS_CREATE_WORK_ORDER: '735ce02a-a6ce-4772-a29d-0c1279908339',
  G2_FLEET_WORK_ORDERS_DOWNLOAD_ATTACHMENT: '48973a5c-4585-47ac-88d8-85de3ec95914',
  G2_FLEET_WORK_ORDERS_SEND_TO_APPROVAL: 'aa896220-da25-44c1-8fef-cca44d92fea6',
  G2_FLEET_WORK_ORDERS_SEND_WORK_ORDER_EMAIL: '4acb4408-6c68-4ba0-81c3-c35ef7ba123c',
  G2_FLEET_WORK_ORDERS_SYNC_WORK_ORDERS_FROM_FLEET_MANAGER: 'ab759e44-fe5b-4fa6-9fb4-9af6eb3ede3b',
  G2_FLEET_WORK_ORDERS_UPDATE_A_WORK_ORDER: '48695d48-44f7-4165-adc7-38e6424af8c7',
  G2_FLEET_WORK_ORDERS_UPLOAD_ATTACHMENT: 'd6ad4cb6-7228-47cf-8128-ec6954bf24b0',
  G2_FLEET_WORK_ORDERS_VIEW_ATTACHMENTS: '34622e01-a8be-4a0e-a4f2-ce18f784e129',
  G2_FLEET_WORK_ORDERS_VIEW_DETAILS_WORK_ORDER: '286ad832-b668-4a8d-8213-36b8b2020ede',
  G2_FLEET_WORK_ORDERS_VIEW_SERVICE_ENTRIES_WORK_ORDER: '42c930b7-b9a9-4384-9081-18527cc9a308',
  G2_FLEET_WORK_ORDERS_VIEW_USERS_ENABLED_TO_APPROVE: 'aa9e91ef-49de-4f2a-9f0d-9b59531fc5d9',
  G2_FLEET_WORK_ORDERS_VIEW_WORK_OPRDER_REPORT: '4fa00f11-b6af-493e-9cbb-642cc8635412',
  G2_FLEET_WORK_ORDERS_VIEW_WORK_ORDERS: 'cdbd8ca8-764c-4778-bf47-c868e8253313',
  G2_GEX_PERMISSION_DB_UPDATE: '1c0eab2e472690e9ca5acf805d7a8c4f',
  G2_GOAL_INV_PRODUCTS_ACTIVATE: 'd016645c-11be-44d8-8bd5-d4f17fefb9e0',
  G2_GOAL_INV_PRODUCTS_ADD: '6e5f7fe7-7459-42cd-87eb-873a0c3fd83d',
  G2_GOAL_INV_PRODUCTS_DELETE: 'a5b3edce-bfcf-4ae3-9c6b-0e0be8025bd5',
  G2_GOAL_INV_PRODUCTS_EDIT: 'e115aaef-4abe-43f7-a959-6bc85bf321bf',
  G2_GOAL_INV_PRODUCTS_INACTIVATE: '32b74f4f-d853-4112-b293-ffc039745624',
  G2_GOAL_INV_PRODUCTS_LIST: '6957c0d2-6cd5-429c-93e4-39cc11fc56a3',
  G2_GOAL_INV_PRODUCTS_UPDATE_WEIGHTS_AND_VOLUMES: '6e1847e3-2b47-40d3-9ebc-26891f998237',
  G2_INCIDENCE_ACTIVATE: 'c1456d8cad4f50085b5c803b8dd47470',
  G2_INCIDENCE_ADD: '4b81a7a1d23fa9f1db8386ba9e2dc2c3',
  G2_INCIDENCE_CANCEL: 'bb7d2002ce83dc206e7b056720257953',
  G2_INCIDENCE_CLOSE: '0e27c12c9119889211eec4d9b065176d',
  G2_INCIDENCE_LIST: '7f8526fd0f2523eace75b037063cd596',
  G2_INCIDENCE_UP: '9c72a53213bab8f92d99303c1ca5ceb4',
  G2_KARDEX_PROCESS: 'dbdd6e9962a8e973e4e2f4f868f46f8a',
  G2_LOGIN: '7cb0a93eb86b70848c59bd56b603df37',
  G2_MODULE_LIST: '68fd7b7f-2385-4444-9775-86ae40f7fcc1',
  G2_PROFIT_CODES: '2343fd960f1cd924ebea244b2b36c78c',
  G2_PURCHASE_DISCARD: '6bca6f08288a6564836de0d74901f639',
  G2_PURCHASE_FFEE_ITEM_AUTH: '9a351418531e542a8527b2d2083bbdd9',
  G2_PURCHASE_FFEE_ITEM_DENY: 'f6929b985773ea68cfa0750e45bc2c1d',
  G2_PURCHASE_FFEE_ITEM_LIST: '6f4780bee107c5783344737855532a59',
  G2_PURCHASE_FFEE_ITEM_UP: '78e58a5f53637113ffadb582f964dd3c',
  G2_PURCHASE_FFEE_LIST: '4056337c00bd5787e72e89744d306aba',
  G2_PURCHASE_FFEE_OPTS: 'c248d0f2ba1b382636addecd08363a61',
  G2_PURCHASE_FFEE_SERVICE_LIST: '481bb96b5ca2e598a3e6ff19a3eed3f1',
  G2_PURCHASE_FFEE_SERVICE_UP: '442df5428b08115c5efdedfdab0eea84',
  G2_PURCHASE_FFEE_SERVICE_VAL: '81f347b2ec288546be0ea2e0c8a45b9e',
  G2_PURCHASE_NCES_LIST: 'c7b8df8f5ff9e702083ddd75ba98f22a',
  G2_PURCHASE_NCES_UP: '98246ba61c9e5b7e6ddd25499b1fbb68',
  G2_PURCHASE_REACTIVE: '162c0f834e49711508fec026e64bb6d7',
  G2_PURCHASE_RRHH_LIST: '69413ffd53478baf90512b161b38aa20',
  G2_PURCHASE_RRHH_UP: '9ad5177c9acae3ecdbb86b027d48a2b0',
  G2_PURCHASE_RRHH_VIEW_ALL: 'eb5125ef68dddfe5597b6068dce6e5e1',
  G2_QUESTIONARY_ADD: 'ab9ded23002baf2bcce242a85dddb8ce',
  G2_QUESTIONARY_LIST: '24eb5740e250a7eea56fb29e0bbcdc89',
  G2_QUESTIONARY_UP: '0584ad34cb9e15edb0ea0cfcbe31ea93',
  G2_QUESTIONARY_VALIDATE: '0ffa3c44c48168aceef23bf2f7586fcb',
  G2_QUEUE_LIST: '9a9846c8160f81324a236376ebc4b0a2',
  G2_RINDEGASTOS_VIEW_EXPENSES: '5d58b36e-3aa9-47f8-a8e1-5ecfe51c54c5',
  G2_RINDEGASTOS_VIEW_EXPENSES_REPORTS: '934917df-2531-465b-b1f9-ee60a2f1e32d',
  G2_RINDEGASTOS_VIEW_EXPENSE_DETAILS: 'f2ad67fb-0a80-40fd-ac64-4dd7196ca4e0',
  G2_RINDEGASTOS_VIEW_EXPENSE_REPORT_DETAIL: '0958dd0a-8929-4852-b72c-156861592d49',
  G2_RINDEGASTO_ADMIN: 'ac65da99-525b-4b43-a295-70f1607d9717',
  G2_RINDEGASTO_ADMIN_USERS_LIST: '2a4a7f29-1cd8-4682-b148-4ac9adad25e4',
  G2_RINDEGASTO_USER_EXPENSE_LIST: '490ed7d6-f712-404d-b95f-0604295438e8',
  G2_RINDEGASTO_USER_REPORT_LIST: 'b273373c-45ab-46e9-935f-a595d3662e23',
  G2_SIRE_PURCHASES_ACCEPT_PLE_DIFFERENCES: '6fb32ec2-f117-4dc9-b5be-9cc852edcdf1',
  G2_SIRE_PURCHASES_ACCEPT_PROPOSAL: '691f3303-db73-4570-a25a-56217fb25dae',
  G2_SIRE_PURCHASES_DELETE_PLE_DIFFERENCES: '0b428c20-ec64-463b-8dc3-919288fb98a3',
  G2_SIRE_PURCHASES_EXPORT_PLE_EXCEL: 'c20a34a8-2834-4f99-92bd-5b2b124e2b0c',
  G2_SIRE_PURCHASES_EXPORT_PROPOSAL_EXCEL: '4130752b-2e0b-4e3d-9d4b-cbfdd9036501',
  G2_SIRE_PURCHASES_GENERATE_PROPOSAL_REPLACEMENT_TXT: '191fd237-f2f2-45f4-a1d3-08d90ddc105b',
  G2_SIRE_PURCHASES_LIST_EXCLUDED_CP_SUMMARY: 'ec179587-932a-4627-b2cd-2cf2a10669a8',
  G2_SIRE_PURCHASES_LIST_GENERATED_SUMMARY: '85c9038b-d6f8-45dd-b477-fface8ce2b10',
  G2_SIRE_PURCHASES_LIST_PAYMENT_VOUCHER_INCONSISTENCIES: '7f64f4a5-e9ab-4351-99c7-efc809a62748',
  G2_SIRE_PURCHASES_LIST_PERIODS: 'df8cfd8e-fc56-4549-a8fa-d8708a93c24c',
  G2_SIRE_PURCHASES_LIST_PLE: '852cb58b-d051-40da-aa33-c8014618194a',
  G2_SIRE_PURCHASES_LIST_PLE_DIFFERENCES: 'bcd6d42a-f6eb-4b11-943f-599b0496706c',
  G2_SIRE_PURCHASES_LIST_PRELIMINARY_SUMMARY: '7705d3f7-431a-489f-9283-06d0afbde1f8',
  G2_SIRE_PURCHASES_LIST_PROPOSAL: '203cfc01-821d-432a-89ec-5849341c62f1',
  G2_SIRE_PURCHASES_LIST_PROPOSAL_REPLACEMENT: '152c013c-5bae-4d46-bc9a-58933b0b22a8',
  G2_SIRE_PURCHASES_LIST_PROPOSAL_SUMMARY: 'b7428117-0d21-4b6a-b0c4-0d3811d2ec81',
  G2_SIRE_PURCHASES_LIST_TICKETS: '556efdb8-dcbe-43ee-9ab7-945a27265e7f',
  G2_SIRE_PURCHASES_LIST_TOTAL_AMOUNT_INCONSISTENCIES: '2a580874-5374-4f37-9b36-a9fee3d4cf95',
  G2_SIRE_PURCHASES_SAP_GENERATE_FOREIGN_PURCHASES_REPLACEMENT_FILE:
    'f80e947e-7741-4f7f-b40a-1f7e99ba4b7c',
  G2_SIRE_PURCHASES_SAP_LIST_FOREIGN_PURCHASES: '6d2d28bf-8d0d-41ba-ba76-15cebb5b72f3',
  G2_SIRE_PURCHASES_SAP_SEE_FOREIGN_PURCHASES_ITEM_DETAIL: 'cdca0174-e09f-4231-8898-894ff83be2eb',
  G2_SIRE_PURCHASES_SAP_SEND_FOREIGN_PURCHASES_REPLACEMENT_FILE:
    '3ef11951-d585-4066-bf59-983de84d0a05',
  G2_SIRE_PURCHASES_SAP_SYNC_FOREIGN_PURCHASES: '02b65dd0-26bb-45a5-9f6f-54017238ae2f',
  G2_SIRE_PURCHASES_SEE_CP_SUMMARY_TAB: '7c7cf462-9303-44a6-8116-cebab98b4836',
  G2_SIRE_PURCHASES_SEE_INCONSISTENCIES_TAB: '334d541f-f310-4e76-a510-7318c482bb6a',
  G2_SIRE_PURCHASES_SEE_MANAGEMENT_INFO: 'e1235be4-3beb-48f4-9bbc-5e762b9059ba',
  G2_SIRE_PURCHASES_SEE_MODULE: 'bdfbb53b-539f-4309-87a4-398c1ad0578f',
  G2_SIRE_PURCHASES_SEE_PLE_DETAIL: '18c28042-f836-4dfc-9633-e5ada23eb7d3',
  G2_SIRE_PURCHASES_SEE_PROPOSAL_DETAIL: '3d76dbc1-6c4f-472e-8213-e83ccbbe383f',
  G2_SIRE_PURCHASES_SEE_PROPOSAL_REPLACEMENT_DETAIL: '460006ff-eba0-43de-84ad-cbf7cb171f0d',
  G2_SIRE_PURCHASES_SEE_RECORD_GENERATION: '06c0869d-13e6-4c74-8a91-eeba099fd93f',
  G2_SIRE_PURCHASES_SEE_SAP_COMPARISON: 'f31a6cf3-c71e-4d7e-9267-1347c324a2b9',
  G2_SIRE_PURCHASES_SEE_SAP_PURCHASES: 'f3ec5895-23fe-402b-9cab-0b76c59b707b',
  G2_SIRE_PURCHASES_SELECT_PLE_DIFFERENCES: '259aff9f-74d1-4d32-bfc6-7b2f68ff54f1',
  G2_SIRE_PURCHASES_SEND_PROPOSAL_REPLACEMENT_TXT: 'ae829e34-73d5-4052-a2aa-34aa10f792a7',
  G2_SIRE_PURCHASES_SYNC_CP_SUMMARY: '04d8a70a-54ba-42cf-a23a-4d5d80eff8f8',
  G2_SIRE_PURCHASES_SYNC_INCONSISTENCIES: '20bf58a9-afe7-42d6-ab24-a5a201b78c11',
  G2_SIRE_PURCHASES_SYNC_PLE: '829c1765-91c9-4ac0-ba29-9392c0e340a4',
  G2_SIRE_PURCHASES_SYNC_PROPOSAL: 'b55e1fc6-074c-4218-a5c1-5d7019ef494f',
  G2_SIRE_PURCHASES_SYNC_TICKETS: '0718b8d1-daa8-4877-a8ab-7f64f8b0e20c',
  G2_SIRE_SALES_ACCEPT_PLE_DIFFERENCES: 'd089ec78-7b17-460c-b0cf-2cacd0875ca1',
  G2_SIRE_SALES_ACCEPT_PROPOSAL: 'a18c75a6-5fff-473d-90fb-cf36ddb91c29',
  G2_SIRE_SALES_DELETE_PLE_DIFFERENCES: '41c38a79-bae4-413b-823d-419a65c824de',
  G2_SIRE_SALES_DELETE_PROPOSAL_REPLACEMENT: 'f5e4b08e-aeb2-4126-8a66-f0df4240e86e',
  G2_SIRE_SALES_EXPORT_PLE_EXCEL: '053ed000-1e11-45d2-a64e-bd2345037093',
  G2_SIRE_SALES_EXPORT_PROPOSAL_EXCEL: '679a069c-f295-465b-bf73-25b5dd98aaa0',
  G2_SIRE_SALES_GENERATE_PROPOSAL_REPLACEMENT_TXT: '89bdd0c3-a6c8-4280-a39b-287f2605256d',
  G2_SIRE_SALES_LIST_CP_NOT_INCLUDED: '9bdf04e9-8af3-4e0d-b71e-a4b641747ed6',
  G2_SIRE_SALES_LIST_GENERATED_SUMMARY: 'f684556b-be9e-4a67-b328-dc0b4ff04c49',
  G2_SIRE_SALES_LIST_PAYMENT_VOUCHER_INCONSISTENCIES: '87b9c228-0a2b-419d-b7f9-e817655d3ea4',
  G2_SIRE_SALES_LIST_PERIODS: 'a8dee536-f7d5-46f4-8a20-71545915be62',
  G2_SIRE_SALES_LIST_PLE: '4719e482-3ea4-4023-bd6f-8120179b4d15',
  G2_SIRE_SALES_LIST_PLE_DIFFERENCES: '7982c653-c723-4ac2-8d69-8fe9ee527556',
  G2_SIRE_SALES_LIST_PRELIMINARY_SUMMARY: '4b5837d9-77e8-491a-9864-14c4a2dab9c5',
  G2_SIRE_SALES_LIST_PROPOSAL: '0aee6ed0-8795-4ab0-85b6-3b4555647e09',
  G2_SIRE_SALES_LIST_PROPOSAL_REPLACEMENT: '0d1a25a5-cfa9-4877-946a-a1656c948175',
  G2_SIRE_SALES_LIST_PROPOSAL_SUMMARY: '9472f663-636e-49a7-b805-6644489b024a',
  G2_SIRE_SALES_LIST_TICKETS: '6cfb7cdb-0ef3-4424-aa1a-45019ac83364',
  G2_SIRE_SALES_SEE_CP_SUMMARY_TAB: 'f68e3188-e5ee-4e71-8b14-00f083baff86',
  G2_SIRE_SALES_SEE_INCONSISTENCIES_TAB: '55d7926d-fdad-4571-80a8-fef41f5fc88e',
  G2_SIRE_SALES_SEE_MANAGEMENT_INFO: '5aa6038c-a26d-4e69-b224-99e45bcc66be',
  G2_SIRE_SALES_SEE_MODULE: '2d01c167-b0df-407e-8cf6-52743fde047e',
  G2_SIRE_SALES_SEE_PLE_DETAIL: '7f51d457-463b-40eb-8b4e-d9b775f9bbdb',
  G2_SIRE_SALES_SEE_PROPOSAL_DETAIL: '5b4b0afb-604d-4d65-b31f-990060ee88c4',
  G2_SIRE_SALES_SEE_PROPOSAL_REPLACEMENT_DETAIL: '3640d18c-44bc-4ed1-9149-de8af158e09d',
  G2_SIRE_SALES_SEE_RECORD_GENERATION: '7823657b-f9a5-4d74-8b50-74530eac77b2',
  G2_SIRE_SALES_SEE_SAP_COMPARISON: '095f5bfb-2010-4f4d-9ab6-67237d33ca91',
  G2_SIRE_SALES_SELECT_PLE_DIFFERENCES: '0796ff41-2c43-4f3a-bc54-ba5af7b65c9b',
  G2_SIRE_SALES_SEND_PROPOSAL_REPLACEMENT_TXT: '74626b0c-842f-4141-ae84-33169932c68a',
  G2_SIRE_SALES_SYNC_CP_SUMMARY: 'adb38548-ebfc-4329-ade8-1f55607bcf2c',
  G2_SIRE_SALES_SYNC_INCONSISTENCIES: '155cdb38-4665-4ac7-bd88-6e4e66e01889',
  G2_SIRE_SALES_SYNC_PLE: 'fcf3c70f-6341-42e9-b30f-2b4580c24d7d',
  G2_SIRE_SALES_SYNC_PROPOSAL: 'b758ac58-6c5f-4720-b3ee-7b6d652f6200',
  G2_SIRE_SALES_SYNC_TICKETS: '48a2178f-b323-48de-a162-7fb9f49404d9',
  G2_SURVEY_ACTIVATE: '61eb86e767b9b344da0ee098b6dd9dda',
  G2_SURVEY_ADD: 'e30cc5c91fd18dd4c4f8dea92d21c376',
  G2_SURVEY_ARCHIVE: '4657f7d379ebbf951808a269fc6f4b54',
  G2_SURVEY_CANCEL: 'd6d3ffc0e40b768617b19fd5d85369cf',
  G2_SURVEY_CLOSE: 'f6935f1db218fb5a3b5385cff88247a1',
  G2_SURVEY_LIST: '99d3f50b0e3ca06afb1209a582e9248f',
  G2_SURVEY_REJECT: 'edcee7a5a8a9b9b732536b2206871f2d',
  G2_SURVEY_UP: 'c418947101577b0dfac3db70c6489926',
  G2_TEMPLATE_ADD: '754a32e7-b1c6-4cc2-924f-fb34c8c8573f',
  G2_TEMPLATE_DELETE: '525e83be-a8e5-45fc-bc68-149c4f817231',
  G2_TEMPLATE_LIST: '72f9aba0-b619-4b3a-b690-bbbbfaf05294',
  G2_TEMPLATE_UPDATE: 'bb7bf062-0261-42de-bd3e-66bb5fb6c41f',
  G2_TOKEN: '16d1420f960e23345adb06d8cb874ad7',
  G2_USER_CURRENT: 'c7c171da78a1b17606ad8818ad289cd9',
  G2_USER_LIST: '9d654c81-c977-4228-a445-37e4817eac37',
  GADMIN_RECEIPT_DELETED_LIST: '3d7e145b466f6d8009e17c33bb5f298f',
  GADMIN_RECEIPT_DESTROY: '1ba7580222685da3ee2678d5d349ef1e',
  GADMIN_RECEIPT_INDEX: '229b3122f5cf900b2fcf009949a666b5',
  GADMIN_RECEIPT_PURGE: 'e36bd3ddeaa0b2a8e135c55c3e20a008',
  GADMIN_RECEIPT_RESTORE: 'b0ef62de47889dcf4ebc92b19effef2e',
  GADMIN_RECEIPT_SHOW: 'b2eeec4c1bb9b6e305c392e0afa5be6a',
  GADMIN_RECEIPT_STORE: 'b38694d27dc6c27927a4afb4ea0ecebb',
  GADMIN_RECEIPT_UPDATE: '88bcb083631aee9723311eed0e29e6f4',
  GCC_ACCCT_IMPORT_PLE_COMPRAS: '6b26def5dc402c38f0e47526626c2bf5',
  GCC_ACCCT_IMPORT_PLE_VENTAS: '30b516a3e4be3ae80487b42ffaab1981',
  GCC_ACCT_ACCOUNT_DET: '6b16eb477f27934c717eba953d713b93',
  GCC_ACCT_ACCOUNT_FISCAL_DET: '2db065547cf853b0c458f932c1ba6684',
  GCC_ACCT_ACCOUNT_SPECIAL: '51cf19bef1bfc68da2659e781dea2b3e',
  GCC_ACCT_BALANCE: '14115c1181a69dc2dfd73d82d0637dba',
  GCC_ACCT_BALANCE_DET: '2efb87ca8c2c52f9300872f809092c16',
  GCC_ACCT_DDJJ: 'b0183d059ea7d97b5bed58e04edc7f69',
  GCC_ACCT_DDJJ_SAP: '2f19014530f6cc3a96a6a57da8eaf49a',
  GCC_ACCT_INVENTORY_BALANCE: '048d0f7e8edbbcf676f97a91f8d75df6',
  GCC_ACCT_JOURNAL_VIEW: 'd9cbf5c4dea711b710928394fa65b93a',
  GCC_ACCT_PLAN: 'd7fbadb7895a0be312c18921d51b7cb2',
  GCC_ACCT_PLAN_UP: '778066911ef7f75d4ed74bafae789dbf',
  GCC_ACCT_PROCESS: '06e681db9978d8e665df43e1d2967072',
  GCC_ACCT_SALDO_INICIAL: 'f8051e5c3204fe122ec788548bd0e3f7',
  GCC_ACCT_SALDO_INICIAL_UP: '446b521d5c4ce8b3392bb40b68990c89',
  GCC_AV_ADD: '97e676f25c288c56e0d6c968b0a25b2f',
  GCC_AV_AUT: '832f8448253dbca92adad900dd03e44b',
  GCC_AV_CONFIRM: 'b0d0e5af588a169f04ec75caed33677c',
  GCC_AV_DET: '2f4b3f5d433203b127060228f343a472',
  GCC_AV_DEV_SAVE: '3c3d995b7670be90a81faf5e2f73600c',
  GCC_AV_EDIT: '932559bca1b4aafa4dcabc2c352e3f69',
  GCC_AV_END: 'def474948d2238922ac57e705eb5c2f9',
  GCC_AV_END_JOR: 'de4b9d89730ebc6691568a7496b589a5',
  GCC_AV_FIX_DATES: '27333f294ddc13012892aa3f9e50602c',
  GCC_AV_LIST: '06d1b43faa8ffcae417218c75e657fcc',
  GCC_AV_TRANS_ADD: '83253755ae0bc9e8672fdf7b6cd5bd5a',
  GCC_AV_TRANS_CONFIRM: '49a1cea2e73ae808ac6b3aa608259f18',
  GCC_AV_TRANS_DET: '7a4fdd2f98b2429f6975587bf1d17608',
  GCC_AV_TRANS_EDIT: '6dbf361ef007c381e2df269c91789e7e',
  GCC_BALANCE_GADMIN: 'e5a2fb38c96c68a3b1d7de0f7b6a8995',
  GCC_BP_ADDRESS_ADD: '72f4e10b40a98a7603b515b15a408897',
  GCC_BP_CATEGORY: '683ef7efc8cd93e2abfdec406d502859',
  GCC_BP_CATEGORY_UP: '230ac6e614eff30e426770721749fead',
  GCC_BP_CHANEL_UP: '6cf0a798f6a11655fd73fe88145503fb',
  GCC_BP_DISABLE_HELA: 'd650277869b2198031083e962fa90dbd',
  GCC_BP_ENABLE: 'f2258d4c7cde95dae603450ab0ab6088',
  GCC_BP_GIROS: 'cdd90098f6b5813441eda13a70874e53',
  GCC_BP_INFO_UP: '7807d4d627c46e69dc7f11c6c18c4495',
  GCC_BP_SUB_GIROS: 'd346a3897fb936629c1390c6f38dd8e0',
  GCC_BP_SUB_GIROS_ALL: '059558dd619bec1d761b06a344aa01cc',
  GCC_BP_VALIDATOR: 'fbab9e9f20fbd4d50b249da036cf14e4',
  GCC_BROWSE_CARTERA_ACTIVA: '1d79894252fb02c3dc0a595d2d1abbe6',
  GCC_BROWSE_CARTERA_FUERA_RUTA: '1298e25c8c881a29f1a35c2bdbfce116',
  GCC_BROWSE_CARTERA_RUTA: '5e209dc465e1060eb8bc18b6ca8ec8a4',
  GCC_BROWSE_HR: '55c409cc4e6e6e4ac4bbc7afb61650f3',
  GCC_BROWSE_ORDER_DEV: '434453927f2f2fe712fc65e3e184088d',
  GCC_BROWSE_ORDER_DIG: '59f308c4957f6dd3a9b1ef7dfe90af96',
  GCC_BROWSE_ORDER_FAC: 'f77c445f57f910b60f38c6e08fb9eeed',
  GCC_BROWSE_ORDER_GRP: '366028fbb6e2ee77b693d812cf541958',
  GCC_BROWSE_ORDER_PRV: 'e1d9d1f54859215fa614c49ac7d97b89',
  GCC_BROWSE_ORDER_REG: 'dc5c399cc0c8073d980026e3ff78b865',
  GCC_BROWSE_ORDER_RET: 'ab28db72bfe1859e09f253415ce36b5b',
  GCC_BROWSE_PRODUCT: 'af205cde99de77fadb2e9186cf7d9847',
  GCC_BROWSE_PRODUCT_DEMAND: '94ec47db77fd3e3f248d9010b06a46dc',
  GCC_BROWSE_PRODUCT_INVENTORY: 'c7bedef0f3ca2d553acec538e6307b2c',
  GCC_BROWSE_VISITA: 'b43382754dd5c02efc24284d0f1bd074',
  GCC_BROWSE_VISITA_DIA: 'd42e6a8e268629930c7c2de23d55f797',
  GCC_CATEGORY: '16132a9fda366f0dadd0464c91781282',
  GCC_CATEGORY_UP: 'e44d5b1a1c2794b76c8cbdf0ef6cd040',
  GCC_CHANNEL: '0aee820bdfb9b644806242304967be70',
  GCC_CHANNEL_UP: '1ba691e327966564442ae1cec45d04c9',
  GCC_COMM_BONOAVCUOTA: 'e6a5d15006f7f47d248c1dcca829750b',
  GCC_COMM_BONOAVCUOTA_ADD: '5bacf2c382a3321e7a350e47e07f85ee',
  GCC_COMM_BONOAVCUOTA_DEL: '4ee9585bab32ac0b005a7f625e2d78ef',
  GCC_COMM_CARGACUOTA: '5fe8f67b26654b053c75d5e32925ace1',
  GCC_COMM_CARGADELTA: '6eb3dde51562910d6010b0858a59adfb',
  GCC_COMM_CUOTAS: '927b0dfab24b703baa8a693c2c325525',
  GCC_COMM_FER: '2d9bc692e49d7d74a5c5c9054313bdf1',
  GCC_COMM_PESOS_PRVLIN: 'c189c06a7efb9aeb76eeb4828a557865',
  GCC_COMM_PESOS_PRVLIN_ADD: '5fa976f4a139ffe22d40383d12634411',
  GCC_COMM_PESOS_PRVLIN_DEL: 'e4303cef5d1158afa28505fc0d518a1e',
  GCC_COMM_PESOS_PRVLIN_UP: '43b943ad78ede7e146f9e6ea68737cd5',
  GCC_COM_PRV_FAM: 'c10b973375880528849291da0c0fa25c',
  GCC_COM_PRV_FAM_ADD: '2c2fd7abbed2afb3f9af45a10d3bbf07',
  GCC_COM_PRV_FAM_DEL: '0835c2363af14fd5d5468fc5d06a92ae',
  GCC_CORE_AKEY: '596ed68fb09c38938b0f0405e0515f20',
  GCC_CORE_AKEY_ADD: 'a22ec7e9cb3d7df0c236591dd2aa2bd8',
  GCC_CORE_AKEY_UP: 'f3c7f0357ea7001c74743b9f4de0a731',
  GCC_CORE_AUTOFIX: 'f586e4a033b432ad311cefebb2d6e4b7',
  GCC_CORE_EXT_LOGIN: '54b5b0e004d7af9b5c8df139c7329cf7',
  GCC_CORE_GET_API_FILE: '5d9186db3c43852e5bb2a2b0adc9bf5f',
  GCC_CORE_LOG: '21490fd7ad1325f5934f5f2c5947ebec',
  GCC_CORE_OPT_ACCESS_DELETE: 'acd3acb79e68ea502f041ae333ac842e',
  GCC_CORE_OPT_ROLES: '5125e576823c5d805410faedf1380c87',
  GCC_CORE_OPT_TO: 'd14597e29dbaaf585c25afff5a217d81',
  GCC_CORE_OPT_UP: '89ae54ac75bd7eefa8e4fd53961e7e65',
  GCC_CORE_ROL_USERS: 'efa5069f2411d328efaf51132b1be578',
  GCC_CORE_SEARCH: 'f1163fad73289c76bcf95c96ec5a8048',
  GCC_CORE_TOKEN_NEW: '4360b6c66959485204ff617ca8e600ba',
  GCC_CORE_USER_ROLES: '22233f0699572dc03badffa99f2b5bbc',
  GCC_DELIVERI_GR_CREATE: '839fd0009d103d949af05aea43986e5f',
  GCC_DELIVERI_GR_CREATE_HR: '125daba8db3a650b2cdd61c2d6397c3e',
  GCC_DELIVERY_CHOFERES: '74c004651d23a0c6195feb1e652d82fa',
  GCC_DELIVERY_CHOFERES_UP: 'f72d272703665a38258568734151d2e6',
  GCC_DELIVERY_DFORGR: '97b43a98296c03eee61f5a97565ab2e2',
  GCC_DELIVERY_FREGR_OPTS: '799a7eeccd5cf2bdef63ad27c230322d',
  GCC_DELIVERY_GET_DOCS: 'ada5800f7dac6d33bcd2f3f7699e9734',
  GCC_DELIVERY_HREP_CONFIRM: '78daa6ca82fe7c17fc3d5c03809267aa',
  GCC_DELIVERY_HREP_HELPERS: 'd59b0931f20bb3dc62a2530052302a25',
  GCC_DELIVERY_HREP_LOG: '3e2a98555cc03f8bac81d206447e8e42',
  GCC_DELIVERY_HREP_ORD_PENDS: 'abd60165cb820cab3973a05ff35afc7d',
  GCC_DELIVERY_HREP_PENDIENTE: '2ac86ad22ae00e7d4059167cabb05d0b',
  GCC_DELIVERY_HR_DESPACH_DATE: '397cff2462d6b3cf742d16cbebb76f0a',
  GCC_DELIVERY_HR_JR_DATE: '6aff11c4b903dea98f174f5515e30bb1',
  GCC_DELIVERY_LIQ: 'b836b06c84bbba4171a88b07a911f74a',
  GCC_DELIVERY_LIQ_DOC: '702f3cb3ca9a573bddd79b56a03b9295',
  GCC_DELIVERY_LIQ_DOC_DET: '8295d17a3e19c90c4b11854fac8840d4',
  GCC_DELIVERY_LIQ_DOC_DET_UP: '2e1b8737c2e647a4d5de6a2a1611bd9d',
  GCC_DELIVERY_LIQ_DOC_FIN: 'a61ddee59ee6b8dbd19ba778b83aa249',
  GCC_DELIVERY_LIQ_DOC_UP: '996bbd52431d6bb41209816240bb24c1',
  GCC_DELIVERY_LIQ_ITEM_QUERY: '9de51d6da0ffebea5106e6833d56646e',
  GCC_DELIVERY_LIQ_MER: '3303eea481448760abcc69b15d9c8cba',
  GCC_DELIVERY_LIQ_MER_FIN: 'e366d029f756521cdcfb64b7db06b3e3',
  GCC_DELIVERY_LIQ_MER_UP: '4a0254958d6a3f52cb8ba5d39c135b65',
  GCC_DELIVERY_LISTMOTIVOS: '661c520fe377577fe45b88b0e5bdeac9',
  GCC_DELIVERY_LIST_VEH: 'da5c5628cf7e9b2faa878304503c73ca',
  GCC_DELIVERY_PAR_APOYOREP: 'e3c297640747b5f98323096c6f7b89d0',
  GCC_DELIVERY_PAR_APOYOREP_UP: '4d1780d59cd28e2ccfe7725890ba8df8',
  GCC_DELIVERY_PAR_APOYOTMPL_UP: '1254b873a47e14f8eaa75747c3ebb12b',
  GCC_DELIVERY_PRELIQ: '20a938bc01cc5944d8435400ddf938e3',
  GCC_DELIVERY_PRELIQ_CLOSE: '6506075edc7958e3ec83e9d6ad3d16f3',
  GCC_DELIVERY_PRELIQ_UP: '2d3199cde5c1eaf34fc4e45e733e2c57',
  GCC_DELIVERY_PROG: 'bff5920d17ea62d9ba0ff08e31d25232',
  GCC_DELIVERY_PROG_AN: '96a68ce0d63d816213bfc4bf46bf4430',
  GCC_DELIVERY_PROG_CHECK: 'c33ee9a98578ba55028b67ecf476d016',
  GCC_DELIVERY_PROG_ED: 'fbcccbd376698dc65a651c6bf8e130e2',
  GCC_DELIVERY_PROG_GE: 'ebf77af2369dbf8aab0491dafae2f567',
  GCC_DELIVERY_PROG_REFRESH: 'bdb2cc9ae99a5ca61258804a408e8bf1',
  GCC_DELIVERY_PROG_UP: 'fc032a9884cfce00ca4bdd776252e6c9',
  GCC_DELIVERY_PROG_UPDATE: 'c81fc6a43992f94b683c5df1b52ed49b',
  GCC_DELIVERY_VEHCHECK_ANUL: 'e7498907294175f466668f41792aa059',
  GCC_DELIVERY_VEHCHECK_CLO: '485e905587e8ab9aae039f6caf2b4bcc',
  GCC_DELIVERY_VEHCHECK_CREATE: 'a80573f6a9c2727455def4aee4d97c06',
  GCC_DELIVERY_VEHCHECK_DET: 'b7f68fe47a85dc7b83af93246cce486c',
  GCC_DELIVERY_VEHCHECK_DET_UP: '9e058b74a8c5580af058fdac836eaacd',
  GCC_DELIVERY_VEHCHECK_TMP: 'df043e5efe028508936c40c9ff60c313',
  GCC_DELIVERY_VEHCHECK_UP: '197c06c581c456ccc6f6aed11c2c6e2f',
  GCC_DELIVERY_VEH_CHECK: 'b5b7f9c74a6e64cb6a9e53f2c97af3cf',
  GCC_DELIVERY_VEH_UP: '07bcaec0b0c1f2450ba3892f8a408292',
  GCC_DET_DEV_DIG: '6a7ca18087cc30557364e83786e9a363',
  GCC_DET_DEV_STATUS: 'ac7c00311981ffcb46b7202081f19578',
  GCC_DET_HR: '0c5ecdd7bdb7966abe5194f161667b1e',
  GCC_DET_ORDER_DIG: '99f1cbde0de5a8db17b14e0b42e97b45',
  GCC_DET_ORDER_FAC: '4883373a2311676b6fa13d52afdd3faf',
  GCC_DET_ORDER_PRE: '2c4b9c4d2dd583bc95ccfacc7ba52f81',
  GCC_DET_ORDER_REG: '14b5fc18e1008a7e34751664638c8a99',
  GCC_DET_ORDER_RET: '1629c5255f4e9d3db71b5d0f8994b242',
  GCC_DET_PRODUCTO_PEDIDO_MOVIL: '6b585e62632a685126b4ccb7cc9497b1',
  GCC_DET_PROMO_DOC: 'acbb6b4454ceeb99878d6a018eaee0ff',
  GCC_DET_PROMO_DUPLI: '0e3f4bbd1f345a76c2ff6cb248b43dfa',
  GCC_DET_PURCHASE_DEV: 'aef54fcf21be272a01ed6a9edf1d83e1',
  GCC_DET_PURCHASE_GR: '2f85de78ee8618cd2769069865b4bc30',
  GCC_DET_PURCHASE_INV: '3db58d2c24c5b13a5dd6acfe6cab279a',
  GCC_DOCUMENT_CANCEL: 'cda6441b0b31de09b7baeebbfc0b44aa',
  GCC_DOCUMENT_DETAIL: 'f00143a3edf381fc0d14a4789b18380d',
  GCC_DOCUMENT_JOURNAL_IMPORT: 'b5b00d5237a0897d9a7d8934d7d548b0',
  GCC_DOC_ANALIZE_SALES: '6dcc26405e276c4158828f35e2d5f525',
  GCC_DOC_AUTH: '77c16d1d744c5d788abf75d4a2c6a571',
  GCC_DOC_AUTH_SAVE: '193f8464cedb36e5bdcfd994c988b8fd',
  GCC_DOC_DIFFS: 'e2d1c1e8e5bf5dc89efb539bfd55923b',
  GCC_DOC_DSCTO_QUERY: '07d764915fa4f0ec84eb200b62081972',
  GCC_DOC_ED_OPTS: 'dc75db6e94b4198b0031b93d750eddc2',
  GCC_DOC_FINAL_SHOT_DISCOUNT_UP: '427a92f77afbf916b81924f82ed72fcc',
  GCC_DOC_FINAL_SHOT_STOCK: '9a98143689d915e08f3e98a2cb08ba8e',
  GCC_DOC_HR_PAYMENT_UP: 'b67b748fea3969cc749e94e8292acf6f',
  GCC_DOC_LINE_ED_OPTS: '081bae682805a7fe9fe6dcfd26372b3c',
  GCC_DOC_NOTES_APPLY: '79f0e6a1a76c9d1765a50c451cc55023',
  GCC_DOC_NOTES_APPLY_PROC: '2e738e17f7c7438fafac126d29e64f54',
  GCC_DOC_NOTES_APPLY_UP: '7188ff58e6f63daf07bb6bd516f4ea29',
  GCC_DOC_NOTES_AUT: 'f123d6bc5e9863327ed57d6bb434f6fb',
  GCC_DOC_NOTES_BP: '77e1d78290aa61ce4b8c90322628409e',
  GCC_DOC_NOTES_DENY: '728e81ccf04e74225f4629593529d023',
  GCC_DOC_NOTES_DISCARD: '0f894506ba56a0e1235f3b5c48639485',
  GCC_DOC_NOTES_FOR_APPLY: '553812a1a31070e9a61294430980f95f',
  GCC_DOC_NOTES_FOR_APPLY_UP: 'c8c866a39300afba7ab65cc0507d5d7a',
  GCC_DOC_NOTES_LIST: '5f2e8d218c04add84c309e7083da3cd2',
  GCC_DOC_NOTES_UP: '607b00ac52fafe17e31d67cccad843b4',
  GCC_DOC_PRECEPTION_CREDIT: '5674c83d1195f424ae7628e2613bb320',
  GCC_DOC_PRECEPTION_CREDIT_UP: '6af7128c6327bff939952ecff167d1af',
  GCC_DOC_REQ_FOR_REPROG: '36d1bee20fc51511f0aa32eef771c36f',
  GCC_DOC_REQ_REPROG: '22f9e5888dcd755588b0a920edd02661',
  GCC_DOC_REQ_REPROG_ANUL: 'ae1da30f884460bdcb820ded43948190',
  GCC_DOC_REQ_REPROG_OK: '9f02e80969eb82a31d23e9c007824104',
  GCC_DOC_RETRY_FORCE: '1d2bfefbc780913b44c08c233f58f06e',
  GCC_DOC_SUCCESS: '18fa84b303528db79b629b29465f80fb',
  GCC_DOC_SUCCESS_ADD: '2c1f17a2e075cd067bda24c4243d9070',
  GCC_EXPORT_XLS: '866b83806b2176186a2d8cdd29374bff',
  GCC_FACELE_SEND: '3f88edfdc41691d38249745369d1d938',
  GCC_FACELE_SEND_MANAGMENT: 'b364bf93efba9c53a206fa6be9af5f3f',
  GCC_FAMILY: '37724b8b4b5d594cd95497795967b541',
  GCC_FAMILY_UP: 'de701fc7ac16feb95478b0a6e4db0a94',
  GCC_FINAL_ORDER_REG: '041c6d0c9dfa1b26f267d670c57f1868',
  GCC_FORM_AUTORIZAR_PEDIDO_RET: '7c24f7e609f949a03c012195a58a195f',
  GCC_FORM_CHANGE_PASSWORD: '88a37d18869075e4a9a958d3d3b8c5a8',
  GCC_FORM_ROUTE_EDIT: '6b37e0254e1c10597bd01a9d916af9bc',
  GCC_FORM_SEDE_EDIT: '6a361d5ece236c6c07974f2e1d44d40e',
  GCC_FORM_USER_ALM_EDIT: '122b46406c7411fbf52bd6791e038f48',
  GCC_FORM_USER_BPGRP_EDIT: 'c2d160b5824f00af435f5e9a8baa1f61',
  GCC_FORM_USER_DIV_EDIT: '405629c475d5c761b6a576649fb70659',
  GCC_FORM_USER_GRPBP_EDIT: '4ad786a3537a481b86c072cb1c08aaf4',
  GCC_FORM_USER_INFO_EDIT: '98cbae3440b5855c17b414d26ae9bec3',
  GCC_FORM_USER_MESA_EDIT: 'b39d484d240633f904a0b1836a14e5ba',
  GCC_FORM_USER_PRV_EDIT: '674d08e059c4980426aec9284b910775',
  GCC_FORM_USER_SEDE_EDIT: 'e2efb630692e6f7b3d67338a57d0431c',
  GCC_FORM_USER_VEN_EDIT: '6c44934831310e13187a23a06f543bae',
  GCC_FORM_USER_ZONA_EDIT: 'f68ca9cad04af7eec059dcdc6ddc983c',
  GCC_FORM_VENDEDOR_EDIT: '466f4c98ad77bfa0d52d0272b5109f11',
  GCC_FORM_VENDEDOR_RUTA_EDIT: '4326f8252202ff493892a687d1174cb2',
  GCC_FUP_DESKTOP: '716b692b11d37e59d127a51de54bb956',
  GCC_GEX_ACCESLIST: '3c128f678722a3c379d8db5c57b2e76a',
  GCC_GEX_LIQ_DIF: '6c55eb6aa133a0c56bef6c014d5f8a3e',
  GCC_GEX_LPROMO: '133da3e18644027b667badf264ce03b7',
  GCC_GEX_RCOMPRAS: 'fdda2621315002b1752515f32d7eb1ee',
  GCC_GEX_RDEP_CAJA: 'a956ed995c04d41eb911b9351beadfd4',
  GCC_GEX_RDEP_CUENTA: '6ae52e490e4e76add542b4285c8c1fb8',
  GCC_GEX_RESPORTS_LIST: '9f6740528770e3eff4eff3bcfa6ccdbe',
  GCC_GEX_RESPORTS_UP: '0f17ccfe735feb11fa67c9fc097b9aec',
  GCC_GEX_SALDO_CUENTA: '309a4762f39d88d577782d358ebf105b',
  GCC_GMOVIL_OPCIONES: '59befc01e0eada5a87659043174d623c',
  GCC_GPRINT_AP_HR_LIST: 'b446d296f2135c250de1edba70a537a6',
  GCC_GPRINT_AP_RECEIPTS_HR_LIST: 'b8c2476b7bc0440e3c5441c5beb057f3',
  GCC_GPRINT_AP_RECEIPTS_LIST: '46304227192f86aaaae749bacd751233',
  GCC_GPRINT_AP_RECEIPTS_VE_LIST: '398cda2604aeb26e73a13dcafa076348',
  GCC_GPRINT_AP_RECEIPT_LOAD: '3ab05bcd88abb3f57d69eefccab8c9b0',
  GCC_GPRINT_AP_RECEIPT_SAVE: '958a670671292c7bcc97dea66026a237',
  GCC_GPRINT_AP_SERIES_LIST: 'f6335b6ffa625791491aefe668c1f8ce',
  GCC_GPRINT_AP_VE_LIST: '0438a1b5b840e72a3f14453c199c711c',
  GCC_GPRINT_DOC_SERIES_UP: '1640b65c5428160ba8059e40625135d5',
  GCC_GPRINT_INVOICE_PREVIEW: '24ae747878a79ab98a673a209e8125a1',
  GCC_GPRINT_LIST_DOC_SERIES: '5e9f4e3eedaff0b0b60dd3d09d073446',
  GCC_GPRINT_RECEIPT_PUBLISH: 'dda7a7ace224182ae33d0494f5c2d5f0',
  GCC_GPRINT_SAVE_AUTORIZATION: '2b89629ca67154dd4d578caf47f046ce',
  GCC_GPRINT_SAVE_REPRINT: '5a68c9e80643235b2c6ca267a00ccc8a',
  GCC_GPRINT_VALIDATE_RECEIPT: '81a2a17007df3ac3dac4ede99c1da1a0',
  GCC_GPROC_DEFAULTS: '3478b35510b6dc751cfbbb519add20cb',
  GCC_GPROC_DEFAULTS_UP: '144ce8c6c5ce09dd86a2482c98b8a17f',
  GCC_GPROC_MNGMT: 'be6a52c6e86a46c3ca1a34855cab26aa',
  GCC_GPROC_QUEUE: 'b15f44c3094dd632447eed58d97d0eb2',
  GCC_GPROC_QUEUE_CANCEL: '04f8b9a951ae0bf003cc2b5e4a5ce81d',
  GCC_GPROC_QUEUE_UP: '2cf22b1f12835a3866b7da29b9ecaf2e',
  GCC_GPROC_SERVICES: '82b9df1ce3e8776319688e06e69c7051',
  GCC_GPROC_SERVICES_UP: '36db9e4b4bb38cb9ae2c8ad67fc2a94a',
  GCC_HRES_ADUSER_UP: '0e4b5dc398a5e47158b33e0730957e34',
  GCC_HRES_AREA: 'c3c5a1b91024523122f34ce1db8c1f5b',
  GCC_HRES_AREA_UP: 'c36c8a03cd751ece06ff7002bcb22d01',
  GCC_HRES_BIRTHDAY_MONTH: '8e947de8cacebc7f05feb13c6c8db3c4',
  GCC_HRES_BRANCH: 'b79455b50aca4c9a3da6a2818ddb3475',
  GCC_HRES_CAT: 'b2db0afa8de81d0a45caaea25ed5fec0',
  GCC_HRES_CAT_ADD: 'bbf73e51535cac6334bd04995f209013',
  GCC_HRES_CAT_UP: '0dc5d12dc437e21034ec5d6e5574fe07',
  GCC_HRES_CC: 'b7e1b303d7bc90d4e516425ee23954d4',
  GCC_HRES_DEP: 'ca118d361216a72a134a420e32b18cd1',
  GCC_HRES_DISCOUNTS: 'eb479d6e77b261ed457d6e5aa16deeb8',
  GCC_HRES_ED_OPTS: '7b5375e283b5869bfea22a5c31fa8c5f',
  GCC_HRES_EMPLOYE: '48952c6c069faf324190be7bfb6c3dc7',
  GCC_HRES_EMPLOYE_UP: '5dab47065a17c9797bbe9021bbb3bf60',
  GCC_HRES_EMP_BP_LIST: '5cc99c3f61e57e7f19eec7ba71f18a91',
  GCC_HRES_EMP_BYDEP: 'e78531c7592a49421ba874c52147b378',
  GCC_HRES_EMP_BYPOS: 'f36877a50a57c6ccd1a8b14055dd17e3',
  GCC_HRES_EMP_BYTYPE: 'ebd073468699a73e5c46307305531814',
  GCC_HRES_EMP_CHPOS: '63d616aec79927575228587566e48a4f',
  GCC_HRES_EMP_CHSTAT: '423cc7f399993ef7d7595dbf6bcdd986',
  GCC_HRES_EMP_DSTART_UP: 'bc6963b475fddd77ae47d4b87662d465',
  GCC_HRES_EMP_EST_HST: '224e5110361b4e7ed1d8aea126c18621',
  GCC_HRES_EMP_IMPORT: 'e074fdecc1a2c77b02359deec697302a',
  GCC_HRES_EMP_POSBRANCH: '3e4719237e7b76a605f76a2a0aa10e8a',
  GCC_HRES_EMP_POSBRANCH_SET: '842174f0fff01de4c3fd8eb51dba727d',
  GCC_HRES_EMP_POS_ADDHST: 'f7b691da678dc60344d1755e9e9eb655',
  GCC_HRES_EMP_POS_BRANCH_ADD: '11c0b520f75eea50a55700c6a5b24b58',
  GCC_HRES_EMP_POS_BRANCH_UP: '6bfb0bb8c8d575cc063b17e8ceef3ea1',
  GCC_HRES_EMP_POS_HST: 'e05e1299eb386d22687eae0828d15487',
  GCC_HRES_EMP_POS_UPHST: '23d406defd924061ba117a914839be5f',
  GCC_HRES_EMP_TYPE: '4931566271dcb4fde891f5a95a563aee',
  GCC_HRES_EMP_TYPE_UP: '20a94bd01ad4e2684e35ecf7ea9e7c03',
  GCC_HRES_EPP: 'efaeaaf24d0e4d0e3823d81e4e80a54d',
  GCC_HRES_LOG_POSBRANCH: '6dc7b3aa1a4f6492dc34542dbd32d466',
  GCC_HRES_MNG: '16a40e84132ff410756002e842bcb805',
  GCC_HRES_PLAN: '58ab575e88afcf7c8a17149fb52d104f',
  GCC_HRES_PLAN_BANCO: '31404c50a90a30e9300a70d68963ac31',
  GCC_HRES_PLAN_DET: '59a00b8c1b2f9fa91cdfd984bcd73ced',
  GCC_HRES_PLAN_UP: 'c97170aeeb74ccee6338410f27cf433c',
  GCC_HRES_PLA_CONCEPT: 'df68bca878b9b59f49c58f68024fb1a0',
  GCC_HRES_POSITION: 'f08185513b3ddd14427210fc5e9e4cd0',
  GCC_HRES_POSITION_UP: '534126807e1557a786d6a13f4fda76c0',
  GCC_HRES_SSPP_COM: 'be717d9c6440f5846bd1977a1df6dfcc',
  GCC_HRES_SSPP_COM_UP: '946c1b60e9bc40fc2d0297b7e5bad508',
  GCC_HR_CATEGORIES: '38d9ddb8e61c0f57c5600b9694dc8ad6',
  GCC_HR_POSITION_ADD: '7073339b0d7296f2db5e6f5ca7a413a0',
  GCC_HR_PREVIEW: 'c2ed96aec4f6a8c15d2add88135dea63',
  GCC_ILO: 'c38e09461db8d2b9a825b4b4e33387a0',
  GCC_IMPORT_DOC: 'f622b72056dc05d705d63512df8d025c',
  GCC_IMPORT_DOC_DET: 'b5e98db514f3a6788c57d26fbb687a49',
  GCC_IMPORT_DOC_UP: 'd4633902eaa5577fc10c06b83ade26d7',
  GCC_IMPORT_NCC: '705aeb6c65d7bb34314b39b314631062',
  GCC_IMPORT_NCC1: '6b30556393014e4294151534e09d8dfb',
  GCC_IMPORT_NCS: 'dd4b78b54daf863dd7294b980fe65669',
  GCC_IMPORT_NCS1: 'ac4a8c45f9b78110b0a6efc2084c5429',
  GCC_IMPORT_ND: '8b2496944a2815a0a3bf23fe657b6685',
  GCC_IMPORT_ND1: 'e3aee4a632dce397d11827c6a30e4c0e',
  GCC_IMPORT_TK: '69d98b9dd2ebd1ae57baecfebf9d6fc2',
  GCC_IMPORT_TK1: '7fc0ce4cde16b6c3b642ed64066fdeef',
  GCC_INVENTORY_EVER: '2667d3cbfc8db7a5b57cb11be161a622',
  GCC_INVENTORY_EVER_CLOSE: '856c1608fa47bfa4512f69c5a32efe1b',
  GCC_INV_AVAL: '22b03409b397179e60d93c9fb342c5e3',
  GCC_INV_CHECK_PRICE: '2ca678eda8b75990eba72b658fb00e75',
  GCC_INV_EXT: 'b17431f1212c403946f62c19e7fc50b0',
  GCC_INV_EXT_KAR: 'f12920acb3aa7ccf5541ace60efe9426',
  GCC_INV_ISCOMMITED_DETAIL: '36cbe0db5debc6121a23b2c73bd92d7a',
  GCC_INV_ITEM: 'c6c8cb101377e91b2aac8df22addcf09',
  GCC_INV_ITEM_ATTRIB_UP: '880293af59927a9bad4268e4f9ebcca1',
  GCC_INV_ITEM_FRGN: '650491e2481e37b965c46b875d05c4f4',
  GCC_INV_ITEM_FRGN_UP: '6fa194c4da8d1e30355677348a4b6343',
  GCC_INV_ITEM_KARDEX: '4d3bd3f863f597a1406137cdea10c857',
  GCC_INV_ITEM_MEDIA_UP: '121a4ebfb73a0b2c3caf9815ddce76c5',
  GCC_INV_ITEM_PRICE_UP_MANUAL: '3031862702f1dd5bc2ec879940ff74ab',
  GCC_INV_ITEM_PROP: '3f23a72e29533be0b5fd59707e9eb18c',
  GCC_INV_ITEM_PROP_UP: '38e4abb3832f699bef41d78991b3d564',
  GCC_INV_ITEM_PRV_BRAND: '5651cc7bb5cbfd873e03f80ad09ef303',
  GCC_INV_ITEM_STOCK_VIEW: '82964d5055966a82fb640626be42aca7',
  GCC_INV_ITEM_UP: '90b49b2bbee986656418588804dab0d0',
  GCC_INV_ITEM_UP_CNT: '9ed49852cd19a3ce26802254f2f425f2',
  GCC_INV_LIST_BYCAT: 'ce93daafaa88df03f463e02d24a29c0f',
  GCC_INV_LIST_BYFAM: 'ac804cef10be4bee33d4c553f03baa1f',
  GCC_INV_LIST_BYPROP: '1838799d17cf9f8506b7aa07436c65d3',
  GCC_INV_REP_ITEM: '6fef64fa31c2d9ac43e396658ee89ce5',
  GCC_INV_REP_SALER: '7a5706dd7277eee4e65317d6209b6a6f',
  GCC_INV_STOCK_SHOW: '92df8c56244a33f1c0d801d815aee162',
  GCC_ITEM_PRICE: '0a379a4ab4a6d27e1d4d30852a1f53ca',
  GCC_ITEM_PRICE_LIST: '24445b3899701b4d099489ae7d80ac69',
  GCC_ITEM_PRICE_UP: '1390550fa8d30d1c77f34ca5f76c6a42',
  GCC_JWT_LOGIN: '854fd1e36d42835b6aea4d13991b5f40',
  GCC_JWT_LOGIN_KEYCLOAK: 'b145cf8ac6ed0cc3523dd0c79f332413',
  GCC_JWT_REFRESH: '581cb71babecccc44261292ffa37b34b',
  GCC_LINK_ABOUT: '8f7f4c1ce7a4f933663d10543562b096',
  GCC_LIQ_ED_OPTS: 'b8ebd8f1fa6933828167a1c26ac1586e',
  GCC_LIQ_NC: 'eea7fb4ea140a916602ef31426134f72',
  GCC_LIQ_PEND: '595bfc1924ee46a1228254223320f209',
  GCC_LIST_GRUPO_DOMI: 'd3044312c4bfa398d588917df0267d79',
  GCC_LIST_LOCALE: 'febd0358f1db7770302a62e7b94ad850',
  GCC_LIST_VIAS: '4a4c4df71fab42de011b9730adff7014',
  GCC_MAP_DRAW_COMMIT: 'eb5cf365d3130a625007ed6e2b0a4a92',
  GCC_MAP_DRAW_LOAD: '57e44563cc1854bc5f60a051c832a431',
  GCC_MAP_DRAW_ROUTE: '272200bba2ab3687d992ad6892a60191',
  GCC_MAP_DRAW_SAVE: '5121e30ee4f2dcddba73bbfca46068fc',
  GCC_MAP_DRAW_TO_SAP: '414e9ba4436263dcde0ecc6cfb860a8e',
  GCC_MAP_DRAW_UP: '8d419bba87b79d12d7f6726d35ebe402',
  GCC_MAP_POLY_UP: '76f741a964131ad06f0681b8de4f5ff2',
  GCC_MAP_PROG_REFR: '361cca032b02de620336c1c1019bb186',
  GCC_MARGIN_DET: '902f1dda4a3074e603c0a235f773e11e',
  GCC_MAT_APOYOS_REPARTO: '22dc6133cc93c9e62b1b4232fea6e842',
  GCC_MY_ORDERS: '6d5abf77d08fce03fa8021c90589e783',
  GCC_NOOFFICE_ORDER_REG: 'fee66165ecbe0239c21f6aa1a6d720d7',
  GCC_OFFICE_ORDER_REG: '2ced557f4287de67ad0b9eb59c6757ea',
  GCC_OPEN_ORDERS: '3d66c8f670ccd9dee1fd7ad451adf68c',
  GCC_ORDER_LINE_DISCOUNT_EDIT: '219892ff0cb3217ad9f0de1fea89928c',
  GCC_ORDER_MANAGER: 'ccbc414fcd8918301fd20d2c1ebf9687',
  GCC_ORDER_NEW: 'ece4721412022c4af94230c609c43578',
  GCC_ORDER_RET_UP: '2c008751822e38aefbb6a586b788b4d5',
  GCC_ORDER_UP: '29d2bf0edaf24fe25c5bb30c2b47094a',
  GCC_PAGE_OPTIONS: '393eb05d38393aafe676699105cf57cf',
  GCC_PAG_PROVEEDORES: '7a90e4604ff4359d9a37cfffbd528832',
  GCC_PRICE_LIST: '2ef6cc2d620f4e1acf5ea479cb0d5862',
  GCC_PROC_CLIENT_EDIT: '99efc795860143fc13e69d15d118f702',
  GCC_PROC_EXPORT_XLS: '0095a9fa74d1713e43e370a7d7846224',
  GCC_PROC_FUERA_RUTA_ADD: '233926cc509b0d57b7188653097d1eec',
  GCC_PROC_INV_CLOSE: '63474823bead00611e210291cce33d70',
  GCC_PROC_INV_STEP1: 'e0fdcb1dcb70cbbbe236c2c5f2962e9e',
  GCC_PROC_INV_STEP2: '6a7c53db2aa1cdb9af21f15270ff694b',
  GCC_PROC_INV_STEP3: 'ac50969984f9ddeb9945400b67f2a5a4',
  GCC_PROC_INV_STEP4: '311321c828dd8f1a47c93e5457a82b05',
  GCC_PROC_INV_STEP5: '9618d38f589f8ecc2dd7958936a62b45',
  GCC_PROC_LOAD_AC_USER: '76da3a44919e7a98aa86fb93668f4a73',
  GCC_PROC_LOAD_CIERRE: '048f412239ea3f7bb34f828ae4686499',
  GCC_PROC_LOAD_CIERRE_INVENTORY: '7313a728afa5925dbf51099530d433e2',
  GCC_PROC_LOAD_RUTA: '50772f744b5f396106453b82583e088d',
  GCC_PROC_LOAD_USER: '670cb4c6dbeabf319b51f63aa8468acb',
  GCC_PROC_LOAD_ZONA: '33cea7d10a3fec3e061675c8e099297d',
  GCC_PROC_LOG: 'a69090ec7968f2d50ac52535becd11d9',
  GCC_PRODUCTOS_MOVIL: '1cac8a07f012816495d1df5f6951f2df',
  GCC_PRODUCT_INVENTORY_AUTREC: 'd454999ea05c7f49bfd54d3fa8157a44',
  GCC_PRODUCT_INVENTORY_CANCEL: '82291b31210cb7032563ccbbb764acf0',
  GCC_PRODUCT_INVENTORY_CLOSE: '1a972576b555e99273bd25fb2db529f8',
  GCC_PRODUCT_INVENTORY_COUNTED: 'a6ba04d84daec526c4dc5f6deb177962',
  GCC_PRODUCT_INVENTORY_CREATE: 'e6720b375ba759f665de72829a7dabbc',
  GCC_PRODUCT_INVENTORY_DETAIL: 'c282cc301d15777ab9296092f807cb8a',
  GCC_PRODUCT_INVENTORY_RECOUNT: 'c197dc4446a88ec5ba635e369be549c4',
  GCC_PRODUCT_INVENTORY_RECOUNTED: '559023d32a8d8e19d5c24dd520818ab3',
  GCC_PRODUCT_INVENTORY_SAVE_COUNT: '8efcdfeeb7cb1607ef98a81427832bb6',
  GCC_PRODUCT_VENDOR: 'd8529b8563bd51f7b387ae8d6d1c3f11',
  GCC_PRODUCT_VENDOR_CERT_UP: 'c8f735dd26191a67bdba28b86f024d5d',
  GCC_PRODUCT_VENDOR_UP: '167ca071554115c3506fb418e7fdff61',
  GCC_PRODUCT_WHITE_LIST_COST: 'ef0bf7dd75d37c014de15f926feccb70',
  GCC_PRODUCT_WHITE_LIST_COST_UP: '029d353ad5c5498b4f7da1cb0f3dcf7b',
  GCC_PROG_NO_PROG: '3b7ecdf3bca90c0e28b21db9bf3980c5',
  GCC_PROG_POLY_UP: 'fbe247d7caa69c774101c2cb69a9bc5a',
  GCC_PROMO: '53372ee0e0b400119e66f538a6011196',
  GCC_PROMOCIONES_POSIBLES_PEDIDO: '102ff72f792f8945a030a0f32dd894d4',
  GCC_PROMO_ADD: '4563d453969bccaf74390db6b328e18a',
  GCC_PROMO_ASSIGNED_DETAIL: 'ee35102c449d4b56d548035682cb4f2a',
  GCC_PROMO_CH_UP: '4e9bb4c4f03c08899550df047036c4a1',
  GCC_PROMO_CUSTOMER_EX_LOAD: 'c927da35faf5e3ba60b2be9dc34a9e50',
  GCC_PROMO_CUSTOMER_INACT: 'ce5c6e5812669bb96b3054a9effc5d11',
  GCC_PROMO_CUSTOMER_LOAD: '90a912c81f0c48b200175ead4941f85c',
  GCC_PROMO_DET: 'd144b25f89b39ce3f990e080f7df523b',
  GCC_PROMO_DONOBONO: 'bd889cbda5ecd8035e280a403fcac2ff',
  GCC_PROMO_EXC_UP: '569f9335113762775dd00bbac2faabde',
  GCC_PROMO_FOR_ORDER: '1c44022d09bd208720a2ecfd079d690f',
  GCC_PROMO_GRB_ADD: 'c30e141d8386c5cb6a1206dab8809ef9',
  GCC_PROMO_GRB_DEL: 'f8a957ad735a3adb0280fdba1c56baa5',
  GCC_PROMO_GRB_UP: 'a3246b83e265befc4894b288a0880270',
  GCC_PROMO_GRP_ADD: '8e295cf33682df0cd589d1ef9daf1e3a',
  GCC_PROMO_GRP_DEL: 'f69e011e62be045b9777f587b84503b7',
  GCC_PROMO_GRP_DET_UP: '2a14aecb34e1274fb52e54de6f4c181f',
  GCC_PROMO_GRP_UP: '271bc4c39e03bccdc9f7aa74da99fdc3',
  GCC_PROMO_OPTS: '7add40796019dcf29d502956e194c40e',
  GCC_PROMO_PEXC_ADD: '2b02f7536d0e8738c883f15afb9bcdf1',
  GCC_PROMO_PEXC_UP: 'fff438b87e05c18daff3f83d451aff7f',
  GCC_PROMO_PRE_CALC: '1d0e6ee4756c5bca007d04c0e121e68d',
  GCC_PROMO_PRE_CALC_ALL: '04c84891cd10a8fa610779744f9c4044',
  GCC_PROMO_TEAM_ADD: 'b54bb6d027519625dcb204935a3b1df5',
  GCC_PROMO_TEAM_UP: 'ca92952667c711abf7b3fc4364f95f1f',
  GCC_PROMO_UP: '7d551460f758853ec5bb020486551302',
  GCC_PURCHASE_FFEE_ADD_MEDIA: '337fce88f4d99be1af186b8d180cbb67',
  GCC_PURCHASE_FFEE_CAB_UP: 'd1fe976dbb7c231f579100cc5bc2e765',
  GCC_PURCHASE_FFEE_CAB_VAL: '3f6ce915acf0af91979ddf3bbce5166d',
  GCC_PURCHASE_FFEE_CONFIRM: '0798bbc37e00969efc3a7de965c05d38',
  GCC_PURCHASE_FFEE_CONTA: '814e56c4f9bcfc621fb1c426560da908',
  GCC_PURCHASE_FFEE_DET: '1650d6d896fa179af0f17d7cd32635d9',
  GCC_PURCHASE_FFEE_DET_UP: 'a2ce9202b0974ee52909bf2a6df2af26',
  GCC_PURCHASE_FFEE_DISCARD: 'e8146e4df812fab6c2c37e900ea55fdf',
  GCC_PURCHASE_FFEE_DOWNLOAD_XML: '994ef6919a538b7ba62deef806380b9b',
  GCC_PURCHASE_FFEE_HST: '64834529b0324e3bc891386402320b12',
  GCC_PURCHASE_FFEE_IMPORT: '5752c02f1fe788b235cdcae0734c527d',
  GCC_PURCHASE_FFEE_LOAD: '2cc7c907ac710799a1939afcbc6cb969',
  GCC_PURCHASE_FFEE_NOCONFIRM: '39cd78b480f7ff0212331788654986c8',
  GCC_PURCHASE_FFEE_PREV_ADD: '5103098642474050e445c3a80fbf6732',
  GCC_PURCHASE_FFEE_PTPAY: '7e2d938dc6bb800711b2aded632fdf0d',
  GCC_PURCHASE_REC_OPEN: '5ecda4df2756df269ab0bd32c6901230',
  GCC_REPORT_CAJA_CUADRE_CUENTAS: 'b39cc59ba9142e9e975b1b6a0d6b0025',
  GCC_REPORT_CLIENTES_BALANCE: '7e819b3ba0215f5b406dbbed7b83d0cb',
  GCC_REPORT_CLIENTES_LETRA: '3539c74b418c81bf30f9f2adbac91ebf',
  GCC_REPORT_CLIENTES_LETRA_G: '3989edfb31607618a4fe8076ce7405f2',
  GCC_REPORT_CLIENTES_MESA_RUTA: 'c199300eb14da8388394ecaf01b4a766',
  GCC_REPORT_CLIENTES_TRADICIONAL: 'bbd33b6c6d1dd71f28e0800fc4dbfde4',
  GCC_REPORT_GPRINT_DEVICES: '129a224fbd50471c76d77d4640665a1e',
  GCC_REPORT_GPRINT_LAST: '9b5dcc307de81d9d8c1985e077a1ce85',
  GCC_REPORT_GPRINT_LIST: 'a75a3ae3fa839a8a9c70c123b6053957',
  GCC_REPORT_PROMO_COLGATE: 'f00017ec42213b5947969bd179fadf57',
  GCC_REPORT_PROMO_INCONSISTENTE: 'f2d0d5ee6c0612f704fab21cfd78be06',
  GCC_REPORT_PROMO_VIGENTE: '95ab22cea9e0f0af823221045ab13ff6',
  GCC_REPORT_RESUMEN_PREVENTA: '25df9a6468324762c338052dcf7f59cb',
  GCC_REPORT_RESUMEN_PRYSUP: '491954c2f929506669a36288707a9900',
  GCC_REPORT_RESUMEN_PRYVND: '4b059dbb9d2956d7096cf01fe9c5265b',
  GCC_REPORT_SUPERVISOR_COMISION: '473d3a4cf2cd67c0068826e2efa01a6c',
  GCC_REPORT_VENDEDOR_COMISION: 'a7c1a4ed2c172498a8fc6ab78035982f',
  GCC_REPORT_VENTA_COLGATE: '3eb2fd7396220860928eea0507aa62f3',
  GCC_SALE_BY_COMODATO_GLORIA: '7c1c3a706c45c6147751d9dc748efd52',
  GCC_SALE_CARTERA_UP: '143dbf489849e7d1a7c7156ab93572b3',
  GCC_SALE_CAT_BYMONTH: '1ed11764e07f591093cf36b2e963a261',
  GCC_SALE_CAT_BYMONTH_CRE: 'bd5d34f88504fbcea194fdffa65b37b8',
  GCC_SALE_CAT_BYMONTH_UP: 'd9e6bf3f54a68e6130516f97a71322b3',
  GCC_SALE_CHORDER_TODEL: '4d63d1afe9515ede4fd6dc6b52a611a9',
  GCC_SALE_CHORDER_TOEXP: '1b511daef132bf72518a126482eeba27',
  GCC_SALE_DWH_SYNC: '3ed64f4e8fb070c175ee507c0e4b9e88',
  GCC_SALE_ED_OPTS: '3a5c6d134a0b78a28ea2ff5825309f3b',
  GCC_SALE_INT_CLOROX: 'c1bbae925fe11134e861a011015301ec',
  GCC_SALE_INT_JYJ: 'a89ba8e88bbb80d2805dd7e600dbbc1f',
  GCC_SALE_INT_KCP: '6f90ceca51289bf14ad5e75171499e30',
  GCC_SALE_INT_LOR: '8848520697e7289b2f04fc02f27cc937',
  GCC_SALE_INT_MOLITALIA: '8f15851afe7306547adf55dc621da9ad',
  GCC_SALE_INT_MOLITALIA_NORTH: '6959c045013bb4544afe01a7c65e6343',
  GCC_SALE_INT_PMO: 'd6ded692e1ff227f0ae48743c551f949',
  GCC_SALE_INT_SFSDF: '17db435639c2f26468c6a0194a3cec2b',
  GCC_SALE_INT_UNILEVER: '59d6ae9429c4acf1c653aa446cf8210f',
  GCC_SALE_ITM_SALES: 'bb460ca3085c72c2f6f7763779a4d9b3',
  GCC_SALE_ITM_SALES_BYCAT: '97328b04f1ff231304e3f46db70546f8',
  GCC_SALE_ITM_SALES_BYFAM: '3190f735db77c2b1dfdc56dfbdf9e091',
  GCC_SALE_PORTAFOLIO: '9ae3af0c4f5541483cdd468912be02a4',
  GCC_SALE_PRICE_TEAM: 'e8fb46bedcac29eabcbf9095e22923db',
  GCC_SALE_PROGRESS: '704cccb4e412d4db8f00a1613c0853e0',
  GCC_SALE_REP_COBERVEN_GEN: 'cb961e1f45eab4422d64c4906d763830',
  GCC_SALE_REP_COBER_GEN: 'df1d7e9b08b1fb6dc2b072e18551fa13',
  GCC_SALE_REP_COBER_SEDE: '2d254912a23132f23b16bc8377395c04',
  GCC_SALE_REP_COBER_VEND: '54144e4e673565cbd04dcf7f4a6b6603',
  GCC_SALE_SALER: 'd57c35bbf7f30406f13625fd04e2bdb1',
  GCC_SALE_SALER_UP: '7c722ef2cfa5b5ebba949cbc5c17ce50',
  GCC_SALE_SALE_ALCOHOL: '0a124818e944a50f6c3c4f851c66ef49',
  GCC_SALE_SUPERVISOR: 'ae6985adfd1007711a52476db4cb8607',
  GCC_SALE_SUPERVISOR_SALERS: 'd6eeeeff2b705792c6ba2494cb7e701f',
  GCC_SALE_SUPERVISOR_UP: '811713bdc187f3c43f850b26cc382fe2',
  GCC_SALE_TEAMS: '5417dd20b12b2d7c1cbc6f30194497b2',
  GCC_SALE_TEAMS_UP: '56a03dd84cf55e9d50c5b7415eb3a31d',
  GCC_SALE_TEAM_ZONE: 'a77b5727bcdfa6b1a3324588ff22e4b8',
  GCC_SALE_TEAM_ZONE_UP: 'ada395abe233229352430fb7204cd011',
  GCC_SALE_VENDOR_BYMONTH: '51b4ce9acd9559d17ba279f619ff7429',
  GCC_SALE_VENDOR_BYMONTH_CRE: 'bd005b7a1f11bdfe1bd56e216202d324',
  GCC_SALE_VENDOR_BYMONTH_UP: 'a2eda8d102c7079ace5747d518317edf',
  GCC_SAVE_ANULAR_PEDIDO_PROCESADO: '75e2596fd821ec548335aec301cb15a5',
  GCC_SAVE_ANULAR_PEDIDO_RET: '82cb0bf6bb0e5890f2c3d9c2caa5660d',
  GCC_SAVE_AUTORIZAR_PEDIDO_RET: 'b02b4a75d293e087ba2949bee2aebdf1',
  GCC_SAVE_CIERRE_PEDIDO: '8a846cca49ea177b2d9690bfc9bb53ad',
  GCC_SAVE_CIERRE_PEDIDO_ABRIR: '73fe1da517853bbf4abdfc24759fdc35',
  GCC_SAVE_CLI_FUERA_RUTA: 'a9fcea951521c42a3e431ce70520007f',
  GCC_SAVE_DESCUENTO_PEDIDO: '702ec9bb6a6c9f301ebaa48dbaa1fdae',
  GCC_SAVE_DESCUENTO_PEDIDO_NC: '6f2f86c7360751025f600fd8f2c139c7',
  GCC_SAVE_SEC_GRANT_FORM: '16c4ce747dc7dcc23297df77c5ed5710',
  GCC_SAVE_SEC_REVOQUE_FORM: '5d5e043e68431d5d245108ecbc579aad',
  GCC_SEARCH_CLIENT: 'aefae688da7febc4232df326a694281f',
  GCC_SEARCH_SN: '04d5615fc845d5548f0e438b259b6a3c',
  GCC_SEMT_SUNAT: '0defd2c1c813d1197a5c95b3f9ee2418',
  GCC_SN_CANAL_UP: '0366753438c302ec87300116b5d75be7',
  GCC_STOCK_BALANCE: '14721c7b609c5044a192ff858c0f45a2',
  GCC_SUNAT_DETRACCION: '2c84252ec41d4c0ad488ad5f0e0abd21',
  GCC_SUNAT_DOMI: 'f68b38cde90c4999b1a0a23310b58bb5',
  GCC_SUNAT_DOMI_UP: '4f7f58b7cd52eb9b8e0d9e6b6d6ac502',
  GCC_SUNAT_SEMT_CONS: 'c49df0f1748b3c4f7b16d680ddcfa525',
  GCC_SUNAT_SEMT_EXCEL: 'eb3c6bc538a15fe9e2153a34e094fd18',
  GCC_SUNAT_SEMT_TXT: '7efdfd8b8355b853de6a606c65f7f584',
  GCC_SYS_EXPLORE_OPTIONS: '7c86ba478765d8a698d3989016818d33',
  GCC_SYS_MENUGRP_MGR: '60976526bdb762ca63190528ef7fa0cf',
  GCC_SYS_MENUGRP_MGR_UP: '4a7f13f0c1b16da8a5d210f51efa0554',
  GCC_SYS_MENU_MGR: '3bc5fec09f80c250bee3530c554f2cd6',
  GCC_SYS_MENU_UP: 'dd686296f1dbcefdb46a476b342603f5',
  GCC_SYS_MODULES_CLASS: '189e53e2322b64633a5f56e3e7d5f894',
  GCC_SYS_MODULES_CLASS_ADD: 'd519862e89bb8c80606637bdc409f50d',
  GCC_SYS_MODULES_CLASS_UP: '29dea929c248f8c3ca31ee74785f7d32',
  GCC_SYS_OPTIONS_CLASS: '61fed9bbf4fdffaf8b75ac680b03808d',
  GCC_SYS_OPTIONS_CLASS_ADD: '4fcdfc2d1333e900b24dc0e7f007f7c6',
  GCC_SYS_OPTIONS_CLASS_UP: 'a3595bd216e8f56daa86a3ee9b396a11',
  GCC_SYS_SECURITY_ACCESS_MGR: '5984f3d38aa9766cc38a1670148dd872',
  GCC_SYS_SECURITY_SESION_CLOSE: 'a3bba2e5571965cf1c094fac98c7d76c',
  GCC_SYS_SECURITY_UPDATE_ACCESS: 'fef3e47085650d667bc1dabf5dae4a08',
  GCC_SYS_USER_RECEIPT_LIST: 'c12c1ed2d63f135ba9c9f3ef706059e7',
  GCC_SYS_USER_RECEIPT_S_LIST: '7c10b660e86dc62c0fc595617b023b30',
  GCC_SYS_USER_RECEIPT_S_UP: '8be037726265fbadd11766bc54c00bf7',
  GCC_SYS_USER_RECEIPT_UP: '22b2736b0c4ad1fb7f1de0a029bdf693',
  GCC_TAKE_POLYGON: 'a3f209dee7a49706c2ce86b20e67bf78',
  GCC_TDOY_DET: '4708a1d5271d67cf1fc661fba2e99059',
  GCC_TDOY_DET_APPLY: 'acc26ba9b1f43239d83c53732567720a',
  GCC_TDOY_DET_CLOSE: '2146dd256caa9e75ce274ef28e1b3d33',
  GCC_TDOY_DET_UP: '183269ea925ea1702175e4b266b393ea',
  GCC_TDOY_DOC_DET: 'fe1b13b5e1100f45e7b652abcef3853c',
  GCC_TDOY_EXPENSE_EDIT: 'dc19d010149107d2e0140ce25e532c58',
  GCC_TDOY_EXPENSE_LIST: '3c99434f580c32ff4ea30bcfe5d6f4ae',
  GCC_TDOY_GEN: 'a305a209adcdc6e53505e682949a8274',
  GCC_TDOY_HR_LIST: '50c73ac31dda1b19c19593286e66a0e6',
  GCC_TDOY_HST: 'fd9384acec19b51ac0f0f83a5c9507dc',
  GCC_TDOY_INV_LIQ: '7ad1cf0628daa9b9ffee90d1f1963055',
  GCC_TDOY_LIQ_DET: '9fdfe3aec6cbec44af556e93290957c5',
  GCC_TDOY_LIQ_PROD_ADD: '4a9be533e10151a714a6f1ded07c4966',
  GCC_TDOY_LIQ_RESET: 'a612da17eb32a7d1e130b4f3e368500b',
  GCC_TDOY_LIST: 'f8a7dfbc2bbcf92a1e50e8d31efd0f1c',
  GCC_TDOY_OPTS: 'bdb21053472e8a1e9c7986b4c4dc0274',
  GCC_TDOY_RESET: '01f8ae6eae98b19b7723b3dfed412ee3',
  GCC_TDOY_RESET_LIQ: 'cd4b0022dc34d966195fc6bb4574428e',
  GCC_TDOY_SUMARY: 'e2982b430309ac4f8c9b9db04ae67239',
  GCC_TDOY_TEAM_ADD: 'ea2ccd8bc9f675cdc2be1c636ff18d9a',
  GCC_TDOY_TEAM_ASSIGN: 'bd1e488dc9cf5aa41b00fca4c39fce64',
  GCC_TDOY_TEAM_LIST: '26502b2a2e008973c246ae5282daaa25',
  GCC_TDOY_TEAM_UNASSIGN: '851bc7fdbc6b92f256a6b5183940567c',
  GCC_TDOY_UNLOCK: '58debe514e268f1119a74ac9e390e45d',
  GCC_TDOY_USER: '870148d2098a9bd9ee96f901184c1fbc',
  GCC_TDOY_USER_LOGINS: '095535234f9fadccb4d33b75bcbfcdb5',
  GCC_TDOY_USER_OPEN_CLOSE: '97f764665a6d415a5c7054f43141bd85',
  GCC_TDOY_USER_UP: '09fa74e8921577f25a5d22b1f689cd3c',
  GCC_TEAM_BRANDS: '077b604c43cfa0d2ced98a2414392b92',
  GCC_TEAM_BRANDS_UP: '37705cf846436b22dcac6b8885558419',
  GCC_VIEW_LOG_GPROC: 'aea9e7e15e2067be65f4f58e57d3cf6a',
  GCC_VIEW_PROFIT: '07a03f3e611931540ac758960bc988b0',
  GCC_VIEW_WITHOUT_TAX: '75bc7ffa30cf00e51b69a202ec0b22c2',
  GCC_WAREHOUSES: '3b5c4dd7bd6eff8e134873b86293fdb1',
  GCC_WAREHOUSES_STOCK_MIN: 'd18167c11c21aedc2c72e8b88ec04c01',
  GCC_WAREHOUSES_STOCK_MIN_UP: '1307baa5fa031a851a110936ffc9f759',
  GCC_WAREHOUSES_UP: '7f23c9f4d6b9f806dda2e40cbd755cdb',
  GC_FORM_CLI_DIRE_EDIT: '8eba67236b21f494847535f62edd93c6',
  GC_FORM_CLI_DIV_EDIT: 'd10d81bdb83b666c06e7b05e506453b6',
  GC_FORM_CLI_MESA_EDIT: 'cd682cf50b4f9bef33b40a2ea52b8523',
  GC_FORM_CLI_NOM_EDIT: '4f4acc69448fab6d6c61f2fcc4004f12',
  GC_SAVE_CLI_DIRE_FORM: '25da60f1ae0c690fdb824a50addd43ce',
  GC_SAVE_CLI_DIV_FORM: '0f7c11d5ab6462540c5f77e0132dcf5c',
  GC_SAVE_CLI_MESA_FORM: 'b7652d85f0a5c4a657e12c9234f22641',
  GC_SAVE_CLI_NOM_FORM: 'aee37c30f5d091a495526f636a3527bb',
  GC_SAVE_VENDEDOR_RUTA_FORM: 'b404ad454756765e40fc82438a7267cc',
  MANAGE_AUTHORIZATION: '2caad4352c274034b1966870309c92c7',
  PERMISSION_INDEX: '2ac2e205cbafb9d0d1cc9f4c30b1b53c',
  PERMISSION_SHOW: '62464e23276f0b985b2d87b3031220af',
  ROLE_DESTROY: '9633deb0878a1d454a9736b699b180cf',
  ROLE_INDEX: '29ef648fa3e0ce43dcb3162ebfa1637a',
  ROLE_SHOW: 'daef149f8edfbbd70416b170972c3e64',
  ROLE_STORE: 'f9e6d4d88f8a58b82681d3e0660d3bcb',
  ROLE_UPDATE: '449e71db11b3c0da87b6098e867da49c',
  USER_DELETED_LIST: 'c7381d6e0bab772f6e0b8a7c6bead651',
  USER_DESTROY: 'e08f1ff446b04b6c9648eaf490ba8894',
  USER_INDEX: 'b8afe2f6cc4aaefc42597e748de9050c',
  USER_PURGE: 'd501ce324fa6c9f598f6163cb3261317',
  USER_RESTORE: 'acf98bdf681cc3897e34a3df011f34a5',
  USER_SHOW: 'fba6181cfdceb4ed9b40159c35970299',
  USER_STORE: '7852f196da741559dd51bc5f72521d06',
  USER_UPDATE: 'bcb2af10c23d78235691169705dd4956',
  VIEW_BACKEND: '7b547a711d0b9fb93968b5b8d81dc86d'
};
export default GadminActions;
export { GadminActions };
