import { useNavigate } from 'react-router-dom';
import { useApp } from '../../hooks/use-app';

export const useGoalLink = () => {
  const { appUrlBase } = useApp();
  const navigate = useNavigate();
  const navigator = (link) => {
    const routeLink = `${appUrlBase}${link}`;
    navigate(routeLink);
  };
  return navigator;
};
