import { createContext, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SurveyResultsViews } from '../../api/goal/survey/surveys-definitions';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { surveyResultsApi } from '../../api/goal/survey/survey-results-api';
import { useData } from '../../hooks/use-data';

const SurveyResultContext = createContext();

const useSurveyResult = () => {
  return useContext(SurveyResultContext);
};

const SurveyResultProvider = ({ children }) => {
  const { resultId } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('survey-result-view') || SurveyResultsViews.ALL;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: surveyResultsApi.getSurveyResult,
    apiParameter: { companyId: currentCompany, id: resultId, view: view },
    loadingMessage: 'Cargando resultado de la encuesta...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    surveyResult: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : false,
    message: dataState.message,
    editMode,
    setEditMode
  };
  return <SurveyResultContext.Provider value={value}>{children}</SurveyResultContext.Provider>;
};

SurveyResultProvider.propTypes = { children: PropTypes.node.isRequired };

export { SurveyResultProvider, useSurveyResult };
