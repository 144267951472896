import { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// material
import {
  Box,
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener,
  IconButton,
  Grid,
  MenuItem
} from '@mui/material';
import { InputField } from '../../components/input-field';
import { XCircle } from '../../icons/x-circle';
import { goalSearchApi } from '../../api/goal/search/goal-search';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';
import { useApp } from '../../hooks/use-app';
import { useQuery } from '../../hooks/use-query';
import { v4 as uuidv4 } from 'uuid';

// import { Plus as ImageIcon } from '../../icons/plus';
// import { Archive as WorkIcon } from '../../icons/archive';
// import { Bell as BeachAccessIcon } from '../../icons/bell';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 220;
const APPBAR_DESKTOP = 70;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.92)}`,
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('sm')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const InputStyle = styled(Input)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1, 2),
  boxShadow: theme.shadows[1],
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 0)
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const navigate = useNavigate();
  const query = useQuery();
  const mounted = useMounted();
  const [isOpen, setOpen] = useState(false);
  const queryText = query.get('query') || '';
  const queryEntity = query.get('entityId') || 0;
  const [tempValue, setTempValue] = useState(queryText);
  const { appUrlBase, currentCompany } = useApp();
  const [searchInEntity, setSearchInEntity] = useState({ id: queryEntity ?? 0 });
  const [searchPlaceholder, setSearchPlaceholder] = useState('Buscar…');

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // redirect to search page

  const handleSearch = (query) => {
    if (query && query.length > 0) {
      if (searchInEntity?.type === 'link') {
        const linkRoute = (searchInEntity?.link || '').replace('{query}', query);
        navigate(`${appUrlBase}${linkRoute}`, {
          replace: true
        });
      } else {
        const uuid = uuidv4();
        navigate(
          `${appUrlBase}/search?query=${query}&companyId=${currentCompany}&entityId=${
            searchInEntity?.id || 0
          }&uuid=${uuid}`,
          {
            replace: true
          }
        );
      }
    }
    handleClose();
  };

  const hnadleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event.target.value);
    }
  };

  const handleChange = (event) => {
    setTempValue(event.target.value);
  };

  const handleClear = () => {
    setTempValue('');
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const handleChangeSearchIn = (event) => {
    const entityId = event.target.value;
    const entity = searchState.data?.find((item) => item.id === entityId);
    setSearchInEntity(entity);
    setSearchPlaceholder(`${entity?.placeholder || 'Buscar…'}`);
  };

  const handleClickAway = () => {
    // handleClose();
  };

  const [searchState] = useData({
    sourceApi: goalSearchApi.getSearch,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted
  });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen} tabIndex={1}>
            <Icon icon={searchFill} width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Grid container sx={{ pt: 1, gap: 0 }}>
              <Grid item xs={12} sm={5} md={2}>
                <SearchInSelect
                  handleChangeSearchIn={handleChangeSearchIn}
                  searchInList={
                    searchState.isLoading && searchState.success ? [] : searchState?.data
                  }
                  searchInValue={searchInEntity?.id || 0}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                md={10}
                sx={{ display: 'flex', alignItems: 'center', marginTop: 1, gap: 2 }}
              >
                <InputStyle
                  fullWidth
                  disableUnderline
                  placeholder={searchPlaceholder}
                  value={tempValue}
                  onChange={handleChange}
                  onKeyDown={hnadleKeyDown}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box
                        component={Icon}
                        icon={searchFill}
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    tempValue && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClear} tabIndex={3}>
                          <Icon icon="eva:close-fill" width={20} height={20} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                  sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                  tabIndex={2}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  disabled={tempValue.length === 0}
                  variant="contained"
                  onClick={() => handleSearch(tempValue)}
                  tabIndex={4}
                >
                  Buscar
                </Button>
                <IconButton onClick={handleClose} color="secondary" aria-label="edit" tabIndex={5}>
                  <XCircle color="primary" fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}

const SearchInSelect = ({ searchInList, searchInValue, handleChangeSearchIn }) => (
  <InputField
    select
    name="search in"
    label="Busca en"
    fullWidth
    value={searchInValue}
    onChange={handleChangeSearchIn}
  >
    {searchInList?.length === 0 ? (
      <MenuItem value="">No hay datos</MenuItem>
    ) : (
      searchInList?.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))
    )}
  </InputField>
);

SearchInSelect.default = {
  searchInList: []
};

SearchInSelect.propTypes = {
  // number or string
  searchInValue: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  searchInList: Proptypes.array,
  handleChangeSearchIn: Proptypes.func
};
