import { appDefinitions } from '../app-definitions';
import { dataLoader } from './../data-loader';

/* eslint class-methods-use-this:0 */

class RolesApi {
  async getRoles({
    companyId,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = '',
    sort = '',
    sortBy = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/roles`,
      params: {
        companyId: companyId,
        view: view,
        page: page,
        per_page: perPage,
        search: search,
        order_direction: sort,
        order_by: sortBy,
        limit: 1000
      }
    });
    if (response.data?.data) {
      response.data = response.data.data;
    }
    return response;
  }

  async getRole({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/roles/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getPermissionsOfRole({
    companyId,
    id,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/roles/${id}/permissions`,
      params: { companyId: companyId, view: view, page: page, per_page: perPage, search: search }
    });
    return response;
  }

  async addPermissionsToRole(id, values) {
    const response = await dataLoader.loadGoalPost({
      action: `/auth/roles/${id}/add-permissions`,
      changes: values
    });
    return response;
  }

  async getRoleUsers({
    companyId,
    id,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/roles/${id}/users`,
      params: { companyId: companyId, view: view, page: page, per_page: perPage, search: search }
    });
    return response;
  }

  async deleteRolePermission({ companyId, id, permissionId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/auth/roles/${id}/permissions/${permissionId}?companyId=${companyId}`
    });
    return response;
  }

  // sync roles
  async syncRoles({ companyId }) {
    const response = await dataLoader.loadGoalPost({
      action: `/auth/roles/sync`,
      changes: { companyId: companyId }
    });
    return response;
  }
}

export const rolesApi = new RolesApi();
