import PropTypes from 'prop-types';
import { useApp } from '../../../hooks/use-app';
import GoalRecordLink from '../../goal-record-link';

export const RindegastoLink = ({ to, ...rest }) => {
  const { rindegastoUrl } = useApp();

  const linkTo = `${rindegastoUrl}${to}`;
  return <GoalRecordLink {...rest} tooltip="Rinde de gasto" to={linkTo} />;
};

RindegastoLink.propTypes = {
  to: PropTypes.string.isRequired
};
