import { createContext, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';
import { useApp } from '../../hooks/use-app';
import { goalCustomeSurveyApi } from '../../api/goal/survey/goal-customer-survey';
import { SurveysViews } from '../../api/goal/survey/surveys-definitions';

const SurveyContext = createContext();

const useSurvey = () => {
  return useContext(SurveyContext);
};

const SurveyProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('survey-view') || SurveysViews.ALL;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: goalCustomeSurveyApi.getSurveyDetail,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando información de la encuesta...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    survey: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : false,
    message: dataState.message,
    editMode,
    setEditMode
  };
  return <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>;
};

SurveyProvider.propTypes = { children: PropTypes.node.isRequired };

export { SurveyProvider, useSurvey };
