import PropTypes from 'prop-types';
import { CommonDialog } from '../common-dialog';
import AttributeSelectorContainer from './attribute-selector-container';
import { DialogButton } from './dialog-button';
import GoalButton from '../../goal-button';
import GradingIcon from '@mui/icons-material/Grading';

const baseSlot = {
  content: AttributeSelectorContainer,
  // component: GoalButton
  component: DialogButton
};

const baseSlotProps = {
  content: {
    sourceApi: () => {},
    apiParameters: {},
    loadingMessage: 'Cargando...',
    label: 'Seleccionar',
    onSelect: () => {},
    getOptionDisabled: (option) => option.disabled,
    groupBy: (option) => option.groupName
  }
};

export const CommonAttributeSelector = ({
  onAfterSave,
  slot: slotParam,
  slotProps: slotPropsParam,
  startIcon = <GradingIcon />
}) => {
  const { component: Component, content: Content } = { ...baseSlot, ...slotParam };
  const { content: contentProps = {}, component: componentProps = {} } = {
    ...baseSlotProps,
    ...slotPropsParam
  };
  return (
    <>
      <Component
        Component={CommonDialog}
        componentProps={{
          slot: { ...slotParam, component: Content },
          slotProps: {
            component: contentProps
          },
          maxWidth: 'md'
        }}
        onAfterSave={onAfterSave}
        button={slotParam.button ?? GoalButton}
        startIcon={startIcon}
      >
        {contentProps.label}
      </Component>
    </>
  );
};

CommonAttributeSelector.propTypes = {
  onAfterSave: PropTypes.func.isRequired,
  startIcon: PropTypes.element,
  slot: PropTypes.shape({
    content: PropTypes.elementType,
    component: PropTypes.elementType,
    button: PropTypes.elementType
  }),
  slotProps: PropTypes.shape({
    content: PropTypes.shape({
      sourceApi: PropTypes.func.isRequired,
      apiParameters: PropTypes.object,
      loadingMessage: PropTypes.string,
      label: PropTypes.string,
      onSelect: PropTypes.func.isRequired,
      getOptionDisabled: PropTypes.func,
      groupBy: PropTypes.func
    })
  })
};

export default CommonAttributeSelector;
