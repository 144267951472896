import PropTypes from 'prop-types';
import CommonStatus from './common-status';
import DeliveryStatusEnum from './enums/delivery-status-enum';

const DeliveryStatus = ({ status }) => {
  return <CommonStatus status={status} statusArray={DeliveryStatusEnum} />;
};

DeliveryStatus.propTypes = {
  status: PropTypes.string.isRequired
};

export default DeliveryStatus;
export { DeliveryStatus };
