import { Card, CardContent, CardHeader, Grid2, Skeleton, Stack } from '@mui/material';
import { useData } from '../../../../hooks/use-data';
import { PropertyList } from '../../../property-list';
import { PropertyListItem } from '../../../property-list-item';
import { goalCustomeDeleveryPlanningApi } from '../../../../api/goal/planning/goal-customer-delivery-planning';
import { useDeliveryPlanning } from '../delivery-planing-context';
import { useMounted } from '../../../../hooks/use-mounted';
import RefreshButton from '../../../common/buttons/refresh-button';
import { useEffect } from 'react';
import { LoadingSkeleton } from '../../../common/loading-skeleton';

export const DeliveryPlaningSummary = () => {
  const mounted = useMounted();
  const { pendingDeliveryParams, currentProfile } = useDeliveryPlanning();
  const [summaryState, handleRefresh] = useData({
    sourceApi: goalCustomeDeleveryPlanningApi.getSummary,
    apiParameter: { ...pendingDeliveryParams },
    mounted,
    defaults: { noAutoload: true }
  });

  const { data = {} } = summaryState;

  useEffect(() => {
    if (currentProfile) {
      handleRefresh(pendingDeliveryParams);
    }
  }, [currentProfile]);
  const renderContent = () => {
    if (summaryState.isLoading) {
      return (
        <LoadingSkeleton>
          <Stack spacing={1}>
            {/* <Skeleton variant="circular" width={40} height={40} /> */}
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
        </LoadingSkeleton>
      );
    }

    return (
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            xs: 12,
            sm: 6
          }}
        >
          <PropertyList>
            <PropertyListItem label="Cargados " value={data.notAssigned} sx={{ px: 0 }} />
            <PropertyListItem
              label="Pendientes de carga"
              value={data.pendingToUpload}
              sx={{ px: 0 }}
            />
          </PropertyList>
        </Grid2>
      </Grid2>
    );
  };
  return (
    <Card>
      <CardHeader
        title="Resumen"
        action={<RefreshButton onClick={() => handleRefresh(pendingDeliveryParams)} />}
      />
      <CardContent>{renderContent()}</CardContent>
    </Card>
  );
};
