import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { esES } from '@mui/x-data-grid/locales';
import { RefreshButton } from '../../common/buttons/refresh-button';

import {
  DataGrid,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import GridMasiveActions from './grid-masive-actions';
const printOptions = {
  hideFooter: true,
  hideToolbar: true,
  disableToolbarButton: false,
  pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }',
  includeCheckboxes: true
};
const CustomToolbar = ({ printOptions, csvOptions, bulkAction, onRefresh, ...other }) => {
  return (
    <GridToolbarContainer>
      <GridMasiveActions {...bulkAction} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarExportContainer {...other}>
        <GridCsvExportMenuItem options={csvOptions} />
        <GridPrintExportMenuItem options={printOptions} />
      </GridToolbarExportContainer>
      {onRefresh && <RefreshButton onClick={onRefresh} />}
    </GridToolbarContainer>
  );
};
export const GMGrid = (props) => {
  const {
    rows,
    columns,
    loading,
    checkboxSelection = true,
    disableSelectionOnClick = true,
    sx,
    onRowSelected,
    apiRef,
    slot = {},
    slotProps = {},
    onRefresh,
    ...rest
  } = props;
  const defaultSx = {
    minHeight: '30vh',
    maxHeight: '90vh'
  };
  const { bulkAction } = slot;
  const { bulkAction: bulkActionProps } = slotProps;

  return (
    <Box>
      <DataGrid
        // autoHeight
        rows={rows}
        columns={columns}
        {...rest}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick={disableSelectionOnClick}
        disableRowSelectionOnClick
        onRowSelected={onRowSelected}
        apiRef={apiRef}
        // pageSize={5}
        // rowsPerPageOptions={[5]}

        // experimentalFeatures={{ newEditingApi: true }}
        loading={loading}
        // rowHeight={38}
        slots={{
          toolbar: CustomToolbar
        }}
        slotProps={{
          toolbar: {
            printOptions,
            csvOptions: {
              // fileName: 'customerDataBase',
              // delimiter: ';',
              utf8WithBom: true
              // fields: columns.map((column) => column.field)
            },
            tooltip: { title: 'Exportar datos' },
            button: { variant: 'outlined' },
            bulkAction: {
              component: bulkAction,
              tooltip: 'Acciones masivas',
              bulkActionProps,
              onAfterSave: rest.handleRefresh
            },
            onRefresh
          },
          loadingOverlay: {
            variant: 'linear-progress'
          }
        }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        ignoreValueFormatterDuringExport
        sx={{ ...defaultSx, ...sx }}
      />
    </Box>
  );
};

GMGrid.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  sx: PropTypes.object,
  checkboxSelection: PropTypes.bool,
  disableSelectionOnClick: PropTypes.bool,
  onRowSelected: PropTypes.func,
  apiRef: PropTypes.object,
  slot: PropTypes.object,
  slotProps: PropTypes.object,
  onRefresh: PropTypes.func
};

CustomToolbar.propTypes = {
  csvOptions: PropTypes.object,
  printOptions: PropTypes.object,
  bulkAction: PropTypes.object,
  onRefresh: PropTypes.func
};
