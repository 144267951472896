import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { formatDistance, format } from 'date-fns';
import { es } from 'date-fns/locale';

import { Link as RouterLink } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
// material
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { documentApi } from '../../../api/document';

import LoadingContent from '../../common/loading-content';
import { useMounted } from '../../../hooks/use-mounted';
import { RenderIf } from '../../render-if';
import { useData } from '../../../hooks/use-data';
import { useApp } from '../../../hooks/use-app';
import { useRef } from 'react';

NewAppLogItem.propTypes = {
  news: PropTypes.object.isRequired
};

function NewAppLogItem({ news }) {
  const { memo, message: description, createdate, docentry, objectName, objtype } = news;
  const { appUrlBase } = useApp();
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box sx={{ minWidth: 240 }}>
        <RenderIf condition={objtype === 124} no={`${docentry} - ${objectName}`}>
          <Link
            to={`${appUrlBase}/sales/orders/${docentry}`}
            color="inherit"
            underline="hover"
            component={RouterLink}
          >
            <Typography variant="subtitle2" noWrap>
              {docentry} - {objectName}
            </Typography>
          </Link>
        </RenderIf>
        <RenderIf condition={!!memo}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {memo}
          </Typography>
        </RenderIf>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {description}
        </Typography>
      </Box>
      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {format(new Date(createdate), 'yyyy-MM-dd HH:mm:ss')}
      </Typography>
      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {formatDistance(new Date(createdate), new Date(), { locale: es })}
      </Typography>
    </Stack>
  );
}

export default function AppLogRecent() {
  const { appUrlBase } = useApp();
  const mounted = useMounted();

  const [appLogStatics, handleRefresh] = useData({
    sourceApi: documentApi.getDocumentLogRecent,
    apiParameter: {
      limit: 100
    },
    loadingMessage: 'Cargando logs recientes',
    mounted
  });

  const scrollbarRef = useRef();

  if (appLogStatics.isLoading || appLogStatics.error)
    return (
      <LoadingContent
        loadingText={appLogStatics.message}
        error={appLogStatics.error}
        state={appLogStatics}
      />
    );
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Sucesos recientes" />
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          endIcon={<Icon icon={refreshFill} />}
          onClick={handleRefresh}
        />
        <Button
          color="primary"
          endIcon={<Icon icon={eyeFill} />}
          size="small"
          sx={{ order: 1 }}
          to={`${appUrlBase}/logs/gadmin-logs`}
          component={RouterLink}
        />
      </Box>

      <Scrollbar ref={scrollbarRef}>
        <Box sx={{ maxHeight: '600px', maxWidth: 'zx', overflow: 'auto' }}>
          <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            {appLogStatics?.data?.map((news) => (
              <NewAppLogItem key={news.id} news={news} />
            ))}
          </Stack>
        </Box>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          color="primary"
          endIcon={<Icon icon={arrowIosForwardFill} />}
          size="small"
          sx={{ order: 1 }}
          to={`${appUrlBase}/logs/gadmin-logs`}
          component={RouterLink}
        >
          Ver Mas
        </Button>
      </Box>
    </Card>
  );
}
