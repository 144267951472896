import { goalCustomeDeleveryPlanningApi } from '../../../../api/goal/planning/goal-customer-delivery-planning';
import { ColumnDataTypesEnum } from '../../../gm/grid/grid-definitions';
import { GMGridContainer } from '../../../gm/grid/gm-grid-context';
import { useDeliveryPlanning } from '../delivery-planing-context';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: ColumnDataTypesEnum.ID,
    link: {
      to: (item) => `/sales/orders/${item.id}`,
      tooltip: 'Ver detalle del pedido'
    }
  },
  {
    field: 'name',
    headerName: 'Placa',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'capacity',
    headerName: 'Peso',
    type: ColumnDataTypesEnum.TOTAL
  },
  {
    field: 'capacity_2',
    headerName: 'Volumen',
    type: ColumnDataTypesEnum.TOTAL
  },
  {
    field: 'capacity_3',
    headerName: 'Cobertura',
    type: ColumnDataTypesEnum.TOTAL
  }
];
export const DeliveryPlaningVehicles = () => {
  const { pendingDeliveryParams } = useDeliveryPlanning();

  return (
    <GMGridContainer
      columns={columns}
      sourceApi={goalCustomeDeleveryPlanningApi.getVehicles}
      apiParameters={pendingDeliveryParams}
      loadingMessage="Cargando pedidos pendientes"
    />
  );
};
