import { dataLoader } from '../../data-loader';

const refreshAllAuthorizations = async ({ companyId }) => {
  const response = await dataLoader.loadGoalGetData({
    action: `/automations/sbo/refresh-all-authorizations`,
    params: { companyId }
  });
  return response;
};

export { refreshAllAuthorizations };
