import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { goalCustomeDeleveryPlanningApi } from '../../../api/goal/planning/goal-customer-delivery-planning';
import { useApp } from '../../../hooks/use-app';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import moment from 'moment';
import { todayAsString } from '../../../utils/formatTime';
import { useSearchParams } from 'react-router-dom';
import { appFormats } from '../../../api/app-definitions';

const DeliveryPlanningContext = createContext();

export const DeliveryPlanningProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [profiles, setProfiles] = useState([]);
  const [currentProfile, setCurrentProfile] = useState();
  const [planningDate, setPlanningDate] = useState(
    moment(searchParams.get('date') || todayAsString()).toDate()
  );
  const [planningOptions, setPlanningOptions] = useState({
    minDate: moment().add(-10, 'day').toDate(),
    maxDate: moment().add(3, 'day').toDate(),
    dateFrom: moment().add(-10, 'day').toDate(),
    dateTo: moment().toDate()
  });

  const mounted = useMounted();

  const { currentCompany, showNotify } = useApp();
  const [deliveryPlans, setDeliveryPlans] = useState([]);

  const [selectedTab, setSelectedTab] = useState(parseInt(searchParams.get('tab') || 0));

  const handleSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
    // setSearchParams({ ...searchParams, tab: newValue });
  };

  const [, handleRefreshStages] = useData({
    sourceApi: goalCustomeDeleveryPlanningApi.getStages,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando informacion de tabs',
    mounted,
    defaults: {
      noAutoload: true
    },
    afterLoad: (result) => {
      if (result.success) {
        setProfiles(result.data);
        if (!currentProfile && result.data.length > 0) {
          setCurrentProfile(result.data[0]);
        }
      } else {
        showNotify(result.message, 'error al cargar los datos');
      }
    }
  });

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      date: moment(planningDate).format(appFormats.backendDateFormat)
    });
  }, [planningDate]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      tab: selectedTab
    });
  }, [selectedTab]);

  const handleRefresh = () => {
    handleRefreshStages();
  };

  const handleAfterSave = () => {
    handleRefresh();
  };

  const addDeliveryPlan = (plan) => {
    setDeliveryPlans([...deliveryPlans, plan]);
  };

  const removeDeliveryPlan = (planId) => {
    setDeliveryPlans(deliveryPlans.filter((plan) => plan.id !== planId));
  };

  const filters = {
    companyId: currentCompany,
    planningDate,
    currentProfile,
    planningOptions
  };

  const setFilters = (filters) => {
    setPlanningDate(filters.planningDate);
    setCurrentProfile(filters.currentProfile);

    setPlanningOptions((prevState) => ({
      ...prevState,
      ...filters.planningOptions
    }));

    handleRefresh();
  };

  // get pending delivery params
  const pendingDeliveryParams = {
    companyId: currentCompany,
    account: currentProfile?.account,
    date: moment(planningDate).format('YYYY-MM-DD'),
    planificationDate: moment(planningDate).format('YYYY-MM-DD'),
    dateFrom: moment(planningOptions.dateFrom).format('YYYY-MM-DD'),
    dateTo: moment(planningOptions.dateTo).format('YYYY-MM-DD')
  };

  useEffect(() => {
    handleRefreshStages();
  }, []);

  return (
    <DeliveryPlanningContext.Provider
      value={{
        deliveryPlans,
        addDeliveryPlan,
        removeDeliveryPlan,
        profiles,
        handleRefresh,
        currentProfile,
        onAfterSave: handleAfterSave,
        setCurrentProfile,
        planningDate,
        setPlanningDate,
        filters,
        setFilters,
        pendingDeliveryParams,
        handleSelectedTab,
        selectedTab
      }}
    >
      {children}
    </DeliveryPlanningContext.Provider>
  );
};
DeliveryPlanningProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useDeliveryPlanning = () => {
  return useContext(DeliveryPlanningContext);
};
