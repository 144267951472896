import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

const endPointLocal = '/surveys/surveys';

class GoalCustomerSurveygApi {
  // Survey
  async getSurveys(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, companyId, searchText } =
      options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `${endPointLocal}`,
      cacheItem: 'customer-survey-list',
      apiParams: {
        companyId,
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        searchText,
        orderDate: mainFilter?.orderDate,
        view
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'title' },
        { type: 'string', name: 'statusName' },
        { type: 'string', name: 'date' },
        { type: 'number', name: 'point' }
      ],
      ...options
    });
  }

  async postSurveySurveyForm(data) {
    const response = await dataLoader.loadGoalPost({
      action: `${endPointLocal}`,
      changes: data
    });
    return response;
  }

  async updateSurveySurveyForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `${endPointLocal}/${id}`,
      changes: data
    });
    return response;
  }

  async getSurveyDetail({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/${id}?companyId=${companyId}`
    });
    return response;
  }

  async cancelSurvey(id, companyId) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${id}/cancel`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async closeSurvey(id, companyId) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${id}/close`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async validateSurvey(id, companyId) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${id}/validate`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async rejectSurvey(id, companyId) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${id}/reject`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async activateSurvey(id, companyId) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${id}/activate`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async archivedSurvey(id, companyId) {
    const response = await dataLoader.goalPut({
      path: `${endPointLocal}/${id}/archive`,
      changes: { company_id: companyId }
    });
    return response;
  }

  // Delete Line From Survey
  async deleteLineFromSurvey({ id, companyId, line }) {
    const response = await dataLoader.goalDelete({
      action: `${endPointLocal}/${id}/delete-line/${line}?companyId=${companyId}`
    });
    return response;
  }

  async getSurveySurveyDeleteLine(id, line) {
    const response = {
      success: true,
      id,
      line
    };
    return response;
  }

  // Delete Lines From Survey
  async deleteLinesFromSurvey({ id, companyId, lines }) {
    const response = await dataLoader.goalPost({
      action: `${endPointLocal}/${id}/delete-questions`,
      data: {
        companyId,
        lines
      }
    });
    return response;
  }

  // add lines from survey
  async addLinesFromSurvey({ id, companyId, lines }) {
    const response = await dataLoader.goalPost({
      action: `${endPointLocal}/${id}/add-questions`,
      data: {
        companyId,
        lines
      }
    });
    return response;
  }

  async getSurveyAssigns({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}/${id}/assignments`,
      params: { companyId }
    });
    return response;
  }

  async getSurveyResults({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}/${id}/results`,
      params: { companyId }
    });
    return response;
  }
}

export const goalCustomeSurveyApi = new GoalCustomerSurveygApi();
