import { useEffect, useState } from 'react';
import { Card, Tabs, Tab, Divider, CardHeader, CardContent } from '@mui/material';
import { PropertyList } from '../../components/property-list';
import { PropertyListItem } from '../../components/property-list-item';
import { useParams } from 'react-router-dom';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';
import LoadingContent from '../../components/common/loading-content';
import { useApp } from '../../hooks/use-app';
import BackButton from '../../components/common/buttons/back-button';
import { RefreshButton, SyncButton } from '../../components/common/buttons';
import {
  getRindegastoUser,
  syncRindegastoUsers
} from '../../api/goal/rindegastos/rindegastos-user-api';
import CustomTabPanel from '../../components/common/custom-tab-panel';

const componentTitle = 'Usuario de Rindegasto';

const parentRoute = `/rindegasto/users`;

const RindegastoUser = () => {
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const { currentCompany, showError, showNotify } = useApp();
  const mounted = useMounted();
  const { id } = useParams();

  const [documentState, handleRefresh] = useData({
    sourceApi: getRindegastoUser,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: 'Cargando detalle del usuario...',
    mounted,
    defaults: {
      noAutoLoad: true
    },
    afterLoad: (result) => {
      if (result.success) {
        setData(result.data);
      } else {
        showError(result.message);
      }
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSync = async () => {
    try {
      const result = await syncRindegastoUsers({ companyId: currentCompany, id });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (mounted.current) handleRefresh();
  }, [id]);

  if (documentState.error || documentState.isLoading || !documentState.data) {
    return (
      <LoadingContent
        loadingText={documentState.message}
        error={documentState.error}
        state={documentState}
      />
    );
  }

  return (
    <Card sx={{ padding: 2, backgroundColor: 'background.default' }}>
      <CardHeader
        subheader={componentTitle}
        title={data.name}
        // avatar={<Avatar src={data.logo} />}
        avatar={<BackButton to={parentRoute} />}
        action={
          <>
            <SyncButton onClick={handleSync} />
            <RefreshButton onClick={handleRefresh} />
          </>
        }
      />
      <CardContent>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="user tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Información General" />
          <Tab label="Relación" />
          <Tab label="Configuración del Plan" />
          <Tab label="Información de Facturación" />
        </Tabs>
        <Divider sx={{ marginBottom: 2 }} />
        <CustomTabPanel value={value} index={0}>
          <PropertyList>
            <PropertyListItem label="Nombre" value={data.name} />
            <PropertyListItem label="Dominio" value={data.domain || 'N/A'} />

            <PropertyListItem label="Teléfono" value={data.relationship?.user?.cellphone || '-'} />

            <PropertyListItem label="Fecha de Creación" value={data.createdAt} />
            <PropertyListItem label="Activo" value={data.isActive ? 'Sí' : 'No'} />
            <PropertyListItem label="Número de Usuarios" value={data.numberOfUsers} />
            <PropertyListItem label="Plan" value={data.planConfiguration.plan} />
          </PropertyList>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PropertyList>
            <PropertyListItem label="ID Relación" value={data.relationship.id} />
            <PropertyListItem label="ID Usuario" value={data.relationship.userId} />
            <PropertyListItem label="ID Compañía" value={data.relationship.companyId} />
            <PropertyListItem label="Roles" value={JSON.stringify(data.relationship.roles)} />
            <PropertyListItem
              label="Permisos de Admin"
              value={JSON.stringify(data.relationship.adminPermissions)}
            />
            <PropertyListItem
              label="Módulos de Empleado"
              value={JSON.stringify(data.relationship.employeeModules)}
            />
            <PropertyListItem label="Activo" value={data.relationship.isActive ? 'Sí' : 'No'} />
          </PropertyList>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <PropertyList>
            <PropertyListItem label="Plan" value={data.planConfiguration.plan} />
            <PropertyListItem
              label="Módulo de Fondos"
              value={data.planConfiguration.fundsModule ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Módulo de Reportes Básicos"
              value={data.planConfiguration.basicReportingModule ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Gastos de Distancia"
              value={data.planConfiguration.distanceExpenses ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Módulo de Estados Personalizados"
              value={data.planConfiguration.customStatusModule ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Módulo de Exportaciones"
              value={data.planConfiguration.exportsModule ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Módulo de Plantillas"
              value={data.planConfiguration.templatesModule ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Módulo de Integración"
              value={data.planConfiguration.integrationModule ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Impuestos Personalizados"
              value={data.planConfiguration.customTaxes ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Validaciones"
              value={data.planConfiguration.validations ? 'Sí' : 'No'}
            />
            <PropertyListItem
              label="Tasa de Cambio Automática"
              value={data.planConfiguration.autoExchangeRate ? 'Sí' : 'No'}
            />
          </PropertyList>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <PropertyList>
            <PropertyListItem
              label="Nombre de Contacto"
              value={`${data.billingInfo.contactUser.firstname} ${data.billingInfo.contactUser.lastname}`}
            />
            <PropertyListItem label="Teléfono" value={data.billingInfo.biPhone} />
            <PropertyListItem label="Correo" value={data.billingInfo.biMail || 'N/A'} />
            <PropertyListItem label="Dirección" value={data.billingInfo.biAddress} />
            <PropertyListItem label="Ciudad" value={data.billingInfo.biCity} />
            <PropertyListItem label="País" value={data.billingInfo.biCountry} />
            <PropertyListItem label="Giro" value={data.billingInfo.biGiro} />
            <PropertyListItem label="Comuna" value={data.billingInfo.biComuna} />
            <PropertyListItem label="Código Postal" value={data.billingInfo.biZipCode} />
            <PropertyListItem label="Casilla DTE" value={data.billingInfo.biCasillaDte} />
          </PropertyList>
        </CustomTabPanel>
      </CardContent>
    </Card>
  );
};

export default RindegastoUser;
