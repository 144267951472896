import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Container } from '@mui/material';
import { useMounted } from '../../hooks/use-mounted';
import { useApp } from '../../hooks/use-app';
import { ImportForm } from '../../components/importer/import-form';
import { goalImporterApi } from '../../api/goal/importer/goal-importer-api';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useData } from '../../hooks/use-data';
import LoadingContent from '../../components/common/loading-content';
import { BackLink } from '../../components/links/back-link';
import { useGoalLink } from '../../components/links/use-goal-link';
import { useAuth } from '../../hooks/use-auth';
import GadminActions from '../../contexts/gadmin-actions';

export const ImportDetailContainer = ({ isNew }) => {
  const { id } = useParams();

  const mounted = useMounted();
  const { title, currentCompany } = useApp();
  const { hasPermission } = useAuth();
  const navigate = useGoalLink();
  const isEditableView = hasPermission(
    isNew ? GadminActions.G2_TEMPLATE_ADD : GadminActions.G2_TEMPLATE_UPDATE
  );

  const [templateState, handleRefresh] = useData({
    sourceApi: goalImporterApi.getManagerTemplate,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      noAutoLoad: true
    }
  });
  const componentTitle = isNew ? 'Crear Plantilla' : 'Detalle de Plantilla';

  useEffect(() => {
    if (!isNew) handleRefresh({ companyId: currentCompany, id });
  }, [id]);

  const renderContent = () => {
    if (!isNew && (templateState.isLoading || !templateState.data || templateState.error)) {
      return (
        <LoadingContent
          loadingText={templateState.message}
          error={templateState.error}
          state={templateState}
        />
      );
    }
    return (
      <ImportForm
        title={componentTitle}
        handleRefresh={
          isNew
            ? (newId) => {
                const neRoute = `${'/managment/import'}/${newId}`;
                navigate(neRoute);
              }
            : handleRefresh
        }
        initialValues={isNew ? {} : templateState.data}
        update={!isNew}
        isEditable={isEditableView}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>{title(componentTitle)} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <CardHeader title={componentTitle} avatar={<BackLink to={`/managment/import`} />} />
            <CardContent>{renderContent()}</CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

ImportDetailContainer.propTypes = {
  isNew: PropTypes.bool
};
