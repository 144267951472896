export const isTrue = (value) => {
  const trueValues = ['y', '1', 'true', 'yes', 'on', 'Y'];
  if (!value) {
    return false;
  }

  // if is  number
  if (!isNaN(value)) {
    return value > 0;
  }

  // if is string
  if (typeof value === 'string') {
    return trueValues.includes(value.toString().toLowerCase());
  }
  return !!value;
};

export const useBolean = () => {
  const handleIsTrue = (value) => {
    return isTrue(value);
  };

  return { isTrue: handleIsTrue };
};

export const isValidPositive = (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (!value || isNaN(value)) {
    return false;
  }
  return value > 0;
};
