import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export const GoalIcon = ({ icon, muiIcon: IconComponent }) => {
  if (icon) {
    return (
      <Box sx={{ mr: 2 }}>
        <Icon icon={icon} width={22} height={22} />
      </Box>
    );
  } else {
    return <IconComponent sx={{ mr: 2, width: 24, height: 24 }} />;
  }
};
GoalIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  muiIcon: PropTypes.object
};

export const getIcon = (name) => <GoalIcon icon={name} />;
