import { appDefinitions } from '../app-definitions';
import { dataLoader } from './../data-loader';

/* eslint class-methods-use-this:0 */

class UsersApi {
  async getUsers({ companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/users`,
      params: {
        companyId: companyId,
        view: view,
        // page: page,
        // per_page: 1000,
        // search: search,
        // order_direction: sort,
        // order_by: sortBy,
        limit: 1000
      }
    });

    if (response.data && response.data.data) response.data = response.data.data;

    return response;
  }

  async getUser({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getRolesOfUser({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}/roles`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getUserInSap({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}/sb1-user`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }

  async getUserPermissionsByTheirRoles({
    companyId,
    id,
    view = '',
    page = 1,
    perPage = appDefinitions.pageSize,
    search = '',
    sort = '',
    sortBy = ''
  }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}/permissions`,
      params: {
        companyId: companyId,
        view: view,
        page: page,
        per_page: perPage,
        search: search,
        order_direction: sort,
        order_by: sortBy
      }
    });
    return response;
  }

  async getCompaniesOfUser({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `auth/users/${id}/companies`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }
}

export const usersApi = new UsersApi();
