import PropTypes from 'prop-types';
import { useApp } from '../../../hooks/use-app';
import { GoalMenu } from '../../common/goal-menu';
import { DeleteMenuItem } from '../../common/menu/delete-menu-item';
import CommonRecordEditor from '../../common/buttons/record-editor-container';
import { GoalMenuDialog } from '../../menu/goal-menu-dialog';
import PolicyTemplateExpenseFieldEditor from './policy-template-expense-field-editor';
import EditIcon from '@mui/icons-material/Edit';

export const PolicyTemplateExpenseFieldMenu = ({ data, onAfterSave }) => {
  const { currentCompany } = useApp();

  const handleAdd = (selected) => {
    console.log('data', data);
  };
  return (
    <GoalMenu>
      <CommonRecordEditor
        onAfterSave={onAfterSave}
        slot={{
          content: PolicyTemplateExpenseFieldEditor,
          component: GoalMenuDialog
        }}
        slotProps={{
          content: {
            data: data
          },
          component: {
            label: 'Editar'
          },
          dialog: {
            title: 'Editar campo'
          }
        }}
        startIcon={<EditIcon />}
      />
      <DeleteMenuItem
        onConfirm={() => console.log('selectedRows', 'asdasd')}
        label="Eliminar campo"
      />
    </GoalMenu>
  );
};

PolicyTemplateExpenseFieldMenu.propTypes = {
  data: PropTypes.object,
  onAfterSave: PropTypes.func
};
