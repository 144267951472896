import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Typography } from '@mui/material';

import moment from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useApp } from '../../../hooks/use-app';
import { HRInvoicingTable } from '../../../components/delivery/hr/hrs-open-table';
import { HRInvoicingStats } from '../../../components/delivery/hr/hrs-open-stats';
import { GoalAccordion } from '../../../components/goal-accordion';
import { appFormats } from '../../../api/app-definitions';
import { dateFromString } from '../../../utils/formatTime';
import { useSearchParams } from 'react-router-dom';

const limitDate = moment().add(7, 'days').toDate();

export const HRInvoicing = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queried = {
    date: searchParams.get('date') || moment().format('YYYY-MM-DD')
  };
  const app = useApp();

  const [currentDate, setCurrentDate] = useState(dateFromString(queried.date));

  const handleDateChanged = (newValue) => {
    if (!newValue) return;
    setCurrentDate(newValue);
    setSearchParams({ date: moment(newValue).format(appFormats.backendDateFormat) });
  };

  return (
    <>
      <Helmet>
        <title>{app.title('HR Facturando')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Facturando
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Fecha"
                  inputFormat={appFormats.date}
                  maxDate={limitDate}
                  value={currentDate}
                  onAccept={handleDateChanged}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <GoalAccordion title={'Estadísticas de Facturación'}>
            <HRInvoicingStats />
          </GoalAccordion>
          <HRInvoicingTable date={currentDate} />
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};
