import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Typography,
  TableContainer
} from '@mui/material';
import TableHeadEnhanced from '../../common/table-head-enhanced';

const headCells = [
  {
    id: 'id',
    label: 'ID'
  },
  {
    id: 'title',
    label: 'Titulo'
  },
  {
    id: 'type_question',
    label: 'Tipo de Pregunta'
  },
  {
    id: 'question',
    disablePadding: false,
    label: 'Pregunta'
  },
  {
    id: 'options',
    label: 'Opciones'
  },
  {
    id: 'correct',
    label: 'Respuesta'
  },
  {
    id: 'score',
    label: 'Puntaje'
  }
  // {
  //   id: 'apply_to',
  //   label: 'Aplicado a'
  // }
];

export const SurveyItemsTable = ({ data = [], onSelect, onSelectAll, selectedItems }) => {
  return (
    <>
      <TableContainer sx={{ maxHeight: '60vh' }}>
        <Table sx={{ minWidth: 500 }}>
          <TableHeadEnhanced
            keyPrefix="survey-questions-column"
            headCells={headCells}
            onSelectAllClick={onSelectAll}
            numSelected={selectedItems.length}
            rowCount={data.length}
          />

          <TableBody>
            {data?.map((item, index) => (
              <TableRow
                key={item.id}
                hover
                selected={!!selectedItems.find((selectedItem) => selectedItem === item.id)}
              >
                {onSelect && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={!!selectedItems.find((selectedItem) => selectedItem === item.id)}
                      onChange={(event) => onSelect(event, item.id)}
                    />
                  </TableCell>
                )}
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.type_question}</TableCell>
                <TableCell>{item.question}</TableCell>
                <TableCell>
                  {item.type_question === 'multiselect' ||
                  item.type_question === 'select' ||
                  item.type_question === 'range' ? (
                    item.options.map((option, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {option}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      ---
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {item.type_question === 'multiselect' ? (
                    item.correct.map((item, index) => (
                      <Typography key={index} color="textSecondary" variant="body2">
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      {item.correct}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {item.score}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  {item.apply_to ? (
                    <>
                      {item.apply_to.slice(0, 5).map((item, index) => (
                        <Typography key={index} color="textSecondary" variant="body2">
                          {item?.name}
                        </Typography>
                      ))}
                      {item.apply_to.length > 5 && (
                        <Typography color="textSecondary" variant="body2">
                          ...
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      --
                    </Typography>
                  )}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SurveyItemsTable.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedItems: PropTypes.array
};
