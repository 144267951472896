import { useState, useMemo } from 'react';
import Proptypes from 'prop-types';
import { Box, CardContent, IconButton, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { makeStyles } from '@mui/styles';
import { Trash } from '../../icons/trash';
import { ConfirmationDialog } from '../confirmation-dialog';
import { capitalizeParagraph } from '../../hooks/use-split';
import Scrollbar from '../Scrollbar';

export const CommonTreeView = ({
  expandedItems,
  setExpandedItems,
  title = '',
  setSelectedNodeDataItem,
  onSelectNode,
  data,
  deleteAction = false,
  onDeleteNode = () => {},
  onLevelChange = () => {}
}) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [idDelete, setIdDelete] = useState(null);

  const renderNodes = (items, level) =>
    items?.map((item) => {
      item.level = level;
      return (
        <TreeItem
          key={item.id}
          itemId={`${item.id}`}
          label={
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {capitalizeParagraph(item.name)}
              {selectedNode === item.id && !item.has_children && deleteAction && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenCornfirm(item);
                  }}
                >
                  <Trash />
                </IconButton>
              )}
            </Box>
          }
          onClick={() => {
            onSelectNode(item);
            setSelectedNode(item.id);
            onLevelChange?.(item.level);
          }}
        >
          {item.children && renderNodes(item.children, level + 1)}
        </TreeItem>
      );
    });

  const memoizedNodes = useMemo(() => renderNodes(data, 1), [data, selectedNode, deleteAction]);

  const handleOpenCornfirm = (item) => {
    setModalOpen(true);
    setIdDelete(item.id);
    setConfirmDialogMessage(`Estas seguro de eliminar este nodo - ${item.name}`);
  };

  const handleClickDelete = async (idDelete) => {
    onDeleteNode(idDelete);
    setModalOpen(false);
  };

  return (
    <Box sx={{ maxHeight: 500, overflowY: 'auto' }}>
      <Scrollbar>
        <SimpleTreeView
          aria-label="file system navigator"
          expandedItems={expandedItems}
          onExpandedItemsChange={(event, nodeIds) => setExpandedItems(nodeIds)}
        >
          <TreeItem
            itemId="node_0"
            label={
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant="h6">{title}</Typography>
              </Box>
            }
            onClick={() => {
              setSelectedNodeDataItem(null);
              setSelectedNode('node_0');
              setExpandedItems([]);
            }}
          />
          {memoizedNodes}
        </SimpleTreeView>
      </Scrollbar>
      <ConfirmationDialog
        message={confirmDialogMessage}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => handleClickDelete(idDelete)}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

CommonTreeView.propTypes = {
  expandedItems: Proptypes.array,
  setExpandedItems: Proptypes.func,
  title: Proptypes.string,
  setSelectedNodeDataItem: Proptypes.func,
  data: Proptypes.array,
  onSelectNode: Proptypes.func,
  onDeleteNode: Proptypes.func,
  deleteAction: Proptypes.bool,
  onLevelChange: Proptypes.func
};
