//import moment from 'moment';
import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalCustomerDeliveryPlanning {
  // get all plans for date
  async getAllPlansForDate({ account, date, $companyId, ...rest }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/simpli-route/${account}/plans-for-date`,
      params: {
        date,
        companyId: $companyId,
        ...rest
      }
    });
    return response;
  }

  async getStages({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/simpli-route/stages?companyId=${companyId}`
    });
    return response;
  }

  async getZonePlanning({ account }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/simpli-route/zones?account=${account}`
    });
    return response;
  }

  async uploadOrders({ account, ...values }) {
    const response = await dataLoader.goalPost({
      action: `/simpli-route/${account}/upload-orders`,
      data: values
    });
    return response;
  }

  async generateRouteSheets({ company_id, account, ...rest }) {
    const response = await dataLoader.goalPost({
      action: `/simpli-route/${account}/generate-routes`,
      data: {
        company_id,
        account,
        ...rest
      }
    });

    return response;
  }

  // get pending orders
  async getPendingOrders({ companyId, account, ...rest }) {
    const response = await dataLoader.goalGet({
      action: `/simpli-route/${account}/pending-orders`,
      params: {
        companyId,
        ...rest
      }
    });
    return response;
  }

  // list  not-assigned-orders
  async getNotAssignedOrders({ companyId, account, ...rest }) {
    const response = await dataLoader.goalGet({
      action: `/simpli-route/${account}/not-assigned-orders`,
      params: {
        companyId,
        ...rest
      }
    });
    return response;
  }
  // get summary
  async getSummary({ companyId, account, ...rest }) {
    const response = await dataLoader.goalGet({
      action: `/simpli-route/${account}/summary`,
      params: {
        companyId,
        ...rest
      }
    });
    return response;
  }

  // get vehicles
  async getVehicles({ companyId, account, ...rest }) {
    const response = await dataLoader.goalGet({
      action: `/simpli-route/${account}/vehicles`,
      params: {
        companyId,
        ...rest
      }
    });
    return response;
  }

  // get drivers

  async getDrivers({ companyId, account, ...rest }) {
    const response = await dataLoader.goalGet({
      action: `/simpli-route/${account}/drivers`,
      params: {
        companyId,
        ...rest
      }
    });
    return response;
  }

  // reset orders
  async resetOrders({ companyId, account, ...rest }) {
    const response = await dataLoader.goalPost({
      action: `/simpli-route/${account}/reset-orders`,
      data: {
        company_id: companyId,
        companyId,
        ...rest
      }
    });
    return response;
  }
}

export const goalCustomeDeleveryPlanningApi = new GoalCustomerDeliveryPlanning();
