import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Card, Tabs, Tab, Divider, CardHeader, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';
import LoadingContent from '../../components/common/loading-content';
import { useApp } from '../../hooks/use-app';

import BackButton from '../../components/common/buttons/back-button';
import { RefreshButton, SyncButton } from '../../components/common/buttons';
import {
  getPolicyTemplate,
  syncPolicyTemplate
} from '../../api/goal/rindegastos/rindegasto-policy-template-api';
import CustomTabPanel from '../../components/common/custom-tab-panel';
import GeneralInfoTab from './policy-template-tabs/general-info-tab';
import DistanceExpensesTab from './policy-template-tabs/distance-expenses-tab';
import TaxesTab from './policy-template-tabs/taxes-tab';
import ExpenseExtraFieldsTab from './policy-template-tabs/expense-extra-fields-tab';
import ReportExtraFieldsTab from './policy-template-tabs/report-extra-fields-tab';
import UsersTab from './policy-template-tabs/users-tab';
import AccountTab from './policy-template-tabs/account-tab';
import CurrencyTab from './policy-template-tabs/currency-tab';
import { GoalContainer } from '../../components/common/goal-container';

const parentRoute = `/rindegasto/policy-templates`;

const RindegastoPolicyTemplate = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [data, setData] = useState([]);
  const { currentCompany, showError, showNotify } = useApp();
  const mounted = useMounted();
  // get id from params
  const { id } = useParams();

  const [documentState, handleRefresh] = useData({
    sourceApi: getPolicyTemplate,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: 'Cargando detalle del flujo de trabajo...',
    mounted,
    defaults: {
      noAutoLoad: true
    },
    afterLoad: (result) => {
      if (result.success) {
        setData(result.data);
      } else {
        showError(result.message);
      }
    }
  });

  const handleSync = async () => {
    try {
      const result = await syncPolicyTemplate({ companyId: currentCompany, id });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleRefresh();
  }, [id]);

  if (documentState.error || documentState.isLoading || !documentState.data) {
    return (
      <LoadingContent
        loadingText={documentState.message}
        error={documentState.error}
        state={documentState}
      />
    );
  }

  return (
    <GoalContainer
      title={data.name}
      subheader="Detalle de la política"
      avatar={<BackButton to={parentRoute} />}
      actions={
        <>
          <SyncButton onClick={handleSync} />
          <RefreshButton onClick={handleRefresh} />
        </>
      }
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="info tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Información General" />
        <Tab label="Moneda" />
        <Tab label="Gastos de Distancia" />
        <Tab label="Impuestos" />
        <Tab label="Campos Extra de Gastos" />
        <Tab label="Campos Extra de Reporte" />
        <Tab label="Cuenta" />
        <Tab label="Usuarios" />
      </Tabs>
      <Divider sx={{ marginBottom: 2 }} />
      <CustomTabPanel value={value} index={0}>
        <GeneralInfoTab data={data} onRefresh={handleRefresh} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CurrencyTab data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <DistanceExpensesTab data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <TaxesTab data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <ExpenseExtraFieldsTab data={data} onRefresh={handleRefresh} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <ReportExtraFieldsTab data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <AccountTab data={data} onRefresh={handleRefresh} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        <UsersTab data={data} />
      </CustomTabPanel>
    </GoalContainer>
  );
};

RindegastoPolicyTemplate.propTypes = {
  data: PropTypes.object
};

export default RindegastoPolicyTemplate;
