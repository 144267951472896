import { dataLoader } from '../data-loader';

/* eslint class-methods-use-this:0 */

class BulksApi {
  async getBulks(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId, searchText } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `automations/bulks`,
      cacheItem: 'bulks',
      apiParams: {
        companyId,
        type: 'list',
        view,
        filterBy: searchText,
        payload: { filters, sort, sortBy, page, query, reload }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: '_id' },
        { type: 'string', name: '_rev' },
        { type: 'string', name: 'ObjectName' },
        { type: 'string', name: 'ObjectCode' },
        { type: 'string', name: 'created_at' },
        { type: 'string', name: 'updated_at' }
      ],
      ...options
    });
  }

  async getBulk({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `automations/bulks/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getQueuesOfOneBulk({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `automations/bulks/${id}/queues?companyId=${companyId}`
    });
    return response;
  }

  async getQueues(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId, searchText } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `automations/queues`,
      cacheItem: 'automationsQueues',
      apiParams: {
        companyId,
        type: 'list',
        view,
        filterBy: searchText,
        payload: { filters, sort, sortBy, page, query, reload }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: '_id' },
        { type: 'string', name: '_rev' },
        { type: 'string', name: 'ObjectName' },
        { type: 'string', name: 'ObjectCode' },
        { type: 'string', name: 'created_at' },
        { type: 'string', name: 'updated_at' },
        { type: 'number', name: 'bulkId' }
      ],
      ...options
    });
  }

  async getQueue({ companyId, id }) {
    const response = await dataLoader.loadGoalGetData({
      action: `automations/queues/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getQueuesByBulkId({ companyId, bulkId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `automations/queues?companyId=${companyId}&bulkId=${bulkId}`
    });
    return response;
  }
}

export const bulksApi = new BulksApi();
