import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalSurveyQuestiongApi {
  async getSurveyQuestionsList(options) {
    const { filters, sort, sortBy, page, query, view, reload, mainFilter, searchText } = options;
    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: '/surveys/surveys-questions',
      cacheItem: 'customer-survey-list',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        searchText,
        orderDate: mainFilter?.orderDate,
        view
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'title' },
        { type: 'number', name: 'category' },
        { type: 'number', name: 'subcategory' },
        { type: 'string', name: 'type_question' },
        { type: 'number', name: 'score' }
      ],
      ...options
    });
  }

  async postSurveyForm(data) {
    const response = await dataLoader.loadGoalPost({
      action: '/surveys/surveys-questions',
      changes: data
    });
    // console.log(data);
    return response;
  }

  async updateSurveyForm(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/surveys/surveys-questions/${id}`,
      changes: data
    });
    // console.log(data);
    return response;
  }

  async deleteSurveyForm(id) {
    const response = await dataLoader.loadGoalDelete({
      action: `/surveys/surveys-questions/${id}`
    });
    return response;
  }

  async getSurveyIssuers() {
    const response = await dataLoader.loadGoalGetData({
      action: '/surveys/surveys-questions/issuers'
    });
    return response;
  }

  async getSurveyCategory({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/surveys/surveys-questions/categories?companyId=${companyId}`
    });
    return response;
  }

  async getSurveySubCategory({ companyId, categoryId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/surveys/surveys-questions/sub-categories?companyId=${companyId || ''}&categoryId=${
        categoryId || ''
      }`
    });
    return response;
  }
}

export const goalSurveyQuestiongApi = new GoalSurveyQuestiongApi();
