import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import numeral from 'numeral';
import { Check as CheckIcon } from '../../../icons/check';
import { X as XIcon } from '../../../icons/x';
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  LinearProgress
} from '@mui/material';
import { Trash as TrashIcon } from '../../../icons/trash';
import { ImageDropzone } from '../../common/image-dropzone';
import { useApp } from '../../../hooks/use-app';
import GoalButton from '../../goal-button';
import { mimeName } from '../../../api/goal/importer/importer-definition';

export const DocumentAppendDialog = (props) => {
  const {
    open = false,
    onClose,
    onExited,
    onVariantsChange,
    variant,
    afterSubmit,
    uploaderApi,
    typeDocument = ['text/xml', 'application/pdf'],
    titleDocument = 'Agregar comprobantes',
    hoverDocument = 'Seleccione hasta 20 archivos',
    maxFileOne = false,
    handleDocuments,
    nameDocument = '',
    documentApi = false,
    maxFiles = 20,
    ...other
  } = props;
  const mode = 'add';
  const { showNotify, showError, showWaring } = useApp();
  const [saving, setSaving] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: variant?.image || '',
      files: [],
      submit: null
    },
    validationSchema: Yup.object().shape({
      image: Yup.string(),
      files: Yup.array().min(1, 'Seleccione almenos un archivo')
    }),
    onSubmit: async (values, helpers) => {
      try {
        setSaving(true);
        showNotify(mode === 'update' ? 'Archivo actualizado' : 'Archivo agregado');
        onVariantsChange?.({ ...variant, ...values }, mode);
        const response = await uploaderApi(values, (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
        });

        if (response.success) {
          if (documentApi) {
            await handleDocuments(formik.values, {
              name: nameDocument,
              code: response.data.code,
              path: response.data.path,
              fileName: response.data.file_name
            });
          } else {
            const count = response?.data?.count;
            if (count) {
              showNotify(`Procesados ${count}`);
            } else {
              showWaring(response.message);
            }
          }

          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          afterSubmit(response);
          onClose?.();
        } else {
          showError(response.message);
        }
      } catch (err) {
        showNotify(`Error al importar  ${err}`, 'error');
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
      setSaving(false);
      setUploadProgress(0);
    }
  });
  const addFiles = (files) => {
    const filteredFiles = files.filter((file) => {
      const allImages = typeDocument.find((type) => type === 'image/*');
      if (allImages) {
        return file.type.includes('image');
      }
      return typeDocument.includes(file.type);
    });

    formik.setFieldValue('files', [
      ...formik.values.files.filter(
        (item) => !filteredFiles.find((file) => file.name === item.name)
      ),
      ...filteredFiles
    ]);
  };

  const removeFiles = (fileValue) => {
    const remove = formik.values.files.filter((file) => file !== fileValue);
    formik.setFieldValue('files', remove);
  };

  // remove all files
  const removeAllFiles = () => {
    formik.setFieldValue('files', []);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 600
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <DialogTitle>{mode === 'update' ? 'Update Variant' : titleDocument}</DialogTitle>
      <DialogContent>
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            {uploadProgress > 0 && (
              <Box sx={{ width: '100%', mb: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Box>
            )}
          </Grid2>
          <Grid2 size={12}>
            {maxFiles > 1 && !maxFileOne ? (
              <Typography color="textPrimary" sx={{ mb: 1.25 }} variant="body2">
                Puede subir hasta {maxFiles} archivos
              </Typography>
            ) : (
              <Typography color="textSecondary" sx={{ mb: 1.25 }} variant="body2">
                Solo un archivo
              </Typography>
            )}
            <Typography color="textPrimary" variant="body2">
              Tipos de archivos permitidos
            </Typography>
            {typeDocument?.map((type) => (
              <Chip key={type} label={mimeName(type)} />
            ))}
          </Grid2>
          <Grid2 size={12}>
            {formik.values.files.length > 0 && (
              <List disablePadding>
                {formik.values.files.map((item) => (
                  <ListItem
                    key={item.name}
                    disableGutters
                    divider
                    sx={{
                      px: 3,
                      py: 1.5
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography color="textPrimary" variant="subtitle2">
                          {item.name}
                        </Typography>
                      }
                      secondary={
                        <Box
                          sx={{
                            flex: 1,
                            mt: 0.5
                          }}
                        >
                          <Typography color="textSecondary" variant="body2">
                            {mimeName(item.type)}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            {numeral(item.size / 1024).format('0.0')} KB
                          </Typography>
                          <IconButton
                            color="error"
                            onClick={() => removeFiles(item)}
                            sx={{
                              right: 0,
                              position: 'absolute',
                              top: 25
                            }}
                          >
                            <TrashIcon />
                          </IconButton>
                        </Box>
                      }
                      sx={{ my: 0 }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid2>
          <Grid2 size={12}>
            <Box sx={{ mt: 2, direction: 'row', gap: 3, display: 'flex', flex: 1 }}>
              <ImageDropzone
                onDrop={(files) => addFiles(files)}
                sx={{
                  minHeight: 126,
                  maxWidth: 326
                }}
                hoverDocument={hoverDocument}
              />
              <GoalButton
                onClick={removeAllFiles}
                disabled={!formik.values.files.length}
                label="Limpiar"
                variant={'text'}
                startIcon={<TrashIcon />}
              />
            </Box>
          </Grid2>
          {formik.errors.submit && (
            <Grid2 size={12}>
              <FormHelperText error>{formik.errors.submit}</FormHelperText>
            </Grid2>
          )}
        </Grid2>
      </DialogContent>
      <DialogActions>
        {maxFileOne ? (
          <GoalButton
            onClick={() => handleDocuments(formik.values, { name: nameDocument })}
            disabled={formik.values.files.length > 1 || formik.values.files.length === 0}
            label="Confirmar"
            startIcon={<CheckIcon />}
          />
        ) : (
          <GoalButton
            onClick={formik.handleSubmit}
            loading={saving}
            disabled={formik.values.files.length > 1 && documentApi}
            label="Confirmar"
            startIcon={<CheckIcon />}
          />
        )}
        <GoalButton
          color="primary"
          onClick={onClose}
          variant="text"
          label="Cancelar"
          startIcon={<XIcon />}
        />
      </DialogActions>
    </Dialog>
  );
};

DocumentAppendDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  afterSubmit: PropTypes.func,
  onVariantsChange: PropTypes.func,
  uploaderApi: PropTypes.func,
  variant: PropTypes.object,
  typeDocument: PropTypes.array,
  titleDocument: PropTypes.string,
  hoverDocument: PropTypes.string,
  maxFileOne: PropTypes.bool,
  handleDocuments: PropTypes.func,
  nameDocument: PropTypes.string,
  documentApi: PropTypes.bool,
  maxFiles: PropTypes.number
};
