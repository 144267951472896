// import PropTypes from 'prop-types';

import AutoModeIcon from '@mui/icons-material/AutoMode';

import { GoalMenu } from '../../common/goal-menu';

import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { ConfirmationMenuItem } from '../../../components/common/confirmation-menu-item';
import { refreshAllAuthorizations } from '../../../api/goal/core/goal-sbo';

export const SboMenu = () => {
  const { currentCompany, showNotify, showError } = useApp();

  const handleAddUsersToAllAutorizationTemplates = async () => {
    const response = await refreshAllAuthorizations({
      companyId: currentCompany
    });
    if (!response.success) {
      showError(response.message || 'Error al agregar usuarios a las plantillas de autorización');
    }
    showNotify(response.message || 'Usuarios agregados a las plantillas de autorización');
  };

  return (
    <GoalMenu label="Acciones">
      <ConfirmationMenuItem
        startIcon={<AutoModeIcon />}
        permission={GadminActions.GCC_GPROC_MNGMT}
        helpText={'Refrescar Usuarios'}
        onConfirm={async () => {
          await handleAddUsersToAllAutorizationTemplates({
            companyId: currentCompany
          });
        }}
        label={'Refrescar Usuarios'}
        confirmationMessage={
          'Está seguro que desea refrescar los usuarios de las plantillas de autorización'
        }
      />
    </GoalMenu>
  );
};

SboMenu.propTypes = {};
