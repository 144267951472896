import { goalCustomeDeleveryPlanningApi } from '../../../../api/goal/planning/goal-customer-delivery-planning';
import { ColumnDataTypesEnum } from '../../../gm/grid/grid-definitions';
import { GMGridContainer } from '../../../gm/grid/gm-grid-context';
import { useDeliveryPlanning } from '../delivery-planing-context';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: ColumnDataTypesEnum.ID,
    link: {
      to: (item) => `/sales/orders/${item.id}`,
      tooltip: 'Ver detalle del pedido'
    }
  },
  {
    field: 'title',
    headerName: 'Nombre',
    type: ColumnDataTypesEnum.TEXT,
    width: 300
  },
  {
    field: 'planned_date',
    headerName: 'Fecha',
    type: ColumnDataTypesEnum.DATE
  },
  {
    field: 'load',
    headerName: 'Peso',
    type: ColumnDataTypesEnum.TOTAL,
    width: 300
  },
  {
    field: 'load_2',
    headerName: 'Volumen',
    type: ColumnDataTypesEnum.TOTAL,
    width: 300
  },
  {
    field: 'load_3',
    headerName: 'Prioridad',
    type: ColumnDataTypesEnum.TOTAL,
    width: 300
  },
  {
    field: 'contact_name',
    headerName: 'Vendedor',
    type: ColumnDataTypesEnum.TEXT,
    width: 300
  },
  {
    field: 'codsede',
    headerName: 'Sede',
    type: ColumnDataTypesEnum.TEXT,
    width: 300
  },
  {
    field: 'groupName',
    headerName: 'Canal',
    type: ColumnDataTypesEnum.TEXT,
    width: 300
  },
  {
    field: 'address',
    headerName: 'Dirección',
    type: ColumnDataTypesEnum.TEXT,
    width: 300
  }
];
export const DeliveryPlaningNotAssignedOrders = () => {
  const { pendingDeliveryParams } = useDeliveryPlanning();

  return (
    <GMGridContainer
      columns={columns}
      sourceApi={goalCustomeDeleveryPlanningApi.getNotAssignedOrders}
      apiParameters={pendingDeliveryParams}
      loadingMessage="Cargando pedidos pendientes"
    />
  );
};
