import PropTypes from 'prop-types';
import PolicyTemplateHeaderView from '../../../components/rindegasto/policy-templates/policy-template-header-view';

const GeneralInfoTab = ({ data, onRefresh }) => {
  return <PolicyTemplateHeaderView data={data} onRefresh={onRefresh} />;
};

GeneralInfoTab.propTypes = {
  data: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired
};

export default GeneralInfoTab;
