const UserPreferencesEnum = Object.freeze({
  THEME: 'theme',
  LANGUAGE: 'language',
  NOTIFICATIONS: 'notifications',
  PRIVACY: 'privacy',
  SHOW_ANIMATION: 'show_animation',
  HIDE_EMOJI: 'hide_emoji',
  SHOW_HELP: 'show_help'
});

export default UserPreferencesEnum;
