import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Container, Typography } from '@mui/material';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { useSplit } from '../../../hooks/use-split';
import { BusinessPartnesAddressesTable } from '../component/business-partners-addresses-table';
import { goalUbigeosApi } from '../../../api/goal/location/ubigeos';
import { useApp } from '../../../hooks/use-app';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { RenderIf } from '../../render-if';
import LoadingContent from '../../common/loading-content';
import { BusinessPartnerAddressesDetail } from '../component/business-partners-addresses-detail';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';
import { Plus as PlusIcon } from '../../../icons/plus';
import { CustomerModal } from '../../delivery/monitoring/customer-modal';
import { BusinessPartnerAddressesForm } from '../component/business-partners-addresses-form';
import { GMGrid } from '../../gm/grid/gm-grid';
import { useGMDataSet } from '../../gm/dataset/use-gm-dataset';
const columns = [
  {
    field: 'Address',
    headerName: 'Código'
  },
  {
    field: 'Street',
    headerName: 'Dirección'
  },
  {
    field: 'U_BKP_UBIGEO',
    headerName: 'Ubigeo'
  },
  {
    field: 'City',
    headerName: 'Provincia'
  },
  {
    field: 'County',
    headerName: 'Departmento'
  }
];
const BusinessPartnerAddresses = () => {
  const { cardcode: cardcodeQuery } = useParams();
  const mounted = useMounted();
  const [editMode, setEditMode] = useState(false);
  const [widhtFormState, setWidhtFormState] = useState('md');
  const [openCreate, onCloseCreate] = useState(false);
  const [itemData, setItemEdit] = useState(null);

  const { currentCompany } = useApp();
  const { code: cardcode } = useSplit(cardcodeQuery);

  // const [documentState, handleRefresh] = useData({
  //   sourceApi: goalBaseBusinessPartnersApi.getAddresses,
  //   apiParameter: { companyId: currentCompany, id: cardcode },
  //   loadingMessage: 'Cargando informacion de direcciones',
  //   mounted
  // });
  const { handleRefresh, ...documentState } = useGMDataSet({
    sourceApi: goalBaseBusinessPartnersApi.getAddresses,
    apiParameters: { companyId: currentCompany, id: cardcode },
    loadingMessage: 'Cargando informacion de direcciones',
    mounted
  });

  const [ubigeoState] = useData({
    sourceApi: goalUbigeosApi.getUbigeos,
    loadingMessage: 'Cargando informacion de ubigeos',
    mounted,
    apiParameter: currentCompany
  });

  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Container
        maxWidth="zl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Direcciones
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={() => onCloseCreate(!openCreate)}
                  variant="contained"
                  sx={{ marginRight: 1 }}
                />
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={() => handleRefresh({ companyId: currentCompany, id: cardcode })}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
        <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
          <GMGrid {...documentState} columns={columns} />

          {/* {!documentState.isLoading && documentState.success ? (
            <RenderIf
              condition={editMode}
              no={
                <BusinessPartnesAddressesTable
                  onCloseEdit={setEditMode}
                  onRefresh={() => handleRefresh({ companyId: currentCompany, id: cardcode })}
                  documents={documentState.data}
                  ubigeoState={ubigeoState.isLoading ? [] : ubigeoState?.data}
                  setWidhtFormState={setWidhtFormState}
                  widhtFormState={widhtFormState}
                  setItemEdit={setItemEdit}
                />
              }
            >
              <BusinessPartnerAddressesDetail
                ubigeoState={ubigeoState.isLoading ? [] : ubigeoState?.data}
                setWidhtFormState={setWidhtFormState}
                widhtFormState={widhtFormState}
                itemData={itemData}
                setItemEditDetail={setEditMode}
              />
            </RenderIf>
          ) : (
            <LoadingContent />
          )} */}
        </Card>
      </Container>
      <CustomerModal
        open={openCreate}
        onClose={onCloseCreate}
        permission={GadminActions.G2_CONTESTS_UP}
        widhtForm={widhtFormState}
      >
        <BusinessPartnerAddressesForm
          onClose={onCloseCreate}
          handleRefresh={() => handleRefresh({ companyId: currentCompany, id: cardcode })}
          title="Direcciones"
          ubigeoState={ubigeoState.isLoading ? [] : ubigeoState?.data}
          setWidhtFormState={setWidhtFormState}
          widhtFormState={widhtFormState}
        />
      </CustomerModal>
    </Box>
  );
};

export { BusinessPartnerAddresses };
