import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid2, Modal, Box, Card, CardContent } from '@mui/material';
import ChangeIcon from '@mui/icons-material/ChangeCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useApp } from '../../hooks/use-app';
import { CommonImageSelector } from './common-image-selector';
import { GoalIconButton } from '../goal-icon-button';
import { AddButton } from '../common/buttons/add-button';
import { useAuth } from '../../hooks/use-auth';
import { showNotify } from '../../hooks/toasty';
import { DeleteButton } from '../common/buttons/delete-button';
import RefreshButton from './buttons/refresh-button';

const GoalImageGallery = ({
  canEdit = false,
  ownerEntity,
  imageHandlerApi,
  afterUpload: onRefresh,
  imageCodes,
  multiple = false,
  permission
}) => {
  const { getImageUrl, noImageUrl, currentCompany, showError } = useApp();
  const [openImage, setOpenImage] = useState(null);
  const [selectingImage, setSelectingImage] = useState(false);
  const [updatable, setUpdatable] = useState(false);
  const [removable, setRemovable] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const { hasPermission } = useAuth();

  const handleCancel = useCallback(() => {
    setSelectingImage(false);
  }, []);

  const handleOpenImage = useCallback((imageUrl) => {
    setOpenImage(imageUrl);
  }, []);

  const handleCloseImage = useCallback(() => {
    setOpenImage(null);
  }, []);

  useEffect(() => {
    const isAllowed = !permission || !!(permission && hasPermission(permission));
    setUpdatable(!!(isAllowed && canEdit && imageHandlerApi && ownerEntity));
    setSelectingImage(false);
  }, [canEdit, ownerEntity]);

  useEffect(() => {
    const splitedImages = Array.isArray(imageCodes) ? imageCodes : imageCodes?.split(/[\s,]+/);
    let newList = [];
    if (splitedImages) {
      const list = splitedImages.map((image_code, index) => ({
        id: index,
        width: 1,
        height: 1,
        title: 'Goal Image',
        image_code
      }));
      newList = [...list];
    } else {
      newList = [];
    }
    setRemovable(multiple && newList.length > 1);
    // if multiple  has marked add plus code into image list
    if (updatable && multiple) {
      newList.push({ image_code: '+', id: newList.length });
    }
    setImageList(newList);
  }, [imageCodes, canEdit, multiple, updatable]);

  const handleImageSelect = useCallback((image) => {
    setSelectedImage(image);
    setSelectingImage(true);
  }, []);

  const handleRemoveImage = useCallback(
    (image) => {
      const imagesToSave = imageList.reduce((acc, img) => {
        if (img.image_code !== '+' && img.image_code !== image.image_code) {
          acc.push(img.image_code);
        }
        return acc;
      }, []);
      imageHandlerApi({
        ...ownerEntity,
        companyId: currentCompany,
        image_code: imagesToSave,
        company_id: currentCompany
      }).then((result) => {
        if (result.success) {
          showNotify('Imagen eliminada correctamente');
          onRefresh?.(result);
        } else {
          showError(result.message);
        }
      });
    },
    [ownerEntity, currentCompany, imageList]
  );

  const handleImageError = useCallback(
    (e) => {
      if (e.target.src.includes(noImageUrl)) {
        e.target.src = '/static/images/pic-share-logo.png';
      } else {
        e.target.onerror = null;
        e.target.src = noImageUrl;
      }
    },
    [noImageUrl]
  );

  const handleAfterUpload = useCallback(
    async (image) => {
      const currentImageCode = selectedImage?.image_code || '';
      const imagesToSave = imageList.reduce((acc, img) => {
        if (img.image_code !== '+') {
          acc.push(img.image_code === currentImageCode ? image.code : img.image_code);
        }
        return acc;
      }, []);

      if (!currentImageCode) {
        imagesToSave.push(image.code);
      }
      const result = await imageHandlerApi({
        ...ownerEntity,
        companyId: currentCompany,
        image_code: imagesToSave,
        company_id: currentCompany
      });
      if (result.success) {
        showNotify('Imagen guardada correctamente');
        setSelectingImage(false);
        onRefresh?.(result);
      } else {
        showError(result.message);
      }
    },
    [ownerEntity, currentCompany, imageList, selectedImage]
  );

  const imageGrid = (
    <Card>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          minHeight: 200
        }}
      >
        <Grid2 container spacing={3}>
          {imageList.map((image) => {
            return (
              <Grid2
                key={image.id}
                size={{
                  xs: 6,
                  sm: 4
                }}
                sx={{
                  position: 'relative'
                }}
              >
                {image.image_code === '+' ? (
                  <AddButton
                    onClick={() => handleImageSelect(null)}
                    sx={{ width: '100%', height: '100%' }}
                    tooltip="Agregar imagen"
                  />
                ) : (
                  <>
                    <img
                      key={image.image_code}
                      src={getImageUrl({ imageCode: image.image_code, thumbMail: true })}
                      alt={image.title || 'Image'}
                      onClick={() => handleOpenImage(getImageUrl({ imageCode: image.image_code }))}
                      onError={handleImageError}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    {updatable && (
                      <RefreshButton
                        onClick={() => handleImageSelect(image)}
                        tooltip="Cambiar imagen"
                        startIcon={<ChangeIcon color="primary" />}
                        sx={{ position: 'absolute', top: 1, right: 1 }}
                      />
                    )}
                    {removable && (
                      <DeleteButton
                        onConfirm={() => handleRemoveImage(image)}
                        sx={{ position: 'absolute', bottom: 1, right: 1 }}
                      />
                    )}
                  </>
                )}
              </Grid2>
            );
          })}
        </Grid2>
      </CardContent>
    </Card>
  );

  if (updatable && (selectingImage || !imageList || imageList.length === 0)) {
    return (
      <CommonImageSelector
        afterUpload={handleAfterUpload}
        activate={selectingImage}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <>
      {imageGrid}
      <Modal open={openImage !== null} onClose={handleCloseImage}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <GoalIconButton
            onClick={handleCloseImage}
            sx={{ position: 'absolute', top: 10, right: 10 }}
            icon={<CloseIcon />}
            tolltip="Cerrar"
          />
          <img
            src={openImage}
            alt="Full Image"
            style={{ width: '100%', maxHeight: '90vh', objectFit: 'contain' }}
            onError={handleImageError}
          />
        </Box>
      </Modal>
    </>
  );
};

GoalImageGallery.propTypes = {
  // images: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     src: PropTypes.string,
  //     alt: PropTypes.string,
  //     image_code: PropTypes.string.isRequired,
  //     title: PropTypes.string,
  //     width: PropTypes.number,
  //     height: PropTypes.number,
  //     id: PropTypes.number
  //   })
  // ),
  sizeImage: PropTypes.string,
  canEdit: PropTypes.bool,
  ownerEntity: PropTypes.object,
  imageHandlerApi: PropTypes.func,
  afterUpload: PropTypes.func,
  imageCodes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multiple: PropTypes.bool,
  permission: PropTypes.string
};

export default GoalImageGallery;
