import PropTypes from 'prop-types';
import { mediaType } from '../../../api/purchase-definitions';
import { GoalRecordLink } from '../../goal-record-link';

import DownloadIcon from '@mui/icons-material/Download';

export const PDFLink = (props) => {
  const { fileName, fileType, content, loading = false } = props;
  if (loading || !content || fileType !== mediaType.PDF) return null;
  return (
    <GoalRecordLink
      to={`data:${fileType};base64,${content}`}
      download={fileName}
      variant="text"
      alt="Download"
      tooltip={'Descargar el archivo PDF'}
      startIcon={<DownloadIcon fontSize="small" color="primary" sx={{ fontSize: 12, mr: 1 }} />}
    >
      {fileName}
    </GoalRecordLink>
  );
};

PDFLink.propTypes = {
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  content: PropTypes.string,
  loading: PropTypes.bool
};
