import PropTypes from 'prop-types';
import { MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { LoadingDialog } from '../loading-dialog';
import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { useApp } from '../../hooks/use-app';
import { GoalListIcon } from '../icon/goal-list-icon';
import { useGoalUserMenu } from '../../contexts/menu/goal-menu-context';
import { useAuth } from '../../hooks/use-auth';

const GoalMenuItem = ({
  startIcon,
  onClick,
  label,
  disabled = false,
  children,
  helpText = '',
  showLoading = true,
  to,
  checked = false,
  tooltip,
  autoClose = true,
  permission
}) => {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const { appUrlBase, showHelp } = useApp();
  const { handleClose } = useGoalUserMenu();
  const { hasPermission } = useAuth();

  const isFullPath = to && to.startsWith('http');

  const linkTo = isFullPath ? to : `${appUrlBase}${to}`;

  const handleClicked = async () => {
    if (loading) return;
    setLoading(true);
    setIsDisabled(true);
    await onClick();
    setLoading(false);
    setIsDisabled(disabled);
    if (autoClose) handleClose();
  };

  if (permission && !hasPermission(permission)) return null;

  const menu = to ? (
    <MenuItem disabled={isDisabled} component={RouterLink} to={linkTo}>
      <>
        <GoalListIcon icon={startIcon} />
        {children || label || '-'}
        {checked && <CheckIcon />}
      </>
    </MenuItem>
  ) : (
    <MenuItem disabled={isDisabled} onClick={handleClicked}>
      <>
        <GoalListIcon icon={startIcon} />
        {loading && 'Loading...'}
        {!loading && (children || label || '-')}
        {checked && <CheckIcon />}
        {showLoading && (
          <LoadingDialog
            message={'Procesando....'}
            open={loading}
            title={tooltip || label || 'Cargando...'}
          />
        )}
      </>
    </MenuItem>
  );

  return <>{showHelp ? <Tooltip title={helpText}>{menu}</Tooltip> : menu}</>;
};

GoalMenuItem.propTypes = {
  startIcon: PropTypes.element,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  component: PropTypes.elementType,
  to: PropTypes.string,
  iconOnly: PropTypes.bool,
  showText: PropTypes.bool,
  helpText: PropTypes.string,
  showLoading: PropTypes.bool,
  checked: PropTypes.bool,
  tooltip: PropTypes.string,
  autoClose: PropTypes.bool,
  permission: PropTypes.string
};

export default GoalMenuItem;
export { GoalMenuItem };
