import { dataLoader } from '../../data-loader';

export const listPolicies = async ({ companyId }) => {
  return await dataLoader.goalGet({
    action: '/integrations/rindegasto/policies',
    params: { companyId }
  });
};

export const getPolicy = async ({ id, companyId }) => {
  return await dataLoader.goalGet({
    action: `/integrations/rindegasto/policies/${id}`,
    params: { companyId }
  });
};

export const syncPolicies = async ({ companyId, id = null }) => {
  return await dataLoader.goalGet({
    action: '/integrations/rindegasto/policies/sync',
    params: { companyId, id }
  });
};
