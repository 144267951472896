import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardHeader, Grid2 } from '@mui/material';
import { CommonDatePicker } from '../../common/common-date-picker';
import { AutocompleteField } from '../../autocomplete-field';
import { useDeliveryPlanning } from './delivery-planing-context';
import { useState } from 'react';
import { ApplyButton } from '../../common/buttons/apply-button';
import { useApp } from '../../../hooks/use-app';

export const DeliveryPlaningFilter = ({ filters, onClosed, ...rest }) => {
  const { profiles, setFilters } = useDeliveryPlanning();
  const [planningDate, setPlanningDate] = useState(filters.planningDate);
  const [currentProfile, setCurrentProfile] = useState(filters.currentProfile);
  const [dateFrom, setDateFrom] = useState(filters.planningOptions.dateFrom);
  const [dateTo, setDateTo] = useState(filters.planningOptions.dateTo);
  const { showError } = useApp();
  const handleAccept = () => {
    // console.log('planningDate', planningDate);
    // console.log('dateFrom', dateFrom);
    // console.log(' dateTo', dateTo);
    // validate that date is not in the future

    // if (dateTo.isAfter(planningDate)) {
    //   showError('La fecha de los pedidos no puede ser mayor a la fecha de planificación');
    //   return;
    // }

    setFilters({
      planningDate,
      currentProfile,
      planningOptions: {
        dateFrom,
        dateTo
      }
    });
    onClosed?.();
  };
  const { minDate, maxDate } = filters.planningOptions;

  return (
    <Card>
      <CardHeader title="Filtros" action={<ApplyButton onClick={handleAccept} />} />
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2
            size={{
              xs: 12
            }}
          >
            <CommonDatePicker
              value={planningDate}
              onAccept={setPlanningDate}
              name="planningDate"
              label="Fecha de planificación"
              minValue={minDate}
              maxValue={maxDate}
            />
          </Grid2>
          <Grid2
            size={{
              xs: 12
            }}
          >
            <AutocompleteField
              label="Zona"
              name="currentProfile"
              placeholder="seleccione Zona"
              options={profiles.length ? profiles : [{ name: 'Cargando', id: 'Cargando' }]}
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option) => option.name || ''}
              onChange={(e, value) => {
                setCurrentProfile(value);
              }}
              value={currentProfile}
              sx={{ marginBottom: '1em', minWidth: '200px' }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              <CommonDatePicker
                value={dateFrom}
                onAccept={setDateFrom}
                name="dateFrom"
                label="Pedidos desde"
                minValue={minDate}
                maxValue={dateTo}
              />
              <Box sx={{ marginLeft: 2 }} />
              <CommonDatePicker
                value={dateTo}
                onAccept={setDateTo}
                name="dateTo"
                label="Pedidos hasta"
                minValue={dateFrom}
                maxValue={maxDate}
              />
            </Box>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

DeliveryPlaningFilter.propTypes = {
  filters: PropTypes.object,
  onClosed: PropTypes.func
};
