import PropTypes from 'prop-types';

const baseSx = {
  width: '100%',
  height: '100%',
  border: 'none',
  minHeight: '80vh'
};
export const GrafanaPanel = ({
  src = 'https://stats.dsroma.info',
  query,
  panelId,
  title,
  sx = {}
}) => {
  const srcUrl = `${src}/d-solo/${panelId}?${query}`;
  return (
    <iframe
      title={title}
      //   src={srcUrl}
      src={
        'https://stats.dsroma.info/d/ravmV6rZk/windows-servers-status-summary?orgId=1&refresh=10s&from=1743406079707&to=1743427679707&theme=light&kiosk&option-dashboardtitle=hide'
      }
      allowFullScreen
      style={{
        ...baseSx,
        ...sx
      }}
    />
  );
};

GrafanaPanel.propTypes = {
  src: PropTypes.string,
  accessToken: PropTypes.string,
  panelId: PropTypes.string.isRequired,
  title: PropTypes.string,
  sx: PropTypes.object,
  query: PropTypes.string
};

/*
<iframe src="https://stats.dsroma.info/d-solo/G6Le1GsVz/pendientes?orgId=1&from=1743179962857&to=1743201562857&panelId=2" 
width="450" height="200" frameborder="0"></iframe>
*/
