import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// import moment from 'moment';
import {
  Avatar,
  Box,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { Pagination } from '../../pagination';
import { ResourceError } from '../../resource-error';
import { ResourceUnavailable } from '../../resource-unavailable';
import Scrollbar from '../../Scrollbar';
// import ColorByRange from './customer-range';
import { useApp } from '../../../hooks/use-app';
import { capitalizeParagraph } from '../../../hooks/use-split';
import { appDefinitions } from '../../../api/app-definitions';
import { fCurrency } from '../../../utils/formatNumber';
import ProgressIndicator from '../../ProgressIndicator';
import { mediaConfig } from '../../../config';

const columns = [
  {
    id: 'cardname',
    disablePadding: true,
    label: 'VENDEDORES'
  },
  {
    id: 'codzon',
    label: 'ZONA'
  },
  {
    id: 'totaldoc',
    label: '#DOCS'
  },
  {
    id: 'montototal',
    label: 'TOTAL'
  },
  {
    id: 'avancesoles',
    label: 'S/ATENDIDOS'
  },
  {
    id: 'avance',
    label: 'AVANCE'
  },
  {
    id: 'entregados',
    label: '#ENT'
  },
  {
    id: 'soles_entregados',
    label: 'SOLES'
  },
  {
    id: 'anulados',
    label: '#ANUL'
  },
  {
    id: 'soles_anulados',
    label: 'SOLES'
  },
  {
    id: 'parciales',
    label: '#PAR'
  },
  {
    id: 'soles_parciales',
    label: 'SOLES'
  },
  {
    id: 'pendientes',
    label: '#PEND'
  },
  {
    id: 'soles_pendientes',
    label: 'SOLES'
  },
  {
    id: 'sname',
    label: 'ESTADO'
  }
];
/**
 * docTotal, registeredValue, programedValue, vatSum
 * Status
 * D Borrador
 * L Autorizado
 * W EN Reparto
 * F Facturando
 * Q Liquidado
 * C Cerrado
 */

export const PresaleMonitoringTable = (props) => {
  const {
    error,
    documents = [],
    documentsCount = 0,
    isLoading,
    onPageChange,
    onSortChange,
    page = 1,
    selectedDocuments = [],
    sort = 'desc',
    sortBy = 'issueDate',
    setLocationState
  } = props;

  const { appUrlBase } = useApp();
  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);
  const detailRoute = `${appUrlBase}/delivery/monitoring/`;

  const urlIcon = `${mediaConfig.assetUrl}IconMap/icon-blue-person.png`;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        maxHeight: 1000,
        overflow: 'auto'
      }}
    >
      <Scrollbar>
        <Table sx={{ maxWidth: 400 }}>
          {false && (
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sort : 'asc'}
                      disabled={isLoading}
                      onClick={(event) => onSortChange(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {documents.map((document) => (
              <TableRow
                hover
                key={document.id}
                selected={
                  !!selectedDocuments.find(
                    (selectedCustomer) => selectedCustomer === document.docEntry
                  )
                }
              >
                {false && (
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`${detailRoute}${document.id}`}
                      underline="none"
                      variant="subtitle2"
                      target="_blank"
                    >
                      #{document.id}
                    </Link>
                  </TableCell>
                )}
                <TableCell>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold' }} color="textSecondary" variant="body2">
                        Zona: {document.zonaCode || '-'}
                      </Typography>
                      <Typography sx={{ fontWeight: 'bold' }} color="textSecondary" variant="body2">
                        Ticket Promedio: {fCurrency(document.ordersAverage) || '0'}
                      </Typography>
                    </Box>
                    <ProgressIndicator
                      labelLeft="Visitas"
                      labelRight="Cartera"
                      valueLeft={document.visitedCount}
                      valueRight={document.customers}
                      value={document.visitedRate * 1}
                      color={document.visitedRate > 0.9 ? 'secondary' : 'primary'}
                    />
                    <ProgressIndicator
                      labelLeft="Visitas efectivas"
                      labelRight="Cartera"
                      valueLeft={document.ordersCustomerCount}
                      valueRight={document.customers}
                      value={document.visitedEffectiveRate * 1}
                      color={document.visitedEffectiveRate > 0.9 ? 'secondary' : 'primary'}
                    />{' '}
                    <ProgressIndicator
                      labelLeft="Venta"
                      labelRight="Cuota"
                      valueLeft={fCurrency(document.ordersAmount ?? 0)}
                      valueRight={fCurrency(document.quota ?? 0)}
                      value={document.progressRate * 1}
                      color={document.progressRate > 0.9 ? 'secondary' : 'primary'}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography color="textSecondary" variant="h5">
                        {capitalizeParagraph(document.name)}
                      </Typography>
                      {document.latitud && document.longitud && (
                        <Avatar
                          alt="person"
                          src={urlIcon}
                          style={{ height: '30px', width: '30px', cursor: 'pointer' }}
                          onClick={() => setLocationState(document.longitud, document.latitud)}
                        />
                      )}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      {documentsCount > appDefinitions.pageSize && (
        <Pagination
          disabled={isLoading}
          onPageChange={onPageChange}
          page={page}
          rowsCount={documentsCount}
        />
      )}
    </Box>
  );
};

PresaleMonitoringTable.propTypes = {
  documents: Proptypes.array,
  documentsCount: Proptypes.number,
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  selectedDocuments: Proptypes.array,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  setLocationState: Proptypes.func
};
