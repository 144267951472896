import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import { useMounted } from '../../../hooks/use-mounted';
import { useImperativeHandle, forwardRef } from 'react';
import { GMForm } from './gm-form';
import { useFormik } from 'formik';
import { useFormSaver } from '../../../hooks/use-form-saver';

const GMFormContext = createContext();

const useGMForm = () => useContext(GMFormContext);

const GMFormContainer = forwardRef(
  (
    {
      updateApi,
      createApi,
      onAfterSave,
      children,
      initialData,
      isNew = false,
      keyField = 'id',
      hashField = null,
      includeNewValues = true,
      mandatoryFields = ['company_id'],
      validationSchema,
      getInitialData,
      transformBeforeSubmit,
      actionsOnHeader = false,
      onSubmit,
      subheader,
      ...rest
    },
    ref
  ) => {
    const mounted = useMounted();
    useImperativeHandle(ref, () => ({
      handlereset: () => {
        console.log('reset');
      }
    }));

    const [handleUpdate, handleAdd] = useFormSaver({
      originalData: initialData,
      apiAddHandler: createApi,
      apiUpdateHandler: updateApi,
      id: keyField,
      hashField,
      includeNewValues,
      handleAfterSave: (saved) => {
        formik.resetForm();
        if (isNew) {
          const { data } = saved;
        }
        onAfterSave?.(saved);
      },
      mandatoryFields
    });

    const handleSubmit = async (values, helpers) => {
      const result = await onSubmit(values);
      if (result.success) {
        helpers.setSubmitting(false);
        helpers.resetForm();
        onAfterSave?.(result);
      } else {
        helpers.setErrors(result.errors);
        helpers.setSubmitting(false);
      }
    };

    const formik = useFormik({
      initialValues: getInitialData?.(initialData) || initialData,
      validationSchema,
      onSubmit: async (values, helpers) => {
        const newValues = transformBeforeSubmit?.(values) || values;
        if (onSubmit) {
          return handleSubmit(newValues, helpers);
        }
        return isNew
          ? await handleAdd({ values: newValues, helpers })
          : await handleUpdate({ values: newValues, helpers });
      }
    });

    return (
      <GMFormContext.Provider
        value={{ updateApi, createApi, onAfterSave, children, initialData, isNew, formik, ...rest }}
      >
        <GMForm actionsOnHeader={actionsOnHeader} subheader={subheader}>
          {children}
        </GMForm>
      </GMFormContext.Provider>
    );
  }
);

GMFormContainer.propTypes = {
  createApi: PropTypes.func,
  updateApi: PropTypes.func,
  onAfterSave: PropTypes.func,
  children: PropTypes.node,
  initialData: PropTypes.object,
  isNew: PropTypes.bool,
  keyField: PropTypes.string,
  hashField: PropTypes.string,
  includeNewValues: PropTypes.bool,
  mandatoryFields: PropTypes.array,
  validationSchema: PropTypes.object,
  getInitialData: PropTypes.func,
  transformBeforeSubmit: PropTypes.func,
  actionsOnHeader: PropTypes.bool,
  onSubmit: PropTypes.func,
  subheader: PropTypes.string
};

export { GMFormContainer, useGMForm };
