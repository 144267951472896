import { Box, Card, CardContent, CardHeader, Container } from '@mui/material';
import { useApp } from '../../hooks/use-app';
import { Helmet } from 'react-helmet-async';
import { RefreshButton } from '../../components/common/buttons/refresh-button';
import { SyncButton } from '../../components/common/buttons/sync-button';
import { ColumnDataTypesEnum } from '../../components/gm/grid/grid-definitions';
import {
  listExpenseUnits,
  syncExpenseUnit
} from '../../api/goal/rindegastos/rindegasto-expense-unit-api';
import { RindegastoExpenseUnitsBulkActionMenu } from '../../components/rindegasto/expense-units/expense-units-bulk-action-menu';
import { useRef } from 'react';
import { GMGridContainer } from '../../components/gm/grid/gm-grid-context';
/* 
{
    "id": 19,
    "company_id": 8,
    "name": "APO-727 SHAC",
    "code": "APO-727",
    "subject_type": "App\\Models\\Fleet\\FleetVehicle",
    "subject_id": 2964,
    "remote_id": null,
    "properties": null,
    "status": "A",
    "branch_id": 2,
    "branch_code": "IC",
    "territory_id": null,
    "territory_code": "IC",
    "sales_team_id": null,
    "sales_team_code": null,
    "sales_team_group_id": null,
    "sales_team_group_code": null,
    "departament_id": null,
    "departament_code": null,
    "portfolio_id": null,
    "portfolio_code": null, 
    "template_id": null,
    }
*/
const componentTitle = 'Unidades de Gasto';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: ColumnDataTypesEnum.ID,
    link: {
      to: (item) => `/rindegasto/expense-units/${item.id}`,
      tooltip: 'Ver detalle de la unidad de gasto'
    }
  },
  {
    field: 'name',
    headerName: 'Nombre',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'code',
    headerName: 'Código',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'subject_type',
    headerName: 'Tipo de Objeto',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'subject_id',
    headerName: 'ID de Objeto',
    type: ColumnDataTypesEnum.ID
  },
  {
    field: 'status',
    headerName: 'Estado',
    type: ColumnDataTypesEnum.STATUS
  },
  {
    field: 'template_name',
    headerName: 'Plantilla',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'branch_id',
    headerName: 'ID de Sucursal',
    type: ColumnDataTypesEnum.ID
  },
  {
    field: 'branch_code',
    headerName: 'Código de Sucursal',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'territory_id',
    headerName: 'ID de Territorio',
    type: ColumnDataTypesEnum.ID
  },
  {
    field: 'territory_code',
    headerName: 'Código de Territorio',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'sales_team_id',
    headerName: 'ID de Equipo de Ventas',
    type: ColumnDataTypesEnum.ID
  },
  {
    field: 'sales_team_code',
    headerName: 'Código de Equipo de Ventas',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'sales_team_group_id',
    headerName: 'ID de Grupo de Equipo de Ventas',
    type: ColumnDataTypesEnum.ID
  },
  {
    field: 'sales_team_group_code',
    headerName: 'Código de Grupo de Equipo de Ventas',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'departament_id',
    headerName: 'ID de Departamento',
    type: ColumnDataTypesEnum.ID
  },
  {
    field: 'departament_code',
    headerName: 'Código de Departamento',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'portfolio_id',
    headerName: 'ID de Portafolio',
    type: ColumnDataTypesEnum.ID
  },
  {
    field: 'portfolio_code',
    headerName: 'Código de Portafolio',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'template_id',
    headerName: 'ID de Plantilla',
    type: ColumnDataTypesEnum.ID
  }
];
export const RindegastoExpenseUnits = () => {
  const { title, currentCompany, showError, showNotify } = useApp();

  const ref = useRef(null);
  const handleRefresh = () => {
    if (ref.current) {
      ref.current.handleRefresh();
    }
  };

  const handleSync = async () => {
    try {
      const result = await syncExpenseUnit({ companyId: currentCompany });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderContent = () => {
    return (
      <Card>
        <CardHeader
          title={componentTitle}
          action={
            <>
              <SyncButton onClick={handleSync} />
              <RefreshButton onClick={handleRefresh} />
            </>
          }
        />
        <CardContent>
          {/* <GMGrid {...documentState}  /> */}

          <GMGridContainer
            sourceApi={listExpenseUnits}
            apiParameters={{ companyId: currentCompany }}
            loadingMessage="Cargando lista   de unidades de gasto..."
            columns={columns}
            ref={ref}
            slot={{ bulkAction: RindegastoExpenseUnitsBulkActionMenu }}
          />
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title(componentTitle)}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
