import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import SyncIcon from '@mui/icons-material/Sync';

export const SyncButton = ({
  onClick,
  startIcon = <SyncIcon />,
  tooltip = 'Sincronizar',
  ...rest
}) => <GoalButton onClick={onClick} startIcon={startIcon} tooltip={tooltip} iconOnly {...rest} />;

SyncButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string
};
