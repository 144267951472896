import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';

import { JournalReceiptPdf } from '../containers/purchases/journal/print-receip-pdft';
import { HRInvoicing } from '../containers/delivery/hr/hr-invoicing';
import { AuxiliariesCurrent } from '../containers/delivery/auxiliary/auxiliaries-current';
import { AuxiliaryHstPlaca } from '../containers/delivery/auxiliary/auxiliary-hst-placa';
import { AuxiliaryHstDate } from '../containers/delivery/auxiliary/auxiliary-hst-date';
import { HRInvoicingPendings } from '../containers/delivery/hr/hr-invoicing-pending';
import { HRInvoicingAll } from '../containers/delivery/hr/hr-invoicing-all';
import { HRInvoicingInvoiced } from '../containers/delivery/hr/hr-invoicing-invoiced';
import { DeliveryMonitoring } from '../containers/delivery/monitoring/delivery-monitoring';
import DeliveryDocuments from '../containers/delivery/monitoring/delivery-documents';
import { DeliveryTab } from '../containers/delivery/monitoring/delivery-tab';
import { DeliverySummary } from '../containers/delivery/monitoring/delivery-summary';
import { DeliveryTeam } from '../containers/delivery/monitoring/delivery-team';
import { DeliverySheets } from '../containers/delivery/monitoring/delivery-sheets';
import { DeliveryExpense } from '../containers/delivery/monitoring/delivery-expence';
import DeliveryNoteCredits from '../containers/delivery/monitoring/delivery-note-credits';
import { DeliveryCreateGr } from '../containers/delivery/create-gr/delivery-create-gr';
import DeliveryLiquidation from '../containers/delivery/monitoring/delivery-liquidation';
import { DeliveryCreateGRTab } from '../containers/delivery/create-gr/delivery-create-gr-tab';
import { DeliveryCreateGrDetail } from '../components/delivery/create-gr/delivery-create-gr-detail';
import { DeliveryCreateGrDataDelivery } from '../components/delivery/create-gr/delivery-create-gr-data-delivery';
import { VehicleDetailContainer } from '../containers/delivery/vehicle/vehicle-detail-container';
import { DeliveryPlanning } from '../containers/delivery/monitoring/delivery-planning';
import { UploadContainer } from '../containers/delivery/delivery-upload/upload-container';

const DeliveryVehiclesTab = Loadable(
  lazy(() =>
    import('../containers/delivery/vehicle/delivery-vehicles-tab').then((module) => ({
      default: module.DeliveryVehiclesTab
    }))
  )
);
const DeliveryVehicleDrivers = Loadable(
  lazy(() =>
    import('../containers/delivery/drivers/delivery-vehicle-drivers').then((module) => ({
      default: module.DeliveryVehicleDrivers
    }))
  )
);
const DeliveryVehicles = Loadable(
  lazy(() =>
    import('../containers/delivery/vehicle/delivery-vehicles').then((module) => ({
      default: module.DeliveryVehicles
    }))
  )
);

// Attachments

/* master-data (begin)*/

/* master-data (end)*/
/* commercial (begin)*/

/* commercial (end)*/

export const DeliveryRoutes = [
  {
    path: 'invoicing',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_OPEN_ORDERS}>
            <HRInvoicing />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings',
        children: [
          {
            path: ':id',
            element: (
              <AllowedGuard permission={GadminActions.gCC_OPEN_ORDERS}>
                <HRInvoicingPendings />
              </AllowedGuard>
            )
          }
        ]
      },
      {
        path: 'invoiced',
        children: [
          {
            path: ':id',
            element: (
              <AllowedGuard permission={GadminActions.gCC_OPEN_ORDERS}>
                <HRInvoicingInvoiced />
              </AllowedGuard>
            )
          }
        ]
      },
      {
        path: 'all',
        children: [
          {
            path: ':id',
            element: (
              <AllowedGuard permission={GadminActions.gCC_OPEN_ORDERS}>
                <HRInvoicingAll />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'auxiliary',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP}>
            <AuxiliariesCurrent />
          </AllowedGuard>
        )
      },
      {
        path: 'hst-vehicle',
        children: [
          {
            path: ':id',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP}>
                <AuxiliaryHstPlaca />
              </AllowedGuard>
            )
          }
        ]
      },
      {
        path: 'hst-date',
        children: [
          {
            path: ':id',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_PAR_APOYOREP}>
                <AuxiliaryHstDate />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'vehicles',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
            <DeliveryVehicles />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
            <DeliveryVehiclesTab />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                <VehicleDetailContainer />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'drivers',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_DELIVERY_CHOFERES}>
            <DeliveryVehicleDrivers />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: ':id',
    element: (
      <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
        <JournalReceiptPdf />
      </AllowedGuard>
    )
  },
  {
    path: 'monitoring',
    // element: (
    //   <AllowedGuard permission={GadminActions.gCC_TDOY_SUMARY}>
    //     <DeliveryMonitoring />
    //   </AllowedGuard>
    // ),
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
            <DeliveryMonitoring />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
            <DeliveryTab />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_SUMARY}>
                <DeliverySummary />
              </AllowedGuard>
            )
          },
          {
            path: 'documents',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
                <DeliveryDocuments />
              </AllowedGuard>
            )
          },
          {
            path: 'team',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_TEAM_LIST}>
                <DeliveryTeam />
              </AllowedGuard>
            )
          },
          {
            path: 'sheets',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_HR_LIST}>
                <DeliverySheets />
              </AllowedGuard>
            )
          },
          {
            path: 'spenses',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                <DeliveryExpense />
              </AllowedGuard>
            )
          },
          {
            path: 'note-credits',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                <DeliveryNoteCredits />
              </AllowedGuard>
            )
          },
          {
            path: 'liquidation',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                <DeliveryLiquidation />
              </AllowedGuard>
            )
          },
          {
            path: 'inventory',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                <DeliveryNoteCredits />
              </AllowedGuard>
            )
          },
          {
            path: 'invoice',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                <DeliveryNoteCredits />
              </AllowedGuard>
            )
          },
          {
            path: 'pick-up',
            element: (
              <AllowedGuard permission={GadminActions.gCC_TDOY_EXPENSE_LIST}>
                <DeliveryNoteCredits />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'create-gr',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
            <DeliveryCreateGr />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.gCC_TDOY_DET}>
            <DeliveryCreateGRTab />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                <DeliveryCreateGrDetail />
              </AllowedGuard>
            )
          },
          {
            path: 'data-delivery',
            element: (
              <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                <DeliveryCreateGrDataDelivery />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'planning',
    children: [
      // {
      //   path: '',
      //   element: (
      //     <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
      //       <DeliveryPlanning />
      //     </AllowedGuard>
      //   )
      // },
      {
        path: ':branchsCode',
        element: (
          <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
            <DeliveryPlanning />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'upload',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_TDOY_LIST}>
            <UploadContainer />
          </AllowedGuard>
        )
      }
    ]
  }
];
