import { useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Tooltip } from '@mui/material';
import { googleMapsConfig } from '../../../config';

export const MaintenaceMap = ({ locationState, handleLocation, draggableForm = true }) => {
  const { coordinates, name } = locationState;

  const containerStyle = {
    width: 'auto',
    height: '800px'
  };

  const [markerPosition, setMarkerPosition] = useState({
    lat: parseFloat(coordinates.latitude),
    lng: parseFloat(coordinates.longitude)
  });
  const url = `${googleMapsConfig.assetUrl}IconMap/`;

  const mapOptions = {
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ]
  };

  const handleMarkerDragEnd = (event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    handleLocation(newPosition.lat, newPosition.lng);
    setMarkerPosition(newPosition);
  };

  const [hoveredLocation, setHoveredLocation] = useState(null);

  const handleMarkerMouseOver = (location) => {
    setHoveredLocation(location);
  };

  const handleMarkerMouseOut = () => {
    setHoveredLocation(null);
  };

  return (
    <LoadScript googleMapsApiKey={googleMapsConfig.apiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={15}
        options={mapOptions}
      >
        <Marker
          position={markerPosition}
          title={name}
          animation={2}
          icon={{
            url: `${url}icon-red.svg`,
            scaledSize: { width: 50, height: 50 }
          }}
          draggable={draggableForm}
          onDragEnd={(event) => handleMarkerDragEnd(event)}
          onMouseOver={() => handleMarkerMouseOver(locationState)}
          onMouseOut={handleMarkerMouseOut}
        />
        {hoveredLocation && (
          <Tooltip title={hoveredLocation.name} placement="top">
            <div>Datos cliente</div>
          </Tooltip>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

MaintenaceMap.propTypes = {
  locationState: PropTypes.object.isRequired,
  handleLocation: PropTypes.func,
  draggableForm: PropTypes.bool
};
