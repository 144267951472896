import { useCallback, useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import { Box } from '@mui/material';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { applicationConfig, googleMapsConfig } from '../../../config';

export const CustomerDeliveryMap = ({
  locationState,
  documentsProp = [],
  zoomMap = 17,
  nameLocation = false
}) => {
  // console.log('documentsProp', documentsProp);
  const { x, y } = locationState;
  const containerStyle = {
    width: 'auto',
    height: '1000px'
  };

  const center = { lat: parseFloat(x), lng: parseFloat(y) };
  const [initialCenter, setInitialCenter] = useState(center);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsConfig.apiKey
  });

  const url = `${googleMapsConfig.assetUrl}IconMap/`;

  const [selectedLocation, setSelectedLocation] = useState(null);

  const mapOptions = {
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ]
  };

  const mapRef = useRef();
  useEffect(() => {
    setInitialCenter(center);
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      const center = new window.google.maps.LatLng(parseFloat(x), parseFloat(y));
      mapRef.current.panTo(center);
      mapRef.current.setZoom(zoomMap);
    }
  }, [x, y, zoomMap]);

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
  }, []);

  const onUnmount = useCallback(function callback() {}, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={initialCenter}
      zoom={zoomMap}
      options={mapOptions}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {documentsProp.map((location, index) => {
        const position = nameLocation
          ? {
              lng: parseFloat(location.longitud),
              lat: parseFloat(location.latitud)
            }
          : {
              lng: parseFloat(location.refX),
              lat: parseFloat(location.refY)
            };
        const colorIcon = location.documentStatus === 'O' ? 'blue' : 'red';
        const urlIcon = `${url}icon-${colorIcon}.svg`;
        return (
          <Marker
            key={index}
            position={position}
            title={nameLocation ? location.name : location.cardName}
            animation={2}
            icon={urlIcon}
            options={{}}
            onClick={() => {
              setSelectedLocation(location);
            }}
          />
        );
      })}
      {selectedLocation && (
        <InfoWindow
          position={
            nameLocation
              ? {
                  lat: parseFloat(selectedLocation.latitud),
                  lng: parseFloat(selectedLocation.longitud)
                }
              : {
                  lat: parseFloat(selectedLocation.refY),
                  lng: parseFloat(selectedLocation.refX)
                }
          }
          onCloseClick={() => {
            setSelectedLocation(null);
          }}
          options={{ pixelOffset: new window.google.maps.Size(0, -35) }}
        >
          <Box>
            <h2>{nameLocation ? selectedLocation.name : selectedLocation.cardName}</h2>
          </Box>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

CustomerDeliveryMap.propTypes = {
  locationState: Proptypes.object,
  documentsProp: Proptypes.array,
  zoomMap: Proptypes.number,
  nameLocation: Proptypes.bool
};
