import PropTypes from 'prop-types';
import { GoalUserMenu } from '../menu/goal-user-menu';
import { GoalUserMenuProvider } from '../../contexts/menu/goal-menu-context';

export const GoalMenu = ({ children, ...props }) => {
  return (
    <GoalUserMenuProvider>
      <GoalUserMenu {...props}>{children}</GoalUserMenu>
    </GoalUserMenuProvider>
  );
};

GoalMenu.propTypes = {
  children: PropTypes.node.isRequired
};
