import { dataLoader } from '../../../data-loader';

export const listSellers = async ({ companyId }) => {
  const response = await dataLoader.goalGet({
    action: '/sales/master/sellers',
    params: { companyId }
  });
  return response;
};

export const syncSellers = async ({ companyId }) => {
  const response = await dataLoader.goalGet({
    action: '/sync/master/sellers',
    params: { companyId }
  });
  return response;
};

// show selller
export const getSeller = async ({ companyId, id }) => {
  const response = await dataLoader.goalGet({
    action: `/sales/master/sellers/${id}`,
    params: { companyId }
  });
  return response;
};
