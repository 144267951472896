import PropTypes from 'prop-types';
import { useState } from 'react';
import { useGoalDialog } from '../../../hooks/use-goal-dialog';
import { useAuth } from '../../../hooks/use-auth';
import GoalButton from '../../goal-button';

export const DialogButton = (props) => {
  const {
    children,
    Component,
    componentProps,
    onClose,
    startIcon,
    permission,
    onAfterSave,
    button: Button = GoalButton,
    ...rest
  } = props;
  const { hasPermission } = useAuth();

  const [loading, setLoading] = useState(false);

  const [dialogControlller] = useGoalDialog({
    handleClose: onClose,
    onAfterSave
  });

  const handleCloseDialog = () => {
    setLoading(false);
    dialogControlller.closeDialog();
  };

  const handleClick = () => {
    setLoading(true);
    dialogControlller.openDialog();
  };

  if (permission && !hasPermission(permission)) {
    return null;
  }

  return (
    <>
      <Button onClick={handleClick} startIcon={startIcon} {...rest} loading={loading}>
        {children}
      </Button>

      <Component
        controller={dialogControlller}
        {...componentProps}
        onClose={handleCloseDialog}
        open={dialogControlller.open}
      />
    </>
  );
};

DialogButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.element,
  onClose: PropTypes.func,
  Component: PropTypes.func.isRequired,
  componentProps: PropTypes.object.isRequired,
  startIcon: PropTypes.node,
  iconSx: PropTypes.object,
  tooltip: PropTypes.string,
  permission: PropTypes.string,
  onAfterSave: PropTypes.func,
  button: PropTypes.elementType
};
