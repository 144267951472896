import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { ExclamationOutlined as ExclamationOutlinedIcon } from '../../icons/exclamation-outlined';
import { Cloud as CloudIcon } from '../../icons/cloud';

const LoadingContent = (props) => {
  const { loadingText = 'Cargando', error, children, sx, loading, state } = props;

  const isLoading = loading || state?.isLoading;

  return (
    <Card
      sx={{
        py: 4,
        minHeight: '10vh',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        ...sx
      }}
    >
      <CardHeader
        title={loadingText || 'Cargando...'}
        avatar={error ? <ExclamationOutlinedIcon /> : <CloudIcon />}
      />
      {isLoading && (
        <CardMedia>
          <CircularProgress />
        </CardMedia>
      )}
      <CardContent>
        <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2"></Typography>
        {error && (
          <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
            Error al cargar los datos
          </Typography>
        )}
        {children}
      </CardContent>
      {/* <CardActions>{!error && <CircularProgress />}</CardActions> */}
    </Card>
  );
};
LoadingContent.propTypes = {
  loadingText: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node,
  sx: PropTypes.object,
  loading: PropTypes.bool,
  state: PropTypes.object
};
export default LoadingContent;

export { LoadingContent };
