import PropTypes from 'prop-types';

import { Card, CardHeader, CardContent } from '@mui/material';

import LoadingContent from '../../common/loading-content';

import { useMounted } from '../../../hooks/use-mounted';
import { useGMDataSet } from '../../gm/dataset/use-gm-dataset';
import { GMGrid } from '../../gm/grid/gm-grid';
import { ColumnDataTypesEnum } from '../../gm/grid/grid-definitions';
import { HRsGroupedMenu } from './hrs-group-menu';

const columns = [
  {
    field: 'fecdsp',
    headerName: 'F Despacho',
    type: ColumnDataTypesEnum.DATE,
    description: 'Fecha de despacho'
  },
  {
    field: 'sheets',
    headerName: 'Hojas',
    type: ColumnDataTypesEnum.ID,
    description: 'Número de hojas de reparto'
  },
  {
    field: 'action',
    headerName: '',
    description: 'Acciones de HR',
    type: ColumnDataTypesEnum.MENU_ACTION,
    slot: {
      menu: HRsGroupedMenu
    },
    slotProps: {
      menu: {}
    }
  },
  {
    field: 'groupable',
    headerName: 'Agrupable',
    description: 'Agrupable',
    type: ColumnDataTypesEnum.BOOLEAN
  },
  {
    field: 'sheetCodes',
    headerName: 'Num. Hojas',
    description: 'numeros de hoja de reparto',
    type: ColumnDataTypesEnum.ARRAY
  },
  {
    field: 'branchCode',
    headerName: 'Sede',
    description: 'Sede de la hoja de reparto'
  },
  {
    field: 'plaveh',
    headerName: 'Placa',
    description: 'Placa del vehículo',
    link: {
      to: (row) => `/delivery/vehicles/${row.plaveh}`,
      tooltip: 'Ver detalle'
    }
  },
  {
    field: 'pendings',
    headerName: 'Pendientes',
    type: ColumnDataTypesEnum.INT,
    description: 'Cantidad de pendientes'
  },
  {
    field: 'invoiced',
    headerName: 'Facturados',
    type: ColumnDataTypesEnum.INT,
    description: 'Cantidad facturada'
  },
  {
    field: 'documents',
    headerName: 'Documentos',
    type: ColumnDataTypesEnum.INT,
    description: 'Cantidad de documentos'
  },

  // soles
  {
    field: 'money',
    headerName: 'Total',
    description: 'Total de la hoja de reparto',
    type: ColumnDataTypesEnum.TOTAL
  },
  // orders
  {
    field: 'orders',
    headerName: 'Pedidos',
    description: 'Cantidad de pedidos',
    type: ColumnDataTypesEnum.INT
  },
  {
    field: 'customers',
    headerName: 'Clientes',
    description: 'Cantidad de clientes',
    type: ColumnDataTypesEnum.INT
  },

  {
    field: 'weight',
    headerName: 'Peso',
    description: 'Peso de la hoja de reparto',
    type: ColumnDataTypesEnum.TOTAL
  },
  {
    field: 'volume',
    headerName: 'Volumen',
    description: 'Volumen de la hoja de reparto',
    type: ColumnDataTypesEnum.TOTAL
  },

  {
    field: 'jrName',
    headerName: 'Jefe de reparto',
    description: 'Jefe de reparto'
  },
  {
    field: 'emName',
    headerName: 'Encargado de mercadería',
    description: 'Encargado de mercadería'
  }
];

export const HRsGroupedTable = ({ data, onRefresh }) => {
  const { mounted } = useMounted();

  const { ...hrsState } = useGMDataSet({
    loadingMessage: 'Cargando hojas de reparto...',
    mounted,
    columns,
    autoload: false,
    localData: data,
    onRefresh
  });

  // console.log(hrsState);
  // if data is empty return null
  if (!data || data.length === 0) {
    return null;
  }

  if (hrsState.isLoading) {
    return <LoadingContent loadingText="Cargando hojas de reparto" loading={hrsState.isLoading} />;
  }
  return (
    <Card variant="outlined">
      <CardHeader title="Hojas de reparto agrupadas" />
      <CardContent>
        <GMGrid {...hrsState} />
      </CardContent>
    </Card>
  );
};

HRsGroupedTable.propTypes = {
  data: PropTypes.array,
  onRefresh: PropTypes.func
};
