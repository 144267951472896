import { dataLoader } from '../../data-loader';

const endpointLocal = 'rindegastos/expenses';

class RindegastosExpensesApi {
  async getExpenses({ companyId, view }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}`,
      params: { companyId, view }
    });
    return response;
  }

  async getExpense({ id, companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}`,
      params: { companyId: companyId, view: view }
    });
    return response;
  }
}

export const rindegastosExpensesApi = new RindegastosExpensesApi();
