import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  Divider,
  FormHelperText,
  Grid,
  MenuItem
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Trash as TrashIcon } from '../../../icons/trash';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { compareRecordChanged } from '../../../hooks/use-item-change';
import { gProcApi } from '../../../api/gproc';
import { RenderIf } from '../../render-if';
import { useApp } from '../../../hooks/use-app';

export const GProcConfigEdit = (props) => {
  const { open, onClose, onExited, afterSubmit, lineState, refreshLine, ...other } = props;
  const [isNew, setIsNew] = useState(false);
  const [modules, setModules] = useState([]);
  const { showNotify } = useApp();
  const handleChanges = async (values) => {
    let saved = { success: false };
    try {
      const changes = compareRecordChanged(lineState, values);
      await gProcApi.upDefaults({
        id: lineState.id,
        ...changes
      });
      refreshLine({ ...lineState, ...changes });
      saved = { ...saved, success: true };
    } catch (error) {
      saved = { ...saved, error };
    }
    return saved;
  };

  useEffect(() => {
    setIsNew(lineState?.id === -1);
  }, [lineState]);

  useEffect(() => {
    const getModules = async () => {
      const modulesContent = await gProcApi.getModules();
      if (modulesContent.success) {
        setModules(() => modulesContent.data);
      }
    };
    getModules();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: lineState?.id || -1,
      module: lineState?.module || 1,
      name: lineState?.name || '',
      code: lineState?.code || '',
      help: lineState?.help || '',
      value: lineState?.value || '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(10, 'Mínimo 10 characteres').max(255).required('Nombre es requerido'),
      code: Yup.string().min(10, 'Mínimo 10 characteres').max(255).required('Código es requerido'),
      module: Yup.number().positive('Debe ser mayor que cero').required('Código es requerido'),
      value: Yup.string().required('Mensage es requerido')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const saved = await handleChanges(values);
        if (!saved.success) throw new Error(saved.error);
        showNotify(`Cuceso guardado`);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        afterSubmit();
        onClose();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 500,
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <Card variant="outlined">
        <CardHeader title="Modificar configuración del gproc" />
        <Divider />
        <form
          onSubmit={async (values, helpers) => {
            await formik.handleSubmit(values, helpers);
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6} xs={12}>
              <PropertyList>
                <PropertyListItem label="ID" value={`${lineState.id} - ${lineState.code}`} />
                <RenderIf condition={isNew}>
                  <PropertyListItem label="Constante">
                    <InputField
                      error={Boolean(formik.touched.code && formik.errors.code)}
                      helperText={formik.touched.code && formik.errors.code}
                      name="code"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.code}
                    />
                  </PropertyListItem>
                </RenderIf>
                <PropertyListItem label="Nombre">
                  <InputField
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </PropertyListItem>
                <PropertyListItem label="Valor">
                  <InputField
                    error={Boolean(formik.touched.value && formik.errors.value)}
                    helperText={formik.touched.value && formik.errors.value}
                    name="value"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.value}
                  />
                </PropertyListItem>
                {/* <RenderIf
                  condition={isNew}
                  no={<PropertyListItem label="Módulo" value={`${lineState.module}`} />}
                > */}
                <PropertyListItem label="Módulo">
                  <InputField
                    error={Boolean(formik.touched.module && formik.errors.module)}
                    helperText={formik.touched.module && formik.errors.module}
                    name="module"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.module}
                    select
                  >
                    {modules.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.descrip} - {option.name}
                      </MenuItem>
                    ))}
                  </InputField>
                </PropertyListItem>
                {/* </RenderIf> */}
              </PropertyList>
            </Grid>
            <Grid item md={8} xs={12}>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <AllowedGuard permission={GadminActions.G2_CD_LINE_UP}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                >
                  Guardar
                </Button>
              </AllowedGuard>
              <Button
                color="secondary"
                size="large"
                startIcon={<TrashIcon />}
                onClick={async () => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Dialog>
  );
};

GProcConfigEdit.propTypes = {
  lineState: PropTypes.object.isRequired,
  refreshLine: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired
  // setEditMode: PropTypes.func.isRequired
};
