import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import { ArrowLeft } from '@mui/icons-material';

const BackButton = ({ to, startIcon = <ArrowLeft />, tooltip = 'Regresar', ...rest }) => (
  <GoalButton to={to} startIcon={startIcon} tooltip={tooltip} iconOnly {...rest} />
);

BackButton.propTypes = {
  to: PropTypes.string,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string
};

export default BackButton;

export { BackButton };
