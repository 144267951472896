import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';

import { useGMForm } from './gm-form-context';
import { SubmitButton } from '../../common/buttons/submit-button';

export const GMForm = ({ actionsOnHeader, children, subheader, title }) => {
  const { formik } = useGMForm();
  // const showSubmitButton = formik.dirty && formik.isValid;
  const showSubmitButton = formik.isValid;

  const submitButton = <SubmitButton formik={formik} loading={formik.isSubmitting} />;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          title={title}
          subheader={subheader}
          action={showSubmitButton && actionsOnHeader && submitButton}
        />
        <CardContent>{children}</CardContent>
        <CardActions
          sx={{
            display: 'flex',
            alignItems: 'end',
            flexDirection: 'column',
            justifyContent: 'end',
            padding: 2
          }}
        >
          {!formik.dirty && <Typography variant="caption">No se han realizado cambios</Typography>}
          {!formik.isValid && (
            <Typography variant="caption" color="error">
              Por favor, complete los campos requeridos
            </Typography>
          )}

          {showSubmitButton && !actionsOnHeader && submitButton}
        </CardActions>
      </Card>
    </form>
  );
};

GMForm.propTypes = {
  children: PropTypes.node,
  actionsOnHeader: PropTypes.bool,
  subheader: PropTypes.string,
  title: PropTypes.string
};
