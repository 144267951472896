export const SurveysViews = {
  ALL: 'all'
};

export const SurveysTabsViews = [
  {
    label: 'Todos',
    value: SurveysViews.ALL
  }
];

export const SurveyResultsViews = {
  ALL: 'all'
};

export const SurveyResultsTabsViews = [
  {
    label: 'Todos',
    value: SurveyResultsViews.ALL
  }
];
