import PropTypes from 'prop-types';
import GoalRecordLink from '../../goal-record-link';

export const GriLinkCell = ({ row, link, children }) => {
  const { condition, to, tooltip, component: LinkComponent } = link;

  if (condition && !condition(row)) {
    return <>{children}</>;
  }
  if (LinkComponent) {
    return (
      <LinkComponent to={to(row)} tooltip={tooltip}>
        {children}
      </LinkComponent>
    );
  }
  return (
    <GoalRecordLink to={to(row)} tooltip={tooltip}>
      {children}
    </GoalRecordLink>
  );
};

GriLinkCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  link: PropTypes.object,
  children: PropTypes.node
};
