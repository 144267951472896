import { useEffect, useState } from 'react';
import {
  comparatorsEnum,
  contains,
  endsWith,
  equal,
  greaterThan,
  isAfter,
  isBefore,
  isBlank,
  isNumber,
  isPresent,
  lessThan,
  notContains,
  notEqual,
  startsWith
} from '../utils/comparators';
import { CustomGridCell } from '../grid/custom-grid-cell';
import { isTrue } from '../../../hooks/use-bolean';
import { RecordStatus } from '../../common/status/record-status';
import LoadingContent from '../../common/loading-content';
import { Box, Skeleton } from '@mui/material';
import {
  ColumnDataTypesEnum,
  getColumnDataTypeByType,
  numericTypes
} from '../grid/grid-definitions';
import moment from 'moment';

// const COLUMN_WIDTH_NUMBER = 140;
// const COLUMN_WIDTH_BOOLEAN = 130;
// const COLUMN_WIDTH_STATUS = 120;
// const COLUMN_WIDTH_DATE = 150;
// const COLUMN_WIDTH_DEFAULT = 155;
const COLUMN_WIDTH_LINK = 30;

// const MIN_COLUMN_WIDTHS = {
//   number: COLUMN_WIDTH_NUMBER,
//   boolean: COLUMN_WIDTH_BOOLEAN,
//   status: COLUMN_WIDTH_STATUS,
//   date: COLUMN_WIDTH_DATE,
//   string: COLUMN_WIDTH_DEFAULT,
//   link: COLUMN_WIDTH_LINK
// };

export const useGMDataSet = (props) => {
  const {
    columns: columnsProp,
    paginated = true,
    pageSize = 50,
    autoload = true,
    loadingMessage = 'Cargando datos...',
    sourceApi,
    apiParameters = {},
    afterLoad,
    localData,
    onrefresh
  } = props;
  const [status, setStatus] = useState({
    loading: true,
    error: false,
    displayLoading: false,
    displayError: false,
    displayUnavailable: false,
    ready: false,
    data: []
  });
  // const { linkTo } = useLink();
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState({
    rawData: [],
    filteredData: [],
    searchedData: [],
    sortedData: [],
    rows: [],
    columns: columnsProp,
    page: 0,
    pageSize: pageSize,
    searchText: '',
    searchFields: [],
    filters: [],
    sort: 'asc',
    sortBy: '',
    paginated: paginated
  });
  const [searchColumns, setSearchColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [sortColumns, setSortColumns] = useState([]);

  useEffect(() => {
    if (autoload) {
      loadData(apiParameters).catch(console.error);
    }
  }, []);

  // aply filters when data.filtercolumns changes or data.RawData changes
  useEffect(() => {
    applyFilters(filterColumns);
  }, [filterColumns, data.rawData]);

  // aply search when  searchColumns changes or  searchText changes
  useEffect(() => {
    search(data.searchText);
  }, [searchColumns, data.searchText]);

  // aply sort when data.searchedData changes or sortColumns changes
  useEffect(() => {
    applySort();
  }, [data.searchedData, sortColumns]);

  // aply pagination when data.sortedData changes
  useEffect(() => {
    applyPagination(null);
  }, [data.sortedData]);

  const afterRerieved = async (result) => {
    if (!result.success) {
      setStatus((prevState) => ({
        ...prevState,
        ...result,
        loading: false,
        isLoaded: true,
        displayLoading: false,
        displayError: !result.success,
        displayUnavailable: result.success && !result.data
      }));
      return result;
    }
    setData((prevState) => ({
      ...prevState,
      rawData: result.data,
      filteredData: result.data,
      searchedData: result.data,
      sortedData: result.data,
      paginatedData: result.data,
      rows: result.data,
      pageCount: Math.ceil((result.data?.length || 0) / pageSize)
    }));

    setStatus((prevState) => ({
      ...prevState,
      loading: false,
      isLoaded: true,
      data: result.data,
      ready: true,
      displayUnavailable: !result.data
    }));
    await afterLoad?.(result);
  };

  useEffect(() => {
    if (localData) {
      afterRerieved({ success: true, data: localData });
    }

    return () => {
      setStatus((prevState) => ({
        ...prevState,
        loading: false,
        error: false,
        displayLoading: false,
        displayError: false,
        displayUnavailable: false
      }));
    };
  }, [localData]);

  const loadData = async (parameters) => {
    setStatus((prevState) => ({
      ...prevState,
      loading: true,
      message: loadingMessage,
      displayLoading: true,
      displayError: false,
      displayUnavailable: false
    }));

    try {
      const result = await sourceApi(parameters);
      await afterRerieved(result);
    } catch (err) {
      console.error('useDataError', err);
      setStatus((prevState) => ({
        ...prevState,
        loading: false,
        error: err.message,
        real: true,
        displayError: true
      }));
    }
  };

  const handleRefresh = async (parameters) => {
    if (onrefresh) {
      return await onrefresh();
    } else {
      let refreshParameters = parameters;

      // if parameter is not SyntheticBaseEvent
      if ((parameters && parameters._reactName === 'onClick') || !parameters) {
        refreshParameters = apiParameters;
      } else {
        refreshParameters = { ...apiParameters, ...parameters };
      }
      return await loadData(refreshParameters);
    }
  };

  useEffect(() => {
    if (!columnsProp || !columnsProp.length) {
      return;
    }
    const newColumns = [];
    columnsProp.forEach((column) => {
      column.type = column.type || ColumnDataTypesEnum.STRING;

      if (column.type === ColumnDataTypesEnum.MENU_ACTION) {
        column.width = column.width || 100;
        column.sortable = false;
        column.filterable = false;
        column.searchable = false;
        column.type = 'actions';
        if (column.slot) {
          const { menu: Component } = column.slot;
          column.renderCell = (params) => {
            const { row } = params;
            return (
              <Component
                row={row}
                column={column}
                {...column.slotProps?.menu}
                onRefresh={handleRefresh}
              />
            );
          };
        }
        newColumns.push(column);
        return;
      }

      // for date columns
      if (
        column.type === ColumnDataTypesEnum.DATE ||
        column.type === ColumnDataTypesEnum.DATETIME
      ) {
        column.valueGetter = (params) => {
          // if value is string
          if (typeof params === ColumnDataTypesEnum.STRING) {
            const mom = moment(params);
            // const date = new Date(mom.year(), mom.month(), mom.date());
            const date = mom.toDate();
            return date;
          }
          if (!params || params.value == null) {
            return '';
          }
          const mom = moment(params.value);
          const date = mom.toDate();
          return date;
        };
        if (!column.renderCell) {
          column.renderCell = (params) => {
            const { value, row } = params;
            return <CustomGridCell value={value} row={row} column={column} />;
          };
        }
      }
      // for number columns
      if (numericTypes.includes(column.type)) {
        if (!column.renderCell) {
          const { type } = column;
          column.renderCell = (params) => {
            const { value, row } = params;
            return <CustomGridCell value={value} row={row} column={{ ...column, type: type }} />;
          };
        }
        column.type = ColumnDataTypesEnum.NUMBER;
      }
      // for boolean
      if (column.type === ColumnDataTypesEnum.BOOLEAN) {
        column.valueGetter = (params) => {
          if (typeof params === ColumnDataTypesEnum.STRING) {
            return isTrue(params);
          }
          if (typeof params === ColumnDataTypesEnum.BOOLEAN) {
            return isTrue(params);
          }
          if (!params || params.value == null) {
            return false;
          }
          const val = isTrue(params.value);

          return val;
        };
        if (!column.renderCell) {
          column.renderCell = (params) => {
            const { value } = params;
            return <CustomGridCell value={value} column={column} />;
          };
        }
      }

      // for status
      if (column.type === ColumnDataTypesEnum.STATUS) {
        if (!column.renderCell) {
          column.renderCell = (params) => {
            const { value } = params;
            const RenderComponent = column.slot?.Component || RecordStatus;
            return <RenderComponent status={value} {...column.slot?.componentProps} />;
          };
        }
        column.type = ColumnDataTypesEnum.STRING;
      }
      if (column.type === ColumnDataTypesEnum.STRING) {
        if (!column.renderCell) {
          column.renderCell = (params) => {
            const { value, row } = params;
            return <CustomGridCell value={value} row={row} column={column} />;
          };
        }
      }
      // if is array
      if (column.type === ColumnDataTypesEnum.ARRAY) {
        column.valueGetter = (params) => {
          if (typeof params === ColumnDataTypesEnum.STRING) {
            return JSON.parse(params);
          }
          if (Array.isArray(params)) {
            return params;
          }
          if (!params || params.value == null) {
            return [];
          }
          return JSON.parse(params.value);
        };
        if (!column.renderCell) {
          column.renderCell = (params) => {
            const { value, row } = params;
            // if is array join else print value
            const valueToRender = Array.isArray(value) ? value.join(', ') : value;
            return <CustomGridCell value={valueToRender} row={row} column={column} />;
          };
        }
      }

      column.width = Math.max(
        column.width || 0,
        getColumnDataTypeByType(column.type)?.width || 0
        // MIN_COLUMN_WIDTHS[column.type] || COLUMN_WIDTH_DEFAULT
      );
      // if column is link add 30 to width
      if (column.link) {
        column.width += COLUMN_WIDTH_LINK;
      }

      newColumns.push(column);
    });

    setColumns(newColumns);

    setSearchColumns(
      columnsProp
        .filter((column) => column.searchable)
        .map((column) => {
          column.type = column.type || ColumnDataTypesEnum.STRING;
          return column;
        })
    );
    setFilterColumns(
      columnsProp
        .filter((column) => column.filterable)
        .map((column) => {
          column.type = column.type || ColumnDataTypesEnum.STRING;
        })
    );
    setSortColumns(
      columnsProp
        .filter((column) => column.sortable)
        .map((column) => {
          column.sortMode = column.sortMode || 'asc';
        })
    );
  }, [columnsProp]);

  const descendingComparator = (a, b, sortBy) => {
    if (b[sortBy] < a[sortBy]) {
      return -1;
    }

    if (b[sortBy] > a[sortBy]) {
      return 1;
    }

    return 0;
  };

  const applySort = () => {
    let sortedData = data.searchedData;

    if (!Array.isArray(sortedData)) {
      return;
    }
    if (sortColumns && sortColumns.length) {
      sortedData = sortedData.sort((a, b) => {
        let result = 0;
        for (let index = 0; index < sortColumns.length; index++) {
          result = descendingComparator(a, b, sortColumns[index].name);
          if (result !== 0) {
            return result;
          }
        }
        return result;
      });
    }

    setData((prevState) => ({
      ...prevState,
      sortedData: sortedData
    }));
  };

  const applyPagination = (page) => {
    let paginatedData = data.sortedData;

    // if paginatedData  is not array return
    if (!Array.isArray(paginatedData)) {
      return;
    }

    if (page === null) {
      paginatedData = paginated ? paginatedData?.slice(0, pageSize) : paginatedData;
    } else {
      paginatedData = paginatedData?.slice(page * pageSize, page * pageSize + pageSize);
    }

    setData((prevState) => ({
      ...prevState,
      page: page,
      rows: paginatedData || []
    }));
  };

  const applyFilters = (filters) => {
    let filteredData = data.rawData;

    if (!Array.isArray(filteredData)) {
      return;
    }

    if (filterColumns && filterColumns.length && filters && filters.length) {
      filteredData = filteredData.filter((row) => {
        let isAccepted = true;

        for (let index = 0; index < filters.length; index++) {
          switch (filters[index].operator) {
            case comparatorsEnum.EQUAL:
              isAccepted = equal(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.GREATER_THAN:
              isAccepted = greaterThan(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.LESS_THAN:
              isAccepted = lessThan(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.IS_AFTER:
              isAccepted = isAfter(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.IS_BEFORE:
              isAccepted = isBefore(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.IS_BLANK:
              isAccepted = isBlank(row[filters[index].property]);
              break;

            case comparatorsEnum.IS_PRESENT:
              isAccepted = isPresent(row[filters[index].property]);
              break;

            case comparatorsEnum.NOT_EQUAL:
              isAccepted = notEqual(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.CONTAINS:
              isAccepted = contains(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.NOT_CONTAINS:
              isAccepted = notContains(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.STARTS_WITH:
              isAccepted = startsWith(row[filters[index].property], filters[index].value);
              break;

            case comparatorsEnum.ENDS_WITH:
              isAccepted = endsWith(row[filters[index].property], filters[index].value);
              break;

            default:
              break;
          }

          if (!isAccepted) {
            break;
          }
        }

        return isAccepted;
      });
    }

    setData((prevState) => ({
      ...prevState,
      filteredData: filteredData
    }));
  };

  const search = async (searchText) => {
    // check if searchText is number
    // if yes, check if row[searchColumns[index].name] is number
    const numberSearch = isNumber(searchText);
    const rows = data.filteredData;
    let searchedData = rows;
    if (searchText && searchColumns && searchColumns.length) {
      searchedData = rows.filter((row) => {
        // If query exists, it looks only in customer id field

        let qualified = 0;
        for (let index = 0; index < searchColumns.length; index++) {
          let isAccepted = true;

          //  if isnumeric searchText
          //  if isnumeric row[searchFields[index].name]

          switch (searchColumns[index].type) {
            case 'string':
              isAccepted =
                isAccepted &&
                row[searchColumns[index].name]?.toLowerCase().includes(searchText.toLowerCase());
              break;
            case 'number':
              isAccepted =
                isAccepted &&
                numberSearch &&
                this.isNumber(row[searchColumns[index].name]) &&
                // eslint-disable-next-line eqeqeq
                row[searchColumns[index].name] == searchText;
              break;
            default:
              break;
          }
          qualified += isAccepted ? 1 : 0;
        }
        if (!qualified) return false;
      });
    }
    setData((prevState) => ({
      ...prevState,
      searchedData: searchedData
    }));
  };

  const renderStatus = () => {
    const { isLoading, error, documents } = status;
    const displayLoading = isLoading;
    const displayError = Boolean(!isLoading && error);
    const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

    if (displayLoading) {
      return (
        <LoadingContent loadingText={loadingMessage}>
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        </LoadingContent>
      );
    }
    if (displayError) {
      return <LoadingContent loadingText={status.message} error={status.error} state={status} />;
    }
    if (displayUnavailable) {
      return <LoadingContent loadingText={status.message} error={status.error} state={status} />;
    }

    return null;
  };

  return { ...props, ...status, handleRefresh, columns, rows: data.sortedData || [], renderStatus };
};
