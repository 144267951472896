import PropTypes from 'prop-types';
import { CommonDialog } from '../common-dialog';
import AttributeSelectorContainer from './attribute-selector-container';
import { DialogButton } from './dialog-button';
import GoalButton from '../../goal-button';
import GradingIcon from '@mui/icons-material/Grading';

const baseSlot = {
  content: AttributeSelectorContainer,
  component: DialogButton
};

export const CommonRecordEditor = ({
  onAfterSave,
  slot: slotParam,
  slotProps: slotPropsParam,
  startIcon = <GradingIcon />
}) => {
  const { component: Component, content: Content } = { ...baseSlot, ...slotParam };
  const {
    content: contentProps = {},
    component: componentProps = {},
    dialog: dialogProps
  } = {
    ...slotPropsParam
  };
  return (
    <>
      <Component
        Component={CommonDialog}
        componentProps={{
          slot: { ...slotParam, component: Content },
          slotProps: {
            component: contentProps
          },
          maxWidth: 'md',
          ...dialogProps
        }}
        onAfterSave={onAfterSave}
        button={slotParam.button ?? GoalButton}
        startIcon={startIcon}
      >
        {componentProps.label}
      </Component>
    </>
  );
};

CommonRecordEditor.propTypes = {
  onAfterSave: PropTypes.func.isRequired,
  startIcon: PropTypes.element,
  slot: PropTypes.shape({
    content: PropTypes.elementType.isRequired,
    component: PropTypes.elementType,
    button: PropTypes.elementType
  }),
  slotProps: PropTypes.shape({
    content: PropTypes.shape({
      data: PropTypes.object
    })
  })
};

export default CommonRecordEditor;
