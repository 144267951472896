import { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Card, CardHeader } from '@mui/material';
import SellerInformationEdit from './seller-information-edit';
import { PropertyList } from '../../../../../components/property-list';
import { PropertyListItem } from '../../../../../components/property-list-item';
import EditButton from '../../../../../components/common/buttons/edit-button';
import RecordStatus from '../../../../../components/common/status/record-status';

export const SellerInformation = ({ data, onRefresh }) => {
  const [editMode, setEditMode] = useState(false);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleAfterSave = () => {
    setEditMode(false);
    onRefresh();
  };

  if (editMode) {
    return (
      <SellerInformationEdit data={data} onClosed={handleCancel} onAfterSave={handleAfterSave} />
    );
  }

  return (
    <Card sx={{ padding: 2 }}>
      <CardHeader
        title="Detalle del Vendedor"
        action={
          <ButtonGroup>
            <EditButton onClick={handleEdit} />
          </ButtonGroup>
        }
      />
      <PropertyList>
        <PropertyListItem align="end" label="ID" value={data.id} />
        <PropertyListItem align="end" label="ID de la Empresa" value={data.company_id} />
        <PropertyListItem label="Nombre de la Empresa" value={data.company?.name} />
        <PropertyListItem label="Nombre Corto de la Empresa" value={data.company?.short_name} />
        <PropertyListItem label="ID Remoto" value={data.remote_id} />
        <PropertyListItem label="Email" value={data.email} />
        <PropertyListItem label="Móvil" value={data.mobile} />
        <PropertyListItem label="Nombre" value={data.name} />
        <PropertyListItem label="Está Activo">
          <RecordStatus status={data.status} />
        </PropertyListItem>
        <PropertyListItem label="Nombre de Usuario" value={data.username} />
      </PropertyList>
    </Card>
  );
};

SellerInformation.propTypes = {
  data: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired
};
