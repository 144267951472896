import Proptypes from 'prop-types';
import { Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { Status } from '../../status';
import GadminActions from '../../../contexts/gadmin-actions';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { StatusColor } from '../../contest/contest-definition';
import { useAuth } from '../../../hooks/use-auth';
import GoalButton from '../../goal-button';
import { useApp } from '../../../hooks/use-app';
import { GetCompanyName } from '../../common/get-company-name';
import { documentStatusViews } from '../../../api/app-definitions';

const getStatusDetailsBasedOnMenu = (item) => {
  const statusDetails = [];

  if (item.status === documentStatusViews.VIEW_ACTIVE) {
    statusDetails.push(
      { label: 'Validado en', value: item?.requestValidateDate },
      { label: 'Validado por', value: item?.requestValidateByName },
      { label: 'Activado en', value: item?.requestActivateDate },
      { label: 'Activado por', value: item?.requestActivateByName }
    );
  }

  if (item.status === documentStatusViews.VIEW_CANCELLED) {
    if (item?.requestValidateDate) {
      statusDetails.push(
        { label: 'Rechazado en', value: item?.requestRejectDate },
        { label: 'Rechazado por', value: item?.requestRejectByName }
      );
    }
    statusDetails.push(
      { label: 'Cancelado en', value: item?.requestCancelDate },
      { label: 'Cancelado por', value: item?.requestCancelByName }
    );
  }

  if (item.status === documentStatusViews.VIEW_PENDING) {
    statusDetails.push(
      { label: 'Validado en', value: item?.requestValidateDate },
      { label: 'Validado por', value: item?.requestValidateByName }
    );
  }

  if (item.status === documentStatusViews.VIEW_DRAFT && item?.requestRejectDate) {
    statusDetails.push(
      { label: 'Rechazado en', value: item?.requestRejectDate },
      { label: 'Rechazado por', value: item?.requestRejectByName }
    );
  }

  if (item.status === documentStatusViews.VIEW_PAUSED) {
    statusDetails.push(
      { label: 'Pausado en', value: item?.requestPausedDate },
      { label: 'Pausado por', value: item?.requestPausedByName }
    );
  }

  if (item.status === documentStatusViews.VIEW_CLOSED) {
    statusDetails.push(
      { label: 'Activado en', value: item?.requestActivateDate },
      { label: 'Activado por', value: item?.requestActivateByName },
      { label: 'Cerrado en', value: item?.requestClosedDate },
      { label: 'Cerrado por', value: item?.requestClosedByName }
    );
  }

  if (item.status === documentStatusViews.VIEW_ARCHIVED) {
    statusDetails.push(
      { label: 'Cancelado en', value: item?.requestCancelDate },
      { label: 'Cancelado por', value: item?.requestCancelByName },
      { label: 'Archivado en', value: item?.requestArchivedDate },
      { label: 'Archivado por', value: item?.requestArchivedByName }
    );
  }

  return statusDetails;
};

export const SurveySumaryDetail = ({ item, setEditMode, canEdit }) => {
  const { companyState } = useApp();
  const { hasPermission } = useAuth();

  const statusDetails = getStatusDetailsBasedOnMenu(item);

  return (
    <Card variant="outlined" sx={{ pb: 3, mb: 3 }}>
      <CardHeader
        title="Detalle de la encuesta"
        subheader={<Status color={StatusColor(item.status)} label={item.statusName} />}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <PropertyList>
              <PropertyListItem label="Empresa">
                <Typography color="textSecondary" variant="body2">
                  {item.company_id ? GetCompanyName(item.company_id, companyState) : '--'}
                </Typography>
              </PropertyListItem>
              <PropertyListItem label="Título">
                <Typography color="textSecondary">{item.title || '--'}</Typography>
              </PropertyListItem>
              <PropertyListItem label="Fecha" value={item.date || '--'} />
              <PropertyListItem label="Equipos de ventas">
                <Typography color="textSecondary">{item?.sales_team_info?.name || '--'}</Typography>
              </PropertyListItem>
            </PropertyList>
          </Grid>

          <Grid item xs={12} md={4} lg={4} xl={4}>
            <PropertyList>
              <PropertyListItem label="Puntos" value={item.points ? `${item.points}` : '0'} />
              <PropertyListItem label="Creado en" value={item.created_at || '--'} />
              <PropertyListItem label="Creado por" value={item.created_by_user || '--'} />
            </PropertyList>
          </Grid>

          <Grid item xs={12} md={4} lg={4} xl={3}>
            <PropertyList>
              {statusDetails.map((detail, index) => (
                <PropertyListItem key={index} label={detail.label} value={detail.value || '--'} />
              ))}
            </PropertyList>
          </Grid>
        </Grid>
      </CardContent>

      {canEdit && hasPermission(GadminActions.G2_SURVEY_ADD) && (
        <CardActions>
          <GoalButton
            color="primary"
            startIcon={<PencilIcon />}
            onClick={() => {
              setEditMode(true);
            }}
          >
            Editar
          </GoalButton>
        </CardActions>
      )}
    </Card>
  );
};
SurveySumaryDetail.propTypes = {
  item: Proptypes.object,
  setEditMode: Proptypes.func,
  canEdit: Proptypes.bool
};
