import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalImporterApi {
  async getTypes({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/v1/importer/resources/types?companyId=${companyId}`
    });
    return response;
  }

  async getManagerTemplateList(options) {
    const { companyId } = options;
    const response = await dataLoader.goalGet({
      action: `/dex/manager/templates`,
      params: { companyId }
    });
    return response;
  }
  async getManagerTemplate({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `/dex/manager/templates/${id}`,
      params: { companyId }
    });
    return response;
  }

  async getManagerTemplateResourceList(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/dex/manager/templates/list?companyId=${id}`
    });
    return response;
  }

  async postManagerTemplate({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/dex/manager/templates',
      changes: values
    });
    return response;
  }

  async updateManagerTemplate(id, data) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/dex/manager/templates/${id}`,
      changes: data
    });
    return response;
  }

  async getManagerTemplateId(id) {
    const response = await dataLoader.loadGoalGetData({
      action: `/dex/manager/templates/${id}`
    });
    return response;
  }

  // async getResponseImport(data) {
  //   const formData = new FormData();
  //   console.log('data', data);

  //   Object.keys(data).forEach((key) => {
  //     if (key !== 'path') {
  //       formData.append(key, data[key]);
  //     }
  //   });

  //   console.log('formData', formData);

  //   const response = await dataLoader.loadGoalFormData({
  //     action: data.path,
  //     changes: formData
  //   });
  //   return response;
  // }

  async getResponseImport(data) {
    // const formData = new FormData();
    // console.log('data', data);

    // Object.keys(data).forEach((key) => {
    //   if (key !== 'path') {
    //     formData.append(key, data[key]);
    //   }
    // });

    // console.log('formData', formData);

    const response = await dataLoader.loadGoalPost({
      action: data.path,
      changes: data
    });
    return response;
  }
}
const goalImporterApi = new GoalImporterApi();

export { goalImporterApi };
