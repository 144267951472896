import { useGMDataSet } from '../../../components/gm/dataset/use-gm-dataset';
import { goalPriceListsApi } from '../../../api/goal/inventory/price-list/goal-price-list';
import { useApp } from '../../../hooks/use-app';
import { useMounted } from '../../../hooks/use-mounted';
import { Card, CardContent, CardHeader } from '@mui/material';
import { PropertyList } from '../../../components/property-list';
import { PropertyListItem } from '../../../components/property-list-item';
import RecordStatus from '../../../components/common/status/record-status';
import { useParams } from 'react-router-dom';
import PriceListEditor from './price-list-editor';
import { useGoalDialog } from '../../../hooks/use-goal-dialog';
import { CommonDialog } from '../../../components/common/common-dialog';
import { EditButton, RefreshButton } from '../../../components/common/buttons';

const PriceListResumen = () => {
  // get id form params
  const { id } = useParams();

  const mounted = useMounted();
  const { currentCompany } = useApp();

  const { handleRefresh, renderStatus, ready, ...priceListState } = useGMDataSet({
    sourceApi: goalPriceListsApi.getDetail,
    apiParameters: { companyId: currentCompany, id },
    loadingMessage: 'Cargando lista de precios...',
    mounted,
    columns: []
  });

  const handleAfterSave = () => {
    handleRefresh();
  };

  const [dialogController] = useGoalDialog({
    onAfterSave: handleAfterSave
  });

  const { data } = priceListState;

  if (!ready) {
    return renderStatus();
  }

  return (
    <Card sx={{ p: 2 }}>
      <CardHeader
        title="Resumen de Lista de Precios"
        action={
          <>
            <EditButton onClick={() => dialogController.openDialog()} />
            <RefreshButton onClick={handleRefresh} />
          </>
        }
      ></CardHeader>
      <CardContent>
        <PropertyList>
          <PropertyListItem label="ID" value={data.id} align="end" />
          <PropertyListItem label="Nombre" value={data.name} align="end" />
          <PropertyListItem label="ID Empresa" value={data.company_id} align="end" />
          <PropertyListItem label="Número SAP" value={data.remote_id} align="end" />
          <PropertyListItem label="ID Lista Costo" value={data.cost_list_id} align="end" />
          <PropertyListItem label="Factor" value={data.factor_labels} align="end" />
          <PropertyListItem label="Tipo" value={data.type} align="end" />
          <PropertyListItem label="Asignable" value={data.assignable} align="end" />
          <PropertyListItem label="Auto Acualizable" value={data.auto_update} align="end" />
          <PropertyListItem label="Estado" align="end">
            <RecordStatus status={data.status} />
          </PropertyListItem>
        </PropertyList>
      </CardContent>
      <CommonDialog
        controller={dialogController}
        slot={{
          component: PriceListEditor
        }}
        slotProps={{
          component: {
            data: data
          }
        }}
        maxWidth="md"
      />
    </Card>
  );
};

export default PriceListResumen;
