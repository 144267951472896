import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';
import { SalesTeamsContainer } from '../containers/sales-teams/sales-teams-container';
import { SalesTeamsTab } from '../containers/sales-teams/sales-teams-tab';
import { SalesTeamsChannel } from '../containers/sales-teams/sales-teams-channel';
import { SalesTeamsZones } from '../containers/sales-teams/sales-teams-zones';
import { SalesTeamsPrices } from '../containers/sales-teams/sales-teams-prices';
import { SalesTeamsRoutes } from '../containers/sales-teams/sales-teams-routes';
import { SalesTeamsSellers } from '../containers/sales-teams/sales-teams-sellers';
import { TablesProviders } from '../containers/sales-teams/sales-teams-providers';
import { SalesTeamsBrands } from '../containers/sales-teams/sales-teams-brands';
import { SalesTeamsProducts } from '../containers/sales-teams/sales-teams-products';
import { ContestContainerAwards } from '../components/contest/contest-container-awards';
import { ContestContainerQuota } from '../components/contest/contest-container-quota';
import { SalesTeamsSummary } from '../containers/sales-teams/sales-teams-summary';
import { PresaleMonitoring } from '../containers/presale/monitoring/presale-monitoring';
import { PromotionProvider } from '../contexts/promotions-context';
import { SalesSellers } from '../containers/sales/master-data/sellers/sales-serllers';
import { SalesSeller } from '../containers/sales/master-data/sellers/sales-serller';

const CustomerContest = Loadable(
  lazy(() =>
    import('../containers/contest/customer-contest').then((module) => ({
      default: module.CustomerContest
    }))
  )
);

const CustomerContestTab = Loadable(
  lazy(() =>
    import('../containers/contest/customer-contest-tab').then((module) => ({
      default: module.CustomerContestTab
    }))
  )
);

const ContestSummary = Loadable(
  lazy(() =>
    import('../components/contest/contest-summary').then((module) => ({
      default: module.ContestSummary
    }))
  )
);

const PromotionsList = Loadable(
  lazy(() =>
    import('../containers/promotions/promotions-container').then((module) => ({
      default: module.PromotionsList
    }))
  )
);

const PromotionCreate = Loadable(
  lazy(() =>
    import('../components/promotions/promotion-create').then((module) => ({
      default: module.PromotionCreate
    }))
  )
);

const PromotionsTab = Loadable(
  lazy(() =>
    import('../containers/promotions/promotions-tab').then((module) => ({
      default: module.PromotionsTab
    }))
  )
);

const PromotionSummary = Loadable(
  lazy(() =>
    import('../containers/promotions/promotions-summary').then((module) => ({
      default: module.PromotionSummary
    }))
  )
);

const PromotionsDetail = Loadable(
  lazy(() =>
    import('../containers/promotions/promotions-detail').then((module) => ({
      default: module.PromotionsDetail
    }))
  )
);

const PromotionChannels = Loadable(
  lazy(() =>
    import('../containers/promotions/promotion-channels').then((module) => ({
      default: module.PromotionChannels
    }))
  )
);

const PromotionsCustomerOnly = Loadable(
  lazy(() =>
    import('../containers/promotions/promotions-customer-only').then((module) => ({
      default: module.PromotionsCustomerOnly
    }))
  )
);

const PromotionsExcludeCustomers = Loadable(
  lazy(() =>
    import('../containers/promotions/promotions-exclude-customers').then((module) => ({
      default: module.PromotionsExcludeCustomers
    }))
  )
);

const PromotionsTables = Loadable(
  lazy(() =>
    import('../containers/promotions/promotions-tables').then((module) => ({
      default: module.PromotionsTables
    }))
  )
);

const PromotionsExclusive = Loadable(
  lazy(() =>
    import('../containers/promotions/promotions-exclusive').then((module) => ({
      default: module.PromotionsExclusive
    }))
  )
);

/* commercial (end)*/

/** Reports (start) */
const ReportsRetailersCommissions = Loadable(
  lazy(() =>
    import('../containers/reports/reports-retailers-commisions').then((module) => ({
      default: module.ReportsRetailersCommissions
    }))
  )
);

const ReportsWholesalerCommissions = Loadable(
  lazy(() =>
    import('../containers/reports/reports-wholesaler-commissions').then((module) => ({
      default: module.ReportsWholesalerCommissions
    }))
  )
);

const ReportsSupervisorsCommissions = Loadable(
  lazy(() =>
    import('../containers/reports/reports-supervisors-commissions').then((module) => ({
      default: module.ReportsSupervisorsCommissions
    }))
  )
);

const ReportsRegionalManagersCommissions = Loadable(
  lazy(() =>
    import('../containers/reports/reports-regional-managers-commissions').then((module) => ({
      default: module.ReportsRegionalManagersCommissions
    }))
  )
);

const ReportsZoneManagersCommissions = Loadable(
  lazy(() =>
    import('../containers/reports/reports-zone-managers-commissions').then((module) => ({
      default: module.ReportsZoneManagersCommissions
    }))
  )
);

export const CommercialRoutes = [
  {
    path: 'retailers-commissions',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard
            permission={GadminActions.G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_RETAIL_SELLERS}
          >
            <ReportsRetailersCommissions />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'wholesalers-commissions',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard
            permission={GadminActions.G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_WHOLESALE_SELLERS}
          >
            <ReportsWholesalerCommissions />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'supervisors-commissions',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard
            permission={GadminActions.G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_SUPERVISORS}
          >
            <ReportsSupervisorsCommissions />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'regional-managers-commissions',
    element: (
      <AllowedGuard
        permission={GadminActions.G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_REGIONAL_MANAGERS}
      >
        <ReportsRegionalManagersCommissions />
      </AllowedGuard>
    )
  },
  {
    path: 'zone-managers-commissions',
    element: (
      <AllowedGuard
        permission={GadminActions.G2_COMMERCIAL_VIEW_COMMISSION_FACTORS_FOR_ZONE_MANAGERS}
      >
        <ReportsZoneManagersCommissions />
      </AllowedGuard>
    )
  },
  {
    path: 'master-data',
    children: [
      {
        path: 'sellers',
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_SALER}>
                <SalesSellers />
              </AllowedGuard>
            )
          },
          {
            path: ':id',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_SALER}>
                <SalesSeller />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },

  {
    path: 'promotion',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <PromotionsList />
          </AllowedGuard>
        )
      },
      {
        path: 'new',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <PromotionCreate />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_TDOY_DET}>
            <PromotionProvider>
              <PromotionsTab />
            </PromotionProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_SUMARY}>
                <PromotionSummary />
              </AllowedGuard>
            )
          },
          {
            path: 'detail',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_DET}>
                <PromotionsDetail />
              </AllowedGuard>
            )
          },
          {
            path: 'channel',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_DET}>
                <PromotionChannels />
              </AllowedGuard>
            )
          },
          {
            path: 'customers-only',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_DET}>
                <PromotionsCustomerOnly />
              </AllowedGuard>
            )
          },
          {
            path: 'exclude-customers',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_DET}>
                <PromotionsExcludeCustomers />
              </AllowedGuard>
            )
          },
          {
            path: 'tables',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_DET}>
                <PromotionsTables />
              </AllowedGuard>
            )
          },
          {
            path: 'exclusive',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_DET}>
                <PromotionsExclusive />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'contest',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
            <CustomerContest />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
            <CustomerContestTab />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                <ContestSummary />
              </AllowedGuard>
            )
          },
          {
            path: 'awards',
            element: (
              <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                <ContestContainerAwards />
              </AllowedGuard>
            )
          },
          {
            path: 'quota',
            element: (
              <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                <ContestContainerQuota />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'sales-teams',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
            <SalesTeamsContainer />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
            <SalesTeamsTab />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <SalesTeamsSummary />
              </AllowedGuard>
            )
          },
          {
            path: 'channel',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <SalesTeamsChannel />
              </AllowedGuard>
            )
          },
          {
            path: 'zones',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <SalesTeamsZones />
              </AllowedGuard>
            )
          },
          {
            path: 'prices',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <SalesTeamsPrices />
              </AllowedGuard>
            )
          },
          {
            path: 'routes',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <SalesTeamsRoutes />
              </AllowedGuard>
            )
          },
          {
            path: 'sellers',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <SalesTeamsSellers />
              </AllowedGuard>
            )
          },
          {
            path: 'providers',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <TablesProviders />
              </AllowedGuard>
            )
          },
          {
            path: 'brands',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <SalesTeamsBrands />
              </AllowedGuard>
            )
          },
          {
            path: 'products',
            element: (
              <AllowedGuard permission={GadminActions.GCC_SALE_TEAMS}>
                <SalesTeamsProducts />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'pre-sale',
    children: [
      // {
      //   path: '',
      //   element: (
      //     <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
      //       <PresaleMonitoring />
      //     </AllowedGuard>
      //   )
      // },
      {
        path: ':teamCode',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_DIG}>
            <PresaleMonitoring />
          </AllowedGuard>
        )
      }
    ]
  }
];
