import { dataLoader } from '../../data-loader';

const endPointLocal = '/surveys/survey-results';

class SurveyResultsApi {
  async getSurveyResult({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}/${id}`,
      params: { companyId }
    });
    return response;
  }

  async getLinesOfSurveyResult({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}/${id}/lines`,
      params: { companyId }
    });
    return response;
  }
}

export const surveyResultsApi = new SurveyResultsApi();
