import { goalCustomeDeleveryPlanningApi } from '../../../../api/goal/planning/goal-customer-delivery-planning';
import { ColumnDataTypesEnum } from '../../../gm/grid/grid-definitions';
import { GMGridContainer } from '../../../gm/grid/gm-grid-context';
import { useDeliveryPlanning } from '../delivery-planing-context';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: ColumnDataTypesEnum.ID,
    link: {
      to: (item) => `/sales/orders/${item.id}`,
      tooltip: 'Ver detalle del pedido'
    }
  },
  {
    field: 'name',
    headerName: 'Nombre',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'is_driver',
    headerName: 'Chofer',
    type: ColumnDataTypesEnum.BOOLEAN
  },
  {
    field: 'last_login',
    headerName: 'Inicio de sesión',
    type: ColumnDataTypesEnum.DATETIME
  }
];
export const DeliveryPlaningDrivers = () => {
  const { pendingDeliveryParams } = useDeliveryPlanning();

  return (
    <GMGridContainer
      columns={columns}
      sourceApi={goalCustomeDeleveryPlanningApi.getDrivers}
      apiParameters={pendingDeliveryParams}
      loadingMessage="Cargando pedidos pendientes"
    />
  );
};
