import { createContext, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { defaultViews } from '../../api/app-definitions';
import { attachmentTemplatesApi } from '../../api/goal/maintenance/assets/attachment-templates-api';

const AttachmentTemplateContext = createContext();

const useAttachmentTemplate = () => {
  return useContext(AttachmentTemplateContext);
};

const AttachmentTemplateProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view =
    new URLSearchParams(search).get('attachment-template-view')?.toUpperCase() || defaultViews.ALL;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: attachmentTemplatesApi.getAttachmentTemplate,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando plantilla de archivo adjunto...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    attachmentTemplate: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : false,
    message: dataState.message,
    editMode,
    setEditMode
  };
  return (
    <AttachmentTemplateContext.Provider value={value}>
      {children}
    </AttachmentTemplateContext.Provider>
  );
};

AttachmentTemplateProvider.propTypes = { children: PropTypes.node.isRequired };

export { AttachmentTemplateProvider, useAttachmentTemplate };
