import Proptypes from 'prop-types';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { goalMaintenancePayrollApi } from '../../../api/goal/maintenance/goal-maintenance-payroll';
import GadminActions from '../../../contexts/gadmin-actions';
import { InputField } from '../../input-field';
import { useApp } from '../../../hooks/use-app';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import { ConstantStatus } from '../../../api/application-enums';
import { useFormSaver } from '../../../hooks/use-form-saver';
import GoalButton from '../../goal-button';

export const MaintenanceDepartmentsForm = ({
  isNew = true,
  initialValues,
  configState = {},
  onClose,
  handleRefresh
}) => {
  const { currentCompany, showNotify, showError } = useApp();

  const [handleUpdate, handleAdd] = useFormSaver({
    originalData: initialValues,
    apiUpdateHandler: goalMaintenancePayrollApi.updateDepartamentsForm,
    apiAddHandler: goalMaintenancePayrollApi.postDepartamentsForm,
    id: 'id',
    includeNewValues: true,
    handleAfterSave: () => {
      formik.resetForm();
      onClose();
      handleRefresh();
    },
    mandatoryFields: ['company_id']
  });

  const formik = useFormik({
    initialValues: {
      id: initialValues?.id || '',
      company_id: initialValues?.company_id || currentCompany,
      name: initialValues?.name || '',
      code: initialValues?.code || '',
      level: initialValues?.level || '1',
      parent_id: initialValues?.parent_id || null,
      email: initialValues?.email || '',
      status: initialValues?.status || ConstantStatus.ACTIVE
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      code: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values, helpers) => {
      if (!configState.success) {
        showError(configState?.message);
        return;
      }

      const level = values.level ?? 1;
      if (
        (configState?.data['max-level'] && configState?.data['max-level'] > parseInt(level, 10)) ||
        configState?.data['max-level'] === -1
      ) {
        const newValues = {
          ...values,
          level: level,
          company_id: parseInt(values.company_id, 10)
        };

        isNew
          ? await handleAdd({ values: newValues, helpers })
          : await handleUpdate({ values: newValues, helpers });
      } else {
        showNotify(
          'No se puede agregar más niveles de Departamentos debido a que alcanzó el máximo permitido.'
        );
      }
    }
  });

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader title={`${isNew ? 'Agregar' : 'Editar'} Departamento`} />
        <CardContent>
          <InputField
            label="Código"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            sx={{ marginBottom: '1em' }}
            disabled={!isNew}
          />

          <CompanyInputDisabled currentCompany={currentCompany} />
          <InputField
            label="Nombre"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{ marginBottom: '1em' }}
          />
          <InputField
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ marginBottom: '1em' }}
          />
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'end',
            padding: '16px'
          }}
        >
          <GoalButton
            color="primary"
            loading={formik.isSubmitting}
            type="submit"
            permission={GadminActions.gCC_TEAM_BRANDS_UP}
          >
            Guardar
          </GoalButton>
          <GoalButton onClick={onClose}>Cancelar</GoalButton>
        </CardActions>
      </form>
    </Card>
  );
};

MaintenanceDepartmentsForm.propTypes = {
  isNew: Proptypes.bool,
  initialValues: Proptypes.object,
  configState: Proptypes.object,
  onClose: Proptypes.func,
  handleRefresh: Proptypes.func
};
