import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';

import { Customer } from '../containers/busines-partners/customer';
import { CustomerProductPurchases } from '../containers/busines-partners/customer-product-purchases';
import { BusinessPartnerAddresses } from '../components/busines-partner/panels/business-parther-addresses';
import { BusinessPartnerDivisions } from '../components/busines-partner/panels/business-parther-divisions';
import { BusinessPartnerTables } from '../components/busines-partner/panels/business-parther-tables';
import { BusinessPartnerDocuments } from '../components/busines-partner/panels/business-parther-documents';
import { BusinessPartnerArticles } from '../components/busines-partner/panels/business-parther-articles';
import { BusinessPartnerElectricInvoice } from '../components/busines-partner/panels/business-parther-electric-invoice';
import { BusinessPartnersForm } from '../containers/busines-partners/business-partners-form';
import { BusinessPartnerSummaryContainer } from '../components/busines-partner/panels/business-parther-summary-container';

const BusinessPartnerHeaderPanel = Loadable(
  lazy(() =>
    import('../components/busines-partner/panels/business-partner-header-panel').then((module) => ({
      default: module.BusinessPartnerHeaderPanel
    }))
  )
);

const BusinessPartnerSalesOrdersPanel = Loadable(
  lazy(() =>
    import('../components/busines-partner/panels/business-partner-customer-sales-panel').then(
      (module) => ({
        default: module.BusinessPartnerSalesOrdersPanel
      })
    )
  )
);

// Attachments

/* master-data (begin)*/

/* master-data (end)*/
/* commercial (begin)*/

/* commercial (end)*/

export const BusinessPartnersRoutes = [
  {
    path: 'customers',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
            <Customer />
          </AllowedGuard>
        )
      },
      {
        path: 'search',
        element: (
          <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
            <Customer />
          </AllowedGuard>
        )
      },
      {
        path: ':cardcode',
        element: (
          <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
            <Customer />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                <BusinessPartnerHeaderPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'summary2',
            element: (
              <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                <BusinessPartnerSummaryContainer />
              </AllowedGuard>
            )
          },
          {
            path: 'sales-orders',
            element: (
              <AllowedGuard permission={GadminActions.gCC_BROWSE_ORDER_REG}>
                <BusinessPartnerSalesOrdersPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'returns',
            element: (
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                {/* <Customer /> */}
                <div>No implementado</div>
              </AllowedGuard>
            )
          },
          {
            path: 'product-purchases',
            element: (
              <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
                <CustomerProductPurchases />
              </AllowedGuard>
            )
          },
          {
            path: 'addresses',
            element: (
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                <BusinessPartnerAddresses />
              </AllowedGuard>
            )
          },
          {
            path: 'divisions',
            element: (
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                <BusinessPartnerDivisions />
              </AllowedGuard>
            )
          },
          {
            path: 'tables',
            element: (
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                <BusinessPartnerTables />
              </AllowedGuard>
            )
          },
          {
            path: 'documents',
            element: (
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                <BusinessPartnerDocuments />
              </AllowedGuard>
            )
          },
          {
            path: 'articles',
            element: (
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                <BusinessPartnerArticles />
              </AllowedGuard>
            )
          },
          {
            path: 'electric_invoice',
            element: (
              <AllowedGuard permission={GadminActions.G2_CD_LIST}>
                <BusinessPartnerElectricInvoice />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'new',
    element: (
      <AllowedGuard permission={GadminActions.gCC_PROC_CLIENT_EDIT}>
        <BusinessPartnersForm />
      </AllowedGuard>
    )
  }
];
