import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import ordersFilled from '@iconify/icons-ant-design/ordered-list';

// material
import { useTheme } from '@mui/material/styles';
import { Box, Button, Card, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import { dashboardApi } from '../../../api/dashboard';
import LoadingContent from '../../common/loading-content';
import { useData } from '../../../hooks/use-data';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { useMounted } from '../../../hooks/use-mounted';
import { IconWrapper } from '../../common/icon-wrapper';
import { ChartWrapper } from '../../common/chart-wrapper';

export default function OrderOpenStatics() {
  const theme = useTheme();
  const mounted = useMounted();

  const [openOrdersStatics, handleRefresh] = useData({
    sourceApi: dashboardApi.getOrdersStaticOpenBySede,
    loadingMessage: 'Cargando Pedidos Abiertos',
    mounted
  });
  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.error.main
    ],
    labels: openOrdersStatics.data?.codes || [],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } }
    }
  });
  if (openOrdersStatics.isLoading || openOrdersStatics.error || !openOrdersStatics.data) {
    return (
      <LoadingContent
        loadingText={openOrdersStatics.message}
        error={openOrdersStatics.error}
        state={openOrdersStatics}
      />
    );
  }

  return (
    <Card
      elevation={0}
      sx={{
        // backgroundColor: alpha(theme.palette.info.main, 0.1),
        mb: 2
      }}
    >
      <Box
        md={6}
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: {
            sm: 'row',
            xs: 'column'
          },
          mt: 2
        }}
      >
        <IconWrapper icon={ordersFilled} />
        <Typography variant="header" sx={{ opacity: 0.72 }}>
          Pedidos Pendientes - preventa{' '}
        </Typography>
        <Button
          color="primary"
          size="medium"
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          variant="text"
        />
      </Box>
      <ChartWrapper dir="ltr">
        <ReactApexChart
          type="pie"
          series={openOrdersStatics.data?.values || []}
          options={chartOptions}
          height={280}
        />
      </ChartWrapper>
    </Card>
  );
}
