import { dataLoader } from '../../data-loader';

export const listPolicyTemplate = async ({ companyId }) => {
  const response = await dataLoader.goalGet({
    action: `/integrations/rindegasto/policy-templates`,
    params: { companyId }
  });
  return response;
};

// syncPolicyTemplate

export const syncPolicyTemplate = async ({ companyId, id = null }) => {
  const response = await dataLoader.goalGet({
    action: '/integrations/rindegasto/policy-templates/sync',
    params: { companyId, id }
  });
  return response;
};

// getPolicyTemplate

export const getPolicyTemplate = async ({ companyId, id }) => {
  const response = await dataLoader.goalGet({
    action: `/integrations/rindegasto/policy-templates/${id}`,
    params: { companyId }
  });
  return response;
};

// storePolicyTemplate
export const storePolicyTemplate = async ({ companyId, values }) => {
  const response = await dataLoader.goalPost({
    action: '/integrations/rindegasto/policy-templates?companyId=' + companyId,
    data: values
  });
  return response;
};

// updatePolicyTemplate
export const updatePolicyTemplate = async ({ companyId, id, changes }) => {
  const response = await dataLoader.goalPut({
    path: `/integrations/rindegasto/policy-templates/${id}?companyId=${companyId}`,
    changes
  });
  return response;
};

export const listAccountingAccounts = async ({ companyId }) => {
  const response = await dataLoader.goalGet({
    action: '/integrations/rindegasto/policy-templates/accounts',
    params: { companyId }
  });
  return response;
};

// add accounting account
export const addAccountingAccount = async ({ companyId, id, values }) => {
  const response = await dataLoader.goalPost({
    action: `/integrations/rindegasto/policy-templates/${id}/expense-accounts?companyId=${companyId}`,
    data: values
  });
  return response;
};

// remove accounting account
export const removeAccountingAccount = async ({ companyId, id, expenseAccountId }) => {
  const response = await dataLoader.goalDelete({
    action: `/integrations/rindegasto/policy-templates/${id}/expense-accounts/${expenseAccountId}?companyId=${
      companyId
    }`
  });
  return response;
};

// add expense field
export const addExpenseField = async ({ companyId, id, values }) => {
  const response = await dataLoader.goalPost({
    action: `/integrations/rindegasto/policy-templates/${id}/add-expense-fields?companyId=${companyId}`,
    data: values
  });
  return response;
};

// update expense field
export const updateExpenseField = async ({ companyId, id, changes }) => {
  const response = await dataLoader.goalPost({
    action: `/integrations/rindegasto/policy-templates/${id}/update-expense-fields/?companyId=${companyId}`,
    data: changes
  });
  return response;
};

// remove expense field
export const removeExpenseField = async ({ companyId, id, expenseFieldId }) => {
  const response = await dataLoader.goalDelete({
    action: `/integrations/rindegasto/policy-templates/${id}/update-expense-fields/${expenseFieldId}?companyId=${
      companyId
    }`
  });
  return response;
};
