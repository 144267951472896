import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Card, Tabs, Tab, Divider, CardHeader, CardContent } from '@mui/material';
import { PropertyListItem } from '../../components/property-list-item';
import { PropertyList } from '../../components/property-list';
import { useParams } from 'react-router-dom';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';
import LoadingContent from '../../components/common/loading-content';
import { useApp } from '../../hooks/use-app';
import { getPolicy, syncPolicies } from '../../api/goal/rindegastos/rindegastos-policy-api';
import BackButton from '../../components/common/buttons/back-button';
import { RefreshButton, SyncButton } from '../../components/common/buttons';
import CustomTabPanel from '../../components/common/custom-tab-panel';

const parentRoute = `/rindegasto/policies`;

const RindegastoPolicy = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [data, setData] = useState([]);
  const { currentCompany, showError, showNotify } = useApp();
  const mounted = useMounted();
  // get id from params
  const { id } = useParams();

  const [documentState, handleRefresh] = useData({
    sourceApi: getPolicy,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: 'Cargando detalle del flujo de trabajo...',
    mounted,
    defaults: {
      noAutoLoad: true
    },
    afterLoad: (result) => {
      if (result.success) {
        setData(result.data);
      } else {
        showError(result.message);
      }
    }
  });

  const handleSync = async () => {
    try {
      const result = await syncPolicies({ companyId: currentCompany, id });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleRefresh();
  }, [id]);

  if (documentState.error || documentState.isLoading || !documentState.data) {
    return (
      <LoadingContent
        loadingText={documentState.message}
        error={documentState.error}
        state={documentState}
      />
    );
  }

  return (
    <Card sx={{ padding: 2, backgroundColor: 'background.default' }}>
      <CardHeader
        subheader="Detalle de la política"
        title={data.name}
        avatar={<BackButton to={parentRoute} />}
        action={
          <>
            <SyncButton onClick={handleSync} />
            <RefreshButton onClick={handleRefresh} />
          </>
        }
      />

      <CardContent>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="info tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Información General" />
          <Tab label="Moneda" />
          <Tab label="Gastos de Distancia" />
          <Tab label="Impuestos" />
          <Tab label="Campos Extra de Gastos" />
          <Tab label="Campos Extra de Reporte" />
          <Tab label="Categorías" />
          <Tab label="Usuarios" />
        </Tabs>
        <Divider sx={{ marginBottom: 2 }} />
        <CustomTabPanel value={value} index={0}>
          <Card sx={{ padding: 2 }}>
            <PropertyList>
              <PropertyListItem label="ID" value={data.id} />
              <PropertyListItem label="ID de la Empresa" value={data.companyId} />
              <PropertyListItem label="ID del Creador" value={data.creatorId} />
              <PropertyListItem label="Nombre del Creador" value={data.creatorName} />
              <PropertyListItem label="Nombre" value={data.name} />
              <PropertyListItem label="Código" value={data.code} />
              <PropertyListItem label="Descripción" value={data.description} />
              <PropertyListItem label="Está Activo" value={data.isActive ? 'Sí' : 'No'} />
              <PropertyListItem
                label="Campo Reembolsable"
                value={data.isReimbursableField ? 'Sí' : 'No'}
              />
            </PropertyList>
          </Card>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Card>
            <PropertyList>
              <PropertyListItem label="ID de Moneda" value={data.currency.id} />
              <PropertyListItem label="Código de Moneda" value={data.currency.code} />
              <PropertyListItem
                label="Permitir Tasa de Cambio del Usuario"
                value={data.currency.isAllowUserExchangeRate ? 'Sí' : 'No'}
              />
            </PropertyList>
          </Card>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Card>
            <PropertyList>
              <PropertyListItem
                label="Habilitado"
                value={data.distanceExpenses.enabled ? 'Sí' : 'No'}
              />
              <PropertyListItem
                label="Tarifa por Milla"
                value={data.distanceExpenses.ratePerMile}
              />
              <PropertyListItem
                label="Tarifa por Kilómetro"
                value={data.distanceExpenses.ratePerKilometer}
              />
              <PropertyListItem
                label="Kilómetro Predeterminado"
                value={data.distanceExpenses.defaultKilometer}
              />
            </PropertyList>
          </Card>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {data.taxes.taxes?.map((tax) => (
            <Card key={tax.id} sx={{ marginBottom: 2, padding: 2 }}>
              <PropertyList>
                <PropertyListItem label="Nombre del Impuesto" value={tax.name} />
                <PropertyListItem label="Valor del Impuesto" value={tax.value} />
                <PropertyListItem label="Tipo de Impuesto" value={tax.type} />
              </PropertyList>
            </Card>
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          {data.expenseExtraFields?.map((field) => (
            <Card key={field.id} sx={{ marginBottom: 2, padding: 2 }}>
              <PropertyList>
                <PropertyListItem label="Nombre del Campo" value={field.name} />
                <PropertyListItem label="Tipo de Campo" value={field.type} />
                <PropertyListItem label="Valor Predeterminado" value={field.defaultValue} />
              </PropertyList>
            </Card>
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          {data.reportExtraFields.map((field) => (
            <Card key={field.id} sx={{ marginBottom: 2, padding: 2 }}>
              <PropertyListItem label="Nombre del Campo" value={field.name} />
              <PropertyListItem label="Tipo de Campo" value={field.type} />
              <PropertyListItem label="Valor Predeterminado" value={field.defaultValue} />
            </Card>
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          {data.categories?.map((category) => (
            <Card key={category.id} sx={{ marginBottom: 2, padding: 2 }}>
              <PropertyListItem
                label="Proveedor"
                value={`${category.supplierInfo?.code || '---'} - ${category.supplierInfo?.name || '---'}`}
              />
              <PropertyListItem label="Código de Categoría" value={category.code} />
              <PropertyListItem label="Nombre del Grupo" value={category.group.name} />
            </Card>
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          {data.users?.map((user) => (
            <Card key={user.id} sx={{ marginBottom: 2, padding: 2 }}>
              <PropertyListItem
                label="Nombre"
                value={`${user.user?.firstname} ${user.user?.lastname}`}
              />
              <PropertyListItem label="ID" value={user.user?.id} />
            </Card>
          ))}
        </CustomTabPanel>
      </CardContent>
    </Card>
  );
};

RindegastoPolicy.propTypes = {
  data: PropTypes.object
};

export default RindegastoPolicy;
