import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@mui/material';
import { SurveySurveyDetailFilter } from './survey-survey-detail-filter';
import { useSelection } from '../../../hooks/use-selection';
import { SurveySurveyDetailTable } from './survey-survey-detail-table';
import GadminActions from '../../../contexts/gadmin-actions';
import GoalButton from '../../goal-button';
import { Plus as PlusIcon } from '../../../icons/plus';
import { useAuth } from '../../../hooks/use-auth';
import { useApp } from '../../../hooks/use-app';
import { goalCustomeSurveyApi } from '../../../api/goal/survey/goal-customer-survey';
import { CommonDialog } from '../../common/common-dialog';
import { useGoalDialog } from '../../../hooks/use-goal-dialog';
import { SurveyQuestionSelector } from './survey-question-selector';

export const SurveyLines = ({ lines, onRefresh, survey = {}, canEdit }) => {
  const [selectedDocuments, handleSelectDetail, handleSelectAllDetail, handleClearSelectedDetail] =
    useSelection(lines);

  const { hasPermission } = useAuth();

  const handleAfterSave = () => {
    onRefresh();
  };
  const [dialogController] = useGoalDialog({
    onAfterSave: handleAfterSave
  });

  const { showNotify, showError, currentCompany } = useApp();

  const handlePlusDialog = () => {
    dialogController.openDialog();
  };

  const deleteQuestions = async (lines) => {
    const response = await goalCustomeSurveyApi.deleteLinesFromSurvey({
      id: survey._id,
      companyId: currentCompany,
      lines: lines
    });
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      onRefresh();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  const deleteQuestion = async (line) => {
    const response = await goalCustomeSurveyApi.deleteLineFromSurvey({
      id: survey._id,
      companyId: currentCompany,
      line: line
    });
    if (response.success) {
      showNotify(`${response?.message}. Cambio exitoso `);
      onRefresh();
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
  };

  return (
    <Card>
      <CardHeader
        title="Preguntas de la encuesta"
        action={
          hasPermission(GadminActions.G2_SURVEY_ADD) &&
          survey.editable && (
            <GoalButton
              color="primary"
              onClick={handlePlusDialog}
              startIcon={<PlusIcon />}
              variant="contained"
              sx={{
                marginRight: 1
              }}
            />
          )
        }
      />
      <CardContent>
        <SurveySurveyDetailFilter
          selectedDocuments={selectedDocuments}
          handleClearSelectedDetail={handleClearSelectedDetail}
          handleUpdateScore={deleteQuestions}
        />
        <SurveySurveyDetailTable
          error={false}
          documents={lines}
          onSelect={handleSelectDetail}
          onSelectAll={handleSelectAllDetail}
          selectedDocuments={selectedDocuments}
          handleRefresh={onRefresh}
          onDelete={deleteQuestion}
          canDelete={canEdit}
        />
        <CommonDialog
          controller={dialogController}
          slot={{
            component: SurveyQuestionSelector
          }}
          slotProps={{
            component: {
              survey
            }
          }}
        />
      </CardContent>
    </Card>
  );
};

SurveyLines.propTypes = {
  lines: PropTypes.array.isRequired,
  onRefresh: PropTypes.func.isRequired,
  survey: PropTypes.object,
  canEdit: PropTypes.bool
};
