import { dataLoader } from '../../../data-loader';

const endpointLocal = 'accounting/sunat/sire-sales';

class SireSalesApi {
  async getPeriods({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/periods`,
      params: { companyId }
    });
    return response;
  }

  async listProposal({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-proposal`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async showProposal({ companyId, id }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/${id}`,
      params: { companyId, id }
    });
    return response;
  }

  async synchronizeCpSummary({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/sync-cp-summary`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async synchronizeProposal({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/sync-proposal`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async synchronizeInconsistencies({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/sync-inconsistencies`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async cpSummaryRvieProposal({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/cp-summary-rvie-proposal`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async cpSummaryNoIncluded({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/cp-summary-no-included`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async cpSummaryPreliminaryRvie({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/cp-summary-preliminary-rvie`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async cpSummaryGenerated({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/cp-summary-generated`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async listInconsistenciesForPaymentReceipts({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/inconsistencies-payment-receipts`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async acceptProposal({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/accept-proposal`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async listTickets({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-tickets`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async syncTickets({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/sync-tickets`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }
}

export const sireSalesApi = new SireSalesApi();
