import Proptypes from 'prop-types';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { es } from 'date-fns/locale';
// import { alpha } from '@mui/material/styles';
import moment from 'moment';
import { Box, FormHelperText } from '@mui/material';
import { CommonHelpText } from './common-help-text';

export const CommonDatePicker = ({
  formik,
  currentDate,
  setCurrentDate,
  dateName,
  minValue = null,
  maxValue = null,
  labelValue = 'Fecha',
  error = null,
  helperText = '',
  message = ''
}) => {
  const handleChangeDate = (newValue) => {
    setCurrentDate(newValue);
  };

  const handleDateChanged = (newValue) => {
    if (!newValue) return;
    setCurrentDate(newValue);
    const current = moment(newValue).format('YYYY-MM-DD');
    if (current) {
      formik.setFieldValue(dateName, current);
    }
  };

  const renderContent = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <MobileDatePicker
          label={labelValue}
          inputFormat="dd/MM/yyyy"
          name={dateName}
          value={currentDate === '' ? null : currentDate}
          minDate={minValue}
          maxDate={maxValue}
          onChange={handleChangeDate}
          onAccept={handleDateChanged}
          slotProps={{ textField: { variant: 'outlined' } }}
          InputLabelProps={{
            shrink: true,
            style: { position: 'relative' }
          }}
          sx={{
            backgroundColor: 'background.paper',
            borderColor: 'neutral.300',
            borderRadius: 1
            //   '&.MuiInputBase-inputAdornedStart': {
            //     pl: 0
            //   },
            //   '&.Mui-disabled': {
            //     backgroundColor: 'action.disabledBackground',
            //     boxShadow: 'none',
            //     borderColor: alpha('#D6DBE1', 0.5)
            //   },
            //   '&:hover': {
            //     backgroundColor: 'background.paper',
            //     borderWidth: 0,
            //     borderStyle: 'solid',
            //     borderColor: 'neutral.300',
            //     borderRadius: 0
            //   },
            //   '&:focus': {
            //     borderRadius: 1,
            //     borderWidth: 1,
            //     borderStyle: 'solid',
            //     // borderColor: 'neutral.300',
            //     backgroundColor: 'background.paper',
            //     boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
            //   }
          }}
          error={error}
          helperText={helperText}
        />
        {error && <FormHelperText error> {helperText} </FormHelperText>}
      </LocalizationProvider>
    );
  };

  return message ? (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {renderContent()}
      <CommonHelpText message={message} />
    </Box>
  ) : (
    renderContent()
  );
};

CommonDatePicker.propTypes = {
  formik: Proptypes.object.isRequired,
  currentDate: Proptypes.oneOfType([Proptypes.instanceOf(Date), Proptypes.string]).isRequired,
  setCurrentDate: Proptypes.func.isRequired,
  dateName: Proptypes.string.isRequired,
  minValue: Proptypes.instanceOf(Date),
  maxValue: Proptypes.instanceOf(Date),
  labelValue: Proptypes.string,
  error: Proptypes.bool,
  helperText: Proptypes.string,
  message: Proptypes.string
};
