import PropTypes from 'prop-types';
import {
  PowerBIResourceTypesEnum,
  PowerBIResourceTypesEnumList
} from './power-bi/base/power-bi-resource-types-enum';

const baseSx = {
  width: '100%',
  height: '100%',
  border: 'none',
  minHeight: '80vh'
};
const PowerBIDashboard = ({
  embedUrl = 'https://app.powerbi.com',
  resourceType = PowerBIResourceTypesEnum.REPORT,
  accessToken,
  embedId,
  title,
  sx = {}
}) => {
  // const src = `${embedUrl}/view?${resourceType}=${embedId}`;
  //src="https://app.powerbi.com/reportEmbed?reportId=2eb575ea-17ca-4d38-a10e-93da3a6291fc&autoAuth=true&ctid=2e38d53d-5c2d-418a-aa9a-896c7e056ae3"
  const src = `${embedUrl}/reportEmbed?${resourceType}=${embedId}`;
  return (
    <iframe
      title={title}
      src={src}
      allowFullScreen
      style={{
        ...baseSx,
        ...sx
      }}
    />
  );
};

PowerBIDashboard.propTypes = {
  embedUrl: PropTypes.string,
  accessToken: PropTypes.string,
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string,
  resourceType: PropTypes.oneOf(PowerBIResourceTypesEnumList),
  sx: PropTypes.object
};
export default PowerBIDashboard;
