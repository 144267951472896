import { dataLoader } from '../../data-loader';

export const listWorkflows = async ({ companyId }) => {
  return await dataLoader.goalGet({
    action: '/integrations/rindegasto/workflows',
    params: { companyId }
  });
};

export const getWorkflow = async ({ id, companyId }) => {
  return await dataLoader.goalGet({
    action: `/integrations/rindegasto/workflows/${id}`,
    params: { companyId }
  });
};

export const syncWorkflow = async ({ companyId, id = null }) => {
  return await dataLoader.goalGet({
    action: '/integrations/rindegasto/workflows/sync',
    params: { companyId, id }
  });
};
