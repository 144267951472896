import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useMounted } from '../../../hooks/use-mounted';
import LoadingContent from '../../common/loading-content';
import { useAuth } from '../../../hooks/use-auth';

// ----------------------------------------------------------------------

export default function LoginKeycloak() {
  const mounted = useMounted();
  const { doLogin } = useAuth();

  const [isProcessing, setIsProcessing] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (mounted.current) {
      if (location.hash) {
        if (location.hash.includes('state=') && location.hash.includes('code=')) {
          setIsProcessing(true);
        }
      }
    }
  }, [location.hash, mounted]);

  return (
    <>
      {!isProcessing && (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isProcessing}
          onClick={() => {
            setIsProcessing(true);
            doLogin({ redirectUri: window.location.href });
          }}
        >
          Empecemos!
        </LoadingButton>
      )}
      {isProcessing && (
        <LoadingContent loadingText="Verificando sessión..." loading={isProcessing} />
      )}
    </>
  );
}
