import PropTypes from 'prop-types';
import { TextField, Card, CardContent } from '@mui/material';
import {
  storePolicyTemplate,
  updatePolicyTemplate
} from '../../../api/goal/rindegastos/rindegasto-policy-template-api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SubmitButton } from '../../common/buttons/submit-button';
import { CancelButton } from '../../common/buttons/cancel-button';
import { useFormSaver } from '../../../hooks/use-form-saver';
const validationSchema = Yup.object({
  name: Yup.string().required('Nombre es requerido'),
  code: Yup.string().required('Código es requerido'),
  description: Yup.string()
});

const PolicyTemplateHeaderEdit = ({ data, onClosed, onAfterSave }) => {
  const isNew = !data.id;
  const [handleUpdate, handleAdd] = useFormSaver({
    originalData: data,
    apiUpdateHandler: updatePolicyTemplate,
    apiAddHandler: storePolicyTemplate,
    // includeNewValues: true,
    handleAfterSave: (saved) => {
      formik.resetForm();
      onAfterSave?.(saved);
    },
    mandatoryFields: ['company_id'],
    companyId: data.company_id
  });
  const formik = useFormik({
    initialValues: {
      name: data.name || '',
      code: data.code || '',
      description: data.description || '',
      id: data.id || 0,
      company_id: data.company_id || 0,
      ...data
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      console.log('isNew', isNew);
      isNew
        ? await handleAdd({ values: values, helpers })
        : await handleUpdate({ values: values, helpers });
    }
  });
  const { values, handleChange, handleBlur, touched, errors } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardContent>
          <TextField
            label="Nombre"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Código"
            name="code"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.code && Boolean(errors.code)}
            helperText={touched.code && errors.code}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Descripción"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
            fullWidth
            margin="normal"
          />
          {/* <SubmitButton onClick={handleSubmit} /> */}
          <SubmitButton />
          <CancelButton onClick={onClosed} />
        </CardContent>
      </Card>
    </form>
  );
};

PolicyTemplateHeaderEdit.propTypes = {
  onClosed: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onAfterSave: PropTypes.func
};

export default PolicyTemplateHeaderEdit;
