import PropTypes from 'prop-types';

import { useState } from 'react';
import { ConfirmationDialog } from '../confirmation-dialog';
import GoalMenuItem from './goal-menu-item';
import { useGoalUserMenu } from '../../contexts/menu/goal-menu-context';

const ConfirmationMenuItem = ({
  startIcon,
  label,
  onConfirm,
  onCancel = null,
  confirmationMessage = '',
  params,
  afterConfirm,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleClose } = useGoalUserMenu();

  const handleConfirm = async () => {
    await onConfirm(params);
    setOpen(false);
    handleClose();
  };

  const handleCancel = () => {
    onCancel?.();
    setOpen(false);
    handleClose();
  };

  return (
    <>
      <GoalMenuItem
        startIcon={startIcon}
        onClick={() => {
          setOpen(true);
        }}
        loading={loading}
        label={label}
        showText={true}
        showLoading={false}
        autoClose={false}
        {...rest}
      />
      <ConfirmationDialog
        message={`${confirmationMessage}. ¿Está seguro que desea continuar?`}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        params={params}
        open={open}
        title={label || 'Confirmación'}
        variant="warning"
        loading={loading}
        setLoading={setLoading}
        afterConfirm={afterConfirm}
      />
    </>
  );
};

ConfirmationMenuItem.propTypes = {
  startIcon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  confirmationMessage: PropTypes.string,
  params: PropTypes.objectOf(PropTypes.any),
  afterConfirm: PropTypes.func
};
export { ConfirmationMenuItem };
