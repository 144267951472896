const DeliveryStatusEnum = [
  {
    code: 'N',
    name: 'BORRADOR',
    label: 'BORRADOR',
    color: 'gray'
  },
  {
    code: 'G',
    name: 'GENERADO',
    label: 'GENERADO',
    color: 'blue'
  },
  {
    code: 'W',
    name: 'EN_REPARTO',
    label: 'EN REPARTO',
    color: 'orange'
  },
  {
    code: '1',
    name: 'LIQ_MER',
    label: 'LIQ MER',
    color: 'green'
  },
  {
    code: '2',
    name: 'LIQ_MER+',
    label: 'LIQ MER+',
    color: 'green'
  },
  {
    code: '3',
    name: 'LIQ_DOCS',
    label: 'LIQ DOCS',
    color: 'green'
  },
  {
    code: '4',
    name: 'LIQ_DOCS+',
    label: 'LIQ DOCS+',
    color: 'green'
  },
  {
    code: 'L',
    name: 'FIN_LIQ_DOCS',
    label: 'FIN LIQ DOCS',
    color: 'lightgreen'
  },
  {
    code: '5',
    name: 'FIN_LIQ',
    label: 'FIN LIQ',
    color: 'lightblue'
  },
  {
    code: 'A',
    name: 'ANULADO',
    label: 'ANULADO',
    color: 'red'
  },
  {
    code: 'R',
    name: 'REPROGRAMANDO',
    label: 'REPROGRAMANDO',
    color: 'purple'
  },
  {
    code: 'U',
    name: 'REPROGRAMANDO+',
    label: 'REPROGRAMANDO+',
    color: 'pink'
  },
  {
    code: 'F',
    name: 'FACTURANDO',
    label: 'FACTURANDO',
    color: 'yellow'
  },
  {
    code: '6',
    name: 'LIQ_DIF',
    label: 'LIQ DIF',
    color: 'darkgreen'
  },
  {
    code: '7',
    name: 'CERRADO',
    label: 'CERRADO',
    color: 'brown'
  }
];

export default DeliveryStatusEnum;
