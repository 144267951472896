import { Box, Card, CardContent, CardHeader, Container } from '@mui/material';
import { useApp } from '../../hooks/use-app';
import { Helmet } from 'react-helmet-async';
import { RefreshButton } from '../../components/common/buttons/refresh-button';
import { SyncButton } from '../../components/common/buttons/sync-button';
import { ColumnDataTypesEnum } from '../../components/gm/grid/grid-definitions';
import {
  listPolicyTemplate,
  syncPolicyTemplate
} from '../../api/goal/rindegastos/rindegasto-policy-template-api';
import AddButton from '../../components/common/buttons/add-button';
import { CommonDialog } from '../../components/common/common-dialog';
import PolicyTemplateHeaderEdit from '../../components/rindegasto/policy-templates/policy-template-header-edit';
import { useGoalDialog } from '../../hooks/use-goal-dialog';
import { useGoalNavigate } from '../../hooks/use-goal-navigate';
import { GMGridContainer } from '../../components/gm/grid/gm-grid-context';
import { useRef } from 'react';

const componentTitle = 'Plantilla de Políticas de RindeGasto';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: ColumnDataTypesEnum.ID,
    link: {
      to: (item) => `/rindegasto/policy-templates/${item.id}`,
      tooltip: 'Ver detalle de la plantilla de política de rindeGasto'
    }
  },
  {
    field: 'code',
    headerName: 'Código',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'name',
    headerName: 'Nombre',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'description',
    headerName: 'Descripción',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'status',
    headerName: 'Estado',
    type: ColumnDataTypesEnum.STATUS
  }
];
export const RindegastoPolicyTemplates = () => {
  const { title, currentCompany, showError, showNotify } = useApp();
  const ref = useRef(null);
  const handleRefresh = () => {
    if (ref.current) {
      ref.current.handleRefresh();
    }
  };

  const navigate = useGoalNavigate();

  const handleAfterSave = (result) => {
    const newId = result.data?.id;
    if (newId) {
      const newUrl = `/rindegasto/policy-templates/${newId}`;
      navigate(newUrl);
    } else {
      handleRefresh();
    }
  };

  const [dialogController] = useGoalDialog({
    onAfterSave: handleAfterSave
  });

  const handleSync = async () => {
    try {
      const result = await syncPolicyTemplate({ companyId: currentCompany });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderContent = () => {
    return (
      <>
        <Card>
          <CardHeader
            title={componentTitle}
            action={
              <>
                <AddButton onClick={() => dialogController.openDialog()} />
                <SyncButton onClick={handleSync} />
                <RefreshButton onClick={handleRefresh} />
              </>
            }
          />
          <CardContent>
            <GMGridContainer
              sourceApi={listPolicyTemplate}
              apiParameters={{ companyId: currentCompany }}
              loadingMessage="Cargando lista  de unidades de gasto..."
              columns={columns}
              ref={ref}
            />
          </CardContent>
        </Card>
        <CommonDialog
          controller={dialogController}
          slot={{
            component: PolicyTemplateHeaderEdit
          }}
          slotProps={{
            component: {
              data: {
                id: 0,
                company_id: currentCompany,
                name: '',
                code: '',
                description: ''
              }
            }
          }}
          maxWidth="md"
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title(componentTitle)}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
