import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { sirePurchasesApi } from '../../api/goal/sunat/sire/sire-purchases-api';

const SirePurchasesContext = createContext();

const useSirePurchases = () => {
  return useContext(SirePurchasesContext);
};

const SirePurchasesProvider = ({ children }) => {
  const { currentCompany } = useApp();
  const mounted = useMounted();

  const [period, setPeriod] = useState(null);
  const [taxPeriods, setTaxPeriods] = useState([]);
  const [taxPeriod, setTaxPeriod] = useState(null);

  const [refreshTabs, setRefreshTabs] = useState(false);

  const triggerTabsRefresh = () => {
    setRefreshTabs((prev) => !prev); // Cambiar el estado para forzar la actualización
  };

  const [dataState, handleRefresh] = useData({
    sourceApi: sirePurchasesApi.getPeriods,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando periodos del RCE...',
    mounted,
    afterLoad: (result) => {
      if (result.data?.length > 0) {
        const newPeriod = result.data[0];
        setPeriod(newPeriod);
        setTaxPeriods(newPeriod['lisPeriodos'] ?? []);
        setTaxPeriod(newPeriod['lisPeriodos'] ? newPeriod['lisPeriodos'][0] : null);
      }
    }
  });

  const value = {
    loading: dataState.isLoading,
    periods: dataState.data ?? [],
    period: dataState.isLoading ? null : (period ?? null),
    setPeriod,
    taxPeriods: dataState.isLoading ? [] : taxPeriods,
    setTaxPeriods,
    taxPeriod: dataState.isLoading ? null : taxPeriod,
    setTaxPeriod,
    handleRefresh,
    error: dataState.displayError ? dataState.displayError : false,
    message: dataState.message ?? '',
    refreshTabs, // Nuevo estado
    triggerTabsRefresh // Nueva función
  };

  return <SirePurchasesContext.Provider value={value}>{children}</SirePurchasesContext.Provider>;
};

SirePurchasesProvider.propTypes = { children: PropTypes.node.isRequired };

export { SirePurchasesProvider, useSirePurchases };
