import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import EditIcon from '@mui/icons-material/Edit';

const EditButton = ({ onClick, startIcon = <EditIcon />, tooltip = 'Editar', ...rest }) => (
  <GoalButton
    onClick={onClick}
    startIcon={startIcon}
    tooltip={tooltip}
    iconOnly
    color="success"
    variant="outlined"
    {...rest}
  />
);

EditButton.propTypes = {
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string
};

export default EditButton;

export { EditButton };
