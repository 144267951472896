import PropTypes from 'prop-types';
import CommonStatus from './common-status';
import RecordStatusEnum from './enums/record-status-enum';

// ...existing code...

const RecordStatus = ({ status }) => {
  return <CommonStatus status={status} statusArray={RecordStatusEnum} />;
};

RecordStatus.propTypes = {
  status: PropTypes.string.isRequired
};

export default RecordStatus;
export { RecordStatus };
