import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';

import { Queues } from '../containers/managment/queues';
import { JournalReceiptPdf } from '../containers/purchases/journal/print-receip-pdft';
import { GProcDashboard } from '../containers/managment/gproc-dashboard';
import { GProcDefaults } from '../containers/managment/gproc-defaults';
import { DexReports } from '../containers/gex/dex-reports';
import { ImportContainer } from '../containers/importer/import-container';
import { UsersProvider } from '../contexts/core/users-context';
import { RolesProvider } from '../contexts/core/roles-context';
import { PermissionsProvider } from '../contexts/core/permissions-context';
import { ImportDetailContainer } from '../containers/importer/import-detail-container';

const GexReports = Loadable(
  lazy(() =>
    import('../containers/gex/gex-reports').then((module) => ({
      default: module.GexReports
    }))
  )
);

const GoalModules = Loadable(
  lazy(() =>
    import('../containers/core/goal-modules').then((module) => ({
      default: module.GoalModules
    }))
  )
);

const Users = Loadable(
  lazy(() =>
    import('../containers/core/users').then((module) => ({
      default: module.Users
    }))
  )
);

const UserTabs = Loadable(
  lazy(() =>
    import('../containers/core/user-tabs').then((module) => ({
      default: module.UserTabs
    }))
  )
);

const User = Loadable(
  lazy(() =>
    import('../containers/core/user').then((module) => ({
      default: module.User
    }))
  )
);

const UserRoles = Loadable(
  lazy(() =>
    import('../components/managment/users/user-roles').then((module) => ({
      default: module.UserRoles
    }))
  )
);
const UserPermissions = Loadable(
  lazy(() =>
    import('../components/managment/users/user-permissions').then((module) => ({
      default: module.UserPermissions
    }))
  )
);

const UserSap = Loadable(
  lazy(() =>
    import('../components/managment/users/user-sap').then((module) => ({
      default: module.UserSap
    }))
  )
);

const UserCompanies = Loadable(
  lazy(() =>
    import('../components/managment/users/user-companies').then((module) => ({
      default: module.UserCompanies
    }))
  )
);

const UserCompany = Loadable(
  lazy(() =>
    import('../components/managment/users/user-company').then((module) => ({
      default: module.UserCompany
    }))
  )
);

const UserCompanyNew = Loadable(
  lazy(() =>
    import('../components/managment/users/user-company-new').then((module) => ({
      default: module.UserCompanyNew
    }))
  )
);

const Roles = Loadable(
  lazy(() =>
    import('../containers/core/roles').then((module) => ({
      default: module.Roles
    }))
  )
);

const RoleTabs = Loadable(
  lazy(() =>
    import('../containers/core/role-tabs').then((module) => ({
      default: module.RoleTabs
    }))
  )
);

const Role = Loadable(
  lazy(() =>
    import('../containers/core/role').then((module) => ({
      default: module.Role
    }))
  )
);

const RolePermissions = Loadable(
  lazy(() =>
    import('../components/managment/roles/role-permissions').then((module) => ({
      default: module.RolePermissions
    }))
  )
);

const RoleUsers = Loadable(
  lazy(() =>
    import('../components/managment/roles/role-users').then((module) => ({
      default: module.RoleUsers
    }))
  )
);

const Permissions = Loadable(
  lazy(() =>
    import('../containers/core/permissions').then((module) => ({
      default: module.Permissions
    }))
  )
);

const PermissionTabs = Loadable(
  lazy(() =>
    import('../containers/core/permission-tabs').then((module) => ({
      default: module.PermissionTabs
    }))
  )
);

const Permission = Loadable(
  lazy(() =>
    import('../containers/core/permission').then((module) => ({
      default: module.Permission
    }))
  )
);

const PermissionRoles = Loadable(
  lazy(() =>
    import('../components/managment/permissions/permission-roles').then((module) => ({
      default: module.PermissionRoles
    }))
  )
);

const PermissionUsers = Loadable(
  lazy(() =>
    import('../components/managment/permissions/permission-users').then((module) => ({
      default: module.PermissionUsers
    }))
  )
);

const PermissionCreate = Loadable(
  lazy(() =>
    import('../components/managment/permissions/permission-create').then((module) => ({
      default: module.PermissionCreate
    }))
  )
);

export const ManagementRoutes = [
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.GCC_GPROC_MNGMT}>
        <GProcDashboard />
      </AllowedGuard>
    )
  },
  {
    path: 'config',
    element: (
      <AllowedGuard permission={GadminActions.GCC_GPROC_MNGMT}>
        <GProcDefaults />
      </AllowedGuard>
    )
  },
  {
    path: 'gex-reports',
    element: (
      <AllowedGuard permission={GadminActions.GCC_GEX_RESPORTS_LIST}>
        <GexReports />
      </AllowedGuard>
    )
  },
  {
    path: 'dex-reports',
    element: (
      <AllowedGuard permission={GadminActions.GCC_GEX_RESPORTS_LIST}>
        <DexReports />
      </AllowedGuard>
    )
  },
  {
    path: 'queues',
    element: (
      <AllowedGuard permission={GadminActions.GCC_GPROC_MNGMT}>
        <Queues />
      </AllowedGuard>
    ),
    children: [
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_GPROC_MNGMT}>
            <JournalReceiptPdf />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'import',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_TEMPLATE_LIST}>
            <ImportContainer />
          </AllowedGuard>
        )
      },
      {
        path: 'new',
        element: (
          <AllowedGuard permission={GadminActions.G2_TEMPLATE_ADD}>
            <ImportDetailContainer isNew />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_TEMPLATE_LIST}>
            <ImportDetailContainer />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'modules',
    element: (
      <AllowedGuard permission={GadminActions.G2_MODULE_LIST}>
        <GoalModules />
      </AllowedGuard>
    )
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        element: <Users />
      },
      {
        path: ':id',
        element: (
          <UsersProvider>
            <UserTabs />
          </UsersProvider>
        ),
        children: [
          {
            path: '',
            element: <User />
          },
          {
            path: 'roles',
            element: <UserRoles />
          },
          {
            path: 'permissions',
            element: <UserPermissions />
          },
          {
            path: 'sap',
            element: <UserSap />
          },
          {
            path: 'companies',
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_VIEW_A_USERS_COMPANIES}>
                    <UserCompanies />
                  </AllowedGuard>
                )
              },
              {
                path: ':userCompanyId',
                element: (
                  <AllowedGuard
                    permission={GadminActions.G2_ADMIN_USERS_VIEW_A_USER_COMPANY_DETAILS}
                  >
                    <UserCompany />
                  </AllowedGuard>
                )
              },
              {
                path: 'new',
                element: (
                  <AllowedGuard permission={GadminActions.G2_ADMIN_USERS_ADD_COMPANY_TO_A_USER}>
                    <UserCompanyNew />
                  </AllowedGuard>
                )
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'roles',
    children: [
      {
        path: '',
        element: <Roles />
      },
      {
        path: ':id',
        element: (
          <RolesProvider>
            <RoleTabs />
          </RolesProvider>
        ),
        children: [
          {
            path: '',
            element: <Role />
          },
          {
            path: 'permissions',
            element: <RolePermissions />
          },
          {
            path: 'users',
            element: <RoleUsers />
          }
        ]
      }
    ]
  },
  {
    path: 'permissions',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.GCC_GEX_RESPORTS_LIST}>
            <Permissions />
          </AllowedGuard>
        )
      },
      {
        path: 'create',
        element: <PermissionCreate />
      },
      {
        path: ':id',
        element: (
          <PermissionsProvider>
            <PermissionTabs />
          </PermissionsProvider>
        ),
        children: [
          {
            path: '',
            element: <Permission />
          },
          {
            path: 'roles',
            element: <PermissionRoles />
          },
          {
            path: 'users',
            element: <PermissionUsers />
          }
        ]
      }
    ]
  }
];
