import PropTypes from 'prop-types';
import FilterIcon from '@mui/icons-material/FilterList';
import { DialogButton } from './dialog-button';
export const FilterButton = (props) => {
  const { startIcon = <FilterIcon />, ...rest } = props;

  return <DialogButton iconOnly {...props} />;
};

FilterButton.propTypes = {
  startIcon: PropTypes.node
};
