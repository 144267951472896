import PropTypes from 'prop-types';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';

export const PropertyListItem = (props) => {
  const {
    align: alignProp = 'column',
    children,
    component,
    value,
    label = '',
    isForm = false,
    minWidth = 190,
    ...other
  } = props;
  const align =
    alignProp === 'vertical'
      ? 'column'
      : alignProp === 'horizontal'
        ? 'row'
        : alignProp === 'right'
          ? 'row'
          : alignProp === 'end'
            ? 'row'
            : alignProp;
  const isRight = alignProp === 'right' || alignProp === 'end';
  return (
    <ListItem
      component={component}
      disableGutters
      sx={{
        px: 3,
        py: 1.5,
        display: 'flex',
        flexDirection: alignProp,
        alignItems: 'start',
        justifyContent: isRight ? 'space-between' : 'flex-start'
      }}
      {...other}
    >
      {isForm ? (
        <Box sx={{ minWidth: 250 }}>{children}</Box>
      ) : (
        <>
          <ListItemText
            disableTypography
            primary={label}
            sx={{
              flex: isRight ? '0 1 auto' : '1 1 auto',
              textAlign: 'left',
              mr: isRight ? 0 : 3.5,
              ml: isRight ? 3.5 : 0
            }}
          />
          <Box
            sx={{
              flex: '1 1 auto',
              textAlign: isRight ? 'right' : 'left'
            }}
          >
            {children || (
              <Typography
                color="textSecondary"
                variant="body2"
                align={isRight ? 'right' : 'inherit'}
              >
                {value}
              </Typography>
            )}
          </Box>
        </>
      )}
    </ListItem>
  );
};

PropertyListItem.propTypes = {
  align: PropTypes.oneOf(['vertical', 'horizontal', 'right', 'column', 'row', 'end']),
  component: PropTypes.elementType,
  children: PropTypes.node,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isForm: PropTypes.bool,
  minWidth: PropTypes.number
};
