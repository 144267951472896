import { dataLoader } from '../../../data-loader';

const endpointLocal = 'accounting/sunat/sire-purchases';

class SirePurchasesApi {
  async getPeriods({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/periods`,
      params: { companyId }
    });
    return response;
  }

  async synchronizeCpSummary({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/sync-cp-summary`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async synchronizeProposal({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/sync-proposal`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async listProposal({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-proposal`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async listPle({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-ple`,
      params: { companyId, perTributario }
    });
    return response;
  }
  async listDifferences({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-differences`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async listRceForReplace({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-rec-for-replace`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async listProposalExcluded({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-proposal-excluded`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async synchronizeInconsistencies({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/sync-inconsistencies`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async rceProposalSummary({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/cp-summary-rce-proposal`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async rceCpSummaryExcluded({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/cp-summary-excluded`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async rceCpPreliminaryRceSummary({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/cp-summary-preliminary-rce`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async rceSummaryGenerated({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/cp-summary-generated`,
      params: { companyId, perTributario }
    });
    return response;
  }

  // Inconsistencies
  async listInconsistenciesForTotalAmounts({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/inconsistencies-total-amounts`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async listInconsistenciesForPaymentReceipts({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/inconsistencies-payment-receipts`,
      params: { companyId, perTributario }
    });
    return response;
  }
}

export const sirePurchasesApi = new SirePurchasesApi();
