import PropTypes from 'prop-types';

import { FormControl, Card, CardContent, CardActions } from '@mui/material';
import { useData } from '../../../hooks/use-data';
import { useEffect, useState } from 'react';
import LoadingContent from '../loading-content';
import { AutocompleteField } from '../../autocomplete-field';
import { ConfirmButton } from './confirm-button';

const AttributeSelectorContainer = (props) => {
  const {
    sourceApi,
    apiParameters,
    loadingMessage = 'Cargar cuentas registros...',
    label = 'Cuenta de gasto',
    onSelect,
    onAfterSave,
    getOptionDisabled,
    groupBy
  } = props;

  const [list, setList] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleAfterLoad = ({ success, data }) => {
    if (success) {
      setList(data);
    }
  };

  const [attributeListState, handleRefresh] = useData({
    sourceApi,
    apiParameter: apiParameters,
    loadingMessage,
    afterLoad: handleAfterLoad,
    defaults: {
      noAutoLoad: true
    }
  });

  const handleSelectChange = (value) => {
    // setSelectedAccount(event.target.value);
    setSelectedItem(value);
  };

  const handleSubmit = async () => {
    const result = (await onSelect(selectedItem)) || { success: true };
    if (result.success) {
      onAfterSave?.();
    }
  };

  useEffect(() => {
    if (!attributeListState.isLoading) {
      handleRefresh();
    }
  }, []);

  if (attributeListState.error || attributeListState.isLoading || !attributeListState.data) {
    return (
      <LoadingContent
        loadingText={attributeListState.message}
        error={attributeListState.error}
        state={attributeListState}
      />
    );
  }

  return (
    <Card>
      <CardContent>
        <FormControl fullWidth>
          <AutocompleteField
            label={label}
            name={label}
            placeholder="Seleccione un elemento"
            fullWidth
            options={list || [{ name: 'Cargando', code: 'Cargando' }]}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option) => option.name || ''}
            onChange={(e, value) => {
              handleSelectChange(value);
            }}
            value={selectedItem}
            sx={{ marginBottom: '1em' }}
            getOptionDisabled={getOptionDisabled}
            groupBy={groupBy}
          />
        </FormControl>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <ConfirmButton requireConfirmation onClick={handleSubmit} title="Confirmar seleccionado" />
      </CardActions>
    </Card>
  );
};
AttributeSelectorContainer.propTypes = {
  sourceApi: PropTypes.func,
  apiParameters: PropTypes.object,
  loadingMessage: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  groupBy: PropTypes.func
};
export default AttributeSelectorContainer;
