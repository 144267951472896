import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import GadminActions from '../../../contexts/gadmin-actions';
import { useApp } from '../../../hooks/use-app';
import { useTabs } from '../../../hooks/use-tabs';

const defaultTabs = [
  {
    href: '',
    label: 'Resumen',
    permission: GadminActions.gCC_INV_ITEM
  },
  {
    href: '/details',
    label: 'Productos',
    permission: GadminActions.gCC_INV_ITEM
  },
  {
    href: '/costs',
    label: 'Costos',
    permission: GadminActions.gCC_INV_ITEM
  },
  {
    href: '/profits',
    label: 'Ganancias',
    permission: GadminActions.gCC_INV_ITEM
  }
];

export const PriceListTabs = () => {
  const app = useApp();
  const { id } = useParams();
  const { appUrlBase } = useApp();

  const { renderTabs } = useTabs({
    basePath: `${appUrlBase}/inventory/price-lists/${id}`,
    backPath: { path: `${appUrlBase}/inventory/price-lists`, label: 'Listas de precios' },
    tabs: defaultTabs
  });

  return (
    <>
      <Helmet>
        <title>{app.title('Price List')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderTabs()}
        </Container>
      </Box>
    </>
  );
};
