export const PowerBIResourceTypesEnum = {
  WORKSPACE: 'workspace',
  DATASET: 'dataset',
  REPORT: 'r',
  DASHBOARD: 'dashboard',
  DATAFLOW: 'dataflow',
  TABLE: 'table'
};

export const PowerBIResourceTypesEnumList = [
  PowerBIResourceTypesEnum.WORKSPACE,
  PowerBIResourceTypesEnum.DATASET,
  PowerBIResourceTypesEnum.REPORT,
  PowerBIResourceTypesEnum.DASHBOARD,
  PowerBIResourceTypesEnum.DATAFLOW,
  PowerBIResourceTypesEnum.TABLE
];
