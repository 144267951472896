import { AllowedGuard } from '../hooks/use-allowed';
import GadminActions from '../contexts/gadmin-actions';
import { lazy } from 'react';
import { Loadable } from './loadable';

const GoalServersContainer = Loadable(
  lazy(() =>
    import('../containers/automations/goal-servers/goal-servers').then((module) => ({
      default: module.GoalServersContainer
    }))
  )
);

const BulksContainer = Loadable(
  lazy(() =>
    import('../containers/automations/bulks/bulks-container').then((module) => ({
      default: module.BulksContainer
    }))
  )
);

const BulkDetailContainer = Loadable(
  lazy(() =>
    import('../containers/automations/bulks/bulk-detail-container').then((module) => ({
      default: module.BulkDetailContainer
    }))
  )
);

const BulkTab = Loadable(
  lazy(() =>
    import('../containers/automations/bulks/bulk-tab').then((module) => ({
      default: module.BulkTab
    }))
  )
);

const BulkQueues = Loadable(
  lazy(() =>
    import('../containers/automations/bulks/bulk-queues').then((module) => ({
      default: module.BulkQueues
    }))
  )
);

const QueuesContainer = Loadable(
  lazy(() =>
    import('../containers/automations/queues/queues-container').then((module) => ({
      default: module.QueuesContainer
    }))
  )
);

const QueueDetailContainer = Loadable(
  lazy(() =>
    import('../containers/automations/queues/queue-detail-container').then((module) => ({
      default: module.QueueDetailContainer
    }))
  )
);

const SyncObjectsContainer = Loadable(
  lazy(() =>
    import('../containers/automations/sync-objects/sync-objects-container').then((module) => ({
      default: module.SyncObjectsContainer
    }))
  )
);

const SyncObjectNew = Loadable(
  lazy(() =>
    import('../containers/automations/sync-objects/sync-object-new').then((module) => ({
      default: module.SyncObjectNew
    }))
  )
);

const SyncObjectTabs = Loadable(
  lazy(() =>
    import('../containers/automations/sync-objects/sync-object-tabs').then((module) => ({
      default: module.SyncObjectTabs
    }))
  )
);

const SyncObject = Loadable(
  lazy(() =>
    import('../containers/automations/sync-objects/sync-object').then((module) => ({
      default: module.SyncObject
    }))
  )
);

import { BulkProvider } from '../contexts/automations/bulk-context';
import { SyncObjectProvider } from '../contexts/automations/sync-object-context';

export const AutomationsRoutes = [
  {
    path: 'bulks',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <BulksContainer />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <BulkProvider>
              <BulkTab />
            </BulkProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <BulkDetailContainer />
              </AllowedGuard>
            )
          },
          {
            path: 'queues',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <BulkQueues />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'queues',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUEUE_LIST}>
            <QueuesContainer />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.G2_QUEUE_LIST}>
                <QueueDetailContainer />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  // goal servers
  {
    path: 'goal-servers',
    children: [
      {
        path: '',
        element: <GoalServersContainer />
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            element: <div />
            // element: <GoalServerDetailContainer />
          }
        ]
      }
    ],
    errorElement: <div />
  },
  {
    path: 'sync-objects',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <SyncObjectsContainer />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <SyncObjectProvider>
              <SyncObjectTabs />
            </SyncObjectProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SyncObject />
              </AllowedGuard>
            )
          }
        ]
      },
      {
        path: 'new',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <SyncObjectNew />
          </AllowedGuard>
        )
      }
    ]
  }
];
