import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import moment from 'moment';

export const GoalDateView = ({ value, format = 'DD/MM/YYYY' }) => {
  // if value is null, return a dash
  if (!value) {
    return <Typography>-</Typography>;
  }
  // if value is date
  if (value instanceof Date) {
    return moment(value).format(format);
  }

  const dateString = moment(value).format(format || 'DD/MM/YYYY');
  return dateString;
};

export default GoalDateView;

GoalDateView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  format: PropTypes.string
};
