import { dataLoader } from '../../data-loader';

export const listExpenseUnits = async ({ companyId }) => {
  return await dataLoader.goalGet({
    action: '/integrations/rindegasto/expense-units',
    params: { companyId }
  });
};

// syncExpenseUnit
export const syncExpenseUnit = async ({ companyId, id = null }) => {
  return await dataLoader.goalGet({
    action: '/integrations/rindegasto/expense-units/sync',
    params: { companyId, id }
  });
};

// list available templates
export const listAvailableTemplates = async ({ companyId, id, templateId }) => {
  return await dataLoader.goalGet({
    action: `/integrations/rindegasto/expense-units/list-available-templates`,
    params: { companyId, id, templateId }
  });
};
