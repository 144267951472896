import { useCallback } from 'react';
const defaultOptions = {
  keyCode: 'code',
  keyLabel: 'name'
};
const useFormikMultiSelect = (formik, optionProps = {}) => {
  const options = { ...defaultOptions, ...optionProps };
  const keyCode = options.keyCode || 'code';
  const keyLabel = options.keyLabel || 'name';
  const useId = keyCode === 'id';

  const handleMultiSelectChange = useCallback(
    (event, item, changeData, valid = false, dataApi) => {
      const { value: selectedValues } = event.target;

      const hasAllOption = selectedValues.some((option) => {
        if (useId) {
          return option.id === 0;
        }
        return option.code === 'ALL';
      });

      if (hasAllOption && selectedValues.length === 1) {
        const updatedData = dataApi.filter((option) => option[keyCode] !== 'ALL');
        formik.setFieldValue(item, updatedData);
        if (valid) {
          const updatedSelected = [];
          changeData(updatedSelected);
        }
      } else {
        const filteredSelectedValues = selectedValues.filter((option) => option[keyCode] !== 'ALL');

        const data = filteredSelectedValues.map((option) => ({
          [keyCode]: option[keyCode],
          [keyLabel]: option[keyLabel]
        }));
        formik.setFieldValue(item, data);
        if (valid) {
          const updatedSelected = dataApi.filter(
            (selectedValue) => !data.some((item) => item[keyCode] === selectedValue[keyCode])
          );
          changeData(updatedSelected);
        }
      }
    },
    [formik, keyCode]
  );

  const getOptionsName = useCallback(
    (item, options) => {
      const optionChip = options.find((option) => option[keyCode] === item);
      return optionChip ? optionChip[keyLabel] : 'sin nombre';
    },
    [keyCode]
  );

  const handleOptionsDelete = useCallback(
    (value, item, option, changeData, valid = false, data) => {
      const updatedSelected = option.filter((selectedValue) => selectedValue !== value);
      formik.setFieldValue(item, updatedSelected);

      if (valid) {
        const arrayActualizado = [value, ...data];
        changeData(arrayActualizado);
      }
    },
    [formik]
  );

  return { handleMultiSelectChange, getOptionsName, handleOptionsDelete };
};

export default useFormikMultiSelect;
