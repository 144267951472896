import { ConfirmationMenuItem } from '../../common/confirmation-menu-item';
import { GoalMenu } from '../../common/goal-menu';
import ArticleIcon from '@mui/icons-material/Article';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UploadIcon from '@mui/icons-material/Upload';
import { DeliveryPlanningForm } from './delivery-planning-form';
import { GoalMenuDialog } from '../../menu/goal-menu-dialog';
import { useDeliveryPlanning } from './delivery-planing-context';
import CommonRecordEditor from '../../common/buttons/record-editor-container';
import { goalCustomeDeleveryPlanningApi } from '../../../api/goal/planning/goal-customer-delivery-planning';
import useProcesor from '../../../hooks/use-procesor';

export const DeliveryPlaningMenu = () => {
  const { currentProfile, onAfterSave, pendingDeliveryParams } = useDeliveryPlanning();

  const { procesor: handleGenerateRouteSheets } = useProcesor({
    handler: goalCustomeDeleveryPlanningApi.generateRouteSheets
  });
  const { procesor: handleResetOrders } = useProcesor({
    handler: goalCustomeDeleveryPlanningApi.resetOrders
  });
  if (!currentProfile) return null;
  return (
    <GoalMenu>
      <CommonRecordEditor
        onAfterSave={onAfterSave}
        slot={{
          content: DeliveryPlanningForm,
          component: GoalMenuDialog
        }}
        slotProps={{
          content: {
            subheader: 'Seleccione las zonas para cargar pedidos',
            //   onClose: onClose,
            //   handleRefresh: handleRefresh,
            account: currentProfile.account
          },
          component: {
            label: 'Cargo pedidos'
          },
          dialog: {
            title: 'Cargo pedidos',
            // showActions: false,
            maxWidth: 'sm'
          }
        }}
        startIcon={<UploadIcon />}
      />
      <ConfirmationMenuItem
        startIcon={<ArticleIcon />}
        helpText={'Generar hojas de reparto'}
        onConfirm={() => handleGenerateRouteSheets(pendingDeliveryParams)}
        label={'Generar HRs'}
        confirmationMessage={'¿Está seguro que desea generar las hojas de reparto?'}
      />
      <ConfirmationMenuItem
        startIcon={<RestartAltIcon />}
        helpText={
          'Al restablecer la programación se eliminarán los pedidos cargadoe en la plataforma y estarán disponibles para una nueva carga'
        }
        onConfirm={() => handleResetOrders(pendingDeliveryParams)}
        label={'Restablecer'}
        confirmationMessage={
          'Los pedidos cargados serán eliminados  de la plataforma, luego deberá cargarlos nuevamente'
        }
      />
    </GoalMenu>
  );
};
