import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import GadminActions from './contexts/gadmin-actions';
import { AllowedGuard } from './hooks/use-allowed';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Authorization from './pages/Authorization';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import NotFound from './pages/Page404';
import Unauthorized from './pages/Page401';
import PageLog from './pages/utils/PageLog';
import Search from './pages/Search';
import { CompanyLayout } from './layouts/dashboard/CompanyLayout';
import { useApp } from './hooks/use-app';
import { GoalImporter } from './containers/importer/goal-importer';

const ResourceFileLogs = Loadable(
  lazy(() =>
    import('./containers/common/resource-file-logs').then((module) => ({
      default: module.ResourceFileLogs
    }))
  )
);
const GadminLogs = Loadable(
  lazy(() =>
    import('./containers/common/gadmin-logs').then((module) => ({
      default: module.GadminLogs
    }))
  )
);

const WhatsappMessage = Loadable(
  lazy(() =>
    import('./containers/miscellaneous/whatsapp-message').then((module) => ({
      default: module.WhatsappMessage
    }))
  )
);

const PicShare = Loadable(
  lazy(() =>
    import('./containers/pic-share/share').then((module) => ({
      default: module.PicShare
    }))
  )
);

import { PurchaseRoutes } from './routes/purchase-routes';
import { InventoryRoutes } from './routes/inventory-routes';
import { Loadable } from './routes/loadable';
import { SalesRoutes } from './routes/sales-routes';
import { BusinessPartnersRoutes } from './routes/business-partners-routes';
import { DeliveryRoutes } from './routes/delivery-routes';
import { ManagementRoutes } from './routes/management-routes';
import { AccountingRoutes } from './routes/accounting-routes';
import { FinanceRoutes } from './routes/finance-routes';
import { SurveyRoutes } from './routes/survey-routes';
import { PromoRoutes } from './routes/promo-routes';
import { CommercialRoutes } from './routes/commercial-routes';
import { MasterDataRoutes } from './routes/master-data-routes';
import { IncidentRoutes } from './routes/incidents-routes';
import { AutomationsRoutes } from './routes/automations-routes';
import { OperationsRoutes } from './routes/operations-routes';
import { JournalRoutes } from './routes/journal-routes';

export default function Router() {
  const { appUrlBase } = useApp();
  return useRoutes([
    {
      path: '',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'app',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: ':company',
              element: <CompanyLayout />,
              children: [
                { path: '', element: <Navigate to={`${appUrlBase}/dashboard`} replace /> },
                { path: 'dashboard', element: <DashboardApp /> },
                { path: 'search', element: <Search /> },
                {
                  path: 'gadmin-logs',
                  element: (
                    <AllowedGuard permission={GadminActions.gCC_PROC_LOG}>
                      <GadminLogs />
                    </AllowedGuard>
                  )
                },
                // purchase routes
                {
                  path: 'purchases',
                  children: PurchaseRoutes
                },
                {
                  path: 'promo',
                  children: PromoRoutes
                },
                {
                  path: 'journal',
                  children: JournalRoutes
                },
                {
                  path: 'inventory',
                  children: InventoryRoutes
                },
                {
                  path: 'busines-partners',
                  children: BusinessPartnersRoutes
                },
                {
                  path: 'delivery',
                  children: DeliveryRoutes
                },
                {
                  path: 'sales',
                  children: SalesRoutes
                },
                {
                  path: 'managment',
                  children: ManagementRoutes
                },
                {
                  path: 'accounting',
                  children: AccountingRoutes
                },
                {
                  path: 'finance',
                  children: FinanceRoutes
                },
                {
                  path: 'logs',
                  children: [
                    {
                      path: 'document-uploads',
                      element: (
                        <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_SERVICE_UP}>
                          <ResourceFileLogs />
                        </AllowedGuard>
                      )
                    },
                    {
                      path: 'gadmin-logs',
                      element: (
                        <AllowedGuard permission={GadminActions.gCC_PROC_LOG}>
                          <GadminLogs />
                        </AllowedGuard>
                      )
                    }
                  ]
                },
                {
                  path: 'survey',
                  children: SurveyRoutes
                },

                {
                  path: 'commercial',
                  children: CommercialRoutes
                },

                {
                  path: 'master-data',
                  children: MasterDataRoutes
                },

                {
                  path: 'incidents',
                  children: IncidentRoutes
                },

                {
                  path: 'automations',
                  children: AutomationsRoutes
                },
                {
                  path: 'inventory',
                  children: []
                },

                {
                  path: 'operations',
                  children: OperationsRoutes
                },

                { path: 'products', element: <Products /> },
                { path: 'import', element: <GoalImporter /> },
                { path: 'whatsapp', element: <WhatsappMessage /> },
                { path: 'pic-share', element: <PicShare /> },
                { path: 'log', element: <PageLog /> }
              ] // end company children
            },
            { path: 'whatsapp', element: <WhatsappMessage /> },
            { path: 'import', element: <GoalImporter /> },

            { path: 'log', element: <PageLog /> },
            {
              path: 'logs',
              children: [
                {
                  path: 'document-uploads',
                  element: (
                    <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_SERVICE_UP}>
                      <ResourceFileLogs />
                    </AllowedGuard>
                  )
                },
                {
                  path: 'gadmin-logs',
                  element: (
                    <AllowedGuard permission={GadminActions.gCC_PROC_LOG}>
                      <GadminLogs />
                    </AllowedGuard>
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'authorization',
          element: (
            <GuestGuard>
              <Authorization />
            </GuestGuard>
          )
        },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '401', element: <Unauthorized /> },
        // { path: 'purchase', element: <PageLog /> },
        { path: '', element: <Navigate to={`${appUrlBase}/dashboard`} /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
