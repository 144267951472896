import { Card, CardContent, CardHeader, Divider, Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../../../../components/common/custom-tab-panel';
import { useParams } from 'react-router-dom';
import BackButton from '../../../../components/common/buttons/back-button';
import { RefreshButton, SyncButton } from '../../../../components/common/buttons';
import { getSeller } from '../../../../api/goal/sales/master-data/sellers-api';
import { useMounted } from '../../../../hooks/use-mounted';
import { useEffect, useState } from 'react';
import { useData } from '../../../../hooks/use-data';
import { useApp } from '../../../../hooks/use-app';
import { SellerInformation } from './seller-details/seller-information';
const parentRoute = `/commercial/master-data/sellers`;

export const SalesSeller = () => {
  const { id } = useParams();
  const { currentCompany, showError, showNotify } = useApp();

  const [data, setData] = useState({});
  const mounted = useMounted();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSync = async () => {
    console.log('syncing seller');
  };

  const [documentState, handleRefresh] = useData({
    sourceApi: getSeller,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: 'Cargando detalle del vendedor...',
    mounted,
    defaults: {
      noAutoLoad: true
    },
    afterLoad: (result) => {
      if (result.success) {
        setData(result.data);
      } else {
        showError(result.message);
      }
    }
  });

  useEffect(() => {
    handleRefresh();
  }, [id]);
  return (
    <Card sx={{ padding: 2, backgroundColor: 'background.default' }}>
      <CardHeader
        subheader="Detalle del vendedor"
        title={data.name}
        avatar={<BackButton to={parentRoute} />}
        action={
          <>
            <SyncButton onClick={handleSync} />
            <RefreshButton onClick={handleRefresh} />
          </>
        }
      />

      <CardContent>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="info tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Información General" />
          <Tab label="Fuerza de ventas" />
          <Tab label="Rutas" />
          <Tab label="Clientes" />
          <Tab label="Pedidos" />
          <Tab label="No Pedidos" />
        </Tabs>
        <Divider sx={{ marginBottom: 2 }} />
        <CustomTabPanel value={value} index={0}>
          <SellerInformation data={data} onRefresh={handleRefresh} />
        </CustomTabPanel>
        {/*
        <CustomTabPanel value={value} index={1}>
          <CurrencyTab data={data} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <DistanceExpensesTab data={data} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <TaxesTab data={data} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <ExpenseExtraFieldsTab data={data} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <ReportExtraFieldsTab data={data} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <AccountTab data={data} onRefresh={handleRefresh} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <UsersTab data={data} />
        </CustomTabPanel> */}
      </CardContent>
    </Card>
  );
};
