import { useEffect, useState } from 'react';

export const useSelection = (sourceRows) => {
  const [rows, setRows] = useState(sourceRows || []);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelect = (event, rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (event.target.checked) {
        return [...prevSelectedRows, rowId];
      }

      return prevSelectedRows.filter((selectedRow) => selectedRow !== rowId);
    });
  };

  const handleClearSelected = () => {
    if (selectedRows.length > 0) setSelectedRows([]);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(rows.map((row) => row.id));
      return;
    }

    handleClearSelected();
  };

  useEffect(() => {
    setRows(sourceRows);
    handleClearSelected();
  }, [sourceRows]);

  const handleSetRows = (newRows) => {
    setRows(newRows);
    handleClearSelected();
  };

  return [
    selectedRows,
    handleSelect,
    handleSelectAll,
    handleClearSelected,
    {
      setRows: handleSetRows
    }
  ];
};
