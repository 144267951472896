import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import UserReducer, { InitialtUser } from './user-reducer';

const UserContext = createContext();
const UserProvider = ({ children }) => {
  const [userState, userDispacher] = useReducer(UserReducer, InitialtUser);

  const { userInfo, permissions } = userState;

  const hasAccess = (requiredPermission) => {
    return permissions.includes(requiredPermission);
  };

  const savePreferences = (preferences) => {
    localStorage.setItem('userPreferences', JSON.stringify(preferences));
  };

  const getPreferences = () => {
    const preferences = localStorage.getItem('userPreferences');
    return preferences ? JSON.parse(preferences) : {};
  };

  const updatePreference = (key, value) => {
    const preferences = getPreferences();
    preferences[key] = value;
    savePreferences(preferences);
  };

  const getPreference = (key, defaultValue) => {
    const preferences = getPreferences();
    return preferences[key] !== undefined ? preferences[key] : defaultValue;
  };

  return (
    <UserContext.Provider
      value={{
        userState,
        userDispacher,
        userInfo,
        hasAccess,
        savePreferences,
        getPreferences,
        updatePreference,
        getPreference
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = { children: PropTypes.node.isRequired };

const WithUser = (Child) => (props) => (
  <UserContext.Consumer>
    {(context, i) => <Child key={i} {...props} userContext={context} />}
  </UserContext.Consumer>
);

export { UserProvider, WithUser, UserContext };
