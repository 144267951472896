/* eslint class-methods-use-this:0 */

import { dataLoader } from '../../../data-loader';

class AttachmentTemplatesApi {
  async getAttachmentTemplates(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `assets/attachment-templates`,
      cacheItem: 'attachment-templates',
      apiParams: {
        companyId,
        type: 'list-attachment-templates',
        view,
        payload: { filters, sort, sortBy, page, query, reload, view }
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'code' },
        { type: 'string', name: 'name' }
      ],
      ...options
    });
  }

  async getSimpleAttachmentTemplates({ companyId }) {
    const response = await dataLoader.goalGet({
      // action: `assets/attachment-templates/simple-list`,
      action: `assets/attachment-templates`,
      params: { companyId }
    });
    return response;
  }

  async getAttachmentTemplate({ id, companyId, view }) {
    const viewParam = view ? `&view=${view}` : ``;
    const response = await dataLoader.loadGoalGetData({
      action: `assets/attachment-templates/${id}?companyId=${companyId}${viewParam}`
    });
    return response;
  }

  async createAttachmentTemplate({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/assets/attachment-templates',
      changes: values
    });
    return response;
  }

  async updateAttachmentTemplate({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/assets/attachment-templates/${id}`,
      changes
    });
    return response;
  }

  async deleteAttachmentTemplate({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/assets/attachment-templates/${id}?companyId=${companyId}`
    });
    return response;
  }

  async restoreAttachmentTemplate({ id, companyId }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/assets/attachment-templates/${id}/restore`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async getAttachmentTemplateAutocomplete({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `assets/attachment-templates/autocomplete?companyId=${companyId}`
    });
    return response;
  }

  async getGroupsOfAttachmentTemplate({ id, companyId, view }) {
    const viewParam = view ? `&view=${view}` : ``;
    const response = await dataLoader.loadGoalGetData({
      action: `assets/attachment-templates/${id}/groups?companyId=${companyId}${viewParam}`
    });
    return response;
  }

  async getLinesOfAttachmentTemplate({ id, companyId, view }) {
    const viewParam = view ? `&view=${view}` : ``;
    const response = await dataLoader.loadGoalGetData({
      action: `assets/attachment-templates/${id}/lines?companyId=${companyId}${viewParam}`
    });
    return response;
  }
}

export const attachmentTemplatesApi = new AttachmentTemplatesApi();
