export const equal = (value1, value2) => {
  if (!value2) {
    return true;
  }

  if (value1) {
    // Here we evaluate == instead of === because values can be number & string pair
    // eslint-disable-next-line eqeqeq
    return value1 == value2;
  }

  return false;
};

export const greaterThan = (value1, value2) => value1 > value2;

export const lessThan = (value1, value2) => value1 < value2;

export const isAfter = (value1, value2) => {
  if (value1 && value2) {
    return value1.getTime() > new Date(value2).getTime();
  }

  return false;
};

export const isBefore = (value1, value2) => {
  if (value1 && value2) {
    return value1.getTime() < new Date(value2).getTime();
  }

  return false;
};

export const isBlank = (value) => !value;

export const isPresent = (value) => !!value;

export const notEqual = (value1, value2) => {
  if (!value2) {
    return true;
  }

  if (value1) {
    return value1 !== value2;
  }

  return false;
};

export const contains = (value1, value2) => {
  if (!value2) {
    return true;
  }

  if (value1) {
    return value1.toLowerCase().includes(value2.toLowerCase());
  }

  return false;
};

export const notContains = (value1, value2) => {
  if (!value2) {
    return true;
  }

  if (value1) {
    return !value1.includes(value2);
  }

  return false;
};

export const startsWith = (value1, value2) => {
  if (!value2) {
    return true;
  }

  if (value1) {
    return value1.substring(0, value2.length) === value2;
  }

  return false;
};

export const endsWith = (value1, value2) => {
  if (!value2) {
    return true;
  }

  if (value1) {
    return value1.substring(value1.length - value2.length) === value2;
  }

  return false;
};

export const isNumber = (value) => !isNaN(value);

export const isNumeric = (value) => !isNaN(parseFloat(value)) && !isNaN(value - 0);

export const comparatorsEnum = {
  EQUAL: 'equal',
  GREATER_THAN: 'greaterThan',
  LESS_THAN: 'lessThan',
  IS_AFTER: 'isAfter',
  IS_BEFORE: 'isBefore',
  IS_BLANK: 'isBlank',
  IS_PRESENT: 'isPresent',
  NOT_EQUAL: 'notEqual',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'notContains',
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith'
};
