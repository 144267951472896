import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useRef } from 'react';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export default function InputFileUpload({ multiple = false, activated = false, onChange, accept }) {
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (activated && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [activated]);

  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      Seleccionar
      <VisuallyHiddenInput
        type="file"
        onChange={onChange}
        multiple={multiple}
        ref={fileInputRef}
        accept={accept}
      />
    </Button>
  );
}

InputFileUpload.propTypes = {
  multiple: PropTypes.bool,
  activated: PropTypes.bool,
  onChange: PropTypes.func,
  accept: PropTypes.string
};
