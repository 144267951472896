import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Grid2, Typography } from '@mui/material';
import { DeleteButton } from '../../../components/common/buttons/delete-button';
import { LoadingContent } from '../../../components/common/loading-content';
import CommonAttributeSelector from '../../../components/common/buttons/common-attribute-selector';
import {
  addAccountingAccount,
  listAccountingAccounts,
  removeAccountingAccount
} from '../../../api/goal/rindegastos/rindegasto-policy-template-api';
import { useApp } from '../../../hooks/use-app';
import { showNotify } from '../../../hooks/toasty';
import { useEffect, useState } from 'react';
import { AddButton } from '../../../components/common/buttons/add-button';

const AccountTab = ({ data, onRefresh }) => {
  const { currentCompany, showError } = useApp();

  const [accountingAccounts, setAccountingAccounts] = useState([]);

  const handleDelete = async (selected) => {
    const account = selected.code;
    const result = await removeAccountingAccount({
      companyId: currentCompany,
      id: data.id,
      expenseAccountId: account
    });
    if (result.success) {
      showNotify(`Cuenta eliminada, ${account}`);
      onRefresh?.();
    } else {
      showError(result.message || 'Error al eliminar la cuenta');
    }
    return result;
  };

  const handleAdd = async (selectedItem) => {
    // fin in data.categories if category exist
    const Selected = {
      code: selectedItem.code,
      name: selectedItem.name
    };

    if (data.categories && data.categories?.length) {
      const existsCategory = accountingAccounts.includes(selectedItem.code);
      if (existsCategory) {
        showError('La cuenta ya existe');
        return { success: false };
      }
    }

    const result = await addAccountingAccount({
      companyId: currentCompany,
      id: data.id,
      values: selectedItem
    });
    if (result.success) {
      data.categories.push(Selected);
    } else {
      showError(result.message || 'Error al agregar la cuenta');
    }
    return result;
  };
  useEffect(() => {
    setAccountingAccounts(data.categories?.map((category) => category.code));
  }, [data.categories]);

  if (!data.categories) {
    return <LoadingContent loadingText={'No se encontraron cuentas'} />;
  }

  return (
    <Card>
      <CardHeader
        action={
          <CommonAttributeSelector
            onAfterSave={onRefresh}
            slot={{
              button: AddButton
            }}
            slotProps={{
              content: {
                sourceApi: listAccountingAccounts,
                apiParameters: { companyId: currentCompany },
                loadingMessage: 'Cargando...',
                label: 'Seleccionar',
                onSelect: handleAdd,
                getOptionDisabled: (option) => accountingAccounts?.includes(option.code)
              }
            }}
          />
        }
      />
      <CardContent>
        <Grid2 container spacing={2}>
          {data.categories?.map((category) => (
            <Grid2
              size={{
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3
              }}
              key={category.code}
            >
              <Card sx={{ padding: 2 }}>
                <CardHeader
                  title={category.code}
                  subheader={category.name}
                  action={<DeleteButton onConfirm={async () => await handleDelete(category)} />}
                />

                {category.group && (
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {category.group?.code || 'Sin código'}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {category.group?.name}
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </CardContent>
    </Card>
  );
};

AccountTab.propTypes = {
  data: PropTypes.object.isRequired,
  onRefresh: PropTypes.func
};

export default AccountTab;
