// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { useAuth } from './hooks/use-auth';
import UserPreferencesEnum from './contexts/logged-user/user-preferences-enum';
import { RenderIf } from './components/render-if';
import Snowfall from 'react-snowfall';
import useLoggedUser from './contexts/logged-user/use-logged-user';

// ----------------------------------------------------------------------

export default function App() {
  const { keycloakInitialized: initialized } = useAuth();
  const content = Router();

  const { getPreference } = useLoggedUser();

  const hideEmoji = getPreference(UserPreferencesEnum.HIDE_EMOJI, false);

  return (
    <>
      <RenderIf condition={!hideEmoji && new Date().getMonth() === 11}>
        <Snowfall />
      </RenderIf>
      <ThemeConfig>
        <ScrollToTop />
        {initialized && content}
      </ThemeConfig>
    </>
  );
}
