import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Box, Checkbox, FormControlLabel, Grid2 } from '@mui/material';
import moment from 'moment';
import * as Yup from 'yup';
import { useApp } from '../../../hooks/use-app';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import { goalCustomeDeleveryPlanningApi } from '../../../api/goal/planning/goal-customer-delivery-planning';
import useFormikMultiSelect from '../../../hooks/use-formik-multiselect';
import MultiSelectField from '../../common/multi-select-field';
import { CommonDatePicker } from '../../common/common-date-picker';
import { GMFormContainer, useGMForm } from '../../gm/form/gm-form-context';
import { useDeliveryPlanning } from './delivery-planing-context';

const FormContent = ({ account, currentCompany }) => {
  const [zonePlanning, setZonePlanning] = useState([]);
  const [zonePlanningData, setZonePlanningData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { formik } = useGMForm();
  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const [zonePlanning] = await Promise.all([
      goalCustomeDeleveryPlanningApi.getZonePlanning({ account })
    ]);

    if (zonePlanning.success && zonePlanning.data.length !== 0) {
      setZonePlanning(zonePlanning.data);
      setZonePlanningData(zonePlanning.data);
    }
  };

  const { handleMultiSelectChange, getOptionsName, handleOptionsDelete } =
    useFormikMultiSelect(formik);

  const handleSelectAllChange = (e) => {
    const allData = zonePlanning.map((item) => item);
    const selectedOptions = e.target.checked ? allData : [];
    formik.setFieldValue('zones', selectedOptions);
    setSelectAll(e.target.checked);
  };
  const handleDateChange = ({ field, value }) => {
    formik.setFieldValue(field, value);
  };

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <CompanyInputDisabled currentCompany={currentCompany} />
        </Grid2>
        <Grid2 size={12}>
          <CommonDatePicker
            formik={formik}
            value={formik.values.planificationDate}
            onAccept={(value) => handleDateChange({ field: 'planificationDate', value })}
            name="planificationDate"
            label="Fecha de planificación"
            disabled={true}
            minValue={moment().add(-10, 'day').toDate()}
            maxValue={moment().add(3, 'day').toDate()}
          />
        </Grid2>{' '}
        <Grid2 size={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            <CommonDatePicker
              formik={formik}
              value={formik.values.dateFrom}
              onAccept={(value) => handleDateChange({ field: 'dateFrom', value })}
              name="dateFrom"
              label="Pedidos desde"
              minValue={moment().add(-10, 'day').toDate()}
              maxValue={moment().add(3, 'day').toDate()}
              disabled
            />
            <Box sx={{ marginLeft: 2 }} />
            <CommonDatePicker
              formik={formik}
              value={formik.values.dateTo}
              onAccept={(value) => handleDateChange({ field: 'dateTo', value })}
              name="dateTo"
              label="Pedidos hasta"
              minValue={formik.values.dateFrom}
              maxValue={moment().toDate()}
              disabled
            />
          </Box>
        </Grid2>
        <Grid2 size={12}>
          <Grid2
            container
            spacing={2}
            sx={{
              marginTop: 1
            }}
          >
            <Grid2 size={9}>
              <MultiSelectField
                label="Zona"
                name="zones"
                value={zonePlanning.length === 0 ? [] : formik.values.zones}
                onChange={(e) =>
                  handleMultiSelectChange(e, 'zones', setZonePlanningData, true, zonePlanningData)
                }
                error={formik.touched.zones && Boolean(formik.errors.zones)}
                options={zonePlanning}
                getOptionName={(value) => getOptionsName(value.code, zonePlanning)}
                onDeleteOption={(value) =>
                  handleOptionsDelete(
                    value,
                    'zones',
                    formik.values.zones,
                    setZonePlanningData,
                    true,
                    zonePlanningData
                  )
                }
                sx={{
                  width: '500px'
                }}
              />
            </Grid2>
            <Grid2 size={3}>
              <FormControlLabel
                sx={{
                  marginTop: 3
                }}
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    name="selectAll"
                    color="secondary"
                  />
                }
                label="Todos"
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
};

FormContent.propTypes = {
  update: Proptypes.bool,
  title: Proptypes.string,
  onClosed: Proptypes.func,
  handleRefresh: Proptypes.func,
  account: Proptypes.number,
  currentCompany: Proptypes.number
};

export const DeliveryPlanningForm = ({
  update = false,
  title,
  initialValues = {},
  onClosed,
  handleRefresh,
  account = 0,
  subheader,
  onAfterSave
  // onAfterSave
}) => {
  const { currentCompany } = useApp();

  const { filters, planningDate } = useDeliveryPlanning();
  const { planningOptions } = filters;
  const handleTransform = (values) => {
    const codes = values.zones.map((item) => item.code);
    return {
      ...values,
      zones: codes,
      planificationDate: moment(values.planificationDate).format('YYYY-MM-DD'),
      dateFrom: moment(values.dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(values.dateTo).format('YYYY-MM-DD')
    };
  };

  return (
    <GMFormContainer
      initialData={{
        companyId: initialValues.companyId || currentCompany,
        zones: initialValues.zones || [],
        dateFrom: initialValues.dateFrom || planningOptions.dateFrom,
        dateTo: initialValues.dateTo || planningOptions.dateTo,
        planificationDate: initialValues.planificationDate || planningDate,
        account: initialValues.account || account
      }}
      validationSchema={Yup.object().shape({
        dateFrom: Yup.date().required('La fecha es importante'),
        dateTo: Yup.date().required('La fecha es importante').min(Yup.ref('dateFrom')),
        planificationDate: Yup.date().required('La fecha es importante'),
        companyId: Yup.number().required('La empresa es importante'),
        zones: Yup.array()
          .of(
            Yup.object().shape({
              code: Yup.string().required(),
              name: Yup.string().required()
            })
          )
          .min(1, 'Seleccione al menos una zona')
      })}
      onSubmit={goalCustomeDeleveryPlanningApi.uploadOrders}
      onAfterSave={onAfterSave}
      subheader={subheader}
      transformBeforeSubmit={handleTransform}
    >
      <FormContent
        update={update}
        title={title}
        onClosed={onClosed}
        handleRefresh={handleRefresh}
        account={account}
        currentCompany={currentCompany}
      />
    </GMFormContainer>
  );
};

DeliveryPlanningForm.propTypes = {
  initialValues: Proptypes.object,
  update: Proptypes.bool,
  onClosed: Proptypes.func,
  title: Proptypes.string,
  handleRefresh: Proptypes.func,
  account: Proptypes.number,
  subheader: Proptypes.string,
  onAfterSave: Proptypes.func
};
