import PropTypes from 'prop-types';
import GoalRecordLink from '../goal-record-link';
import ArrowLeft from '@mui/icons-material/ArrowLeft';

export const BackLink = ({ to, label = 'Lista' }) => {
  return (
    <GoalRecordLink
      to={to}
      startIcon={<ArrowLeft fontSize="small" color="primary" sx={{ mr: 2 }} />}
      label={label}
    />
  );
};

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string
};
