import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { PropertyListItem } from '../../../components/property-list-item';
import { PropertyList } from '../../../components/property-list';

const DistanceExpensesTab = ({ data }) => {
  return (
    <Card>
      <PropertyList>
        <PropertyListItem label="Habilitado" value={data.distanceExpenses?.enabled ? 'Sí' : 'No'} />
        <PropertyListItem label="Tarifa por Milla" value={data.distanceExpenses?.ratePerMile} />
        <PropertyListItem
          label="Tarifa por Kilómetro"
          value={data.distanceExpenses?.ratePerKilometer}
        />
        <PropertyListItem
          label="Kilómetro Predeterminado"
          value={data.distanceExpenses?.defaultKilometer}
        />
      </PropertyList>
    </Card>
  );
};

DistanceExpensesTab.propTypes = {
  data: PropTypes.object.isRequired
};

export default DistanceExpensesTab;
