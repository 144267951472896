export const bulkViews = {
  OPEN: 'O',
  CLOSED: 'C',
  CANCELED: 'L',
  ALL: 'all'
};

export const BulksViews = {
  ALL: 'all'
};

export const bulksShowFrom = {
  ME: 'me',
  ALL_USERS: 'allUsers'
};

export const dataBulksShowFrom = [
  { value: bulksShowFrom.ME, label: 'Mi usuario' },
  { value: bulksShowFrom.ALL_USERS, label: 'Todos' }
];
