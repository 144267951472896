import { dataLoader } from '../../data-loader';

const loadInvoicing = async ({ companyId, date }) => {
  const resutl = await dataLoader.goalGet({
    action: '/delivery/handling/invoicing',
    params: {
      companyId,
      date
    }
  });
  return resutl;
};

// load sheet pdf
const loadSheetPdf = async ({ companyId, id }) => {
  const resutl = await dataLoader.goalGet({
    action: `/delivery/handling/invoicing/${id}/load-pdf`,
    params: {
      companyId,
      id
    }
  });
  return resutl;
};
// pickingSheetPdf sheet pdf
const pickingSheetPdf = async ({ companyId, id }) => {
  const resutl = await dataLoader.goalGet({
    action: `/delivery/handling/invoicing/${id}/picking-pdf`,
    params: {
      companyId,
      id
    }
  });
  return resutl;
};
// pickingGroupedPdf sheet pdf
const pickingGroupedPdf = async ({ companyId, id }) => {
  const resutl = await dataLoader.goalGet({
    action: `/delivery/handling/invoicing/${id}/picking-grouped-pdf`,
    params: {
      companyId,
      id
    }
  });
  return resutl;
};

// delivery sheet pdf
const deliverySheetPdf = async ({ companyId, id }) => {
  const resutl = await dataLoader.goalGet({
    action: `/delivery/handling/invoicing/${id}/delivery-sheet-pdf`,
    params: {
      companyId,
      id
    }
  });
  return resutl;
};

// /make-picking-group
const makePickingGroup = async ({ companyId, sheets }) => {
  const resutl = await dataLoader.goalPost({
    action: `/delivery/handling/invoicing/make-picking-group`,
    data: {
      companyId,
      sheets
    }
  });
  return resutl;
};

// ungroupPicking
const ungroupPicking = async ({ companyId, id }) => {
  const resutl = await dataLoader.goalPost({
    action: `/delivery/handling/invoicing/ungroup-picking`,
    data: {
      companyId,
      pickingId: id
    }
  });
  return resutl;
};

// retryInvoicing
const retryInvoicing = async ({ companyId, id }) => {
  const resutl = await dataLoader.goalGet({
    action: `/delivery/handling/invoicing/${id}/retry-invoicing`,
    params: {
      companyId
    }
  });
  return resutl;
};

export {
  loadInvoicing,
  loadSheetPdf,
  deliverySheetPdf,
  pickingSheetPdf,
  pickingGroupedPdf,
  makePickingGroup,
  ungroupPicking,
  retryInvoicing
};
