import { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Card, CardHeader } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import PolicyTemplateHeaderEdit from './policy-template-header-edit';
import { EditButton } from '../../common/buttons';
import RecordStatus from '../../common/status/record-status';

const PolicyTemplateHeaderView = ({ data, onRefresh }) => {
  const [editMode, setEditMode] = useState(false);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleAfterSave = () => {
    setEditMode(false);
    onRefresh();
  };

  if (editMode) {
    return (
      <PolicyTemplateHeaderEdit data={data} onClosed={handleCancel} onAfterSave={handleAfterSave} />
    );
  }

  return (
    <Card sx={{ padding: 2 }}>
      <CardHeader
        title="Detalle de Plantilla de Política"
        action={
          <ButtonGroup>
            <EditButton onClick={handleEdit} />
          </ButtonGroup>
        }
      />
      <PropertyList>
        <PropertyListItem align="end" label="ID" value={data.id} />
        <PropertyListItem align="end" label="ID de la Empresa" value={data.company_id} />

        <PropertyListItem label="Nombre" value={data.name} />
        <PropertyListItem label="Código" value={data.code} />
        <PropertyListItem label="Descripción" value={data.description} />
        <PropertyListItem label="Está Activo">
          <RecordStatus status={data.status} />
        </PropertyListItem>
      </PropertyList>
    </Card>
  );
};

PolicyTemplateHeaderView.propTypes = {
  data: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired
};

export default PolicyTemplateHeaderView;
