import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useData } from '../../hooks/use-data';
import { useMounted } from '../../hooks/use-mounted';
import { PDFLink } from './pdf/pdf-link';
import { PDFBody } from './pdf/pdf-body';
import { GoalButton } from '../goal-button';

export const PDFViewerdDialog = (props) => {
  const { open = false, onClose, onExited, apiHandle, apiParams, ...other } = props;
  const mounted = useMounted();
  const [documentState, handleRefresh] = useData({
    sourceApi: apiHandle,
    apiParameter: apiParams,
    loadingMessage: 'Cargando PDF',
    mounted,
    defaults: { noAutoLoad: true }
  });
  useEffect(() => {
    if (open && mounted.current) {
      // if (open) {
      handleRefresh();
    }
  }, [apiParams, open]);
  const isLoading = documentState.isLoading;
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
        }
      }}
      {...other}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <PDFBody
          fileName={documentState.data?.fileName}
          fileType={documentState.data?.fileType}
          content={documentState.data?.content}
          loading={documentState.isLoading}
        />
      </DialogContent>
      <DialogActions>
        {!isLoading && (
          <PDFLink
            fileName={documentState.data?.fileName}
            fileType={documentState.data?.fileType}
            content={documentState.data?.content}
            loading={documentState.isLoading}
          />
        )}
        <GoalButton color="primary" onClick={onClose} variant="text">
          Cerrar
        </GoalButton>
      </DialogActions>
    </Dialog>
  );
};

PDFViewerdDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  afterSubmit: PropTypes.func,
  onVariantsChange: PropTypes.func,
  variant: PropTypes.object,
  apiHandle: PropTypes.func,
  apiParams: PropTypes.object
};
