import Proptypes from 'prop-types';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { es } from 'date-fns/locale';
import { alpha } from '@mui/material/styles';
import { Box, FormHelperText } from '@mui/material';
import { CommonHelpText } from './common-help-text';

export const CommonDatePicker = ({
  name,
  minValue = null,
  maxValue = null,
  label = 'Fecha',
  error = null,
  helperText = '',
  message = '',
  onAccept,
  value,
  ...rest
}) => {
  const renderContent = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <MobileDatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          name={name}
          value={value}
          minDate={minValue}
          maxDate={maxValue}
          onAccept={onAccept}
          slotProps={{ textField: { variant: 'outlined' } }}
          InputLabelProps={{
            shrink: true,
            style: { position: 'relative' }
          }}
          sx={{
            backgroundColor: 'background.paper',
            borderColor: 'neutral.300',
            borderRadius: 1,
            '&:hover': {
              backgroundColor: 'background.paper',
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: 'neutral.300',
              borderRadius: 0
            },
            '&:focus': {
              borderRadius: 1,
              borderWidth: 1,
              borderStyle: 'solid',
              backgroundColor: 'background.paper',
              boxShadow: (theme) => `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
            }
          }}
          error={error}
          helperText={helperText}
          {...rest}
        />
        {error && <FormHelperText error> {helperText} </FormHelperText>}
      </LocalizationProvider>
    );
  };

  return message ? (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {renderContent()}
      <CommonHelpText message={message} />
    </Box>
  ) : (
    renderContent()
  );
};

CommonDatePicker.propTypes = {
  name: Proptypes.string.isRequired,
  minValue: Proptypes.instanceOf(Date),
  maxValue: Proptypes.instanceOf(Date),
  label: Proptypes.string,
  error: Proptypes.bool,
  helperText: Proptypes.string,
  message: Proptypes.string,
  onAccept: Proptypes.func,
  value: Proptypes.instanceOf(Date)
};
