import { createContext, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { attachmentTypesApi } from '../../api/goal/maintenance/assets/attachment-types-api';
import { defaultViews } from '../../api/app-definitions';

const AttachmentTypeContext = createContext();

const useAttachmentType = () => {
  return useContext(AttachmentTypeContext);
};

const AttachmentTypeProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view =
    new URLSearchParams(search).get('attachment-type-view')?.toUpperCase() || defaultViews.ALL;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: attachmentTypesApi.getAttachmentType,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando tipo de archivo adjunto...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    attachmentType: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : false,
    message: dataState.message,
    editMode,
    setEditMode
  };
  return <AttachmentTypeContext.Provider value={value}>{children}</AttachmentTypeContext.Provider>;
};

AttachmentTypeProvider.propTypes = { children: PropTypes.node.isRequired };

export { AttachmentTypeProvider, useAttachmentType };
