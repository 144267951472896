import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Chip, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropertyList } from '../../../components/property-list';
import { PropertyListItem } from '../../../components/property-list-item';
import { InputField } from '../../../components/input-field';
import RecordStatus from '../../../components/common/status/record-status';
import { SubmitButton } from '../../../components/common/buttons/submit-button';
import { PriceListTypeEnum } from '../../../components/inventory/peice-lists/definitions/list-type-enum';
import { isTrue } from '../../../hooks/use-bolean';
import useFormikMultiSelect from '../../../hooks/use-formik-multiselect';
import { useState } from 'react';
import { PriceListFactorsEnum } from '../../../components/inventory/peice-lists/definitions/price-list-factors';
import MultiSelectField from '../../../components/common/multi-select-field';
import { goalPriceListsApi } from '../../../api/goal/inventory/price-list/goal-price-list';
import { useFormChecker } from '../../../hooks/use-form-checker';
import { useFormSaver } from '../../../hooks/use-form-saver';

const PriceListEditor = ({ data, onAfterSave }) => {
  const [factorsList, setFactorsList] = useState([]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    company_id: Yup.string().required('Required'),
    remote_id: Yup.string().required('Required'),
    cost_list_id: Yup.number(),
    factor_codes: Yup.array().required('Required'),
    type: Yup.string().required('Required'),
    assignable: Yup.boolean().required('Required'),
    auto_update: Yup.boolean().required('Required'),
    status: Yup.string().required('Required')
  });

  const [handleUpdate] = useFormSaver({
    originalData: data,
    apiUpdateHandler: goalPriceListsApi.updatePriceList,
    handleAfterSave: onAfterSave,
    companyId: data.company_id
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || '',
      company_id: data.company_id || '',
      remote_id: data.remote_id || '',
      cost_list_id: data.cost_list_id || '',
      factor_codes: data.factor_codes || '',
      type: data.type || '',
      assignable: isTrue(data.assignable),
      auto_update: isTrue(data.auto_update),
      status: data.status || ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      await handleUpdate({ values, helpers });
    }
  });
  const [checkForm] = useFormChecker({ formik, setSaving: formik.setSubmitting });

  const { handleMultiSelectChange, getOptionsName, handleOptionsDelete } =
    useFormikMultiSelect(formik);

  const handleChangeChip = (value, formikChange) => {
    if (value) {
      formik.setFieldValue(formikChange, false);
    } else {
      formik.setFieldValue(formikChange, true);
    }
  };
  return (
    <form
      onSubmit={async (values, helpers) => {
        if (!checkForm()) return;
        formik.handleSubmit(values, helpers);
      }}
    >
      <Card sx={{ p: 2 }}>
        <CardHeader title="Editar Lista de Precios" action={<SubmitButton />} />
        <CardContent>
          <PropertyList>
            <PropertyListItem>
              <InputField
                name="name"
                label="Nombre"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                value={formik.values.name}
              />
            </PropertyListItem>
            <PropertyListItem label="Lista SAP" value={formik.values.remote_id} align="end" />
            <PropertyListItem label="Lista Costo" value={formik.values.cost_list_id} align="end" />
            <PropertyListItem>
              <MultiSelectField
                label="Factores"
                name="factor_codes"
                error={formik.touched.factor_codes && !!formik.errors.factor_codes}
                helperText={formik.touched.factor_codes && formik.errors.factor_codes}
                onBlur={formik.handleBlur}
                value={PriceListFactorsEnum.length === 0 ? [] : formik.values.factor_codes}
                onChange={(e) =>
                  handleMultiSelectChange(e, 'factor_codes', setFactorsList, true, factorsList)
                }
                options={PriceListFactorsEnum}
                getOptionName={(value) => getOptionsName(value.code, PriceListFactorsEnum)}
                onDeleteOption={(value) =>
                  handleOptionsDelete(
                    value,
                    'factor_codes',
                    formik.values.factor_codes,
                    setFactorsList,
                    true,
                    factorsList
                  )
                }
              />
            </PropertyListItem>
            <PropertyListItem>
              <InputField
                name="type"
                label="Tipo"
                error={formik.touched.type && !!formik.errors.type}
                helperText={formik.touched.type && formik.errors.type}
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                select
              >
                {PriceListTypeEnum.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </PropertyListItem>
            <PropertyListItem label="Flags">
              <Chip
                label="Asignable"
                variant={formik.values.assignable ? 'filled' : 'outlined'}
                color={formik.values.assignable ? 'primary' : 'default'}
                onClick={() => handleChangeChip(formik.values.assignable, 'assignable')}
                sx={{ marginBottom: '1em', marginRight: 2 }}
              />

              <Chip
                label="Auto Actualizable"
                variant={formik.values.auto_update ? 'filled' : 'outlined'}
                color={formik.values.auto_update ? 'primary' : 'default'}
                onClick={() => handleChangeChip(formik.values.auto_update, 'auto_update')}
                sx={{ marginBottom: '1em', marginRight: 2 }}
              />
            </PropertyListItem>
            <PropertyListItem label="Estado" align="end">
              <RecordStatus status={formik.values.status} />
            </PropertyListItem>
          </PropertyList>
        </CardContent>
      </Card>
    </form>
  );
};

PriceListEditor.propTypes = {
  data: PropTypes.object,
  onAfterSave: PropTypes.func
};

export default PriceListEditor;
