import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Grid2 } from '@mui/material';
import { PropertyList } from '../../../components/property-list';
import { PropertyListItem } from '../../../components/property-list-item';
import { AddButton, DeleteButton } from '../../../components/common/buttons';

const TaxesTab = ({ data }) => {
  const handleDelete = (id) => {
    console.log('Delete', id);
  };

  const handleAdd = () => {
    console.log('Add');
  };

  return (
    <Card>
      <CardHeader action={<AddButton onClick={handleAdd} />} />
      <CardContent>
        <Grid2 container spacing={2}>
          {data.taxes.taxes?.map((tax) => (
            <Grid2
              isze={{
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3
              }}
              key={tax.id}
            >
              <Card sx={{ padding: 2 }}>
                <CardHeader
                  title={tax.name}
                  action={<DeleteButton onConfirm={() => handleDelete(tax.id)} />}
                />
                <PropertyList>
                  <PropertyListItem label="Nombre del Impuesto" value={tax.name} />
                  <PropertyListItem label="Valor del Impuesto" value={tax.value} />
                  <PropertyListItem label="Tipo de Impuesto" value={tax.type} />
                </PropertyList>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </CardContent>
    </Card>
  );
};

TaxesTab.propTypes = {
  data: PropTypes.object.isRequired
};

export default TaxesTab;
