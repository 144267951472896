import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationButton } from '../confirmation-button';

export const DeleteButton = ({
  onClick,
  startIcon = <DeleteIcon />,
  tooltip = 'Eliminar',
  title = 'Eliminar',
  message = 'Eliminar',
  onConfirm,
  ...rest
}) => (
  <ConfirmationButton
    onConfirm={onConfirm}
    startIcon={startIcon}
    tooltip={tooltip}
    iconOnly
    title={title}
    message={message}
    color="error"
    {...rest}
  />
);

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func
};
