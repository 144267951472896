import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';
import { SirePurchasesProvider } from '../contexts/sunat/sire-purchases-context';
import { SireSalesProvider } from '../contexts/sunat/sire-sales-context';

const AccountingDashboard = Loadable(
  lazy(() =>
    import('../containers/accounting/accounting-dashboard').then((module) => ({
      default: module.AccountingDashboard
    }))
  )
);

const FEPendingsFacele = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-pendings-facele').then((module) => ({
      default: module.FEPendingsFacele
    }))
  )
);

const FEPendingsSAP = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-pendings-sap').then((module) => ({
      default: module.FEPendingsSAP
    }))
  )
);

const FESentsFacele = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-sents-facele').then((module) => ({
      default: module.FESentsFacele
    }))
  )
);

const GREAll = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/gre-all').then((module) => ({
      default: module.GREAll
    }))
  )
);

const GREPendings = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/gre-pendings').then((module) => ({
      default: module.GREPendings
    }))
  )
);

const Journal = Loadable(
  lazy(() =>
    import('../containers/accounting/journal/journal').then((module) => ({
      default: module.Journal
    }))
  )
);

const KardexDiscrepancies = Loadable(
  lazy(() =>
    import('../containers/managment/kardex/karedex-discrepancies').then((module) => ({
      default: module.KardexDiscrepancies
    }))
  )
);

/**
 * (START) SIRE SALES
 */
const SireRvie = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie').then((module) => ({
      default: module.SireRvie
    }))
  )
);

const SireRvieRecordGeneration = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-record-generation').then(
      (module) => ({
        default: module.SireRvieRecordGeneration
      })
    )
  )
);

const SireRvieManagementInformation = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-management-information').then(
      (module) => ({
        default: module.SireRvieManagementInformation
      })
    )
  )
);

const SireRvieComparison = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-comparison').then((module) => ({
      default: module.SireRvieComparison
    }))
  )
);

/** (START) SireRvieRecordGeneration content  */
const SireRvieCpSummaryTabs = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-sales/record-generation/sire-rvie-cp-summary-tabs'
    ).then((module) => ({
      default: module.SireRvieCpSummaryTabs
    }))
  )
);

const SireRvieProposal = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/record-generation/sire-rvie-proposal').then(
      (module) => ({
        default: module.SireRvieProposal
      })
    )
  )
);

/** cp summary sales */
const SireSalesRvieProposalSummary = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-proposal-summary').then(
      (module) => ({
        default: module.SireRvieProposalSummary
      })
    )
  )
);

const SireSalesRvieCpSummaryNoIncluded = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-cp-summary-no-included').then(
      (module) => ({
        default: module.SireRvieCpSummaryNoIncluded
      })
    )
  )
);

const SireSalesRviePreliminarySummary = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-preliminary-summary').then(
      (module) => ({
        default: module.SireRviePreliminarySummary
      })
    )
  )
);

const SireSalesRvieSummaryGenerated = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-summary-generated').then(
      (module) => ({
        default: module.SireRvieSummaryGenerated
      })
    )
  )
);
/** cp summary sales */

const SireSalesRvieInconsistencies = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-sales/record-generation/sire-rvie-inconsistencies'
    ).then((module) => ({
      default: module.SireRvieInconsistencies
    }))
  )
);
/** (END) SireRvieRecordGeneration content  */
/** (START) SireRvieManagementInformation content  */
const SireRvieQueriesAndDownloads = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-sales/management-information/sire-rvie-queries-and-downloads'
    ).then((module) => ({
      default: module.SireRvieQueriesAndDownloads
    }))
  )
);

const SireRvieTicketSubmissionStatus = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-sales/management-information/queries-and-downloads/sire-rvie-ticket-submission-status'
    ).then((module) => ({
      default: module.SireRvieTicketSubmissionStatus
    }))
  )
);

/** (END) SireRvieManagementInformation content  */
/** (START) SireRvieComparison content  */
const SireRvieComparisonDifferences = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-sales/comparison/sire-rvie-comparison-differences'
    ).then((module) => ({
      default: module.SireRvieComparisonDifferences
    }))
  )
);

const SireRvieComparisonSapPle = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-sales/comparison/sire-rvie-comparison-sap-ple'
    ).then((module) => ({
      default: module.SireRvieComparisonSapPle
    }))
  )
);

const SireRvieForReplace = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/comparison/sire-rvie-for-replace').then(
      (module) => ({
        default: module.SireRvieForReplace
      })
    )
  )
);
/** (END) SireRvieComparison content  */
////////////////////////////////////////////////////////////////////////
/**
 * (END) SIRE SALES
 */
/**
 * (START) SIRE PURCHASES
 */
const SireRce = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce').then((module) => ({
      default: module.SireRce
    }))
  )
);

const SireRceRecordGeneration = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-record-generation').then(
      (module) => ({
        default: module.SireRceRecordGeneration
      })
    )
  )
);

const SireRceManagementInformation = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-management-information').then(
      (module) => ({
        default: module.SireRceManagementInformation
      })
    )
  )
);

const SireRceComparison = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-comparison').then((module) => ({
      default: module.SireRceComparison
    }))
  )
);

/** (START) SireRceRecordGeneration content  */
const SirePurchasesRceCpSummaryTabs = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-cp-summary-tabs').then(
      (module) => ({
        default: module.SireRceCpSummaryTabs
      })
    )
  )
);

/** cp summary purchases */
const SirePurchasesRceProposalSummary = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-proposal-summary').then(
      (module) => ({
        default: module.SireRceProposalSummary
      })
    )
  )
);

const SirePurchasesRceCpSummaryExcluded = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-cp-summary-excluded').then(
      (module) => ({
        default: module.SireRceCpSummaryExcluded
      })
    )
  )
);

const SirePurchasesRceCpPreliminaryRceSummary = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-purchases/sire-rce-cp-preliminary-rce-summary'
    ).then((module) => ({
      default: module.SireRceCpPreliminaryRceSummary
    }))
  )
);

const SirePurchasesRceSummaryGenerated = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-summary-generated').then(
      (module) => ({
        default: module.SireRceSummaryGenerated
      })
    )
  )
);
/** cp summary purchases */

const SirePurchasesRceProposal = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-proposal').then((module) => ({
      default: module.SireRceProposal
    }))
  )
);

const SirePurchasesInconsistencies = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-inconsistencies').then(
      (module) => ({
        default: module.SireRceInconsistencies
      })
    )
  )
);

/** (END) SireRceRecordGeneration content  */
/** (START) SireRceManagementInformation content  */
const SireRceQueriesAndDownloads = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-purchases/management-information/sire-rce-queries-and-downloads'
    ).then((module) => ({
      default: module.SireRceQueriesAndDownloads
    }))
  )
);

const SireRceTicketSubmissionStatus = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-purchases/management-information/queries-and-downloads/sire-rce-ticket-submission-status'
    ).then((module) => ({
      default: module.SireRceTicketSubmissionStatus
    }))
  )
);

/** (END) SireRceManagementInformation content  */
/** (START) SireRceComparison content  */
const SireRceComparisonDifferences = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-purchases/comparison/sire-rce-comparison-differences'
    ).then((module) => ({
      default: module.SireRceComparisonDifferences
    }))
  )
);

const SireRceComparisonSapPle = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-purchases/comparison/sire-rce-comparison-sap-ple'
    ).then((module) => ({
      default: module.SireRceComparisonSapPle
    }))
  )
);

const SireRceForReplace = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/comparison/sire-rce-for-replace').then(
      (module) => ({
        default: module.SireRceForReplace
      })
    )
  )
);
/** (END) SireRceComparison content  */

/** (START) Sap Purchases in Sire content */
const SireSapPurchasesTabs = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-purchases/sap-purchases/sire-sap-purchases-tabs'
    ).then((module) => ({
      default: module.SireSapPurchasesTabs
    }))
  )
);

const SireSapNonResidentPurchases = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-purchases/sap-purchases/sire-sap-non-resident-purchases'
    ).then((module) => ({
      default: module.SireSapNonResidentPurchases
    }))
  )
);

/** (END) Sap Purchases in Sire content */
/**
 * (END) SIRE PURCHASES
 */

export const AccountingRoutes = [
  {
    path: 'fe',
    children: [
      {
        path: 'sents-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FESentsFacele />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings-sap',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FEPendingsSAP />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FEPendingsFacele />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'gre',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <GREAll />
          </AllowedGuard>
        )
      },
      {
        path: 'gre-pendings-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <GREPendings />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'journals',
    children: [
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_ACCT_JOURNAL_VIEW}>
            <Journal />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.GCC_ACCT_PROCESS}>
        <AccountingDashboard />
      </AllowedGuard>
    )
  },
  {
    path: 'balance',
    element: (
      <AllowedGuard permission={GadminActions.G2_KARDEX_PROCESS}>
        <KardexDiscrepancies />
      </AllowedGuard>
    )
  },
  {
    path: 'sunat',
    children: [
      {
        path: 'rvie',
        element: (
          <AllowedGuard permission={GadminActions.G2_SIRE_SALES_SEE_MODULE}>
            <SireSalesProvider>
              <SireRvie />
            </SireSalesProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: 'record-generation',
            element: (
              <AllowedGuard permission={GadminActions.G2_SIRE_SALES_SEE_RECORD_GENERATION}>
                <SireRvieRecordGeneration />
              </AllowedGuard>
            ),
            children: [
              {
                path: 'cp-summary',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_SALES_SEE_CP_SUMMARY_TAB}>
                    <SireRvieCpSummaryTabs />
                  </AllowedGuard>
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_SIRE_SALES_LIST_PROPOSAL_SUMMARY}>
                        <SireSalesRvieProposalSummary />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'cp-summary-no-included',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_SIRE_SALES_LIST_CP_NOT_INCLUDED}>
                        <SireSalesRvieCpSummaryNoIncluded />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'preliminary-rvie-summary',
                    element: (
                      <AllowedGuard
                        permission={GadminActions.G2_SIRE_SALES_LIST_PRELIMINARY_SUMMARY}
                      >
                        <SireSalesRviePreliminarySummary />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'rvie-summary-generated',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_SIRE_SALES_LIST_GENERATED_SUMMARY}>
                        <SireSalesRvieSummaryGenerated />
                      </AllowedGuard>
                    )
                  }
                ]
              },
              {
                path: 'rvie-proposal',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_SALES_LIST_PROPOSAL}>
                    <SireRvieProposal />
                  </AllowedGuard>
                )
              },
              {
                path: 'inconsistencies',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_SALES_SEE_INCONSISTENCIES_TAB}>
                    <SireSalesRvieInconsistencies />
                  </AllowedGuard>
                )
              }
            ]
          },
          {
            path: 'management-information',
            element: (
              <AllowedGuard permission={GadminActions.G2_SIRE_SALES_SEE_MANAGEMENT_INFO}>
                <SireRvieManagementInformation />
              </AllowedGuard>
            ),
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireRvieQueriesAndDownloads />
                  </AllowedGuard>
                )
              },
              {
                path: 'queries-and-downloads',
                children: [
                  {
                    path: 'ticket-submission-status',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                        <SireRvieTicketSubmissionStatus />
                      </AllowedGuard>
                    )
                  }
                ]
              }
            ]
          },
          {
            path: 'comparison',
            element: (
              <AllowedGuard permission={GadminActions.G2_SIRE_SALES_SEE_SAP_COMPARISON}>
                <SireRvieComparison />
              </AllowedGuard>
            ),
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_SALES_LIST_PLE_DIFFERENCES}>
                    <SireRvieComparisonDifferences />
                  </AllowedGuard>
                )
              },
              {
                path: 'sap-ple',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_SALES_LIST_PLE}>
                    <SireRvieComparisonSapPle />
                  </AllowedGuard>
                )
              },
              {
                path: 'rvie-proposal',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_SALES_LIST_PROPOSAL}>
                    <SireRvieProposal />
                  </AllowedGuard>
                )
              },
              {
                path: 'rvie-for-replace',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_SALES_LIST_PROPOSAL_REPLACEMENT}>
                    <SireRvieForReplace />
                  </AllowedGuard>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'rce',
        element: (
          <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_SEE_MODULE}>
            <SirePurchasesProvider>
              <SireRce />
            </SirePurchasesProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: 'record-generation',
            element: (
              <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_SEE_RECORD_GENERATION}>
                <SireRceRecordGeneration />
              </AllowedGuard>
            ),
            children: [
              {
                path: 'cp-summary',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_SEE_CP_SUMMARY_TAB}>
                    <SirePurchasesRceCpSummaryTabs />
                  </AllowedGuard>
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <AllowedGuard
                        permission={GadminActions.G2_SIRE_PURCHASES_LIST_PROPOSAL_SUMMARY}
                      >
                        <SirePurchasesRceProposalSummary />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'cp-summary-excluded',
                    element: (
                      <AllowedGuard
                        permission={GadminActions.G2_SIRE_PURCHASES_LIST_EXCLUDED_CP_SUMMARY}
                      >
                        <SirePurchasesRceCpSummaryExcluded />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'cp-preliminary-rce-summary',
                    element: (
                      <AllowedGuard
                        permission={GadminActions.G2_SIRE_PURCHASES_LIST_PRELIMINARY_SUMMARY}
                      >
                        <SirePurchasesRceCpPreliminaryRceSummary />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'rce-summary-generated',
                    element: (
                      <AllowedGuard
                        permission={GadminActions.G2_SIRE_PURCHASES_LIST_GENERATED_SUMMARY}
                      >
                        <SirePurchasesRceSummaryGenerated />
                      </AllowedGuard>
                    )
                  }
                ]
              },
              {
                path: 'rce-proposal',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_LIST_PROPOSAL}>
                    <SirePurchasesRceProposal />
                  </AllowedGuard>
                )
              },
              {
                path: 'inconsistencies',
                element: (
                  <AllowedGuard
                    permission={GadminActions.G2_SIRE_PURCHASES_SEE_INCONSISTENCIES_TAB}
                  >
                    <SirePurchasesInconsistencies />
                  </AllowedGuard>
                )
              }
            ]
          },
          {
            path: 'management-information',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SireRceManagementInformation />
              </AllowedGuard>
            ),
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireRceQueriesAndDownloads />
                  </AllowedGuard>
                )
              },
              {
                path: 'queries-and-downloads',
                children: [
                  {
                    path: 'ticket-submission-status',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                        <SireRceTicketSubmissionStatus />
                      </AllowedGuard>
                    )
                  }
                ]
              }
            ]
          },
          {
            path: 'comparison',
            element: (
              <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_SEE_SAP_COMPARISON}>
                <SireRceComparison />
              </AllowedGuard>
            ),
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_LIST_PLE_DIFFERENCES}>
                    <SireRceComparisonDifferences />
                  </AllowedGuard>
                )
              },
              {
                path: 'sap-ple',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_LIST_PLE}>
                    <SireRceComparisonSapPle />
                  </AllowedGuard>
                )
              },
              {
                path: 'rce-proposal',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_LIST_PROPOSAL}>
                    <SirePurchasesRceProposal />
                  </AllowedGuard>
                )
              },
              {
                path: 'rce-for-replace',
                element: (
                  <AllowedGuard
                    permission={GadminActions.G2_SIRE_PURCHASES_LIST_PROPOSAL_REPLACEMENT}
                  >
                    <SireRceForReplace />
                  </AllowedGuard>
                )
              }
            ]
          },
          {
            path: 'sap-purchases',
            element: (
              <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_SEE_SAP_PURCHASES}>
                <SireSapPurchasesTabs />
              </AllowedGuard>
            ),
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SIRE_PURCHASES_LIST_PLE}>
                    <SireRceComparisonSapPle />
                  </AllowedGuard>
                )
              },
              {
                path: 'non-resident-purchases',
                element: (
                  <AllowedGuard
                    permission={GadminActions.G2_SIRE_PURCHASES_SAP_LIST_FOREIGN_PURCHASES}
                  >
                    <SireSapNonResidentPurchases />
                  </AllowedGuard>
                )
              }
            ]
          }
        ]
      }
    ]
  }
];
