import { useNavigate } from 'react-router-dom';
import { useApp } from './use-app';

export const useGoalNavigate = () => {
  const navigate = useNavigate();
  const { appUrlBase } = useApp();

  const handleNavigate = (url) => {
    navigate(`${appUrlBase}${url}`);
  };
  return handleNavigate;
};
