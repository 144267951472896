import { dataLoader } from '../../data-loader';

export const listRindegastoUsers = async ({ companyId }) => {
  return await dataLoader.goalGet({
    action: '/integrations/rindegasto/users',
    params: { companyId }
  });
};
export const getRindegastoUser = async ({ id, companyId }) => {
  return await dataLoader.goalGet({
    action: `/integrations/rindegasto/users/${id}`,
    params: { companyId }
  });
};
export const syncRindegastoUsers = async ({ companyId, id = null }) => {
  return await dataLoader.goalGet({
    action: '/integrations/rindegasto/users/sync',
    params: { companyId, id }
  });
};
export const createRindegastoUser = async ({ companyId, data }) => {
  return await dataLoader.goalPost({
    action: '/integrations/rindegasto/users',
    params: { companyId },
    data
  });
};
