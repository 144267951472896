import { dataLoader } from '../../data-loader';

/* eslint class-methods-use-this:0 */

class GoalMaintenanceSales {
  async getTerritoriesApi(options) {
    const { filters, sort, sortBy, page, query, view, reload, searchText, id } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: `/sales/master/territories?companyId=${id}`,
      cacheItem: 'territories',
      apiParams: {
        type: 'diary',
        filters,
        sort,
        sortBy,
        page,
        query,
        view,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  // sync territories
  async syncTerritories({ companyId }) {
    const response = await dataLoader.loadGoalPost({
      action: '/sales/master/territories/sync',
      changes: { companyId }
    });
    return response;
  }

  // get with children
  async getTerritoriesWithChildren({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/sales/master/territories/with-children?companyId=${companyId}`
    });
    return response;
  }

  async getTerritoriesList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `sales/master/territories?companyId=${companyId}`
    });
    return response;
  }

  async getTerritoriesContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `sales/master/territories/${id}/territories?companyId=${companyId}`
    });
    return response;
  }

  async postTerritoriesForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/sales/master/territories',
      changes: values
    });
    return response;
  }

  async updateTerritoriesForm({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/sales/master/territories/${id}`,
      changes: changes
    });
    return response;
  }

  async deleteTerritory({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/sales/master/territories/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getTerritoriesConfig(companyId) {
    const response = await dataLoader.loadGoalGetData({
      action: `/sales/master/territories/config?companyId=${companyId}`
    });
    return response;
  }

  async getBusinessTypes(options) {
    const { filters, sort, sortBy, page, query, view, reload, companyId, searchText } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.loadGoal({
      action: '/sales/master/business-types',
      cacheItem: 'business-types',
      apiParams: {
        companyId,
        type: 'diary',
        view,
        filters,
        sort,
        sortBy,
        page,
        query,
        reload,
        searchText
      },
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'name' },
        { type: 'string', name: 'status' },
        { type: 'string', name: 'company_id' },
        { type: 'string', name: 'code' }
      ],
      ...options
    });
  }

  //  get with children
  async getBusinessTypesWithChildren({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/sales/master/business-types/with-children?companyId=${companyId}`
    });
    return response;
  }

  async getBusinessTypeList({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `sales/master/business-types?companyId=${companyId}`
    });
    return response;
  }

  async getBusinessTypeContainer({ id, companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `sales/master/business-types/${id}/business-types?companyId=${companyId}`
    });
    return response;
  }

  async postBusinessTypeForm({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: '/sales/master/business-types',
      changes: values
    });
    return response;
  }

  async updateBusinessTypeForm({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `/sales/master/business-types/${id}`,
      changes: changes
    });
    return response;
  }

  async deleteBusinessType({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `/sales/master/business-types/${id}?companyId=${companyId}`
    });
    return response;
  }

  async getBusinessTypeConfig({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `/sales/master/business-types/config?companyId=${companyId}`
    });
    return response;
  }
}

export const goalMaintenanceSalesApi = new GoalMaintenanceSales();
