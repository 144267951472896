import PropTypes from 'prop-types';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';

export const PropertyListItem = (props) => {
  const {
    align: alignProp = 'vertical',
    children,
    component,
    value,
    label = '',
    isForm = false,
    minWidth = 190,
    ...other
  } = props;
  const align =
    alignProp === 'column'
      ? 'vertical'
      : alignProp === 'row'
        ? 'horizontal'
        : alignProp === 'right'
          ? 'horizontal'
          : alignProp;
  const isRight = alignProp === 'right' || alignProp === 'end';
  return (
    <ListItem
      component={component}
      disableGutters
      sx={{
        px: 3,
        py: 1.5
      }}
      {...other}
    >
      {isForm && <Box sx={{ minWidth: 250 }}>{children}</Box>}
      {!isForm && (
        <>
          {!isRight && (
            <ListItemText
              disableTypography
              primary={label}
              secondary={
                <Box
                  sx={{
                    flex: 1,
                    mt: align === 'vertical' ? 0.5 : 0,
                    mr: align === 'vertical' ? 0 : 3.5
                  }}
                >
                  {children || (
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      align={align === 'right' ? 'right' : 'inherit'}
                    >
                      {value}
                    </Typography>
                  )}
                </Box>
              }
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: align === 'vertical' ? 'column' : 'row',
                my: 0
              }}
            />
          )}

          {isRight && (
            <Box
              sx={{
                alignItems: 'space-between',
                display: 'flex',
                minWidth: minWidth
              }}
            >
              <ListItemText disableTypography primary={label} />
              <Box sx={{ flexGrow: 1 }} />

              {children || (
                <Typography
                  color="textSecondary"
                  variant="body2"
                  align={isRight ? 'right' : 'inherit'}
                >
                  {value}
                </Typography>
              )}
            </Box>
          )}
        </>
      )}
    </ListItem>
  );
};

PropertyListItem.propTypes = {
  align: PropTypes.oneOf(['vertical', 'horizontal', 'right', 'column', 'row', 'end']),
  component: PropTypes.elementType,
  children: PropTypes.node,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isForm: PropTypes.bool,
  minWidth: PropTypes.number
};
