import PropTypes from 'prop-types';
import { useGoalUserMenu } from '../../contexts/menu/goal-menu-context';
import { Box, Menu } from '@mui/material';
import GoalButton from '../goal-button';
import KeyboardArrowDownIcon from '@mui/icons-material/MoreVert';

export const GoalUserMenu = ({
  label,
  children,
  iconOnly = false,
  startIcon = <KeyboardArrowDownIcon />
}) => {
  const { anchorRef, open, handleOpen, handleClose } = useGoalUserMenu();

  const iconButton = iconOnly || !label;

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          p: 1
        }}
      >
        {iconButton && startIcon}
        {!iconButton && (
          <GoalButton variant="text">
            {label}
            {startIcon}
          </GoalButton>
        )}
      </Box>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {children}
      </Menu>
    </>
  );
};

GoalUserMenu.propTypes = {
  startIcon: PropTypes.node,
  children: PropTypes.node,
  label: PropTypes.string,
  iconOnly: PropTypes.bool
};
