const {
  REACT_APP_KEYCLOAK_REALM: realm,
  REACT_APP_KEYCLOAK_URL: url,
  REACT_APP_KEYCLOAK_CLIENT_ID: clientId,
  REACT_APP_NAME,
  REACT_APP_STAGE,
  REACT_APP_VERSION,
  REACT_APP_API_BASE_URL,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_PORTAL_URL,
  REACT_APP_WS_HOST,
  REACT_APP_CACHE_ENABLED: cacheEnabled = 'false',
  REACT_APP_API_TOKEN_KEY_GOAL,
  REACT_APP_API_BASE_CLIENT_ID,
  REACT_APP_API_BASE_CLIENT_SECRET,
  REACT_APP_UTILS,
  REACT_APP_MEDIA_API_URL,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  NODE_ENV: environment,
  REACT_APP_DEBUG_MODE: debugMode,
  REACT_APP_API_URL: gadminEndPoint,
  REACT_APP_COMPANY: appCompany,
  REACT_APP_GADMIN_CLIENT_ID: gadminClientId,
  REACT_APP_GADMIN_CLIENT_SECRET: gadminClientSecret,
  REACT_APP_API_LOGIN_PATH: gadminLoginPath,
  REACT_APP_API_TOKEN_KEY: TOKEN_KEY,
  REACT_APP_API_TOKEN_KEY_GOAL: TOKEN_KEY_GOAL,
  REACT_APP_API_TOKEN_KEY_KC: TOKEN_KEY_KC,
  REACT_APP_API_CLIENT_ID: apiCientId,
  REACT_APP_API_CLIENT_SECRET: apiClientSecret,
  REACT_APP_API_BASE_URL: baseURL,
  REACT_APP_API_BASE_VERSION: apiVersion,
  PUBLIC_URL
} = process.env;

export const keycloakConfig = { realm, url, clientId };

export const applicationConfig = {
  uid: 'dc4ef54715eb0ce748d1789b7c122e7b',
  name: REACT_APP_NAME,
  stage: REACT_APP_STAGE,
  appId: `${REACT_APP_NAME}-${REACT_APP_STAGE}`.toLowerCase(),
  pageSize: 50,
  version: REACT_APP_VERSION ?? '1.0.0+1',
  tokenKeyGoal: REACT_APP_API_TOKEN_KEY_GOAL,
  applicationAssetsUrl: REACT_APP_UTILS,
  googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  environment,
  debugMode,
  cacheEnabled: cacheEnabled === 'true',
  appName: REACT_APP_NAME
};

export const applicationUrls = {
  BACKEND_URL: REACT_APP_API_BASE_URL,
  AUTH_URL: REACT_APP_KEYCLOAK_URL,
  PORTAL_URL: REACT_APP_PORTAL_URL,
  WS_HOST: REACT_APP_WS_HOST,
  MEDIA_URL: REACT_APP_MEDIA_API_URL,
  PUBLIC_URL
};

export const apiBaseClient = {
  clientId: REACT_APP_API_BASE_CLIENT_ID,
  clientSecret: REACT_APP_API_BASE_CLIENT_SECRET
};

export const googleMapsConfig = {
  apiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  assetUrl: REACT_APP_UTILS
};

export const mediaConfig = {
  mediaUrl: REACT_APP_MEDIA_API_URL,
  assetUrl: REACT_APP_UTILS
};

export const gadminConfig = {
  appCompany,
  gadminClientId,
  gadminClientSecret,
  gadminEndPoint,
  gadminLoginPath
};

export const backendConfig = {
  baseURL,
  apiCientId,
  apiClientSecret,
  apiVersion
};

export const tokenKeys = {
  TOKEN_KEY,
  TOKEN_KEY_GOAL,
  TOKEN_KEY_KC
};
