import { AllowedGuard } from '../hooks/use-allowed';
import GadminActions from '../contexts/gadmin-actions';
import { lazy } from 'react';
import { Loadable } from './loadable';
import { PriceListTabs } from '../containers/inventory/price-list/price-list-tabs';
import PriceListResumen from '../containers/inventory/price-list/price-list-resumen';
import { PriceListLines } from '../containers/inventory/price-list/price-list-lines';

const Product = Loadable(
  lazy(() =>
    import('../containers/inventory/product').then((module) => ({
      default: module.Product
    }))
  )
);
const ProductForm = Loadable(
  lazy(() =>
    import('../containers/inventory/product-form').then((module) => ({
      default: module.ProductForm
    }))
  )
);
const ProductScanner = Loadable(
  lazy(() =>
    import('../containers/inventory/product-scanner').then((module) => ({
      default: module.ProductScanner
    }))
  )
);
const ProductResumenPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-resumen-panel').then((module) => ({
      default: module.ProductResumenPanel
    }))
  )
);
const ProductResumenPanel2 = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-resumen2-panel').then((module) => ({
      default: module.ProductResumenPanel2
    }))
  )
);
const ProductAccountingPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-accounting-panel').then((module) => ({
      default: module.ProductAccountingPanel
    }))
  )
);
const ProductGalleryPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-gallery-panel').then((module) => ({
      default: module.ProductGalleryPanel
    }))
  )
);
const ProductStockPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-stock-panel').then((module) => ({
      default: module.ProductStockPanel
    }))
  )
);
const ProductPricesPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-prices-panel').then((module) => ({
      default: module.ProductPricesPanel
    }))
  )
);
const ProductCostsPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-costs-panel').then((module) => ({
      default: module.ProductCostsPanel
    }))
  )
);
const ProductMeasuresPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-measures-panel').then((module) => ({
      default: module.ProductMeasuresPanel
    }))
  )
);
const ProductTeamsPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-teams-panel').then((module) => ({
      default: module.ProductTeamsPanel
    }))
  )
);
const ProductAttributesPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-attributes-panel').then((module) => ({
      default: module.ProductAttributesPanel
    }))
  )
);
const ProductTransactionsPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-transactions-panel').then((module) => ({
      default: module.ProductTransactionsPanel
    }))
  )
);
const ProductIncommingsPanel = Loadable(
  lazy(() =>
    import('../components/inventory/product/panels/product-incommings-panel').then((module) => ({
      default: module.ProductIncommingsPanel
    }))
  )
);
const Picking = Loadable(
  lazy(() =>
    import('../containers/inventory/picking').then((module) => ({
      default: module.Picking
    }))
  )
);
const PickingGroup = Loadable(
  lazy(() =>
    import('../containers/inventory/pricking-group').then((module) => ({
      default: module.PickingGroup
    }))
  )
);

const Warehouses = Loadable(
  lazy(() =>
    import('../containers/inventory/warehouses/warehouses').then((module) => ({
      default: module.Warehouses
    }))
  )
);
const WarehousesTab = Loadable(
  lazy(() =>
    import('../containers/inventory/warehouses/warehouses-tab').then((module) => ({
      default: module.WarehousesTab
    }))
  )
);
const WarehouseResume = Loadable(
  lazy(() =>
    import('../containers/inventory/warehouses/warehouse-resume').then((module) => ({
      default: module.WarehouseResume
    }))
  )
);
const WarehouseStockPanel = Loadable(
  lazy(() =>
    import('../containers/inventory/warehouses/warehouse-stock-panel').then((module) => ({
      default: module.WarehouseStockPanel
    }))
  )
);
const PriceLists = Loadable(
  lazy(() =>
    import('../containers/inventory/price-list/price-lists').then((module) => ({
      default: module.PriceLists
    }))
  )
);

export const InventoryRoutes = [
  {
    path: 'products',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
            <Product />
          </AllowedGuard>
        )
      },
      {
        path: 'new',
        element: (
          <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
            <ProductForm itemcode={'new'} />
          </AllowedGuard>
        )
      },
      {
        path: 'scan',
        element: (
          <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
            <ProductScanner />
          </AllowedGuard>
        )
      },
      {
        path: ':itemcode',
        element: (
          <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
            <Product />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductResumenPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'summary2',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductResumenPanel2 />
              </AllowedGuard>
            )
          },
          {
            path: 'accounting',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductAccountingPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'gallery',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductGalleryPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'stock',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductStockPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'prices',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductPricesPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'costs',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductCostsPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'measures',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductMeasuresPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'teams',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductTeamsPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'attributes',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductAttributesPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'transactions',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductTransactionsPanel />
              </AllowedGuard>
            )
          },
          {
            path: 'incommings',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <ProductIncommingsPanel />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'picking',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
            <Picking />
          </AllowedGuard>
        )
      },
      {
        path: 'groups',
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <PickingGroup />
              </AllowedGuard>
            )
          },
          {
            path: ':id',
            element: (
              <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
                <PickingGroup />
              </AllowedGuard>
            )
          }
        ]
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.gCC_INV_ITEM}>
            <Picking />
          </AllowedGuard>
        )
      }
    ]
  },
  // warehouses
  {
    path: 'warehouses',
    children: [
      {
        path: '',
        element: (
          // <AllowedGuard permission={GadminActions.gCC_WAREHOUSE_LIST}>
          <Warehouses />
          // </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          // <AllowedGuard permission={GadminActions.gCC_WAREHOUSE_LIST}>
          <WarehousesTab />
          // </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: <WarehouseResume />
          },
          {
            path: 'stock',
            element: <WarehouseStockPanel />
          }
        ]
      }
    ]
  },

  // price lists
  {
    path: 'price-lists',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_PRICE_LIST}>
            <PriceLists />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.gCC_PRICE_LIST}>
            <PriceListTabs />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: <PriceListResumen />
          },
          {
            path: 'details',
            element: <PriceListLines />
          }
        ]
      }
    ]
  }
];
