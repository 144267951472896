import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import DoneIcon from '@mui/icons-material/Done';
export const ApplyButton = ({
  onClick,
  startIcon = <DoneIcon />,
  tooltip = 'Aplicar ',
  ...rest
}) => (
  <GoalButton
    onClick={onClick}
    color="success"
    startIcon={startIcon}
    tooltip={tooltip}
    iconOnly
    {...rest}
  />
);

ApplyButton.propTypes = {
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string
};
