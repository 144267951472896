import { AllowedGuard } from '../hooks/use-allowed';
import GadminActions from '../contexts/gadmin-actions';
import { lazy } from 'react';
import { Loadable } from './loadable';

const VehicleOdometers = Loadable(
  lazy(() =>
    import('../containers/operations/vehicles/vehicle-odometers').then((module) => ({
      default: module.VehicleOdometers
    }))
  )
);
const Vehicles = Loadable(
  lazy(() =>
    import('../containers/operations/vehicles/vehicles').then((module) => ({
      default: module.Vehicles
    }))
  )
);

const VehiclesTab = Loadable(
  lazy(() =>
    import('../containers/operations/vehicles/vehicles-tab').then((module) => ({
      default: module.VehiclesTab
    }))
  )
);

const VehicleWorkOrderTab = Loadable(
  lazy(() =>
    import('../containers/operations/vehicles/vehicle-work-order-tab').then((module) => ({
      default: module.VehicleWorkOrderTab
    }))
  )
);

const VehicleWorkOrders = Loadable(
  lazy(() =>
    import('../containers/operations/vehicles/vehicle-work-orders').then((module) => ({
      default: module.VehicleWorkOrders
    }))
  )
);

const VehicleAttachmentsContainer = Loadable(
  lazy(() =>
    import('../containers/operations/vehicles/vehicle-attachments-container').then((module) => ({
      default: module.VehicleAttachmentsContainer
    }))
  )
);

const VehicleWorkOrderServiceEntries = Loadable(
  lazy(() =>
    import('../containers/operations/vehicles/vehicle-work-order-service-entries').then(
      (module) => ({
        default: module.VehicleWorkOrderServiceEntries
      })
    )
  )
);
/** (start) Work Orders */
const WorkOrders = Loadable(
  lazy(() =>
    import('../containers/operations/work-orders/work-orders').then((module) => ({
      default: module.WorkOrders
    }))
  )
);

const WorkOrderTab = Loadable(
  lazy(() =>
    import('../containers/operations/work-orders/work-order-tab').then((module) => ({
      default: module.WorkOrderTab
    }))
  )
);

const WorkOrderDetail = Loadable(
  lazy(() =>
    import('../containers/operations/work-orders/work-order-detail').then((module) => ({
      default: module.WorkOrderDetail
    }))
  )
);

const WorkOrderAttachments = Loadable(
  lazy(() =>
    import('../containers/operations/work-orders/work-order-attachments').then((module) => ({
      default: module.WorkOrderAttachments
    }))
  )
);

const VehicleDetailContainer = Loadable(
  lazy(() =>
    import('../containers/delivery/vehicle/vehicle-detail-container').then((module) => ({
      default: module.VehicleDetailContainer
    }))
  )
);

const VehicleProvider = Loadable(
  lazy(() =>
    import('../contexts/operations/vehicle-context').then((module) => ({
      default: module.VehicleProvider
    }))
  )
);

const VehicleWorkOrderProvider = Loadable(
  lazy(() =>
    import('../contexts/operations/vehicle-work-order-context').then((module) => ({
      default: module.VehicleWorkOrderProvider
    }))
  )
);

export const OperationsRoutes = [
  {
    path: 'vehicles',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
            <Vehicles />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
            <VehicleProvider>
              <VehiclesTab />
            </VehicleProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                <VehicleDetailContainer />
              </AllowedGuard>
            )
          },
          {
            path: 'attachments',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                <VehicleAttachmentsContainer />
              </AllowedGuard>
            )
          },
          {
            path: 'work-orders',
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                    <VehicleWorkOrders />
                  </AllowedGuard>
                )
              },
              {
                path: ':workReference',
                element: (
                  <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                    <VehicleWorkOrderProvider>
                      <VehicleWorkOrderTab />
                    </VehicleWorkOrderProvider>
                  </AllowedGuard>
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                        <WorkOrderDetail />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'attachments',
                    element: (
                      <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                        <WorkOrderAttachments />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'service-entries',
                    element: (
                      <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                        <VehicleWorkOrderServiceEntries />
                      </AllowedGuard>
                    )
                  }
                ]
              }
            ]
          },
          {
            path: 'odometers',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                <VehicleOdometers />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'work-orders',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
            <WorkOrders />
          </AllowedGuard>
        )
      },
      {
        path: ':workReference',
        element: (
          <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
            <VehicleWorkOrderProvider>
              <WorkOrderTab />
            </VehicleWorkOrderProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                <WorkOrderDetail />
              </AllowedGuard>
            )
          },
          {
            path: 'attachments',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                <WorkOrderAttachments />
              </AllowedGuard>
            )
          },
          {
            path: 'service-entries',
            element: (
              <AllowedGuard permission={GadminActions.gCC_DELIVERY_LIST_VEH}>
                <VehicleWorkOrderServiceEntries />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  }
];
