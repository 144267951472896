import { GoalContainer } from '../../../components/common/goal-container';
import PowerBIDashboard from '../../../components/PowerBIDashboard';
import CleanIcon from '@mui/icons-material/CleaningServices';

export const VehicleCleaningReport = () => {
  return (
    <GoalContainer
      title="Limpieza de Vehículos"
      subheader={'Reporte de limpieza de vehículos'}
      avatar={<CleanIcon />}
      hideTitle
    >
      {/* <PowerBIDashboard
        accessToken={'accessToken'}
        embedId={
          'eyJrIjoiNjI2YTU0NjQtY2QwMy00YmM0LTljZDAtNDFiMjU0OWRhZDZhIiwidCI6IjJlMzhkNTNkLTVjMmQtNDE4YS1hYTlhLTg5NmM3ZTA1NmFlMyJ9'
        }
      /> */}
      <PowerBIDashboard
        accessToken={'accessToken'}
        resourceType="reportId"
        embedId={
          '2eb575ea-17ca-4d38-a10e-93da3a6291fc&autoAuth=true&ctid=2e38d53d-5c2d-418a-aa9a-896c7e056ae3'
        }
      />
    </GoalContainer>
  );
};
export default VehicleCleaningReport;
