import { Helmet } from 'react-helmet-async';
import { Box, Card } from '@mui/material';
import { useApp } from '../../../hooks/use-app';
import { RenderIf } from '../../../components/render-if';
import { SurveySumaryDetail } from '../../../components/survey/survey/survey-summary-detail';
import { SurveySummaryDetailEdit } from '../../../components/survey/survey/survey-summary-detail-edit';
import { SurveyLines } from '../../../components/survey/survey/survey-lines';
import { useSurvey } from '../../../contexts/surveys/survey-context';

export const SurveyDetailContainer = () => {
  const { title } = useApp();

  const { survey, handleRefresh, editMode, setEditMode } = useSurvey();

  const canEdit = survey?.editable;

  return (
    <>
      <Helmet>
        <title>{title('Detalle')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default' }}>
        <RenderIf
          condition={editMode}
          no={<SurveySumaryDetail item={survey} setEditMode={setEditMode} canEdit={canEdit} />}
        >
          <SurveySummaryDetailEdit
            initialValues={survey}
            onClose={setEditMode}
            onRefresh={handleRefresh}
          />
        </RenderIf>
        <Card variant="outlined">
          {survey?.selectQuestions && (
            <SurveyLines
              lines={survey?.selectQuestions}
              onRefresh={handleRefresh}
              survey={survey}
              canEdit={canEdit}
            />
          )}
        </Card>
      </Box>
    </>
  );
};
