import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';

import { ExpenseReportProvider } from '../contexts/rindegastos/expense-report-context';
import { ExpenseProvider } from '../contexts/rindegastos/expense-context';

const ExpenseReports = Loadable(
  lazy(() =>
    import('../containers/rindegastos/expense-reports-container').then((module) => ({
      default: module.ExpenseReports
    }))
  )
);

const ExpenseReportTabs = Loadable(
  lazy(() =>
    import('../containers/rindegastos/expense-report-tabs').then((module) => ({
      default: module.ExpenseReportTabs
    }))
  )
);

const ExpenseReport = Loadable(
  lazy(() =>
    import('../containers/rindegastos/expense-report').then((module) => ({
      default: module.ExpenseReport
    }))
  )
);

const ExpenseReportExpenses = Loadable(
  lazy(() =>
    import('../containers/rindegastos/expense-report-expenses').then((module) => ({
      default: module.ExpenseReportExpenses
    }))
  )
);

const Expenses = Loadable(
  lazy(() =>
    import('../containers/rindegastos/expenses/expenses-container').then((module) => ({
      default: module.Expenses
    }))
  )
);

const ExpenseTabs = Loadable(
  lazy(() =>
    import('../containers/rindegastos/expenses/expense-tabs').then((module) => ({
      default: module.ExpenseTabs
    }))
  )
);

const Expense = Loadable(
  lazy(() =>
    import('../containers/rindegastos/expenses/expense').then((module) => ({
      default: module.Expense
    }))
  )
);

export const FinanceRoutes = [
  {
    path: 'expense-reports',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <ExpenseReports />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <ExpenseReportProvider>
            <ExpenseReportTabs />
          </ExpenseReportProvider>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <ExpenseReport />
              </AllowedGuard>
            )
          },
          {
            path: 'expenses',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <ExpenseReportExpenses />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'expenses',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <Expenses />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <ExpenseProvider>
            <ExpenseTabs />
          </ExpenseProvider>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <Expense />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  }
];
