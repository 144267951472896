import { useCallback, useEffect, useState } from 'react';

export const useData = ({ sourceApi, apiParameter = {}, loadingMessage, defaults, afterLoad }) => {
  const [documentState, setDocumentState] = useState({
    isLoading: !defaults?.noAutoLoad,
    reload: !defaults?.noAutoLoad
  });
  const { noAutoLoad } = defaults || { noAutoLoad: false };
  const autoLoad = !noAutoLoad;

  const getDocument = useCallback(
    async (parameters) => {
      setDocumentState((prevState) => ({
        ...prevState,
        isLoading: true,
        message: loadingMessage,
        displayLoading: true,
        displayError: false,
        displayUnavailable: false
      }));
      // }
      try {
        const result = await sourceApi(parameters);
        setDocumentState((prevState) => ({
          ...prevState,
          ...result,
          isLoading: false,
          reload: false,
          isLoaded: true,
          displayLoading: false,
          displayError: !result.success,
          displayUnavailable: result.success && !result.data
        }));
        if (result.success && afterLoad) {
          afterLoad(result);
        }
      } catch (err) {
        console.error('useDataError', err);
        setDocumentState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: err.message,
          real: true,
          displayError: true
        }));
      }
    },
    [sourceApi, afterLoad, loadingMessage]
  );

  useEffect(() => {
    if (autoLoad) {
      getDocument(apiParameter).catch(console.error);
    }
  }, [autoLoad]);

  const handleRefresh = async (parameters) => {
    let refreshParameters = parameters;

    // if parameter is not SyntheticBaseEvent
    if ((parameters && parameters._reactName === 'onClick') || !parameters) {
      refreshParameters = apiParameter;
    } else {
      refreshParameters = { ...apiParameter, ...parameters };
    }
    return await getDocument(refreshParameters);
  };

  return [documentState, handleRefresh];
};
