import { goalCustomeDeleveryPlanningApi } from '../../../../api/goal/planning/goal-customer-delivery-planning';
import { ColumnDataTypesEnum } from '../../../gm/grid/grid-definitions';
import { GMGridContainer } from '../../../gm/grid/gm-grid-context';
import { useDeliveryPlanning } from '../delivery-planing-context';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: ColumnDataTypesEnum.ID
    // link: {
    //   to: (item) => `/sales/orders/${item.id}`,
    //   tooltip: 'Ver detalle del pedido'
    // }
  },
  {
    field: 'name',
    headerName: 'Nombre',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'driver_name',
    headerName: 'Chofer',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'color',
    headerName: 'Color'
  }
];
export const DeliveryPlaningPlansForDate = () => {
  const { pendingDeliveryParams } = useDeliveryPlanning();

  return (
    <GMGridContainer
      columns={columns}
      sourceApi={goalCustomeDeleveryPlanningApi.getAllPlansForDate}
      apiParameters={pendingDeliveryParams}
      loadingMessage="Cargando pedidos pendientes"
    />
  );
};
