import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useKeycloak } from '@react-keycloak/web';
import {
  wsInit,
  getCurrentUser,
  getLocalSid,
  GadminLogin,
  wsLogout,
  setLocalToken,
  setLocalUser,
  GadminLoginKeycloak,
  setLocalTokenGoal,
  setLocalTokenKeycloak
} from '../services/gadmin';
import { goalLogin, goalLogout } from '../api/goal/login';
import { useLocalStorage } from '../hooks/local-access';

// import { goalCurrentUser, goalLogin } from '../api/goal/login';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const defaultLoggedInfo = {
  gadminLogged: false,
  keycloakLogged: false,
  goalLogged: false,
  isLogged: false,
  unauthorized: false,
  isInitialized: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, permissions } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      permissions
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      permissions: user.permissions?.map((Permission) => Permission.hash)
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  // console.log('AuthProvider');
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [validatingLogin, setValidatingLogin] = useState(true);
  // const location = useLocation();
  // console.log('AuthProvider location', location);
  const [logged, setLogged] = useState(defaultLoggedInfo);

  const { keycloak, initialized } = useKeycloak();

  const [ssid, setSsid, removeSsid] = useLocalStorage('ssid');

  const sid = getLocalSid();
  const account = { userName: state?.user?.name, ...state?.user };

  const loginWithKeycloak = useCallback(async (token, sid, email) => {
    setLocalTokenKeycloak(token);

    const resp = await Promise.all([
      await goalSession({ token, ssid }),
      await gadminSession({ token, email })
    ]);
    const toSave = {
      ...logged,
      keycloakLogged: true,
      goalLogged: resp[0],
      gadminLogged: resp[1]
    };
    startSession(toSave);
  }, []);

  useEffect(() => {
    const doLogin = async () => {
      // console.log('AuthProvider useEffect');
      if (initialized) {
        // console.log('AuthProvider initialized');
        if (keycloak.authenticated) {
          const userInfo = keycloak.idTokenParsed;
          // setLogged({ ...logged, keycloakLogged: true });
          await loginWithKeycloak(keycloak.token, sid, userInfo.preferred_username);
        }
      }
      setValidatingLogin(() => false);
    };
    if (validatingLogin && initialized) {
      doLogin();
    }
  }, [
    initialized,
    keycloak.authenticated,
    validatingLogin,
    keycloak.idTokenParsed,
    keycloak.token,
    sid,
    loginWithKeycloak
  ]);

  const [errorLogin, seterrorLogin] = useState(true);
  const [errorMsg, seterrorMsg] = useState(null);

  const registerLoginGoal = async (loginData) => {
    // console.log('registerLoginGoal', loginData);
    if (loginData.success) {
      setLocalTokenGoal(loginData.data.access_token);
      // const userData = await goalCurrentUser();
      // console.log('userData', userData);
    } else {
      seterrorLogin(loginData.success);
      seterrorMsg(loginData.message);
      setTimeout(() => {
        seterrorLogin(true);
        seterrorMsg(null);
      }, 3000);
    }
  };

  const registerLogin = async (loginData, email) => {
    if (loginData.success) {
      setLocalToken(loginData.data.Token);
      setLocalUser(loginData.data.Username);
      wsInit({ email, initializeInterceptors: true });
      const userData = await getCurrentUser();
      if (userData.success) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            // user: { ...userData.data, displayName: userData.data.name },
            isAuthenticated: true,
            user: { ...userData.data, displayName: userData.data.name },
            permissions: userData.data.permissions?.map((Permission) => Permission.hash)
          }
        });
      }
    } else {
      seterrorLogin(loginData.success);
      seterrorMsg(loginData.message);
      setTimeout(() => {
        seterrorLogin(true);
        seterrorMsg(null);
      }, 3000);
    }
  };
  const gadminSession = async ({ token, email }) => {
    const loginData = await GadminLoginKeycloak({
      keycloak_token: token,
      localSid: sid
    });
    if (loginData.success) {
      await registerLogin(loginData, email);
      // setLogged(() => ({ ...logged, gadminLogged: true }));
    }
    return loginData.success;
  };

  const goalSession = async ({ token, ssid }) => {
    const goalLoginData = await goalLogin({ token, ssid });
    if (goalLoginData.success) {
      setSsid(goalLoginData.data.expires_in);
      await registerLoginGoal(goalLoginData);
    }
    return goalLoginData.success;
  };

  const login = async (email, password) => {
    const loginData = await GadminLogin({ email, password, localSid: sid });
    await registerLogin(loginData, email);
  };

  const hasPermission = (permission) => {
    if (!permission) return false;
    if (permission.constructor === Array) {
      return state.permissions?.some((r) => permission.indexOf(r) >= 0);
    }
    return state.permissions?.includes(permission);
  };

  const renderAllowed = (permission, component) => {
    if (!hasPermission(permission)) return null;
    return component;
  };

  const logout = async () => {
    cleanSession();
    await goalLogout();

    keycloak.logout();

    wsLogout();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const accessToken = `${email}@${name}${password}`;
    localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user: {}
      }
    });
  };

  const cleanSession = () => {
    setLogged(defaultLoggedInfo);
    removeSsid();
  };

  const startSession = (toSave) => {
    const isLogged = toSave.keycloakLogged && toSave.gadminLogged && toSave.goalLogged;
    if (isLogged) {
      setLogged({ ...logged, ...toSave, isLogged: isLogged, isInitialized: true });
    } else {
      setLogged({ ...logged, ...toSave, isInitialized: true });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        hasPermission,
        renderAllowed,
        errorLogin,
        errorMsg,
        account,
        validatingLogin,
        setValidatingLogin,
        isLogged: logged.isInitialized && logged.isLogged,
        isInitialized: logged.isInitialized,
        keycloakInitialized: initialized,
        doLogin: keycloak.login
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
