import { format, formatDistanceToNow } from 'date-fns';
import { appFormats } from '../api/app-definitions';

// ----------------------------------------------------------------------

export function fDate(date) {
  if (date instanceof Date) {
    return format(date, appFormats.date);
  }
  if (typeof date === 'string') {
    return format(new Date(date), appFormats.date);
  }
  return '';
}

export function fDateTime(date) {
  if (date instanceof Date) {
    return format(date, appFormats.dateTime);
  }
  if (typeof date === 'string') {
    return format(new Date(date), appFormats.dateTime);
  }
  return '';
}

export function fDateTimeSuffix(date) {
  // if date is date
  if (date instanceof Date) {
    return format(date, 'dd/MM/yyyy hh:mm p');
  }
  if (typeof date === 'string') {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
  }

  return '';
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

// date from string
export function dateFromString(date, defaultDate = new Date()) {
  return date ? new Date(`${date}T00:00:00-05:00`) : defaultDate;
}

// today as string date
export function todayAsString() {
  return format(new Date(), 'yyyy-MM-dd');
}
