import { ColumnDataTypesEnum } from '../../../../components/gm/grid/grid-definitions';
import { useApp } from '../../../../hooks/use-app';
import { useMounted } from '../../../../hooks/use-mounted';
import { Box, Card, CardContent, CardHeader, Container } from '@mui/material';
import { RefreshButton } from '../../../../components/common/buttons/refresh-button';
import { SyncButton } from '../../../../components/common/buttons/sync-button';
import { Helmet } from 'react-helmet-async';
import { listSellers, syncSellers } from '../../../../api/goal/sales/master-data/sellers-api';
import { GMGridContainer } from '../../../../components/gm/grid/gm-grid-context';
import { useRef } from 'react';
import { AddButton } from '../../../../components/common/buttons/add-button';

const componentTitle = 'Vendedores';
const columns = [
  {
    field: 'id',
    headerName: 'ID',
    type: ColumnDataTypesEnum.ID,
    link: {
      to: (item) => `/commercial/master-data/sellers/${item.id}`,
      tooltip: 'Ver detalle del vendedor'
    }
  },
  {
    field: 'name',
    headerName: 'Nombre',
    type: ColumnDataTypesEnum.TEXT,
    width: 300
  },
  {
    field: 'email',
    headerName: 'Correo electrónico',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'mobile',
    headerName: 'Teléfono',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'manager_name',
    headerName: 'Jefe inmediato',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'sales_team_name',
    headerName: 'Mesa',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'sales_team_group_name',
    headerName: 'Equipo',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'status',
    headerName: 'Estado',
    type: ColumnDataTypesEnum.STATUS
  },
  {
    field: 'employee_name',
    headerName: 'Nombre del empleado',
    type: ColumnDataTypesEnum.TEXT
  },
  {
    field: 'employee_position_name',
    headerName: 'Puesto',
    type: ColumnDataTypesEnum.DATE
  }
];

export const SalesSellers = () => {
  const mounted = useMounted();
  const { title, currentCompany, showError, showNotify } = useApp();
  const ref = useRef(null);

  const handleRefresh = () => {
    if (ref.current) {
      ref.current.handleRefresh();
    }
  };

  const handleAdd = () => {
    console.log('Add');
  };

  const handleSync = async () => {
    try {
      const result = await syncSellers({ companyId: currentCompany });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderContent = () => {
    return (
      <Card>
        <CardHeader
          title={componentTitle}
          action={
            <>
              <AddButton onClick={handleAdd} />
              <SyncButton onClick={handleSync} />
              <RefreshButton onClick={handleRefresh} />
            </>
          }
        />
        <CardContent>
          <GMGridContainer
            columns={columns}
            sourceApi={listSellers}
            apiParameters={{ companyId: currentCompany }}
            loadingMessage="Cargando lista de vendedores..."
            ref={ref}
          />
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title(componentTitle)}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
