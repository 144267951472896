const ColumnDataTypesEnum = Object.freeze({
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
  PRICE: 'price',
  TOTAL: 'total',
  DISCOUNT: 'discount',
  PERCENT: 'percent',
  ID: 'id',
  INT: 'int',
  DATETIME: 'date',
  STATUS: 'status',
  TAX: 'tax',
  MARGIN: 'margin',
  ACTION: 'action',
  MENU_ACTION: 'menu-action',
  ARRAY: 'array'
});

const ColumnDataType = Object.freeze([
  { code: ColumnDataTypesEnum.STRING, name: 'String', format: '', width: 150 },
  { code: ColumnDataTypesEnum.NUMBER, name: 'Number', format: '#,##0', width: 100 },
  { code: ColumnDataTypesEnum.DATE, name: 'Date', format: 'DD/MM/YYYY', width: 120 },
  { code: ColumnDataTypesEnum.DATETIME, name: 'DateTime', format: 'DD/MM/YYYY HH:mm', width: 180 },
  { code: ColumnDataTypesEnum.BOOLEAN, name: 'Boolean', format: '', width: 80 },
  { code: ColumnDataTypesEnum.PRICE, name: 'Price', format: '0.0000', width: 100 },
  { code: ColumnDataTypesEnum.TOTAL, name: 'Total', format: '#,##0.00', width: 100 },
  { code: ColumnDataTypesEnum.DISCOUNT, name: 'Discount', format: '0.00%', width: 100 },
  { code: ColumnDataTypesEnum.PERCENT, name: 'Percent', format: '0.00%', width: 100 },
  { code: ColumnDataTypesEnum.ID, name: 'ID', format: '0', width: 80 },
  { code: ColumnDataTypesEnum.INT, name: 'Int', format: '#,##0', width: 100 },
  { code: ColumnDataTypesEnum.STATUS, name: 'Status', format: '', width: 100 },
  { code: ColumnDataTypesEnum.TAX, name: 'Tax', format: '0.00%', width: 100 },
  { code: ColumnDataTypesEnum.MARGIN, name: 'Margin', format: '0.00%', width: 100 },
  { code: ColumnDataTypesEnum.ACTION, name: 'Action', format: '', width: 100 },
  { code: ColumnDataTypesEnum.MENU_ACTION, name: 'Menu Action', format: '', width: 100 },
  { code: ColumnDataTypesEnum.ARRAY, name: 'Array', format: '', width: 150 }
]);

export const numericTypes = [
  ColumnDataTypesEnum.NUMBER,
  ColumnDataTypesEnum.PRICE,
  ColumnDataTypesEnum.TOTAL,
  ColumnDataTypesEnum.DISCOUNT,
  ColumnDataTypesEnum.PERCENT,
  ColumnDataTypesEnum.INT,
  ColumnDataTypesEnum.ID,
  ColumnDataTypesEnum.TAX,
  ColumnDataTypesEnum.MARGIN
];

export const dateTypes = [ColumnDataTypesEnum.DATE, ColumnDataTypesEnum.DATETIME];

export const booleanTypes = [ColumnDataTypesEnum.BOOLEAN];

export const progressTypes = [
  ColumnDataTypesEnum.PERCENT,
  ColumnDataTypesEnum.DISCOUNT,
  ColumnDataTypesEnum.TAX,
  ColumnDataTypesEnum.MARGIN
];

export const isProgressType = (type) => {
  return progressTypes.includes(type);
};

const getColumnDataTypeByType = (type) => {
  return ColumnDataType.find((column) => column.code === type);
};

export { ColumnDataTypesEnum, ColumnDataType, getColumnDataTypeByType };
