import PropTypes from 'prop-types';
import { Box, Typography, Card, CardContent, CardActions, Skeleton } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import useImageToBase64 from '../../hooks/use-image-to-base-64';
import { goalMediaApi } from '../../api/goal/media/goal-media';
import { useState } from 'react';
import { useApp } from '../../hooks/use-app';
import InputFileUpload from './buttons/upload-button';
import { CancelButton } from './buttons/cancel-button';
import { ConfirmButton } from './buttons/confirm-button';

export const CommonImageSelector = ({ afterUpload, activate, onCancel }) => {
  const { showError } = useApp();
  const [imageLoading, setImageLoading] = useState(false);
  const { account } = useAuth();
  const [selectedImage, setSelectedImage] = useState(null);

  const {
    base64Image: base64ImageFile,
    previewImage: previewImageFile,
    resetImage: resetImageFile
  } = useImageToBase64(selectedImage);

  const handleCancel = () => {
    resetImageFile(null);
    onCancel?.();
  };
  const handleImagenChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleSubmitImage = async () => {
    setImageLoading(true);
    const dataImage = {
      data: base64ImageFile,
      name: selectedImage.name,
      type: selectedImage.type,
      application: 'GOAL',
      folder: 'document',
      owner: account?.userName
    };
    const response = await goalMediaApi.submitImage({ values: dataImage });
    if (response.data && response.success) {
      resetImageFile();
      afterUpload?.(response.data);
    } else {
      showError(`${response?.message}. No se proceso la acción `);
    }
    setImageLoading(false);
  };

  return (
    <Card>
      <CardContent>
        {previewImageFile ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography color="textSecondary" variant="body2">
              {selectedImage.name}
            </Typography>
            <img
              src={previewImageFile}
              style={{
                width: '200px',
                height: '200px',
                objectFit: 'contain'
              }}
              alt="Preview"
            />
          </Box>
        ) : (
          <Skeleton variant="rectangular" width={200} height={200} />
        )}
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'flex-end'
        }}
      >
        <InputFileUpload
          multiple={false}
          activated={activate}
          onChange={handleImagenChange}
          accept="image/*"
        />
        <CancelButton onClick={handleCancel} disabled={imageLoading} />
        {previewImageFile && <ConfirmButton onClick={handleSubmitImage} />}
      </CardActions>
    </Card>
  );
};

CommonImageSelector.propTypes = {
  afterUpload: PropTypes.func,
  activate: PropTypes.bool,
  onCancel: PropTypes.func
};
