import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';
import { SirePurchasesProvider } from '../contexts/sunat/sire-purchases-context';
import { SireSalesProvider } from '../contexts/sunat/sire-sales-context';

const AccountingDashboard = Loadable(
  lazy(() =>
    import('../containers/accounting/accounting-dashboard').then((module) => ({
      default: module.AccountingDashboard
    }))
  )
);

const FEPendingsFacele = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-pendings-facele').then((module) => ({
      default: module.FEPendingsFacele
    }))
  )
);

const FEPendingsSAP = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-pendings-sap').then((module) => ({
      default: module.FEPendingsSAP
    }))
  )
);

const FESentsFacele = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-sents-facele').then((module) => ({
      default: module.FESentsFacele
    }))
  )
);

const GREAll = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/gre-all').then((module) => ({
      default: module.GREAll
    }))
  )
);

const GREPendings = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/gre-pendings').then((module) => ({
      default: module.GREPendings
    }))
  )
);

const Journal = Loadable(
  lazy(() =>
    import('../containers/accounting/journal/journal').then((module) => ({
      default: module.Journal
    }))
  )
);

const KardexDiscrepancies = Loadable(
  lazy(() =>
    import('../containers/managment/kardex/karedex-discrepancies').then((module) => ({
      default: module.KardexDiscrepancies
    }))
  )
);

/**
 * (START) SUNAT MODULE
 */
const SireSales = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire/sire-sales').then((module) => ({
      default: module.SireSales
    }))
  )
);

const SireSalesRvieCpSummaryTabs = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-cp-summary-tabs').then(
      (module) => ({
        default: module.SireRvieCpSummaryTabs
      })
    )
  )
);

const SireSalesRvieProposal = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-proposal').then((module) => ({
      default: module.SireRvieProposal
    }))
  )
);

const SireSalesRvieInconsistencies = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-inconsistencies').then(
      (module) => ({
        default: module.SireRvieInconsistencies
      })
    )
  )
);

/** cp summary sales */
const SireSalesRvieProposalSummary = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-proposal-summary').then(
      (module) => ({
        default: module.SireRvieProposalSummary
      })
    )
  )
);

const SireSalesRvieCpSummaryNoIncluded = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-cp-summary-no-included').then(
      (module) => ({
        default: module.SireRvieCpSummaryNoIncluded
      })
    )
  )
);

const SireSalesRviePreliminarySummary = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-preliminary-summary').then(
      (module) => ({
        default: module.SireRviePreliminarySummary
      })
    )
  )
);

const SireSalesRvieSummaryGenerated = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-sales/sire-rvie-summary-generated').then(
      (module) => ({
        default: module.SireRvieSummaryGenerated
      })
    )
  )
);
/** cp summary sales */
////////////////////////////////////////////////////////////////////////
const SireRce = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce').then((module) => ({
      default: module.SireRce
    }))
  )
);

const SireRceRecordGeneration = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-record-generation').then(
      (module) => ({
        default: module.SireRceRecordGeneration
      })
    )
  )
);

const SireRceManagementInformation = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-management-information').then(
      (module) => ({
        default: module.SireRceManagementInformation
      })
    )
  )
);

// const SirePurchases = Loadable(
//   lazy(() =>
//     import('../containers/accounting/sunat/sire/sire-purchases').then((module) => ({
//       default: module.SirePurchases
//     }))
//   )
// );

const SirePurchasesRceCpSummaryTabs = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-cp-summary-tabs').then(
      (module) => ({
        default: module.SireRceCpSummaryTabs
      })
    )
  )
);

const SirePurchasesRceProposal = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-proposal').then((module) => ({
      default: module.SireRceProposal
    }))
  )
);

const SireRceComparison = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-comparison').then((module) => ({
      default: module.SireRceComparison
    }))
  )
);

const SirePurchasesInconsistencies = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-inconsistencies').then(
      (module) => ({
        default: module.SireRceInconsistencies
      })
    )
  )
);

/** (start) Comparison between SAP and Rce */
const SireRceComparisonDifferences = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-comparison-differences').then(
      (module) => ({
        default: module.SireRceComparisonDifferences
      })
    )
  )
);

const SireRceComparisonSapPle = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-comparison-sap-ple').then(
      (module) => ({
        default: module.SireRceComparisonSapPle
      })
    )
  )
);

const SireRceComparisonRceProposal = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-comparison-rce-proposal').then(
      (module) => ({
        default: module.SireRceComparisonRceProposal
      })
    )
  )
);

// SireRceForReplace
const SireRceForReplace = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-for-replace').then((module) => ({
      default: module.SireRceForReplace
    }))
  )
);

/** (end) Comparison between SAP and Rce */

/** cp summary purchases */
const SirePurchasesRceProposalSummary = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-proposal-summary').then(
      (module) => ({
        default: module.SireRceProposalSummary
      })
    )
  )
);

const SirePurchasesRceCpSummaryExcluded = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-cp-summary-excluded').then(
      (module) => ({
        default: module.SireRceCpSummaryExcluded
      })
    )
  )
);

const SirePurchasesRceCpPreliminaryRceSummary = Loadable(
  lazy(() =>
    import(
      '../containers/accounting/sunat/sire-purchases/sire-rce-cp-preliminary-rce-summary'
    ).then((module) => ({
      default: module.SireRceCpPreliminaryRceSummary
    }))
  )
);

const SirePurchasesRceSummaryGenerated = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-summary-generated').then(
      (module) => ({
        default: module.SireRceSummaryGenerated
      })
    )
  )
);
/** cp summary purchases */

/**
 * (END) SUNAT MODULE
 */

export const AccountingRoutes = [
  {
    path: 'fe',
    children: [
      {
        path: 'sents-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FESentsFacele />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings-sap',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FEPendingsSAP />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FEPendingsFacele />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'gre',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <GREAll />
          </AllowedGuard>
        )
      },
      {
        path: 'gre-pendings-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <GREPendings />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'journals',
    children: [
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_ACCT_JOURNAL_VIEW}>
            <Journal />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.GCC_ACCT_PROCESS}>
        <AccountingDashboard />
      </AllowedGuard>
    )
  },
  {
    path: 'balance',
    element: (
      <AllowedGuard permission={GadminActions.G2_KARDEX_PROCESS}>
        <KardexDiscrepancies />
      </AllowedGuard>
    )
  },
  {
    path: 'sunat',
    children: [
      {
        path: 'sire-sales',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <SireSalesProvider>
              <SireSales />
            </SireSalesProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: 'cp-summary',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SireSalesRvieCpSummaryTabs />
              </AllowedGuard>
            ),
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireSalesRvieProposalSummary />
                  </AllowedGuard>
                )
              },
              {
                path: 'cp-summary-no-included',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireSalesRvieCpSummaryNoIncluded />
                  </AllowedGuard>
                )
              },
              {
                path: 'preliminary-rvie-summary',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireSalesRviePreliminarySummary />
                  </AllowedGuard>
                )
              },
              {
                path: 'rvie-summary-generated',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireSalesRvieSummaryGenerated />
                  </AllowedGuard>
                )
              }
            ]
          },
          {
            path: 'rvie-proposal',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SireSalesRvieProposal />
              </AllowedGuard>
            )
          },
          {
            path: 'inconsistencies',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SireSalesRvieInconsistencies />
              </AllowedGuard>
            )
          }
        ]
      },
      {
        path: 'rce',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <SirePurchasesProvider>
              <SireRce />
            </SirePurchasesProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: 'record-generation',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SireRceRecordGeneration />
              </AllowedGuard>
            ),
            children: [
              {
                path: 'cp-summary',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SirePurchasesRceCpSummaryTabs />
                  </AllowedGuard>
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                        <SirePurchasesRceProposalSummary />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'cp-summary-excluded',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                        <SirePurchasesRceCpSummaryExcluded />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'cp-preliminary-rce-summary',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                        <SirePurchasesRceCpPreliminaryRceSummary />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'rce-summary-generated',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                        <SirePurchasesRceSummaryGenerated />
                      </AllowedGuard>
                    )
                  }
                ]
              },
              {
                path: 'rce-proposal',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SirePurchasesRceProposal />
                  </AllowedGuard>
                )
              },
              {
                path: 'inconsistencies',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SirePurchasesInconsistencies />
                  </AllowedGuard>
                )
              }
            ]
          },
          {
            path: 'management-information',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SireRceManagementInformation />
              </AllowedGuard>
            )
          },
          {
            path: 'comparison',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SireRceComparison />
              </AllowedGuard>
            ),
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireRceComparisonDifferences />
                  </AllowedGuard>
                )
              },
              {
                path: 'sap-ple',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireRceComparisonSapPle />
                  </AllowedGuard>
                )
              },
              {
                path: 'rce-proposal',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireRceComparisonRceProposal />
                  </AllowedGuard>
                )
              },
              {
                path: 'rce-for-replace',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SireRceForReplace />
                  </AllowedGuard>
                )
              }
            ]
          }
        ]
      }
    ]
  }
];
