export const queueViews = {
  OPEN: 'O',
  CLOSED: 'C',
  CANCELED: 'L',
  ALL: 'all'
};

export const QueuesViews = {
  ALL: 'all'
};

// Queues Tabs Views
export const QueuesTabsViews = [
  {
    label: 'Todos',
    value: QueuesViews.ALL
  }
];
