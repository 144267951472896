import { useEffect, useState } from 'react';
import {
  Card,
  Tabs,
  Tab,
  Box,
  Divider,
  CardHeader,
  CardContent,
  Avatar,
  Typography
} from '@mui/material';
import { PropertyList } from '../../components/property-list';
import { PropertyListItem } from '../../components/property-list-item';
import { useParams } from 'react-router-dom';
import { useData } from '../../hooks/use-data';
import { getWorkflow, syncWorkflow } from '../../api/goal/rindegastos/rindegastos-workflow-api';
import { useMounted } from '../../hooks/use-mounted';
import LoadingContent from '../../components/common/loading-content';
import { useApp } from '../../hooks/use-app';
import BackButton from '../../components/common/buttons/back-button';
import { RefreshButton, SyncButton } from '../../components/common/buttons';
import CustomTabPanel from '../../components/common/custom-tab-panel';

const parentRoute = `/rindegasto/workflows`;

const RindegastoWorkflow = () => {
  const [value, setValue] = useState(0);

  const [data, setData] = useState([]);
  const { currentCompany, showError, showNotify } = useApp();
  const mounted = useMounted();
  // get id from params
  const { id } = useParams();

  const [documentState, handleRefresh] = useData({
    sourceApi: getWorkflow,
    apiParameter: { companyId: currentCompany, id },
    loadingMessage: 'Cargando detalle del flujo de trabajo...',
    mounted,
    defaults: {
      noAutoLoad: true
    },
    afterLoad: (result) => {
      if (result.success) {
        setData(result.data);
      } else {
        showError(result.message);
      }
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSync = async () => {
    try {
      const result = await syncWorkflow({ companyId: currentCompany, id });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (mounted.current) handleRefresh();
  }, [id]);

  if (documentState.error || documentState.isLoading || !documentState.data) {
    return (
      <LoadingContent
        loadingText={documentState.message}
        error={documentState.error}
        state={documentState}
      />
    );
  }

  return (
    <Card sx={{ padding: 2, backgroundColor: 'background.default' }}>
      <CardHeader
        subheader="Detalle del flujo de trabajo"
        title={data.name}
        avatar={<BackButton to={parentRoute} />}
        action={
          <>
            <SyncButton onClick={handleSync} />
            <RefreshButton onClick={handleRefresh} />
          </>
        }
      />
      <CardContent>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="workflow tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Información General" />
          <Tab label="Flujos" />
          <Tab label="Políticas" />
          <Tab label="Usuarios" />
        </Tabs>
        <Divider sx={{ marginBottom: 2 }} />
        <CustomTabPanel value={value} index={0}>
          <Card sx={{ padding: 2 }}>
            <PropertyList>
              <PropertyListItem label="ID" value={data._id} />
              <PropertyListItem label="ID de la Empresa" value={data.companyId} />
              <PropertyListItem label="Nombre" value={data.name} />
              <PropertyListItem label="Código" value={data.code || 'N/A'} />
              <PropertyListItem label="Descripción" value={data.description || 'N/A'} />
              <PropertyListItem label="Está Activo" value={data.isActive ? 'Sí' : 'No'} />
              <PropertyListItem label="Está Integrado" value={data.isIntegrated ? 'Sí' : 'No'} />
              <PropertyListItem
                label="Omitir Aprobadores Anteriores"
                value={data.skipPreviousApprovers ? 'Sí' : 'No'}
              />
            </PropertyList>
          </Card>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {data.flow.map((flowItem) => (
            <Card key={flowItem._id} sx={{ marginBottom: 2, padding: 2 }}>
              <Typography variant="h6">Posición {flowItem.position}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <Avatar src={flowItem.user.picture} alt={flowItem.user.firstname} />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="body1">{`${flowItem.user.firstname} ${flowItem.user.lastname}`}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {flowItem.user.username}
                  </Typography>
                </Box>
              </Box>
              <PropertyList>
                <PropertyListItem
                  label="Puede Aprobar Gastos"
                  value={flowItem.permission.canApproveExpenses ? 'Sí' : 'No'}
                />
                <PropertyListItem
                  label="Puede Editar Gastos"
                  value={flowItem.permission.canEditExpenses ? 'Sí' : 'No'}
                />
                <PropertyListItem
                  label="Puede Editar Reportes"
                  value={flowItem.permission.canEditReports ? 'Sí' : 'No'}
                />
              </PropertyList>
              {flowItem.reviewConditions.length > 0 && (
                <Box sx={{ marginTop: 1 }}>
                  <Typography variant="body2" color="textSecondary">
                    Condiciones de Revisión:
                  </Typography>
                  {flowItem.reviewConditions.map((condition, idx) => (
                    <PropertyList key={idx}>
                      <PropertyListItem label="Moneda" value={condition.currency} />
                      <PropertyListItem label="Valor" value={condition.value} />
                      <PropertyListItem label="Tipo" value={condition.type} />
                    </PropertyList>
                  ))}
                </Box>
              )}
            </Card>
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {data.policies?.map((policy) => (
            <Card key={policy._id} sx={{ marginBottom: 2, padding: 2 }}>
              <Typography variant="h6">{policy.name}</Typography>
              <PropertyList>
                <PropertyListItem label="ID" value={policy._id} />
                <PropertyListItem label="Nombre" value={policy.name} />
                <PropertyListItem label="Descripción" value={policy.description || 'N/A'} />
                <PropertyListItem label="Está Activo" value={policy.isActive ? 'Sí' : 'No'} />
              </PropertyList>
            </Card>
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {data.users?.map((user) => (
            <Card key={user._id} sx={{ marginBottom: 2, padding: 2 }}>
              <Typography variant="h6">{`${user.user?.firstname}  ${user.user?.lastname}`}</Typography>
              <PropertyList>
                <PropertyListItem label="ID" value={user.user?.id || user._id} />
                <PropertyListItem
                  label="Nombre de Usuario"
                  value={`${user.user?.firstname}  ${user.user?.lastname}`}
                />
                <PropertyListItem label="Correo Electrónico" value={user.user?.username} />
                <PropertyListItem label="Teléfono" value={user.user?.cellphone} />
              </PropertyList>
            </Card>
          ))}
        </CustomTabPanel>
      </CardContent>
    </Card>
  );
};

export default RindegastoWorkflow;
