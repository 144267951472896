import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box } from '@mui/material';

export const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            alt="Logo"
            src="/static/logo.svg"
            style={{
              width: 64,
              height: 64
            }}
          />
          <Box sx={{ mt: 2 }}>Loading...</Box>
        </Box>
      </Box>
    </Box>
  );
};
