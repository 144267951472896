import { createContext, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { bulksApi } from '../../api/managment/bulks';
import { useData } from '../../hooks/use-data';
import { BulksViews } from '../../api/managment/bulks-definitions';

const BulkContext = createContext();

const useBulk = () => {
  return useContext(BulkContext);
};

const BulkProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view') || BulksViews.ALL;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: bulksApi.getBulk,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando detalle de procesos masivos...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    bulk: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : '',
    editMode,
    setEditMode
  };

  return <BulkContext.Provider value={value}>{children}</BulkContext.Provider>;
};

BulkProvider.propTypes = { children: PropTypes.node.isRequired };

export { BulkProvider, useBulk };
