import { lazy } from 'react';
import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { Loadable } from './loadable';

const Invoices = Loadable(
  lazy(() =>
    import('../containers/purchases/invoices').then((module) => ({
      default: module.Invoices
    }))
  )
);

const JournalReceiptPdf = Loadable(
  lazy(() =>
    import('../containers/purchases/journal/print-receip-pdft').then((module) => ({
      default: module.JournalReceiptPdf
    }))
  )
);

export const JournalRoutes = [
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.gCC_ACCT_ACCOUNT_DET}>
        <Invoices />
      </AllowedGuard>
    )
  },
  {
    path: ':id',
    element: (
      <AllowedGuard permission={GadminActions.gCC_ACCT_ACCOUNT_DET}>
        <JournalReceiptPdf />
      </AllowedGuard>
    )
  }
];
