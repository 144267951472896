import PropTypes from 'prop-types';
import { useApp } from '../hooks/use-app';
import GoalButton from './goal-button';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import { Link, Tooltip } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GoalListIcon } from './icon/goal-list-icon';

export const useLink = (to, target = '_self') => {
  const isFullPath = (link) => link && (link.startsWith('http') || link.startsWith('data:'));

  const { appUrlBase } = useApp();

  const navigate = useNavigate();

  const handleLink = () => {
    handleLinkTo(to, target);
  };

  const handleLinkTo = (link, _target = '_self') => {
    if (link) {
      if (isFullPath(link)) {
        window.open(link, _target);
      } else {
        navigate(`${appUrlBase}${link}`);
      }
    }
  };

  return { handleLink, linkTo: handleLinkTo };
};

export const GoalRecordLink = (props) => {
  const {
    label = '',
    tooltip = 'Ver detalle',
    to,
    onClick,
    children,
    target,
    variant = 'text',
    editable = false,
    startIcon = <StarIcon fontSize="small" color="secondary" sx={{ fontSize: 10, mr: 0 }} />,
    ...rest
  } = props;
  const { appUrlBase } = useApp();
  const icon = editable ? (
    <EditIcon fontSize="small" color="primary" sx={{ fontSize: 12, mr: 0 }} />
  ) : (
    startIcon
  );
  const isFullPath =
    to &&
    (to.startsWith('http') ||
      to.startsWith('data:') ||
      to.startsWith('mailto:') ||
      to.startsWith('tel:') ||
      to.startsWith(appUrlBase));

  const detailRoute = to ? `${appUrlBase}${to}` : null;

  const openInTarget = target ? target : isFullPath ? '_blank' : '_self';

  if (to && variant === 'text') {
    return (
      <Tooltip title={tooltip}>
        <Link
          component={RouterLink}
          underline="hover"
          to={isFullPath ? to : detailRoute}
          rel="noopener noreferrer"
          target={openInTarget}
          {...rest}
        >
          <GoalListIcon icon={icon} />
          {children ?? label}
        </Link>
      </Tooltip>
    );
  }

  return (
    <GoalButton
      to={isFullPath ? to : detailRoute}
      label={`${tooltip ?? label}`}
      startIcon={icon}
      variant={variant == 'button' ? 'contained' : variant}
      onClick={onClick}
      target={target}
      {...rest}
    >
      {children ?? label}
    </GoalButton>
  );
};

export default GoalRecordLink;

GoalRecordLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltip: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  fullPath: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'filled', 'outlined', 'button']),
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top', 'framename']),
  editable: PropTypes.bool,
  startIcon: PropTypes.node
};
