import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useApp } from '../hooks/use-app';
import { Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { useEffect, useState } from 'react';

const GoalButton = ({
  startIcon,
  onClick,
  label,
  loading: loadingProp = false,
  color = 'inherit',
  variant = 'contained',
  disabled = false,
  children,
  component = 'button',
  to,
  iconOnly = false,
  showText = false,
  type,
  tooltip,
  permission,
  ...rest
}) => {
  const { showHelp } = useApp();
  const { hasPermission } = useAuth();
  const [loading, setLoading] = useState(loadingProp);
  const componentProps = to ? RouterLink : component;
  const { sx } = rest;
  const { appUrlBase } = useApp();

  const isFullPath =
    to &&
    (to.startsWith('http') ||
      to.startsWith('data:') ||
      to.startsWith('mailto:') ||
      to.startsWith('tel:') ||
      to.startsWith(appUrlBase));

  const detailRoute = to ? (isFullPath ? to : `${appUrlBase}${to}`) : null;

  const handleOnClick = async (e) => {
    setLoading(true);
    if (onClick) {
      await onClick(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(loadingProp);
  }, [loadingProp]);

  if (permission && !hasPermission(permission)) return null;
  if (showText)
    return (
      <Tooltip title={tooltip || label}>
        <LoadingButton
          color={color}
          startIcon={startIcon}
          loading={loading}
          size={showHelp ? 'small' : 'large'}
          sx={{ px: 2, py: 2, mx: 0.8, ...sx }}
          variant={variant}
          onClick={handleOnClick}
          disabled={disabled}
          component={componentProps}
          to={detailRoute}
          type={type}
          {...rest}
        >
          {children || label || ''}
        </LoadingButton>
      </Tooltip>
    );

  const button = iconOnly ? (
    <LoadingButton
      color={color}
      startIcon={startIcon}
      loading={loading}
      size={showHelp ? 'small' : 'large'}
      sx={{ px: 2, py: 2, mx: 0.8, ...sx }}
      variant={variant}
      onClick={handleOnClick}
      disabled={disabled}
      component={componentProps}
      to={detailRoute}
      type={type}
      {...rest}
    />
  ) : (
    <LoadingButton
      color={color}
      startIcon={startIcon}
      loading={loading}
      size={showHelp ? 'small' : 'large'}
      sx={{ px: 2, py: 2, mx: 0.8, ...sx }}
      variant={variant}
      onClick={handleOnClick}
      disabled={disabled}
      component={componentProps}
      to={detailRoute}
      type={type}
      {...rest}
    >
      {showHelp || variant == 'text' || children ? (children ?? label) : ''}
    </LoadingButton>
  );
  return !disabled ? <Tooltip title={tooltip || label}>{button}</Tooltip> : button;
};

GoalButton.propTypes = {
  startIcon: PropTypes.element,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  component: PropTypes.elementType,
  to: PropTypes.string,
  iconOnly: PropTypes.bool,
  showText: PropTypes.bool,
  type: PropTypes.string,
  tooltip: PropTypes.string,
  permission: PropTypes.string
};

export default GoalButton;
export { GoalButton };
