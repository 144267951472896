import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import AddIcon from '@mui/icons-material/Add';

export const AddButton = ({ onClick, startIcon = <AddIcon />, tooltip = 'Agregar', ...rest }) => (
  <GoalButton onClick={onClick} startIcon={startIcon} tooltip={tooltip} iconOnly {...rest} />
);

AddButton.propTypes = {
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string
};
