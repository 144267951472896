import { dataLoader } from '../../../data-loader';

const endPointLocal = '/assets/attachment-types';

class AttachmentTypesApi {
  async getAttachmentTypes({ companyId, view = '' }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}`,
      params: { companyId, view }
    });
    return response;
  }

  async getSimpleAttachmentTypes({ companyId }) {
    const response = await dataLoader.goalGet({
      // action: `assets/attachment-types/simple-list`,
      action: `${endPointLocal}`,
      params: { companyId }
    });
    return response;
  }

  async getAttachmentType({ id, companyId, view }) {
    const response = await dataLoader.goalGet({
      action: `${endPointLocal}/${id}`,
      params: { companyId, view }
    });
    return response;
  }

  async createAttachmentType({ values }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endPointLocal}`,
      changes: values
    });
    return response;
  }

  async updateAttachmentType({ id, changes }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `${endPointLocal}/${id}`,
      changes
    });
    return response;
  }

  async deleteAttachmentType({ id, companyId }) {
    const response = await dataLoader.loadGoalDelete({
      action: `${endPointLocal}/${id}?companyId=${companyId}`
    });
    return response;
  }

  async restoreAttachmentType({ id, companyId }) {
    const response = await dataLoader.loadGoalUpdate({
      action: `${endPointLocal}/${id}/restore`,
      changes: { company_id: companyId }
    });
    return response;
  }

  async getAttachmentTypesAutocomplete({ companyId }) {
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/autocomplete?companyId=${companyId}`
    });
    return response;
  }

  async getLinesOfAttachmentType({ id, companyId, view }) {
    const viewParam = view ? `&view=${view}` : ``;
    const response = await dataLoader.loadGoalGetData({
      action: `${endPointLocal}/${id}/lines?companyId=${companyId}${viewParam}`
    });
    return response;
  }
}

export const attachmentTypesApi = new AttachmentTypesApi();
