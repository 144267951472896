import PropTypes from 'prop-types';
import GoalButton from '../../goal-button';
import CheckIcon from '@mui/icons-material/Check';
import { ConfirmationButton } from '../confirmation-button';

export const ConfirmButton = ({
  onClick,
  startIcon = <CheckIcon />,
  tooltip = 'Confirmar',
  requireConfirmation = false,
  title,
  ...rest
}) => {
  if (requireConfirmation) {
    return (
      <ConfirmationButton
        onConfirm={onClick}
        startIcon={startIcon}
        tooltip={tooltip}
        iconOnly
        color="success"
        title={title}
        {...rest}
      />
    );
  }
  return (
    <GoalButton onClick={onClick} startIcon={startIcon} tooltip={tooltip} iconOnly {...rest} />
  );
};

ConfirmButton.propTypes = {
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  tooltip: PropTypes.string,
  requireConfirmation: PropTypes.bool,
  title: PropTypes.string
};
