import PropTypes from 'prop-types';
import { Card, CardHeader, Grid2 } from '@mui/material';
import { PropertyList } from '../../../components/property-list';
import { PropertyListItem } from '../../../components/property-list-item';

const CurrencyTab = ({ data }) => {
  return (
    <Grid2 container spacing={2}>
      {data.currency?.map((currency) => (
        <Grid2
          size={{
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3
          }}
          key={currency.code}
        >
          <Card sx={{ padding: 2 }}>
            <CardHeader title={currency.code} />
            <PropertyList>
              {/* <PropertyListItem label="ID de Moneda" value={currency.id} /> */}
              <PropertyListItem label="Código de Moneda" value={currency.code} />
              <PropertyListItem
                label="Permitir Tasa de Cambio del Usuario"
                value={currency.isAllowUserExchangeRate ? 'Sí' : 'No'}
              />
            </PropertyList>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );
};

CurrencyTab.propTypes = {
  data: PropTypes.object.isRequired
};

export default CurrencyTab;
