import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';

import { SurveyCreate } from '../containers/survey/questionnaire/survey-create';
import SurveyList from '../containers/survey/questionnaire/survey-list';
import { SurveyTab } from '../containers/survey/survey/survey-tabs';
import { SurveyDetailContainer } from '../containers/survey/survey/survey-detail-container';
import { SurveyProvider } from '../contexts/surveys/survey-context';
import { SurveyResultProvider } from '../contexts/surveys/survey-result-context';

const SurveyContainer = Loadable(
  lazy(() =>
    import('../containers/survey/survey/survey-container').then((module) => ({
      default: module.SurveyContainer
    }))
  )
);

const SurveyAssignments = Loadable(
  lazy(() =>
    import('../containers/survey/survey/survey-assignments').then((module) => ({
      default: module.SurveyAssignments
    }))
  )
);

const SurveyResults = Loadable(
  lazy(() =>
    import('../containers/survey/survey/survey-results').then((module) => ({
      default: module.SurveyResults
    }))
  )
);

const SurveyResultTabs = Loadable(
  lazy(() =>
    import('../containers/survey/survey/survey-result-tabs').then((module) => ({
      default: module.SurveyResultTabs
    }))
  )
);

const SurveyResult = Loadable(
  lazy(() =>
    import('../containers/survey/survey/survey-result').then((module) => ({
      default: module.SurveyResult
    }))
  )
);

const SurveyResultLines = Loadable(
  lazy(() =>
    import('../containers/survey/survey/survey-result-lines').then((module) => ({
      default: module.SurveyResultLines
    }))
  )
);

export const SurveyRoutes = [
  {
    path: 'create',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <SurveyCreate />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'questionnaire',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_LIST}>
            <SurveyList />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'survey',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
            <SurveyContainer />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
            <SurveyProvider>
              <SurveyTab />
            </SurveyProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                <SurveyDetailContainer />
              </AllowedGuard>
            )
          },
          {
            path: 'assignments',
            element: (
              <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                <SurveyAssignments />
              </AllowedGuard>
            )
          },
          {
            path: 'results',
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                    <SurveyResults />
                  </AllowedGuard>
                )
              },
              {
                path: ':resultId',
                element: (
                  <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
                    <SurveyResultProvider>
                      <SurveyResultTabs />
                    </SurveyResultProvider>
                  </AllowedGuard>
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                        <SurveyResult />
                      </AllowedGuard>
                    )
                  },
                  {
                    path: 'lines',
                    element: (
                      <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                        <SurveyResultLines />
                      </AllowedGuard>
                    )
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
