import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Grid2,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Button,
  Card,
  CardContent,
  CardActions
} from '@mui/material';
import { RindeGastoFieldTypesEnum } from '../core/rindegasto-definitions';
import { InputField } from '../../input-field';
import { useFormChecker } from '../../../hooks/use-form-checker';
import StartIcon from '@mui/icons-material/Star';
import { DeleteButton } from '../../common/buttons/delete-button';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { GMFormContainer, useGMForm } from '../../gm/form/gm-form-context';
import {
  addExpenseField,
  updateExpenseField
} from '../../../api/goal/rindegastos/rindegasto-policy-template-api';

const EditorContent = () => {
  const { formik } = useGMForm();
  const [selectedOption, setSelectedOption] = useState('');
  const [newItem, setNewItem] = useState({ code: '', value: '' });
  const [showAddNewItem, setShowAddNewItem] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const [checkForm] = useFormChecker({ formik, setSaving: formik.setSubmitting });

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRemoveItem = (code) => {
    const updatedOptions = formik.values.options.filter((option) => option.code !== code);
    formik.setFieldValue('options', updatedOptions);
    if (formik.values.defaultCode === code) {
      formik.setFieldValue('defaultCode', '');
      formik.setFieldValue('defaultValue', '');
    }
  };

  const handleSetDefault = (code) => {
    const updatedOptions = formik.values.options.map((option) => {
      if (option.code === code) {
        return { ...option, byDefault: true };
      }
      return { ...option, byDefault: false };
    });
    formik.setFieldValue('options', updatedOptions);
    const defaultOption = formik.values.options.find((option) => option.code === code);
    formik.setFieldValue('defaultCode', defaultOption.code);
    formik.setFieldValue('defaultValue', defaultOption.value);
  };

  const handleAddNewItem = () => {
    if (newItem.code && newItem.value) {
      const updatedOptions = editItem
        ? formik.values.options.map((option) => (option.code === editItem.code ? newItem : option))
        : [...formik.values.options, newItem];
      formik.setFieldValue('options', updatedOptions);
      setNewItem({ code: '', value: '' });
      setShowAddNewItem(false);
      setEditItem(null);
    }
  };

  const handleEditItem = (item) => {
    setNewItem(item);
    setShowAddNewItem(true);
    setEditItem(item);
  };

  const handleRenderField = (field, formik) => {
    const { type, name, options = [] } = field;
    if (type === RindeGastoFieldTypesEnum.DROPDOWN) {
      return (
        <>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {options.map((option) => (
                <ListItem key={`${option.code}-${option.value}`} disablePadding>
                  <ListItemIcon>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={option.byDefault ? () => {} : () => handleSetDefault(option.code)}
                    >
                      {option.byDefault ? <StartIcon /> : <CheckIcon />}
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={option.code} secondary={option.value} />
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditItem(option)}>
                    <EditIcon />
                  </IconButton>
                  <DeleteButton onConfirm={() => handleRemoveItem(option.code)} variant="text" />
                </ListItem>
              ))}
            </List>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6 }}>
            <InputField
              error={Boolean(formik.touched.defaultCode && formik.errors.defaultCode)}
              helperText={formik.touched.defaultCode && formik.errors.defaultCode}
              name="defaultCode"
              type="string"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue('defaultCode', e.target.value || '');
              }}
              value={formik.values.defaultCode}
              label="Código por defecto"
              fullWidth
              disabled
            />
            <InputField
              error={Boolean(formik.touched.defaultValue && formik.errors.defaultValue)}
              helperText={formik.touched.defaultValue && formik.errors.defaultValue}
              name="defaultValue"
              type="string"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue('defaultValue', e.target.value || '');
              }}
              value={formik.values.defaultValue}
              label="Valor por defecto"
              fullWidth
              disabled
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setShowAddNewItem(!showAddNewItem);
                setEditItem(null);
                setNewItem({ code: '', value: '' });
              }}
            >
              {showAddNewItem ? 'Cancelar' : 'Añadir Nuevo Item'}
            </Button>
            {showAddNewItem && (
              <Card sx={{ marginTop: 2 }}>
                <CardContent>
                  <InputField
                    name="newItemCode"
                    type="string"
                    onChange={(e) => setNewItem({ ...newItem, code: e.target.value })}
                    value={newItem.code}
                    label="Nuevo Código"
                    fullWidth
                  />
                  <InputField
                    name="newItemValue"
                    type="string"
                    onChange={(e) => setNewItem({ ...newItem, value: e.target.value })}
                    value={newItem.value}
                    label="Nuevo Valor"
                    fullWidth
                  />
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewItem}
                    disabled={!newItem.code || !newItem.value}
                  >
                    {editItem ? 'Guardar Cambios' : 'Añadir'}
                  </Button>
                </CardActions>
              </Card>
            )}
          </Grid2>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth>
            <InputLabel id="type">Tipo</InputLabel>
            <Select
              labelId="type"
              id="type"
              value={formik.values.type}
              onChange={(e) => {
                formik.setFieldValue('type', e.target.value);
              }}
            >
              <MenuItem value={RindeGastoFieldTypesEnum.TEXT}>Texto</MenuItem>
              <MenuItem value={RindeGastoFieldTypesEnum.DROPDOWN}>Desplegable</MenuItem>
              <MenuItem value={RindeGastoFieldTypesEnum.DATE}>Fecha</MenuItem>
              <MenuItem value={RindeGastoFieldTypesEnum.NUMBER}>Número</MenuItem>
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 4 }}>
          <InputField
            error={Boolean(formik.touched.index && formik.errors.index)}
            helperText={formik.touched.index && formik.errors.index}
            name="index"
            type="number"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue('index', e.target.value || '');
            }}
            value={formik.values.index}
            label="Posición"
            fullWidth
            disabled
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={!formik.values.isHiddenField}
                onChange={(e) => {
                  console.log('e.target.checked', e.target.checked);
                  formik.setFieldValue('isHiddenField', !e.target.checked);
                }}
                name="isHiddenField"
                color="primary"
              />
            }
            label={formik.values.isHiddenField ? 'Oculto' : 'Visible'}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <InputField
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            name="name"
            type="string"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue('name', e.target.value || '');
            }}
            value={formik.values.name}
            label="Nombre"
            fullWidth
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 6 }}>
          <InputField
            error={Boolean(formik.touched.instructions && formik.errors.instructions)}
            helperText={formik.touched.instructions && formik.errors.instructions}
            name="instructions"
            type="string"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.setFieldValue('instructions', e.target.value || '');
            }}
            value={formik.values.instructions}
            label="Instrucciones"
            fullWidth
            multiline
            rows={4}
          />
        </Grid2>

        {handleRenderField(formik.values, formik)}
      </Grid2>
    </>
  );
};

const PolicyTemplateExpenseFieldEditor = ({ data, isNew = false, onAfterSave }) => {
  return (
    <GMFormContainer
      initialData={{
        id: data?.id || '',
        name: data?.name || '',
        instructions: data?.instructions || '',
        index: data?.index || 0,
        isHiddenField: data?.isHiddenField || false,
        isMandatory: data?.isMandatory || false,
        defaultCode: data?.defaultCode || '',
        defaultValue: data?.defaultValue || '',
        options: data?.options || [],
        type: data?.type || RindeGastoFieldTypesEnum.TEXT
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Requerido'),
        instructions: Yup.string(),
        index: Yup.number().required('Requerido'),
        defaultCode: Yup.string(),
        defaultValue: Yup.string(),
        options: Yup.array()
          .when('type', (argType, schema) => {
            if (argType === RindeGastoFieldTypesEnum.DROPDOWN) {
              return schema.min(1, 'Debe tener al menos un item');
            }
            return schema;
          })
          .of(
            Yup.object().shape({
              code: Yup.string().required('Requerido'),
              value: Yup.string().required('Requerido')
            })
          )
          .nullable()
          .default([])
          .transform((value) => (value === null ? [] : value)),

        type: Yup.string().required('Requerido')
      })}
      onAfterSave={onAfterSave}
      isNew={isNew}
      createApi={addExpenseField}
      updateApi={updateExpenseField}
    >
      <EditorContent />
    </GMFormContainer>
  );
};

PolicyTemplateExpenseFieldEditor.propTypes = {
  data: PropTypes.object,
  isNew: PropTypes.bool,
  onAfterSave: PropTypes.func
};

export default PolicyTemplateExpenseFieldEditor;
