import PropTypes from 'prop-types';
import { useApp } from '../../hooks/use-app';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent, CardHeader } from '@mui/material';

export const GoalContainer = ({
  subheader,
  avatar,
  actions,
  title: titleProps = '-',
  children,
  hideTitle = false
}) => {
  const { title } = useApp();
  return (
    <>
      <Helmet>
        <title>{title(titleProps)} </title>
      </Helmet>
      <Card>
        {!hideTitle && (
          <CardHeader title={titleProps} action={actions} avatar={avatar} subheader={subheader} />
        )}
        <CardContent>{children}</CardContent>
      </Card>
    </>
  );
};

GoalContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.node,
  avatar: PropTypes.node,
  subheader: PropTypes.node,
  hideTitle: PropTypes.bool
};
