import { createContext, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { useData } from '../../hooks/use-data';
import { syncObjectsApi } from '../../api/goal/automations/sync-objects-api';
import { defaultViews } from '../../api/app-definitions';

const SyncObjectContext = createContext();

const useSyncObject = () => {
  return useContext(SyncObjectContext);
};

const SyncObjectProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view') || defaultViews.ALL;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: syncObjectsApi.getSyncObject,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando sincronización...',
    mounted
  });

  const value = {
    view: view,
    loading: dataState.isLoading,
    syncObject: dataState.data ?? {},
    handleRefresh,
    error: dataState.displayError ? dataState.message : false,
    message: dataState.message,
    editMode,
    setEditMode
  };

  return <SyncObjectContext.Provider value={value}>{children}</SyncObjectContext.Provider>;
};

SyncObjectProvider.propTypes = { children: PropTypes.node.isRequired };

export { SyncObjectProvider, useSyncObject };
