import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationMenuItem } from '../confirmation-menu-item';

export const DeleteMenuItem = ({
  onConfirm,
  children,
  label = 'Eliminar',
  startIcon = <DeleteIcon />,
  confirmationMessage = 'Eliminar',
  ...rest
}) => {
  return (
    <ConfirmationMenuItem
      onConfirm={onConfirm}
      startIcon={startIcon}
      label={label}
      confirmationMessage={confirmationMessage}
      {...rest}
    >
      {children || label}
    </ConfirmationMenuItem>
  );
};

DeleteMenuItem.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
  startIcon: PropTypes.node,
  onCancel: PropTypes.func,
  confirmationMessage: PropTypes.string
};
