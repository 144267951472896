import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Typography, Box } from '@mui/material';

export const NumberColor = ({
  value = 0,
  minValue = 0,
  minColor = 'error.main',
  color = 'inherit',
  format = '0.00',
  upper = false,
  prefix,
  align = 'right',
  variant = 'body2',
  withoutSpace = false,
  fontSize = 'inherit',
  fontWeight = 'normal',
  margin = 1.75,
  withoutStyle = false,
  ...rest
}) => {
  const numericValue = numeral(value || 0);
  const [currentColor, setCurrentColor] = useState(color);

  useEffect(() => {
    if (upper) {
      setCurrentColor(value > minValue ? minColor : color);
    } else {
      setCurrentColor(value <= minValue ? minColor : color);
    }
  }, [value]);
  const body = (
    <>
      {' '}
      {prefix}
      {withoutSpace ? '' : ' '}
      {numericValue.format(format)}
    </>
  );

  if (withoutStyle) {
    return body;
  }
  return (
    <Typography
      sx={{
        color: currentColor,
        fontSize: fontSize,
        fontWeight: fontWeight
      }}
      variant={variant}
      align={align}
      {...rest}
    >
      {body}
    </Typography>
  );
};

NumberColor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValue: PropTypes.number,
  minColor: PropTypes.string,
  color: PropTypes.string,
  format: PropTypes.string,
  upper: PropTypes.bool,
  prefix: PropTypes.any,
  align: PropTypes.string,
  variant: PropTypes.string,
  withoutSpace: PropTypes.bool,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  margin: PropTypes.number,
  withoutStyle: PropTypes.bool
};
