import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';

const AccountingDashboard = Loadable(
  lazy(() =>
    import('../containers/accounting/accounting-dashboard').then((module) => ({
      default: module.AccountingDashboard
    }))
  )
);

const FEPendingsFacele = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-pendings-facele').then((module) => ({
      default: module.FEPendingsFacele
    }))
  )
);

const FEPendingsSAP = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-pendings-sap').then((module) => ({
      default: module.FEPendingsSAP
    }))
  )
);

const FESentsFacele = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-sents-facele').then((module) => ({
      default: module.FESentsFacele
    }))
  )
);

const GREAll = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/gre-all').then((module) => ({
      default: module.GREAll
    }))
  )
);

const GREPendings = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/gre-pendings').then((module) => ({
      default: module.GREPendings
    }))
  )
);

const Journal = Loadable(
  lazy(() =>
    import('../containers/accounting/journal/journal').then((module) => ({
      default: module.Journal
    }))
  )
);

const KardexDiscrepancies = Loadable(
  lazy(() =>
    import('../containers/managment/kardex/karedex-discrepancies').then((module) => ({
      default: module.KardexDiscrepancies
    }))
  )
);

export const AccountingRoutes = [
  {
    path: 'fe',
    children: [
      {
        path: 'sents-facele',
        element: (
          <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
            <FESentsFacele />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings-sap',
        element: (
          <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
            <FEPendingsSAP />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings-facele',
        element: (
          <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
            <FEPendingsFacele />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'gre',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
            <GREAll />
          </AllowedGuard>
        )
      },
      {
        path: 'gre-pendings-facele',
        element: (
          <AllowedGuard permission={GadminActions.gCC_FACELE_SEND}>
            <GREPendings />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'journals',
    children: [
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.gCC_ACCT_JOURNAL_VIEW}>
            <Journal />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.gCC_ACCT_PROCESS}>
        <AccountingDashboard />
      </AllowedGuard>
    )
  },
  {
    path: 'balance',
    element: (
      <AllowedGuard permission={GadminActions.G2_KARDEX_PROCESS}>
        <KardexDiscrepancies />
      </AllowedGuard>
    )
  }
];
