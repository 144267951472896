import PropTypes from 'prop-types';
import { useGridApiContext } from '@mui/x-data-grid';
import { GoalMenu } from '../../common/goal-menu';
import GoalMenuItem from '../../common/goal-menu-item';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useApp } from '../../../hooks/use-app';
import { listAvailableTemplates } from '../../../api/goal/rindegastos/rindegasto-expense-unit-api';
import GradingIcon from '@mui/icons-material/Grading';
import { GoalMenuDialog } from '../../menu/goal-menu-dialog';
import CommonAttributeSelector from '../../common/buttons/common-attribute-selector';
export const RindegastoExpenseUnitsBulkActionMenu = ({
  onAfterSave,
  selectedRows,
  selectedCount = 0
}) => {
  const apiRef = useGridApiContext();
  const { currentCompany } = useApp();

  const handleAdd = (selected) => {
    console.log('selectedRows', selectedRows);
    console.log('selected', selected);
  };
  return (
    <GoalMenu label="Acciones" startIcon={<KeyboardArrowDownIcon />}>
      <GoalMenuItem onClick={() => console.log('selectedRows', apiRef.current.getSelectedRows())}>
        Sincronizar {selectedCount} unidades de gasto
      </GoalMenuItem>

      <CommonAttributeSelector
        onAfterSave={onAfterSave}
        slot={{
          component: GoalMenuDialog
        }}
        slotProps={{
          content: {
            sourceApi: listAvailableTemplates,
            apiParameters: { companyId: currentCompany, id: 0 },
            loadingMessage: 'Cargando...',
            label: 'Seleccionar plantilla',
            onSelect: handleAdd
            // getOptionDisabled: (option) => accountingAccounts?.includes(option.code)
          }
        }}
      />
    </GoalMenu>
  );
};

RindegastoExpenseUnitsBulkActionMenu.propTypes = {
  selectedRows: PropTypes.array,
  selectedCount: PropTypes.number,
  onAfterSave: PropTypes.func
};
