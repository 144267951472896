import { useCallback, useEffect, useState } from 'react';
import LoadingContent from '../components/common/loading-content';
import { Box, Skeleton } from '@mui/material';

export const useDataBrowser = ({
  sourceApi,
  loadingMessage,
  mounted,
  onPostRefresh,
  defaults,
  filterRow
}) => {
  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });
  const [controller, setController] = useState({
    filters: [],
    page: 0,
    searchText: '',
    query: '',
    sort: 'desc',
    sortBy: 'ItemName',
    view: 'all',
    reload: true,
    refresh: true,
    ...defaults
  });

  const getDocument = useCallback(async () => {
    if (controller.refresh) {
      setDocumentState((prevState) => ({
        ...prevState,
        isLoading: true,
        message: loadingMessage,
        displayLoading: true,
        displayError: false,
        displayUnavailable: false
      }));
      try {
        const {
          filters,
          page,
          query,
          sort,
          sortBy,
          view,
          reload,
          apiData,
          searchText,
          apiResponse,
          mainFilter,
          id,
          companyId = 0,
          idsToExclude = ''
        } = controller;

        const result = await sourceApi({
          filters,
          page,
          query,
          sort,
          sortBy,
          view,
          reload,
          apiData,
          searchText,
          apiResponse,
          mainFilter,
          id,
          companyId,
          idsToExclude,
          filterRow
        });

        if (mounted.current) {
          setDocumentState((prevState) => ({
            ...prevState,
            ...result,
            isLoading: false,
            reload: false,
            refresh: false,
            initialLoad: true,
            displayLoading: false,
            displayError: !result.success,
            displayUnavailable: result.success && !result.documents?.length
          }));

          if (reload) {
            controller.apiData = result.apiData;
            controller.apiResponse = result.apiResponse;
          }

          onPostRefresh?.(result);
        }
      } catch (err) {
        console.error('useDataError', err);
        if (mounted.current) {
          setDocumentState((prevState) => ({
            ...prevState,
            isLoading: false,
            displayError: true
          }));
        }
      }
      controller.refresh = false;
    }
  }, [controller, mounted, sourceApi, loadingMessage]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [getDocument]);

  const handleRefresh = () => {
    setController({
      ...controller,
      filters: [],
      page: 0,
      query: '',
      sort: 'desc',
      sortBy: 'ItemName',
      view: defaults?.view || 'all',
      reload: true,
      refresh: true
    });
  };

  const handleViewChange = (newView) => {
    setController({
      ...controller,
      page: 0,
      view: newView,
      reload: true,
      refresh: true
    });
  };
  const handleQueryChange = (newQuery) => {
    if (documentState.initialLoad) {
      setController({
        ...controller,
        page: 0,
        query: newQuery,
        reload: false,
        refresh: true
      });
    }
  };
  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value
    }));

    setController({
      ...controller,
      page: 0,
      filters: parsedFilters,
      reload: false,
      refresh: true
    });
  };
  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: [],
      reload: false,
      refresh: true
    });
  };
  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
      reload: false,
      refresh: true
    });
  };

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
      reload: false,
      refresh: true
    });
  };
  const handleSetController = (options) => {
    setController({
      ...controller,
      ...options,
      refresh: true
    });
  };

  const setMainFilter = (newFilter) => {
    handleSetController({ mainFilter: { ...controller.mainFilter, ...newFilter }, reload: true });
    // handleSetController({ mainFilter: newFilter });
  };

  const setSearchText = (newSearchText) => {
    handleSetController({ searchText: newSearchText, reload: true });
  };

  const onControllerChange = {
    setMainFilter,
    setSearchText
  };

  const renderStatus = () => {
    const { isLoading, error, documents } = documentState;
    const displayLoading = isLoading;
    const displayError = Boolean(!isLoading && error);
    const displayUnavailable = Boolean(!isLoading && !error && !documents?.length);

    if (displayLoading) {
      return (
        <LoadingContent loadingText={loadingMessage}>
          <Box sx={{ p: 2 }}>
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </Box>
        </LoadingContent>
      );
    }
    if (displayError) {
      return (
        <LoadingContent
          loadingText={documentState.message}
          error={documentState.error}
          state={documentState}
        />
      );
    }
    if (displayUnavailable) {
      return (
        <LoadingContent
          loadingText={documentState.message}
          error={documentState.error}
          state={documentState}
        />
      );
    }

    return null;
  };

  const isReady = !documentState.isLoading;
  const isEmpty = (documentState.documents?.length || 0) === 0;

  return [
    documentState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    onControllerChange,
    {
      renderStatus,
      isReady,
      isEmpty
    }
  ];
};
