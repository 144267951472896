import { Box, Divider, Tab, Tabs } from '@mui/material';

import { GoalContainer } from '../../../components/common/goal-container';
import { useDeliveryPlanning } from '../../../components/delivery/planning/delivery-planing-context';
import { RefreshButton } from '../../../components/common/buttons/refresh-button';
import { DeliveryPlaningMenu } from '../../../components/delivery/planning/delivery-planing-menu';
import CommonRecordEditor from '../../../components/common/buttons/record-editor-container.js';
import { DeliveryPlaningFilter } from '../../../components/delivery/planning/delivery-planing-filter.js';
import FilterIcon from '@mui/icons-material/FilterList';
import { FilterButton } from '../../../components/common/buttons/filter-button.js';
import { appFormats } from '../../../api/app-definitions.js';
import { formatDate } from 'date-fns';
import CustomTabPanel from '../../../components/common/custom-tab-panel.js';
import { DeliveryPlaningPendingOrders } from '../../../components/delivery/planning/tabs/delivery-planning-pending-orders.js';
import { DeliveryPlaningSummary } from '../../../components/delivery/planning/tabs/delivery-planning-summary.js';
import { DeliveryPlaningNotAssignedOrders } from '../../../components/delivery/planning/tabs/delivery-planning-not-assigned-orders.js';
import { DeliveryPlaningDrivers } from '../../../components/delivery/planning/tabs/delivery-planning-drivers.js';
import { DeliveryPlaningVehicles } from '../../../components/delivery/planning/tabs/delivery-planning-vehicles.js';
import { DeliveryPlaningPlansForDate } from '../../../components/delivery/planning/tabs/delivery-planning-plans-for-date.js';

export const DeliveryPlanning = () => {
  const { currentProfile, handleRefresh, planningDate, filters, selectedTab, handleSelectedTab } =
    useDeliveryPlanning();

  return (
    <>
      <GoalContainer
        title={`Planificación de entregas - ${currentProfile?.name}`}
        subheader={`Fecha de planificación: ${formatDate(planningDate, appFormats.frontDateFormatString)}`}
        actions={
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <CommonRecordEditor
                onAfterSave={() => console.log('After Save')}
                slot={{
                  content: DeliveryPlaningFilter,
                  component: FilterButton
                }}
                slotProps={{
                  content: {
                    filters
                  },
                  component: {
                    label: 'Filtrar'
                  },
                  dialog: {
                    title: 'Filtrar'
                  }
                }}
                startIcon={<FilterIcon />}
              />
              <RefreshButton onClick={handleRefresh} />
              <DeliveryPlaningMenu />
            </Box>
          </>
        }
      >
        {currentProfile && (
          <>
            <Tabs
              value={selectedTab}
              onChange={handleSelectedTab}
              aria-label="info tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{ marginBottom: 2 }}
            >
              <Tab label="Información General" />
              <Tab label="Pendientes de carga" />
              <Tab label="Cargados" />
              <Tab label="Rutas generadas" />
              <Tab label="Choferes" />
              <Tab label="Vehículos" />
            </Tabs>
            <Divider sx={{ marginBottom: 2 }} />
            <CustomTabPanel value={selectedTab} index={0}>
              <DeliveryPlaningSummary />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={1}>
              <DeliveryPlaningPendingOrders />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={2}>
              <DeliveryPlaningNotAssignedOrders />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={3}>
              <DeliveryPlaningPlansForDate />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={4}>
              <DeliveryPlaningDrivers />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={5}>
              <DeliveryPlaningVehicles />
            </CustomTabPanel>
          </>
        )}
      </GoalContainer>
    </>
  );
};
